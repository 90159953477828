import React from "react";
import Comentario from "./Comentario";

import { Box, Divider, Typography } from "@mui/material";

const ComentariosList = ({ comentarios }) => {
  return (
    <Box sx={{ maxHeight: 250, overflowY: "auto" }}>
      {comentarios?.map((comentario) => (
        <>
          <Comentario key={comentario.id} comentario={comentario} />
          <Divider />
        </>
      ))}
    </Box>
  );
};

export default ComentariosList;
