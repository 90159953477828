import React, { useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Decimals from "../../support/Decimals";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DetalleHorasMobile = ({ open, handleClose, registroHoras }) => {
  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Detalle de Horas
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 4 }}>
        {/* Aquí puedes colocar el contenido del dialog, por ejemplo: */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Horas"
              fullWidth
              size="small"
              disabled
              value={Decimals.format(registroHoras?._value) || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descripción de Tareas"
              fullWidth
              size="small"
              multiline
              disabled
              value={registroHoras?._hoursDescription || ""}
            />
          </Grid>
          {/* Añade más campos según sea necesario */}
        </Grid>
      </Box>
    </Dialog>
  );
};
