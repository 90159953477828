import { ethers } from "ethers";
import * as Common from "../support/Common";
import * as Constants from "./Constants";
import { getPerson, getPersonByMemberId } from "../services/PersonsServices";
import {
  getCompanyByMemberId,
  getCompany,
} from "../services/CompaniesServices";
import { handleRequestError } from "./ApiUtils";
import { showErrorMessage } from "../components/ui/toast";
import { Web3Auth } from "@web3auth/node-sdk";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { jwtDecode } from "jwt-decode";
import { defineChain } from "viem";
import { LocalAccountSigner } from "@alchemy/aa-core";
import { createLightAccountAlchemyClient } from "@alchemy/aa-alchemy";

////////////////////////////////////////////////////////////////////////////

// Provider de acceso a la blackchain. En este caso, usamos un inyected provider, con metamask
let WEB3_PROVIDER = null;
export let CURRENT_NETWORK_CHAIN_ID = null;
let NETWORK_TX_CONFIRMATIONS = process.env.REACT_APP_NETWORK_TX_CONFIRMATIONS;
const ACCOUNT_CONFIG = process.env.REACT_APP_WEB3_ACCOUNTS;
export const useMetamask = ACCOUNT_CONFIG?.toLowerCase().trim() === "metamask";

// alchemy-related infra variables
const baseRpcUrl = process.env.REACT_APP_WEB3_ALCHEMY_RPC_URL;
const alchemyApiKey = process.env.REACT_APP_WEB3_ALCHEMY_API_KEY;
const rpcProvider = baseRpcUrl + "/" + alchemyApiKey;
let abstractionProvider;

let WAIT_SYNC_DATABASE_TRANSACTION =
  process.env.REACT_APP_WAIT_SYNC_DATABASE_TRANSACTION;

// user addresses
let signer;
let smartAddress;

console.log("NETWORK_TX_CONFIRMATIONS " + NETWORK_TX_CONFIRMATIONS);
// Transacciones que han ocurrido en la sesion actual del usaurio. En caso de desconexion, se pierden
export const SESSION_TRANSACTIONS = {};

// Cantidad de confirmaciones que vamos a solicitar a las transacciones para determinar si estan finalizadas
// CUIDADO con esto, porque si la blockchain (ej. Ganache) esta configurada para finalizar la TX con 1 confirmacion, si tenemos
// mas, no nos enteramos cuando finaliza
// Update: Ahora se trae del .env, se usa 1 para local (ganache), 5 para mumbai y para prod se recomienda 14
export const TX_CONFIRMATIONS = NETWORK_TX_CONFIRMATIONS;

// Datos del socio con el que nos conectamos, junto con los datos basicos de metamask que necesitamos
export const MEMBER_DATA = {
  // Direccion del miembro, coincide con la direccion de la cuenta Metamask seleccionada
  memberAddress: null,

  memberCode: null,

  memberId: null,

  // Nombre descriptivo del miembro
  memberName: null,

  // Tipo de miembro, puede ser persona, empresa, o sin registro
  memberType: Constants.MEMBER_TYPE_SIN_REGISTRO,

  // Estado del miembro
  memberStatus: Constants.MEMBER_STATUS_ANY,

  // Roles del miembro
  memberRoles: [],

  // lo usamos para mostrar un error si se ingresa un id en la url que no corresponde al socio
  memberError: null,
};

////////////////////////////////////////////////////////////////////////////
// Contract objects

// Contract names that we are going to use in the frontend
const contractNames = [
  "AccessControl",
  "Parameters",
  "Principles",
  "Commitments",
  "Persons",
  "Companies",
  "Applications",
  "HoursRegister",
  "Proposals",
  "Ballots",
  "Collaborations",
  "Reports",
  "MemberNotifications",
];

// Contract definitions
let CONTRACT_DEFINITIONS = {};

// Proxies a los contratos, inicializados con la cuenta seleccionada en Metamask
// Usamos una variable por contrato, porque la interaccion es mas sencilla
export let CONTRACT_ACCESS_CONTROL = null;
export let CONTRACT_PARAMETERS = null;
export let CONTRACT_PRINCIPLES = null;
export let CONTRACT_COMMITMENTS = null;
export let CONTRACT_PERSONS = null;
export let CONTRACT_COMPANIES = null;
export let CONTRACT_APPLICATIONS = null;
export let CONTRACT_HOURSREGISTER = null;
export let CONTRACT_PROPOSALS = null;
export let CONTRACT_BALLOTS = null;
export let CONTRACT_COLLABORATIONS = null;
export let CONTRACT_REPORTS = null;
export let CONTRACT_MEMBER_NOTIFICATIONS = null;

////////////////////////////////////////////////////////////////////////////

function isMetamaskBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("metamask");
}

////////////////////////////////////////////////////////////////////////////

// General Configuration

export async function configure() {
  // Configuramos el provider de acceso a la blockchain

  if (useMetamask) {
    if (window.ethereum) {
      WEB3_PROVIDER = new ethers.providers.Web3Provider(window.ethereum, "any");
    } else {
      if (!isMetamaskBrowser()) {
        alert(
          "No se ha detectado una instalación de Metamask. Por favor, instale Metamask y vuelva a intentarlo."
        );
        window.location = "https://metamask.io/";
        return;
      }
    }
  } else {
    WEB3_PROVIDER = new ethers.providers.JsonRpcProvider(rpcProvider);
  }

  const { chainId } = await WEB3_PROVIDER.getNetwork();

  console.log("web3 provider", chainId);

  CURRENT_NETWORK_CHAIN_ID = chainId;

  // Configuramos listener para detectar cambios de cadena y de cuenta
  // Debido a que ethers.io es de mas alto nivel que ethereum, no tiene abstracciones correctas para los eventos
  // que nos interesa escuchar. En el caso de cambio de red, si, podemos usar el provider. Pero para los demas no

  if (useMetamask) {
    WEB3_PROVIDER.on("network", networkChangedListener);
    window.ethereum.on("accountsChanged", accountsChangedListener);
  }
}

////////////////////////////////////////////////////////////////////////////
// Listener por cambios en cuentas y cadenas

function networkChangedListener(newNetwork, oldNetwork) {
  console.log("networkChangedListener", newNetwork, oldNetwork);
  const idSocio = new URLSearchParams(window.location.search).get("user_id");
  // When a Provider makes its initial connection, it emits a "network"
  // event with a null oldNetwork along with the newNetwork. So, if the
  // oldNetwork exists, it represents a changing network

  // Si oldNetwork viene con algun dato, entonces significa que estamos cambiando de red.
  // La forma correcta de asegurarnos que tenemos consistencia, es hacer un reload de la aplicacion
  if (oldNetwork) {
    Common.reloadRoot(idSocio);
    return;
  }

  // En este caso usamos el chainId para determinar las redes soportadas

  if (!newNetwork) {
    alert(
      "No se detecta una red valida selecciona en Metamask. Por favor, cambie a una red soportada por la aplicacion."
    );
    return;
  }

  // Nos quedamos con el chainId de la red actual
  CURRENT_NETWORK_CHAIN_ID = String(newNetwork.chainId);

  if (
    String(newNetwork.chainId) !==
    String(process.env.REACT_APP_SUPPORTED_CHAIN_ID)
  ) {
    alert(
      "La red seleccionada en Metamask no está soportada. Por favor, cambie a una red soportada por la aplicacion (" +
        Constants.getNombreRed(process.env.REACT_APP_SUPPORTED_CHAIN_ID) +
        ")."
    );
    return;
  }

  // En este punto, estamos en una red, la cual podemos soportar o no. Segun el chainId podemos decidir
  console.log("Current network chainId", CURRENT_NETWORK_CHAIN_ID);
}

function accountsChangedListener(accounts) {
  const idSocio = new URLSearchParams(window.location.search).get("user_id");
  console.log("Cuenta cambiada. Realizamos desconexion", accounts);
  Common.reloadRoot(idSocio);
}

////////////////////////////////////////////////////////////////////////////
// Conexion y desconexion con el provider

export async function connect(idSocio, keycloak, callback) {
  // provider inspection
  if (WEB3_PROVIDER == null) {
    if (useMetamask) {
      alert(
        "No se ha detectado una instalacion de Metamask (connect). Por favor, instale Metamask y vuelva a intentarlo."
      );
      window.location = "https://metamask.io/";
      return;
    } else {
      showErrorMessage(
        "Ha ocurrido un error al conectarse al proveedor",
        rpcProvider
      );
    }
  }

  console.log(
    "Current %s, seleccionada %s ",
    CURRENT_NETWORK_CHAIN_ID,
    process.env.REACT_APP_SUPPORTED_CHAIN_ID
  );

  // network inspection
  if (
    CURRENT_NETWORK_CHAIN_ID != String(process.env.REACT_APP_SUPPORTED_CHAIN_ID)
  ) {
    alert(
      "La red seleccionada en Metamask no está soportada. Por favor, cambie a una red soportada por la aplicacion (" +
        Constants.getNombreRed(process.env.REACT_APP_SUPPORTED_CHAIN_ID) +
        ")."
    );
    return;
  }

  // connection logic
  if (useMetamask) {
    const response = await WEB3_PROVIDER.send("eth_requestAccounts", []);
    if (response && response.length > 0) {
      await initializeAccount(response[0], idSocio, callback);
    } else {
      console.log(
        "No fue posible conectarnos con una cuenta al provider. Respuesta invalida: " +
          JSON.stringify(response)
      );
    }
  } else {
    if (!keycloak.authenticated) {
      throw { name: "UserUnauthenticated" };
    } else {
      const { chainId } = await WEB3_PROVIDER.getNetwork();

      const ethereumProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig: {
            chainId: "0x" + chainId.toString(16),
            rpcTarget: rpcProvider,
          },
        },
      });

      const web3auth = new Web3Auth({
        clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
        web3AuthNetwork: process.env.REACT_APP_WEB3AUTH_NETWORK,
      });
      web3auth.init({ provider: ethereumProvider });

      // refresh token for web3auth
      await keycloak.updateToken(-1);
      const jwt = keycloak.token;
      const decodedJwt = jwtDecode(jwt);
      console.log(decodedJwt);

      const web3AuthProvider = await web3auth.connect({
        verifier: process.env.REACT_APP_WEB3AUTH_VERIFIER,
        verifierId: decodedJwt.sub,
        idToken: jwt,
      });

      const signingKey = await web3AuthProvider.request({
        method: "eth_private_key",
      });

      signer = new ethers.Wallet(signingKey, WEB3_PROVIDER);

      // specifically-typed chain and signer objects used for abstraction only
      const viemChain = defineChain({
        id: chainId,
        rpcUrls: {
          alchemy: {
            http: [baseRpcUrl],
          },
        },
      });
      const abstractionSigner = LocalAccountSigner.privateKeyToAccountSigner(
        `0x${signingKey}`
      );
      const GAS_MANAGER_POLICY_ID =
        process.env.REACT_APP_WEB3_ALCHEMY_GAS_MANAGER_POLICY_ID;

      abstractionProvider = await createLightAccountAlchemyClient({
        apiKey: alchemyApiKey,
        chain: viemChain,
        signer: abstractionSigner,
        gasManagerConfig: {
          policyId: GAS_MANAGER_POLICY_ID,
        },
        opts: {
          txMaxRetries: parseInt(
            process.env.REACT_APP_WEB3_ALCHEMY_TX_MAX_RETRIES
          ),
          txRetryIntervalMs: parseInt(
            process.env.REACT_APP_WEB3_ALCHEMY_RETRY_INTERVAL_MS
          ),
        },
      });
      smartAddress = abstractionProvider.getAddress();

      console.log("signer address", signer.address);
      console.log("smart address", smartAddress);

      await initializeAccount(
        smartAddress,
        decodedJwt.memberId.toString(),
        callback
      );
    }
  }
}

async function initializeAccount(account, idSocio, callback) {
  // Primero, limpiamos los datos del socio
  clearAccount();

  // Con esta direccion, debemos determinar el tipo de socio con el que nos estamos conectando, si
  // es un socio persona, una empresa, o nada. Luego de esto, ejecutamos el callback correspondiente
  MEMBER_DATA.memberAddress = account;

  // Inicializamos los contactos
  await initializeContracts();

  // Tratamos de traer los datos de la persona o de la empresa, para dejar informacion general de la cuenta
  await populateAccount(idSocio, callback);
}

export function disconnect(callback) {
  // Limpiamos la cuenta
  clearAccount();

  // Ejecutamos el callback de desconexion
  if (callback) {
    callback();
  }
}

export function isAccountDisabled() {
  return MEMBER_DATA.memberStatus === Constants.MEMBER_STATUS_DISABLED;
}

export function isAccountConnected() {
  return MEMBER_DATA.memberAddress != null;
}

export function getAccountToDisplay() {
  return MEMBER_DATA.memberAddress;
}

function clearAccount() {
  MEMBER_DATA.memberAddress = null;
  MEMBER_DATA.memberCode = null;
  MEMBER_DATA.memberId = null;
  MEMBER_DATA.memberName = null;
  MEMBER_DATA.memberType = Constants.MEMBER_TYPE_SIN_REGISTRO;
  MEMBER_DATA.memberStatus = Constants.MEMBER_STATUS_ANY;
  MEMBER_DATA.memberRoles = [];
}

async function populateAccount(idSocio, callback) {
  try {
    let personData = null;

    //intetamos recuperar socio persona
    personData = await getPerson(MEMBER_DATA.memberAddress);

    if (personData && personData.length > 0) {
      MEMBER_DATA.memberCode = personData[0].code;
      MEMBER_DATA.memberName =
        personData[0].firstName + " " + personData[0].lastName;
      MEMBER_DATA.memberType = Constants.MEMBER_TYPE_PERSONA;
      MEMBER_DATA.memberStatus = personData[0].status;
      MEMBER_DATA.memberRoles = personData[0].roles;
      MEMBER_DATA.memberCategory = personData[0].category;
      MEMBER_DATA.memberId = personData[0].memberId;
      MEMBER_DATA.memberError = idSocio && idSocio !== personData[0].memberId;
    } else {
      //no recuperamos persona, buscamos empresa
      const companyData = await getCompany(MEMBER_DATA.memberAddress);

      if (companyData && companyData.length > 0) {
        MEMBER_DATA.memberCode = companyData[0].code;
        MEMBER_DATA.memberName = companyData[0].businessName;
        MEMBER_DATA.memberType = Constants.MEMBER_TYPE_EMPRESA;
        MEMBER_DATA.memberStatus = companyData[0].status;
        MEMBER_DATA.memberRoles = companyData[0].roles;
        MEMBER_DATA.memberCategory = companyData[0].category;
        MEMBER_DATA.memberId = companyData[0].memberId;
        MEMBER_DATA.memberError =
          idSocio && idSocio !== companyData[0].memberId;
      }
    }

    // no encontramos socio persona ni empresa
    // buscamos por idSocio (por URL o token) para ver si se encuentra registrado en estado NOWALLET
    if (!MEMBER_DATA.memberCode && idSocio) {
      personData = await getPersonByMemberId(idSocio);

      if (personData && personData.length > 0) {
        if (personData[0].status === Constants.MEMBER_STATUS_NOWALLET) {
          MEMBER_DATA.memberCode = personData[0].code;
          MEMBER_DATA.memberId = personData[0].memberId;
          MEMBER_DATA.memberName =
            personData[0].firstName + " " + personData[0].lastName;
          MEMBER_DATA.memberType = Constants.MEMBER_TYPE_PERSONA;
          MEMBER_DATA.memberStatus = personData[0].status;
          MEMBER_DATA.memberRoles = personData[0].roles;
          MEMBER_DATA.memberCategory = personData[0].category;
        } else {
          MEMBER_DATA.memberError = true;
        }
      } else {
        const companyData = await getCompanyByMemberId(idSocio);

        if (companyData && companyData.length > 0) {
          if (companyData[0].status === Constants.MEMBER_STATUS_NOWALLET) {
            MEMBER_DATA.memberCode = companyData[0].code;
            MEMBER_DATA.memberId = companyData[0].memberId;
            MEMBER_DATA.memberName = companyData[0].businessName;
            MEMBER_DATA.memberType = Constants.MEMBER_TYPE_EMPRESA;
            MEMBER_DATA.memberStatus = companyData[0].status;
            MEMBER_DATA.memberRoles = companyData[0].roles;
            MEMBER_DATA.memberCategory = companyData[0].category;
          } else {
            MEMBER_DATA.memberError = true;
          }
        }
      }
    }

    if (callback) {
      callback();
    }
  } catch (error) {
    console.log("Error: " + error);
    showErrorMessage(handleRequestError(error));
  }
}

export async function callContract(contract, func, ...funcArgs) {
  if (useMetamask) {
    return contract[func](...funcArgs);
  } else {
    const { hash: uoHash } = await abstractionProvider.sendUserOperation({
      uo: {
        target: contract.address,
        data: contract.interface.encodeFunctionData(func, funcArgs),
      },
    });
    console.log("UserOperation Hash: ", uoHash);

    const txHash = await abstractionProvider.waitForUserOperationTransaction({
      hash: uoHash,
    });
    console.log("Transaction Hash: ", txHash);

    const tx = await abstractionProvider.getTransaction({ hash: txHash });
    console.log(tx);
    return tx;
  }
}

// Inicializamos los objetos de los contratos, para ser usados por la interfaz de usuario
async function initializeContracts() {
  // Obtenemos los archivos de los contratos
  for (const contractName of contractNames) {
    console.log("Cargando definicion de contrato...", contractName);

    try {
      const contractDefinition = await import(
        "../configuration/contracts/" +
          process.env.REACT_APP_SUPPORTED_CHAIN_ID +
          "/" +
          contractName +
          ".json"
      );
      CONTRACT_DEFINITIONS[contractName] = contractDefinition;
    } catch (error) {
      console.log("Error cargando definicion de contrato", contractName, error);
    }
  }

  let WEB3_SIGNER;
  if (useMetamask) {
    WEB3_SIGNER = WEB3_PROVIDER.getSigner(MEMBER_DATA.memberAddress);
  } else {
    WEB3_SIGNER = signer;
  }

  if (CONTRACT_DEFINITIONS["AccessControl"]) {
    CONTRACT_ACCESS_CONTROL = new ethers.Contract(
      CONTRACT_DEFINITIONS["AccessControl"].address,
      CONTRACT_DEFINITIONS["AccessControl"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Parameters"]) {
    CONTRACT_PARAMETERS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Parameters"].address,
      CONTRACT_DEFINITIONS["Parameters"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Principles"]) {
    CONTRACT_PRINCIPLES = new ethers.Contract(
      CONTRACT_DEFINITIONS["Principles"].address,
      CONTRACT_DEFINITIONS["Principles"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Commitments"]) {
    CONTRACT_COMMITMENTS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Commitments"].address,
      CONTRACT_DEFINITIONS["Commitments"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Persons"]) {
    CONTRACT_PERSONS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Persons"].address,
      CONTRACT_DEFINITIONS["Persons"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Companies"]) {
    CONTRACT_COMPANIES = new ethers.Contract(
      CONTRACT_DEFINITIONS["Companies"].address,
      CONTRACT_DEFINITIONS["Companies"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Applications"]) {
    CONTRACT_APPLICATIONS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Applications"].address,
      CONTRACT_DEFINITIONS["Applications"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["HoursRegister"]) {
    CONTRACT_HOURSREGISTER = new ethers.Contract(
      CONTRACT_DEFINITIONS["HoursRegister"].address,
      CONTRACT_DEFINITIONS["HoursRegister"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Proposals"]) {
    CONTRACT_PROPOSALS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Proposals"].address,
      CONTRACT_DEFINITIONS["Proposals"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Ballots"]) {
    CONTRACT_BALLOTS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Ballots"].address,
      CONTRACT_DEFINITIONS["Ballots"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Collaborations"]) {
    CONTRACT_COLLABORATIONS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Collaborations"].address,
      CONTRACT_DEFINITIONS["Collaborations"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["Reports"]) {
    CONTRACT_REPORTS = new ethers.Contract(
      CONTRACT_DEFINITIONS["Reports"].address,
      CONTRACT_DEFINITIONS["Reports"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }

  if (CONTRACT_DEFINITIONS["MemberNotifications"]) {
    CONTRACT_MEMBER_NOTIFICATIONS = new ethers.Contract(
      CONTRACT_DEFINITIONS["MemberNotifications"].address,
      CONTRACT_DEFINITIONS["MemberNotifications"].abi,
      WEB3_PROVIDER
    ).connect(WEB3_SIGNER);
  }
}

////////////////////////////////////////////////////////////////////////////
// Funciones utilitarias de manejo de errores

// Los mensajes de error tienen diferente formato, segun la blockchain.
// En este caso, tenemos dos, una para Ganache y otra para Mumbai
// En general el mensaje de error llega en una propiedad message, luego de la palabra revert
// Si nos envian directamente un string, devolvemos y mostramos eso

export function processErrorResponse(error) {
  if (error.name === "FailedToFindTransactionError") {
    return {
      text: "La confirmación está pendiente debido a la demora en la red. Por favor, verifique nuevamente en unos minutos.",
      type: "info",
    };
  }
  if (typeof error === "string" || error instanceof String) {
    return error;
  }

  const pairs = Common.flattenProperties(error);

  // Recorremos esta parejas, buscando cualquiera que tenga message como parte de su nombre en la propiedad
  // Y alli buscamos las palabras "message", "reason" y "details"
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i];

    // caso "message"
    if (pair[0].indexOf("message") >= 0) {
      // En esta propiedad, tenemos el posible mensaje de error. Buscamos si dentro del mensaje aparece la palabra 'revert'
      // Si aparece, tomamos el valor, pero luego de la palabra revert
      if (pair[1].indexOf("revert") >= 0) {
        const errorMessage = pair[1].substring(pair[1].indexOf("revert") + 7);
        // Si encontramos un mensaje, lo devolvemos, sino, logueamos y devolvemos el mensaje general
        // en la testnet, aparece 'd:' al principio de los mensajes de error. Lo quitamos usando replace
        if (errorMessage && errorMessage.trim().length > 0) {
          return errorMessage.replace(/^d: /, "");
        }
      }

      //buscamos si dentro del mensaje aparece "the tx doesn't have the correct nonce."
      //Si encontramos este string, armamos un mensaje para el usuario explicandole que debe limpiar activity tab data
      if (pair[1].indexOf("the tx doesn't have the correct nonce.") !== -1) {
        const errorMessage =
          "Existe un error asociado a su billetera.\n Para corregir este problema, siga estos pasos en MetaMask:\n" +
          "1. Vaya a Configuración (Settings).\n" +
          "2. Seleccione Avanzado (Advanced).\n" +
          "3. Haga clic en el botón 'Borrar datos de la pestaña de actividad' (Clear activity tab data).";
        return errorMessage;
      }

      //buscamos si dentro del mensaje aparece "User denied transaction signature"
      if (pair[1].indexOf("User denied transaction signature") !== -1) {
        const errorMessage = "Transacción cancelada";
        return errorMessage;
      }

      if (pair[1].indexOf("Exceeds block gas limit") !== -1) {
        const errorMessage = "La transacción excedió el límite de gas";
        return errorMessage;
      }
    }

    // caso "reason"
    if (pair[0].indexOf("reason") >= 0) {
      // En esta propiedad, tenemos el posible mensaje de error. Buscamos si dentro del mensaje aparece la palabra 'revert'
      // Si aparece, tomamos el valor, pero luego de la palabra revert
      // en la testnet, aparece 'd:' al principio de los mensajes de error. Lo quitamos usando replace
      if (pair[1].indexOf("revert") >= 0) {
        const errorMessage = pair[1].substring(pair[1].indexOf("revert") + 7);

        // Si encontramos un mensaje, lo devolvemos, sino, logueamos y devolvemos el mensaje general
        if (errorMessage && errorMessage.trim().length > 0) {
          return errorMessage.replace(/^d: /, "");
        }
      } else {
        return pair[1];
      }
    }

    // caso AA
    if (pair[0].indexOf("details") >= 0) {
      try {
        // en esta propiedad tenemos el posible mensaje de error como lo devuelve Alchemy
        const parsedDetails = JSON.parse(pair[1]);
        if (parsedDetails.message) {
          return parsedDetails.message;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  // Como no pudimos procesar el error, al menos enviamos el error a consola
  console.log(JSON.stringify(error));

  // Tuvimos un error, pero no pudimos encontrar la causa descriptiva del problema. Devolvemos un mensaje general
  return "Ha ocurrido un error procesando la solicitud";
}

//////////////////////////////////////////////////////////////////////////////////////
// Funciones utilitarias para procesar las transacciones, cuando el submit es exitoso
//
// En este caso, nos quedamos con el hash y lo usamos para consultar el estado de la transaccion
// o para armar un listener sobre la misma

/*
{
   "hash":"0x8effa9896f5edcdae0e6f0a84c8663591782ec2717ea7f74cab1b08f23828786",
   "type":0,
   "accessList":null,
   "blockHash":null,
   "blockNumber":null,
   "transactionIndex":null,
   "confirmations":0,
   "from":"0x627306090abaB3A6e1400e9345bC60c78a8BEf57",
   "gasPrice":{
      "type":"BigNumber",
      "hex":"0x04a817c800"
   },
   "gasLimit":{
      "type":"BigNumber",
      "hex":"0x682e"
   },
   "to":"0xB9B7e0cb2EDF5Ea031C8B297A5A1Fa20379b6A0a",
   "value":{
      "type":"BigNumber",
      "hex":"0x00"
   },
   "nonce":37,
   "data":"0x3b185f97000000000000000000000000000000000000000000000000000000000000006f",
   "r":"0x1213f58cecc79f7cf4509216f05805c3a2b2c1e382600fae737d59edf17ac22b",
   "s":"0x2f1558641e7641047742ce01c1c65bc21ced7cfdc00c1d3ec63fd803d3f6eba6",
   "v":2709,
   "creates":null,
   "chainId":1337
} */

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function waitAfterTransaction(callback) {
  await sleep(WAIT_SYNC_DATABASE_TRANSACTION);
  callback();
}

export function processSuccessResponse(response, callbackAfterSuccess) {
  const txRecord = {
    hash: String(response.hash),
    from: String(response.from),
    to: String(response.to),
    pending: true,
    error: false, // Efectivamente determinado cuando la transaccion finalice
    receipt: null, // Se carga cuando la transaccion finaliza
  };

  // Guardamos la transaccion en el mapa de transacciones pendientes
  SESSION_TRANSACTIONS[txRecord.hash] = txRecord;

  // Registramos un callback, que luego del exito de la transaccion, se ejecutara para realizar acciones
  // post finalizacion (ejemplo, recargar una grilla o un dato)
  WEB3_PROVIDER.waitForTransaction(txRecord.hash, TX_CONFIRMATIONS)
    .then((receipt) => {
      console.log(
        "Recibimos la notificacion de que la transaccion finaliza: " +
          txRecord.hash
      );

      console.log("Receipt:", receipt);

      // La transaccion finalizo. Actualizamos el estado de la misma
      txRecord.pending = false;
      txRecord.receipt = receipt;

      // Si la transaccion finalizo con error, lo marcamos
      if (receipt.status === 0) {
        txRecord.error = true;
      }

      // Ejecutamos el callback, si es que se definio
      if (callbackAfterSuccess) {
        waitAfterTransaction(callbackAfterSuccess);
      }
    })
    .catch((error) => {
      console.log(
        "Recibimos un error esperando informacion sobre la transaccion: " +
          JSON.stringify(error)
      );

      txRecord.pending = false;
      txRecord.error = true;
    });

  return txRecord.hash;
}
