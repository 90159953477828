import { Button } from "@mui/material";
import React from "react";

export const SubmitButton = ({ label, disabled }) => {
  return (
    <Button
      sx={{ boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)" }}
      variant="contained"
      type="submit"
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export const CancelButton = ({ onClick, label, disabled }) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#fff",
        color: "#000",
        border: "0.5px #000",
        borderRadius: "4px",
        boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)",
        "&:hover": {
          backgroundColor: "#D5D8DC",
          color: "#fff",
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
