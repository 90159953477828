import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Box, Typography, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import { useNotification } from "../../hooks/useNotification";
import { CompromisosTable } from "../../components/compromisos/CompromisosTable";
import { CompromisoCard } from "../../components/compromisos/CompromisoCard";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";

import CompromisosContext from "../../context/compromisos/CompromisosContext";

import SpinnerContext from "../../context/spinner/SpinnerContext";

const AdminCompromisosPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [filtroCodigo, setFiltroCodigo] = useState(0);
  const [filtroTitulo, setFiltroTitulo] = useState("");
  const [filtroComunidad, setFiltroComunidad] = useState("");
  const [filtroAplicaA, setFiltroAplicaA] = useState([]);
  const [filtroDimensiones, setFiltroDimensiones] = useState([]);
  const [filtroODS, setFiltroODS] = useState([]);

  const [openModalCompromiso, setOpenModalCompromiso] = useState(false);
  const [compromiso, setCompromiso] = useState(null);
  const [openDetalleCompromiso, setOpenDetalleCompromiso] = useState(false);
  const [openEliminarCompromiso, setOpenEliminarCompromiso] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const {
    compromisos,
    getCompromisosAsignados,
    isLoading,
    cambiarHabilitacionCompromiso,
    eliminarCompromiso,
  } = useContext(CompromisosContext);

  const handleBuscarCompromisos = () => {
    getCompromisosAsignados(
      filtroCodigo,
      filtroTitulo,
      filtroComunidad,
      filtroAplicaA,
      filtroDimensiones,
      filtroODS
    );
  };

  const handleAgregarCompromiso = () => {
    setOpenModalCompromiso(true);
    setCompromiso(null);
  };

  const handleDetalleCompromiso = (compromisoSelected) => {
    setOpenDetalleCompromiso(true);
    setCompromiso(compromisoSelected);
  };

  const handleIrSociosAdheridosCompromiso = (compromisoSelected) => {
    Common.goForward(
      "/view/compromiso/CompromisoSociosAdheridosPage/" +
        compromisoSelected.code
    );
  };

  const handleCloseDetalleCompromiso = () => {
    setOpenDetalleCompromiso(false);
    setCompromiso(null);
  };

  const handleEditarCompromiso = (compromisoSelected) => {
    setOpenModalCompromiso(true);
    setCompromiso(compromisoSelected);
  };

  const handleCloseModalCompromiso = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getCompromisosAsignados();
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenModalCompromiso(false);
    setCompromiso(null);
  };

  const handleHabilitarCompromiso = (compromisoSelected) => {
    openSpinner();
    cambiarHabilitacionCompromiso(true, compromisoSelected.code)
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getCompromisosAsignados();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
      });
  };

  const handleDeshabilitarCompromiso = (compromisoSelected) => {
    openSpinner();
    cambiarHabilitacionCompromiso(false, compromisoSelected.code)
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getCompromisosAsignados();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
      });
  };

  const handleEliminarCompromiso = (compromisoSelected) => {
    setOpenEliminarCompromiso(true);
    setCompromiso(compromisoSelected);
  };

  const handleCloseEliminarCompromiso = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getCompromisosAsignados();
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenEliminarCompromiso(false);
    setCompromiso(null);
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getCompromisosAsignados();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Gestión de Compromisos
        </Typography>
      </Box>
      <>
        {matches ? (
          <CompromisoCard
            isReadOnly={false}
            compromisos={compromisos}
            isLoading={isLoading}
            eliminarCompromiso={eliminarCompromiso}
            handleAgregarCompromiso={handleAgregarCompromiso}
            handleBuscarCompromisos={handleBuscarCompromisos}
            handleCloseDetalleCompromiso={handleCloseDetalleCompromiso}
            handleEliminarCompromiso={handleEliminarCompromiso}
            handleCloseEliminarCompromiso={handleCloseEliminarCompromiso}
            handleCloseModalCompromiso={handleCloseModalCompromiso}
            handleDeshabilitarCompromiso={handleDeshabilitarCompromiso}
            handleDetalleCompromiso={handleDetalleCompromiso}
            handleEditarCompromiso={handleEditarCompromiso}
            handleHabilitarCompromiso={handleHabilitarCompromiso}
            handleIrSociosAdheridosCompromiso={
              handleIrSociosAdheridosCompromiso
            }
            openModalCompromiso={openModalCompromiso}
            compromiso={compromiso}
            openDetalleCompromiso={openDetalleCompromiso}
            openEliminarCompromiso={openEliminarCompromiso}
            showSpinner={showSpinner}
            filtroCodigo={filtroCodigo}
            filtroTitulo={filtroTitulo}
            filtroComunidad={filtroComunidad}
            setFiltroCodigo={setFiltroCodigo}
            setFiltroTitulo={setFiltroTitulo}
            setFiltroComunidad={setFiltroComunidad}
            filtroODS={filtroODS}
            setFiltroODS={setFiltroODS}
          />
        ) : (
          <CompromisosTable
            isReadOnly={false}
            compromisos={compromisos}
            isLoading={isLoading}
            eliminarCompromiso={eliminarCompromiso}
            handleAgregarCompromiso={handleAgregarCompromiso}
            handleBuscarCompromisos={handleBuscarCompromisos}
            handleCloseDetalleCompromiso={handleCloseDetalleCompromiso}
            handleEliminarCompromiso={handleEliminarCompromiso}
            handleCloseEliminarCompromiso={handleCloseEliminarCompromiso}
            handleCloseModalCompromiso={handleCloseModalCompromiso}
            handleDeshabilitarCompromiso={handleDeshabilitarCompromiso}
            handleDetalleCompromiso={handleDetalleCompromiso}
            handleEditarCompromiso={handleEditarCompromiso}
            handleHabilitarCompromiso={handleHabilitarCompromiso}
            handleIrSociosAdheridosCompromiso={
              handleIrSociosAdheridosCompromiso
            }
            openModalCompromiso={openModalCompromiso}
            compromiso={compromiso}
            openDetalleCompromiso={openDetalleCompromiso}
            openEliminarCompromiso={openEliminarCompromiso}
            showSpinner={showSpinner}
            filtroCodigo={filtroCodigo}
            filtroTitulo={filtroTitulo}
            filtroComunidad={filtroComunidad}
            setFiltroCodigo={setFiltroCodigo}
            setFiltroTitulo={setFiltroTitulo}
            setFiltroComunidad={setFiltroComunidad}
            filtroAplicaA={filtroAplicaA}
            setFiltroAplicaA={setFiltroAplicaA}
            filtroDimensiones={filtroDimensiones}
            setFiltroDimensiones={setFiltroDimensiones}
            filtroODS={filtroODS}
            setFiltroODS={setFiltroODS}
          />
        )}
      </>
    </Container>
  );
};

export default withRouter(AdminCompromisosPage);
