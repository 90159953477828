import React, { useContext, useReducer } from "react";
import * as Web3Manager from "../../support/Web3Manager";
import { useNotification } from "../../hooks/useNotification";
import SociosReducer from "./SociosReducer";
import SociosContext from "./SociosContext";
import * as Constants from "../../support/Constants";
import { setSocioWallet } from "../../services/SociosServices";
import { getPerson, getPersons } from "../../services/PersonsServices";
import { getCompanies, getCompany } from "../../services/CompaniesServices";
import { handleRequestError } from "../../support/ApiUtils";
import SpinnerContext from "../spinner/SpinnerContext";
import { getCommitments } from "../../services/CommitmentsServices";
import { getApplications } from "../../services/ApplicationsServices";

const SociosState = (props) => {
  const initialState = {
    socios: [],
    isLoading: false,
    compromisosSocio: [],
  };

  const [state, dispatch] = useReducer(SociosReducer, initialState);
  const { socios, isLoading, compromisosSocio } = state;

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const getSocios = async (
    filtroTipo,
    filtroCategoria,
    filtroNombre,
    filtroEstado,
    conServicios = false
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      // Construimos una lista de socios con los datos de las personas y empresas
      const resultSocios = [];

      if (
        filtroTipo === Constants.MEMBER_TYPE_SIN_REGISTRO ||
        filtroTipo === Constants.MEMBER_TYPE_PERSONA
      ) {
        const personas = await getPersons(
          filtroCategoria,
          filtroEstado,
          filtroNombre
        );

        if (personas && personas.length > 0) {
          personas.forEach((persona) => {
            // Obtenemos los datos de la persona
            resultSocios.push({
              ...persona,
              code: persona.id,
              name: persona.firstName + " " + persona.lastName,
              type: Constants.MEMBER_TYPE_PERSONA,
              roles:
                persona.roles && persona.roles.length > 0
                  ? persona.roles.filter(
                      (role) =>
                        role !== Constants.ROLE_ADMIN &&
                        role !== Constants.ROLE_SOCIO
                    )
                  : [],
            });
          });
        }
      }

      if (
        filtroTipo === Constants.MEMBER_TYPE_SIN_REGISTRO ||
        filtroTipo === Constants.MEMBER_TYPE_EMPRESA
      ) {
        // Ahora recuperamos las empresas
        const empresas = await getCompanies(
          filtroCategoria,
          filtroEstado,
          filtroNombre
        );

        if (empresas && empresas.length > 0) {
          for (let i = 0; i < empresas.length; i++) {
            const empresa = empresas[i];

            const socioEmpresa = {
              ...empresa,
              code: empresa.id,
              name: empresa.businessName,
              type: Constants.MEMBER_TYPE_EMPRESA,
              roles:
                empresa.roles && empresa.roles.length > 0
                  ? empresa.roles.filter(
                      (role) =>
                        role !== Constants.ROLE_ADMIN &&
                        role !== Constants.ROLE_SOCIO
                    )
                  : [],
            };

            if (conServicios) {
              //Obtengo los servicios de la empresa
              const servicios = [];

              const responseServicios = await getApplications(empresa.code);

              if (responseServicios && responseServicios.length > 0) {
                responseServicios.forEach((elemento) => {
                  var commitments = [];
                  elemento?.commitments?.forEach((assignedCommitment) => {
                    commitments.push({
                      commitmentCode: assignedCommitment.commitmentCode,
                      status: assignedCommitment.status,
                      deadlineDate: assignedCommitment.deadlineDate,
                      modificationDate: assignedCommitment.modificationDate,
                    });
                  });

                  servicios.push({
                    code: elemento.code,
                    name: elemento.name,
                    description: elemento.description,
                    applicationType: elemento.applicationType,
                    email: elemento.email,
                    url: elemento.url,
                    enabled: elemento.enabled,
                    commitments: commitments,
                    creationDate: elemento.creationDate,
                  });
                });
              }
              socioEmpresa.services = servicios;
            }

            resultSocios.push(socioEmpresa);
          }
        }
      }

      dispatch({ type: "SET_SOCIOS", payload: { socios: resultSocios } });
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(handleRequestError(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const cambiarEstadoSocio = async (
    _type,
    _address,
    _status,
    _statusMessage
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "updatePersonStatus",
          _address,
          _status,
          _statusMessage
        );

        return response;
      } else {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "updateCompanyStatus",
          _address,
          _status,
          _statusMessage
        );

        return response;
      }
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const setLoading = async (isLoading) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: isLoading } });
  };

  const getCompromisosSocio = async (_type, _code) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      var response;
      var commitments = [];

      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        response = await Web3Manager.CONTRACT_PERSONS.getPersonCommitments(
          _code
        );
      } else if (_type === Constants.MEMBER_TYPE_EMPRESA) {
        response = await Web3Manager.CONTRACT_COMPANIES.getCompanyCommitments(
          _code
        );
      }

      for (const assignedCommitment of response || []) {
        const commitment = await getCommitments(
          assignedCommitment.commitmentCode.toNumber()
        );
        const commitmentTitle = commitment[0]?.title || "";
        commitments.push({
          commitmentCode: assignedCommitment.commitmentCode.toNumber(),
          status: assignedCommitment.status,
          deadlineDate: assignedCommitment.deadlineDate.toNumber(),
          modificationDate: assignedCommitment.modificationDate.toNumber(),
          title: commitmentTitle,
        });
      }

      dispatch({
        type: "SET_COMPROMISOS_SOCIO",
        payload: { compromisosSocio: commitments },
      });
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const guardarCompromisosSocio = async (_type, _code, _commitments) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "updatePersonCommitments",
          _code,
          _commitments
        );

        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
          })
        );
      } else if (_type === Constants.MEMBER_TYPE_EMPRESA) {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "updateCompanyCommitments",
          _code,
          _commitments
        );

        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
          })
        );
      }
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const adherirACompromiso = async (
    _socioType,
    _socioCode,
    _commitmentCode,
    _status,
    _deadlineDate,
    _modificationDate
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    const _commitment = {
      commitmentCode: _commitmentCode,
      status: _status,
      deadlineDate: _deadlineDate,
      modificationDate: _modificationDate,
    };
    if (_socioType === Constants.MEMBER_TYPE_PERSONA) {
      try {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "addCommitment",
          _socioCode,
          _commitment
        );
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
        return response;
      } catch (error) {
        // console.error("Error: " + JSON.stringify(error));
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    } else {
      try {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "addCommitment",
          _socioCode,
          _commitment
        );
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
        return response;
      } catch (error) {
        //console.error("Error: " + JSON.stringify(error));
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    }
  };

  const editarAdhesionCompromiso = async (
    _socioType,
    _socioCode,
    _commitmentCode,
    _status,
    _deadlineDate,
    _modificationDate
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    const _commitment = {
      commitmentCode: _commitmentCode,
      status: _status,
      deadlineDate: _deadlineDate,
      modificationDate: _modificationDate,
    };

    if (_socioType === Constants.MEMBER_TYPE_PERSONA) {
      try {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "modifyCommitment",
          _socioCode,
          _commitment
        );
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
        return response;
      } catch (error) {
        //console.error("Error: " + JSON.stringify(error));
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    } else {
      try {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "modifyCommitment",
          _socioCode,
          _commitment
        );
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
        return response;
      } catch (error) {
        //console.error("Error: " + JSON.stringify(error));
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      }
    }
  };

  const eliminarAdhesionCompromiso = async (
    _socioCode,
    _socioType,
    _compromisoCode
  ) => {
    try {
      dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

      let contract;
      if (_socioType === Constants.MEMBER_TYPE_PERSONA) {
        contract = Web3Manager.CONTRACT_PERSONS;
      } else {
        contract = Web3Manager.CONTRACT_COMPANIES;
      }

      const response = await Web3Manager.callContract(
        contract,
        "removeCommitment",
        _socioCode,
        _compromisoCode
      );
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      return response;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const setSocioAddress = async (_code, _address, _type) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
    try {
      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "setPersonWallet",
          _code,
          _address
        );
        await checkWalletStatus(_address, _type);
        return response;
      } else if (_type === Constants.MEMBER_TYPE_EMPRESA) {
        const response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "setCompanyWallet",
          _code,
          _address
        );
        await checkWalletStatus(_address, _type);
        return response;
      }
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  async function checkWalletStatus(socio_address, tipo_socio) {
    let status;
    let continuePolling = true;

    try {
      while (continuePolling) {
        await new Promise((resolve) =>
          setTimeout(resolve, Constants.POLLING_INTERVAL)
        );

        if (tipo_socio === Constants.MEMBER_TYPE_PERSONA) {
          const persona = await getPerson(socio_address);
          status = persona[0]?.status;
        } else {
          const empresa = await getCompany(socio_address);
          status = empresa[0]?.status;
        }

        if (status === Constants.MEMBER_STATUS_WALLETASSIGNED) {
          continuePolling = false;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  const eliminarSocio = async (_type, _code) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
    openSpinner();

    try {
      let response;

      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "deletePerson",
          _code
        );
      } else if (_type === Constants.MEMBER_TYPE_EMPRESA) {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "deleteCompany",
          _code
        );
      }

      handleOpenNotificacionTx(
        Web3Manager.processSuccessResponse(response, () => {
          getSocios(
            Constants.MEMBER_TYPE_SIN_REGISTRO,
            Constants.CATEGORY_ANY,
            "",
            Constants.MEMBER_STATUS_ANY
          );
          dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
          closeSpinner();
        })
      );
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      closeSpinner();
    }
  };

  const habilitacionSocio = async (_type, _code, habilitar) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });
    openSpinner();

    try {
      let response;

      const status = habilitar
        ? Constants.MEMBER_STATUS_WALLETASSIGNED
        : Constants.MEMBER_STATUS_DISABLED;

      if (_type === Constants.MEMBER_TYPE_PERSONA) {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_PERSONS,
          "updatePersonStatus",
          _code,
          status,
          "Cambio habilitacion " + new Date()
        );
      } else if (_type === Constants.MEMBER_TYPE_EMPRESA) {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMPANIES,
          "updateCompanyStatus",
          _code,
          status,
          "Cambio habilitacion " + new Date()
        );
      }

      handleOpenNotificacionTx(
        Web3Manager.processSuccessResponse(response, () => {
          getSocios(
            Constants.MEMBER_TYPE_SIN_REGISTRO,
            Constants.CATEGORY_ANY,
            "",
            Constants.MEMBER_STATUS_ANY
          );
          dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
          closeSpinner();
        })
      );
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
      closeSpinner();
    }
  };

  const cleanCompromisosSocio = () => {
    dispatch({
      type: "SET_COMPROMISOS_SOCIO",
      payload: { compromisosSocio: [] },
    });
  };

  return (
    <SociosContext.Provider
      value={{
        socios,
        getSocios,
        isLoading,
        setLoading,
        cambiarEstadoSocio,
        getCompromisosSocio,
        compromisosSocio,
        guardarCompromisosSocio,
        setSocioAddress,
        eliminarSocio,
        habilitacionSocio,
        cleanCompromisosSocio,
        adherirACompromiso,
        editarAdhesionCompromiso,
        eliminarAdhesionCompromiso,
      }}
    >
      {props.children}
    </SociosContext.Provider>
  );
};
export default SociosState;
