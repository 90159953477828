import React, { useState } from "react";
import * as Constants from "../../support/Constants";
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGrid, esES } from "@mui/x-data-grid";
import Edit from "@mui/icons-material/Edit";
import {
  Accessibility,
  AccountBalance,
  Business,
  Delete,
  Groups,
  NotInterested,
  OpenInBrowser,
  PhoneAndroid,
  Public,
  Restore,
  ZoomIn,
} from "@mui/icons-material";

import { ModalCompromsioForm } from "../../components/compromisos/ModalCompromisoForm";

import { ModalCompromisoDetalle } from "../../components/compromisos/ModalCompromisoDetalle";
import { ModalEliminarCompromiso } from "./ModalEliminarCompromiso";

import { GridActionItem } from "../ui/GridActionItem";
import { CompromisosFilterMobile } from "./CompromisosFilterMobile";
import CompromisosFilter from "./CompromisosFilter";

export const CompromisosTable = ({
  isReadOnly,
  compromisos,
  isLoading,
  eliminarCompromiso,
  handleAgregarCompromiso,
  handleBuscarCompromisos,
  handleCloseDetalleCompromiso,
  handleEliminarCompromiso,
  handleCloseEliminarCompromiso,
  handleCloseModalCompromiso,
  handleDeshabilitarCompromiso,
  handleDetalleCompromiso,
  handleEditarCompromiso,
  handleHabilitarCompromiso,
  handleIrSociosAdheridosCompromiso,
  openModalCompromiso,
  compromiso,
  openDetalleCompromiso,
  openEliminarCompromiso,
  showSpinner,
  filtroCodigo,
  filtroTitulo,
  filtroComunidad,
  setFiltroCodigo,
  setFiltroTitulo,
  setFiltroComunidad,
  filtroAplicaA,
  setFiltroAplicaA,
  filtroDimensiones,
  setFiltroDimensiones,
  filtroODS,
  setFiltroODS,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const columnsCompromisos = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "community",
      headerName: "Comunidad",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "appliesTo",
      headerName: "Aplicable a",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const { appliesToPersons, appliesToCompanies, appliesToServices } =
          params.row;

        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="Personas" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <Accessibility
                    color={appliesToPersons ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Empresas" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <Business
                    color={appliesToCompanies ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Aplicaciones" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <PhoneAndroid
                    color={appliesToServices ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "ESGDimensions",
      headerName: "Dimensiones ESG",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        const { dimensionE, dimensionS, dimensionG } = params.row;

        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Environment" placement="top" arrow>
              <div>
                <Chip
                  icon={<Public />}
                  label="(E)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionE}
                />
              </div>
            </Tooltip>
            <Tooltip title="Social" placement="top" arrow>
              <div>
                <Chip
                  icon={<Groups />}
                  label="(S)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionS}
                />
              </div>
            </Tooltip>
            <Tooltip title="Gonvernance" placement="top" arrow>
              <div>
                <Chip
                  icon={<AccountBalance />}
                  label="(G)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionG}
                />
              </div>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      minWidth: isReadOnly ? 100 : 250,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={isLoading}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleIrSociosAdheridosCompromiso(params.row)}
            />

            {!isReadOnly && (
              <>
                {true && ( // replace `true` with `!compromiso.hasAssignees` to roll back temp changes
                  <GridActionItem
                    disabled={isLoading}
                    icon={<Edit />}
                    label="Editar"
                    onClick={() => handleEditarCompromiso(params.row)}
                  />
                )}
                {params.row.enabled ? (
                  <GridActionItem
                    disabled={isLoading}
                    icon={<NotInterested />}
                    label="Deshabilitar"
                    onClick={() => handleDeshabilitarCompromiso(params.row)}
                  />
                ) : (
                  <GridActionItem
                    disabled={isLoading}
                    icon={<Restore />}
                    label="Habilitar"
                    onClick={() => handleHabilitarCompromiso(params.row)}
                  />
                )}

                <GridActionItem
                  disabled={isLoading}
                  icon={<Delete />}
                  label="Eliminar"
                  onClick={() => handleEliminarCompromiso(params.row)}
                />
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  if (!isReadOnly) {
    columnsCompromisos.splice(0, 0, {
      field: "code",
      headerName: "Cod.",
      flex: 1,
      minWidth: 50,
    });
    columnsCompromisos.splice(4, 0, {
      field: "enabled",
      headerName: "Hab?",
      flex: 1,
      minWidth: 50,
      valueGetter: (params) => {
        return params.value ? "SI" : "NO";
      },
    });
  }

  const sortedCompromisos = compromisos.slice().sort((a, b) => {
    const compareComunidad = a.community
      .trim()
      .localeCompare(b.community.trim());
    if (compareComunidad !== 0) {
      return compareComunidad;
    }

    return a.title.trim().localeCompare(b.title.trim());
  });

  return (
    <>
      <Grid container>
        <Grid item lg={4}>
          {!isReadOnly && !matches && (
            <Box sx={{ justifyContent: "left", display: "flex" }} pb={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAgregarCompromiso()}
                startIcon={<AddCircleIcon />}
                disabled={showSpinner}
              >
                Agregar
              </Button>
            </Box>
          )}
        </Grid>

        {matches ? (
          <Grid item sx={{ width: "100%" }}>
            <CompromisosFilterMobile
              filtroCodigo={filtroCodigo}
              filtroTitulo={filtroTitulo}
              setFiltroCodigo={setFiltroCodigo}
              setFiltroTitulo={setFiltroTitulo}
              handleBuscarCompromisos={handleBuscarCompromisos}
              handleAgregarCompromiso={handleAgregarCompromiso}
              isReadOnly={isReadOnly}
              filtroComunidad={filtroComunidad}
              setFiltroComunidad={setFiltroComunidad}
              filtroAplicaA={filtroAplicaA}
              setFiltroAplicaA={setFiltroAplicaA}
              filtroDimensiones={filtroDimensiones}
              setFiltroDimensiones={setFiltroDimensiones}
              filtroODS={filtroODS}
              setFiltroODS={setFiltroODS}
            />
          </Grid>
        ) : (
          <Grid item sx={{ justifyContent: "right", display: "flex" }} lg={12}>
            <Box pb={3}>
              <CompromisosFilter
                filtroCodigo={filtroCodigo}
                setFiltroCodigo={setFiltroCodigo}
                filtroTitulo={filtroTitulo}
                setFiltroTitulo={setFiltroTitulo}
                filtroComunidad={filtroComunidad}
                setFiltroComunidad={setFiltroComunidad}
                filtroAplicaA={filtroAplicaA}
                setFiltroAplicaA={setFiltroAplicaA}
                filtroDimensiones={filtroDimensiones}
                setFiltroDimensiones={setFiltroDimensiones}
                filtroODS={filtroODS}
                setFiltroODS={setFiltroODS}
                handleBuscarCompromisos={handleBuscarCompromisos}
                isReadOnly={isReadOnly}
                isLoading={isLoading}
                showSpinner={showSpinner}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <DataGrid
        rows={
          !isReadOnly
            ? sortedCompromisos
            : sortedCompromisos.filter((c) => c.enabled)
        }
        pageSize={50}
        autoHeight={true}
        loading={isLoading && !showSpinner}
        columns={columnsCompromisos}
        getRowId={(row) => row.code}
        rowsPerPageOptions={[50]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />

      <br />

      <ModalCompromsioForm
        open={openModalCompromiso}
        handleClose={handleCloseModalCompromiso}
        compromiso={compromiso}
      />

      {openDetalleCompromiso && (
        <ModalCompromisoDetalle
          open={openDetalleCompromiso}
          handleClose={handleCloseDetalleCompromiso}
          compromiso={compromiso}
        />
      )}
      {openEliminarCompromiso && (
        <ModalEliminarCompromiso
          open={openEliminarCompromiso}
          handleClose={handleCloseEliminarCompromiso}
          compromiso={compromiso}
          eliminarCompromiso={eliminarCompromiso}
        />
      )}
    </>
  );
};
