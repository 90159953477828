import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function CustomToastContainer() {
  return (
    <ToastContainer
      position="top-center"
      bodyClassName="custom-toast"
      autoClose={false}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}

const GLOBAL_TOASTIFY_OPTIONS = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export function showInfoMessage(msg) {
  toast.info(msg, {
    ...GLOBAL_TOASTIFY_OPTIONS,
  });
}

export function showErrorMessage(msg) {
  toast.error(msg, {
    ...GLOBAL_TOASTIFY_OPTIONS,
    autoClose: false,
    style: {
      whiteSpace: "pre-line",
    },
  });
}

export function showSuccessMessage(msg) {
  toast.success(msg, {
    ...GLOBAL_TOASTIFY_OPTIONS,
  });
}
