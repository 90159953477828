import React, { useState, useEffect, useContext } from "react";
import { Container, Box, Typography, useMediaQuery } from "@mui/material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";

import { useNotification } from "../../hooks/useNotification";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import ServiciosContext from "../../context/servicios/ServiciosContext";
import { getApplicationTypes } from "../../services/ParametersServices";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { AplicacionesTable } from "../../components/aplicaciones/AplicacionesTable";
import { AplicacionCard } from "../../components/aplicaciones/AplicacionCard";

const AplicacionesPage = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const {
    aplicaciones,
    getServiciosEmpresas,
    isLoading: loadingServicios,
  } = useContext(ServiciosContext);

  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const [companies, setCompanies] = useState([]);
  const [tiposServicio, setTiposServicio] = useState([]);
  const [empresasConAplicaciones, setEmpresasConAplicaciones] = useState([]);
  const [aplicacionesFiltradas, setAplicacionesFiltradas] = useState([]);
  const [openModalServicio, setOpenModalServicio] = useState(false);
  const [filtroTipo, setFiltroTipo] = useState();
  const [filtroEmpresa, setFiltroEmpresa] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [aplicacionSeleccionada, setAplicacionSeleccionada] = useState(null);
  const [isReadOnlyModalFormServicio, setIsReadOnlyModalFormServicio] =
    useState(false);

  const getTiposServicio = () => {
    getApplicationTypes()
      .then((response) => {
        setTiposServicio(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    setEmpresasConAplicaciones([
      ...new Set(aplicaciones.map((a) => a.company)),
    ]);
  }, [aplicaciones]);

  const handleDetalleServicio = (app) => {
    setAplicacionSeleccionada(app);
    setIsReadOnlyModalFormServicio(true);
    setOpenModalServicio(true);
  };

  const handleAgregarServicio = () => {
    setAplicacionSeleccionada(null);
    setIsReadOnlyModalFormServicio(false);
    setOpenModalServicio(true);
  };

  const handleCloseModalServicio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getServiciosEmpresas(companies);
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return;
      }
    }

    setOpenModalServicio(false);
    setAplicacionSeleccionada(null);
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }
    getServiciosEmpresas();
    getTiposServicio();
    getCompromisosEnabled();
  }, []);

  const filterAplicaciones = () => {
    setIsFiltered(true);
    let aplicacionesFiltradas = aplicaciones;

    // Filtrar por tipo
    if (filtroTipo) {
      aplicacionesFiltradas = aplicacionesFiltradas.filter(
        (app) => app.applicationType === filtroTipo
      );
    }

    // Filtrar por empresa
    if (filtroEmpresa) {
      aplicacionesFiltradas = aplicacionesFiltradas.filter(
        (app) => app.company === filtroEmpresa
      );
    }

    setAplicacionesFiltradas(aplicacionesFiltradas);
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Aplicaciones
        </Typography>
      </Box>
      <>
        {matches ? (
          <AplicacionCard
            loadingCompromisos={loadingCompromisos}
            handleDetalleServicio={handleDetalleServicio}
            getServiciosEmpresas={getServiciosEmpresas}
            getTiposServicio={getTiposServicio}
            getCompromisosEnabled={getCompromisosEnabled}
            setIsFiltered={setIsFiltered}
            aplicaciones={aplicaciones}
            filtroTipo={filtroTipo}
            filtroEmpresa={filtroEmpresa}
            setAplicacionesFiltradas={setAplicacionesFiltradas}
            handleAgregarServicio={handleAgregarServicio}
            showSpinner={showSpinner}
            setFiltroEmpresa={setFiltroEmpresa}
            empresasConAplicaciones={empresasConAplicaciones}
            setFiltroTipo={setFiltroTipo}
            tiposServicio={tiposServicio}
            isFiltered={isFiltered}
            aplicacionesFiltradas={aplicacionesFiltradas}
            loadingServicios={loadingServicios}
            openModalServicio={openModalServicio}
            handleCloseModalServicio={handleCloseModalServicio}
            aplicacionSeleccionada={aplicacionSeleccionada}
            isReadOnlyModalFormServicio={isReadOnlyModalFormServicio}
            compromisos={compromisos}
            filterAplicaciones={filterAplicaciones}
          />
        ) : (
          <AplicacionesTable
            loadingCompromisos={loadingCompromisos}
            handleDetalleServicio={handleDetalleServicio}
            getServiciosEmpresas={getServiciosEmpresas}
            getTiposServicio={getTiposServicio}
            getCompromisosEnabled={getCompromisosEnabled}
            setIsFiltered={setIsFiltered}
            aplicaciones={aplicaciones}
            filtroTipo={filtroTipo}
            filtroEmpresa={filtroEmpresa}
            setAplicacionesFiltradas={setAplicacionesFiltradas}
            handleAgregarServicio={handleAgregarServicio}
            showSpinner={showSpinner}
            setFiltroEmpresa={setFiltroEmpresa}
            empresasConAplicaciones={empresasConAplicaciones}
            setFiltroTipo={setFiltroTipo}
            tiposServicio={tiposServicio}
            isFiltered={isFiltered}
            aplicacionesFiltradas={aplicacionesFiltradas}
            loadingServicios={loadingServicios}
            openModalServicio={openModalServicio}
            handleCloseModalServicio={handleCloseModalServicio}
            aplicacionSeleccionada={aplicacionSeleccionada}
            isReadOnlyModalFormServicio={isReadOnlyModalFormServicio}
            compromisos={compromisos}
          />
        )}
      </>
    </Container>
  );
};

export default AplicacionesPage;
