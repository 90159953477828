import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  MenuItem,
  TextField,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Edit from "@mui/icons-material/Edit";
import { Delete, Search, ZoomIn } from "@mui/icons-material";
import { ModalPropuestaForm } from "../../components/propuestas/ModalPropuestaForm";
import { ModalVotarPropuesta } from "../../components/propuestas/ModalVotarPropuesta";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { VoteBar } from "../../components/propuestas/VoteBar";
import { ModalEliminarPropuesta } from "../../components/propuestas/ModalEliminarPropuesta";
import { useNotification } from "../../hooks/useNotification";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { getProposals } from "../../services/ProposalServices";
import { getBallotCategories } from "../../services/ParametersServices";
import { PropuestasTable } from "../../components/propuestas/PropuestasTable";
import { PropuestasCard } from "../../components/propuestas/PropuestasCard";

const PropuestasPage = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [filtroCategoria, setFiltroCategoria] = useState("");
  const [filtroTitulo, setFiltroTitulo] = useState("");
  const [filtroEstado, setFiltroEstado] = useState(
    Constants.PROPOSAL_STATUS_ANY
  );
  const [openModalPropuesta, setOpenModalPropuesta] = useState(false);
  const [openModalEliminarPropuesta, setOpenModalEliminarPropuesta] =
    useState(false);
  const [openVoteModal, setOpenVoteModal] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [roles, setRoles] = useState([]);
  const [categoriasPropuesta, setCategoriasPropuesta] = useState([]);
  const [propuestasData, setPropuestasData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propuestaSelected, setPropuestaSelected] = useState(null);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const handleAgregarPropuesta = () => {
    setOpenModalPropuesta(true);
    setModeEdit(false);
    setPropuestaSelected(null);
  };

  const handleEditarPropuesta = (propuestaSelected) => {
    setOpenModalPropuesta(true);
    setModeEdit(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleEliminarPropuesta = (propuestaSelected) => {
    setOpenModalEliminarPropuesta(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleOpenVoteModal = (propuestaSelected) => {
    setOpenVoteModal(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleCloseModalPropuesta = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalPropuesta(false);
    setPropuestaSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalVotar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();

            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenVoteModal(false);
    setPropuestaSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalEliminar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenModalEliminarPropuesta(false);
    setPropuestaSelected(null);
  };

  const getPropuestas = () => {
    const categoriaParsed = filtroCategoria === "TODAS" ? "" : filtroCategoria;
    setLoading(true);
    getProposals(categoriaParsed, filtroTitulo, filtroEstado)
      .then((response) => {
        setPropuestasData([...response.sort((a, b) => b.code - a.code)]);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const getCategoriasVotacion = async () => {
    getBallotCategories()
      .then((response) => {
        setCategoriasPropuesta(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getRoles = async () => {
    Web3Manager.CONTRACT_ACCESS_CONTROL.getAvailableRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getCategoriasVotacion();
    getRoles();
    getPropuestas();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Box>
          <Typography
            variant="h3"
            color={"#005A59"}
            align="center"
            p={3}
            sx={{
              fontSize: {
                lg: "48px",
                sm: "36px",
                xs: "30px",
              },
            }}
          >
            Propuestas
          </Typography>
        </Box>
      </Box>
      <>
        <Grid item lg={6}>
          <Box sx={{ justifyContent: "left", display: "flex" }} pb={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAgregarPropuesta()}
              startIcon={<AddCircleIcon />}
              disabled={showSpinner}
              fullWidth={matches}
            >
              Agregar
            </Button>
          </Box>
        </Grid>
        {matches ? (
          <PropuestasCard
            handleAgregarPropuesta={handleAgregarPropuesta}
            handleEditarPropuesta={handleEditarPropuesta}
            handleEliminarPropuesta={handleEliminarPropuesta}
            handleOpenVoteModal={handleOpenVoteModal}
            handleCloseModalPropuesta={handleCloseModalPropuesta}
            handleCloseModalVotar={handleCloseModalVotar}
            handleCloseModalEliminar={handleCloseModalEliminar}
            openModalPropuesta={openModalPropuesta}
            openModalEliminarPropuesta={openModalEliminarPropuesta}
            openVoteModal={openVoteModal}
            modeEdit={modeEdit}
            roles={roles}
            categoriasPropuesta={categoriasPropuesta}
            propuestasData={propuestasData}
            loading={loading}
            setLoading={setLoading}
            propuestaSelected={propuestaSelected}
            showSpinner={showSpinner}
            setFiltroCategoria={setFiltroCategoria}
            filtroCategoria={filtroCategoria}
            setFiltroTitulo={setFiltroTitulo}
            filtroTitulo={filtroTitulo}
            setFiltroEstado={setFiltroEstado}
            filtroEstado={filtroEstado}
            getPropuestas={getPropuestas}
          />
        ) : (
          <PropuestasTable
            handleAgregarPropuesta={handleAgregarPropuesta}
            handleEditarPropuesta={handleEditarPropuesta}
            handleEliminarPropuesta={handleEliminarPropuesta}
            handleOpenVoteModal={handleOpenVoteModal}
            handleCloseModalPropuesta={handleCloseModalPropuesta}
            handleCloseModalVotar={handleCloseModalVotar}
            handleCloseModalEliminar={handleCloseModalEliminar}
            openModalPropuesta={openModalPropuesta}
            openModalEliminarPropuesta={openModalEliminarPropuesta}
            openVoteModal={openVoteModal}
            modeEdit={modeEdit}
            roles={roles}
            categoriasPropuesta={categoriasPropuesta}
            propuestasData={propuestasData}
            loading={loading}
            setLoading={setLoading}
            propuestaSelected={propuestaSelected}
            showSpinner={showSpinner}
            setFiltroCategoria={setFiltroCategoria}
            filtroCategoria={filtroCategoria}
            setFiltroTitulo={setFiltroTitulo}
            filtroTitulo={filtroTitulo}
            setFiltroEstado={setFiltroEstado}
            filtroEstado={filtroEstado}
            getPropuestas={getPropuestas}
          />
        )}
      </>
    </Container>
  );
};

export default PropuestasPage;
