import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  useTheme,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Constants from "../../support/Constants";
import { Clear } from "@mui/icons-material";

const SociosFilterMobile = ({
  getSocios,
  isLoading,
  setLoading,
  isReadOnly,
}) => {
  const theme = useTheme();
  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState(
    Constants.CATEGORY_ANY
  );
  const [filtroEstado, setFiltroEstado] = useState(Constants.MEMBER_STATUS_ANY);
  const [filtroTipo, setFiltroTipo] = useState(
    Constants.MEMBER_TYPE_SIN_REGISTRO
  );
  const [expanded, setExpanded] = useState(false);

  const handleSearch = () => {
    setLoading(true);
    getSocios(filtroTipo, filtroCategoria, filtroNombre, filtroEstado);
    setLoading(false);
    handleAccordionChange("panel1")(null, false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleAccordionChange("panel1")}
      sx={{ mb: 2, width: "100%" }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: 48,
          "&.Mui-expanded": {
            minHeight: 48,
            height: 48,
          },
        }}
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText }} />
        }
      >
        <Typography>Filtrar socios</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre completo"
              variant="outlined"
              size="small"
              value={filtroNombre}
              onChange={(e) => setFiltroNombre(e.target.value)}
              InputProps={{
                endAdornment: filtroNombre && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setFiltroNombre("")}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Estado"
              variant="outlined"
              size="small"
              value={filtroEstado}
              select
              onChange={(newValue) => setFiltroEstado(newValue.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem
                key={"_status_" + Constants.MEMBER_STATUS_ANY}
                value={Constants.MEMBER_STATUS_ANY}
              >
                Todos
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.MEMBER_STATUS_NOWALLET}
                value={Constants.MEMBER_STATUS_NOWALLET}
              >
                Sin billetera
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.MEMBER_STATUS_WALLETASSIGNED}
                value={Constants.MEMBER_STATUS_WALLETASSIGNED}
              >
                Billetera asignada
              </MenuItem>
              {!isReadOnly && (
                <MenuItem
                  key={"_status_" + Constants.MEMBER_STATUS_DISABLED}
                  value={Constants.MEMBER_STATUS_DISABLED}
                >
                  Deshabilitado
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Categoría"
              variant="outlined"
              size="small"
              value={filtroCategoria}
              select
              onChange={(newValue) => setFiltroCategoria(newValue.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem key={"_status_todos"} value={Constants.CATEGORY_ANY}>
                {Constants.getNombreCategoria(Constants.CATEGORY_ANY)}
              </MenuItem>
              <MenuItem
                key={"_status_suscriptor"}
                value={Constants.CATEGORY_SUSCRIPTOR}
              >
                {Constants.getNombreCategoria(Constants.CATEGORY_SUSCRIPTOR)}
              </MenuItem>
              <MenuItem
                key={"_status_activo"}
                value={Constants.CATEGORY_ACTIVO}
              >
                {Constants.getNombreCategoria(Constants.CATEGORY_ACTIVO)}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Tipo"
              variant="outlined"
              size="small"
              value={filtroTipo}
              select
              onChange={(newValue) => setFiltroTipo(newValue.target.value)}
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem
                key={"_tipo_todos"}
                value={Constants.MEMBER_TYPE_SIN_REGISTRO}
              >
                Todos
              </MenuItem>
              <MenuItem
                key={"_tipo_persona"}
                value={Constants.MEMBER_TYPE_PERSONA}
              >
                Persona
              </MenuItem>
              <MenuItem
                key={"_tipo_empresa"}
                value={Constants.MEMBER_TYPE_EMPRESA}
              >
                Empresa
              </MenuItem>
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "right", display: "flex", gap: "10px" }}
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={isLoading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SociosFilterMobile;
