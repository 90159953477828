import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TextField,
  Stack,
  Grid,
  MenuItem,
  InputAdornment,
  CircularProgress,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";
import ServiciosContext from "../../context/servicios/ServiciosContext";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";
import { SeccionCompromisosAdheridosMobile } from "../compromisos/SeccionCompromisosAdheridosMobile";
import moment from "moment";
import CustomTextEditor from "../ui/CustomTextEditor";
import HtmlContentDisplay from "../ui/HtmlContentDisplay";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalServicioFormMobile = ({
  open,
  handleClose,
  servicio,
  tiposServicio,
  compromisosEnabled,
  isReadOnly,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [disableButton, setDisableButton] = useState(false);
  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const { crearServicio, isLoading, modificarServicio } =
    useContext(ServiciosContext);

  useEffect(() => {
    //Se setean los compromisos seleccionados
    //getCompromisosEnabled();

    if (servicio) {
      const compromisosSeleccionadosList = compromisosEnabled.filter(
        (compromiso) => {
          // Verificar si el código del compromiso está presente en la lista de commitmentCode
          return servicio?.commitments?.some(
            (commitment) => commitment.commitmentCode == compromiso.code
          );
        }
      );

      setAssignedCommitments(servicio?.commitments);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    } else {
      setAssignedCommitments([]);
    }
  }, [servicio]);

  const showUrlTextField = () => {
    if (isReadOnly && values._url) {
      return true;
    }
    if (!isReadOnly) {
      return true;
    }
    return false;
  };

  const validationSchema = Yup.object({
    _name: Yup.string().required("Debes ingresar un nombre para el servicio"),
    _description: Yup.string()
      .required("Debes ingresar una descripción para la aplicación")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para la aplicación",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
    _email: Yup.string()
      .email("Debes ingresar un correo electrónico válido")
      .required("Debes ingresar un correo electrónico"),
    _tipoServicio: Yup.string().required(
      "Debes ingresar un tipo de aplicación"
    ),
  });

  const initialState = {
    _name: "",
    _description: "",
    _tipoServicio: "",
    _email: "",
    _url: "",
    _enabled: true,
    _commitments: [],
  };

  const getFormData = () => {
    let formData;

    if (!servicio) {
      formData = initialState;
    } else {
      formData = {
        _name: servicio.name,
        _description: servicio.description,
        _tipoServicio: servicio.applicationType,
        _email: servicio.email,
        _url: servicio.url,
        _enabled: servicio.enabled,
        _commitments: servicio.commitments,
      };
    }

    return formData;
  };

  const form = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _name, _description, _tipoServicio, _email, _url, _enabled } =
        values;

      const newListWithUnixDates = assignedCommitments.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate, "YYYY-MM-DD").unix(), // Convertimos el campo a unix
          };
        } else {
          return item;
        }
      });

      openSpinner();
      if (!servicio) {
        crearServicio(
          _name,
          _description,
          _tipoServicio,
          _email,
          _url,
          _enabled,
          newListWithUnixDates
        )
          .then((response) => {
            handleClose(false, response, true);
            setAssignedCommitments([]);
            setCompromisosSeleccionados([]);
            resetForm();
          })
          .catch((error) => {
            handleClose(false, error, false);
          });
      } else {
        modificarServicio(
          servicio.code,
          _name,
          _description,
          _tipoServicio,
          _email,
          _url,
          _enabled,
          newListWithUnixDates
        )
          .then((response) => {
            resetForm();
            handleClose(false, response, true);
            setAssignedCommitments([]);
            setCompromisosSeleccionados([]);
          })
          .catch((error) => {
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = form;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {servicio
              ? isReadOnly
                ? "Detalle aplicación"
                : "Modificar aplicación"
              : "Nueva aplicación"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(true, null, false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
        {/* Contenido del formulario */}
        <TextField
          fullWidth
          margin="normal"
          id="_name"
          name="_name"
          label="Nombre"
          variant="outlined"
          value={values._name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched._name && Boolean(errors._name)}
          helperText={touched._name && errors._name}
          disabled={isReadOnly}
        />

        {!isReadOnly ? (
          <Box mt={2} sx={{ borderRadius: "4px" }}>
            <CustomTextEditor
              name="_description"
              placeholder="Descripción"
              value={values._description}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
        ) : (
          <Box mt={2}>
            <HtmlContentDisplay content={values._description} />
          </Box>
        )}

        <TextField
          fullWidth
          margin="normal"
          id="_tipoServicio"
          name="_tipoServicio"
          label="Tipo de aplicación"
          variant="outlined"
          select
          value={values._tipoServicio}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched._tipoServicio && Boolean(errors._tipoServicio)}
          helperText={touched._tipoServicio && errors._tipoServicio}
          disabled={isReadOnly}
        >
          {tiposServicio.map((item) => (
            <MenuItem key={item.id} value={item.type}>
              {item.type}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          margin="normal"
          id="_email"
          name="_email"
          label="Email"
          variant="outlined"
          value={values._email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched._email && Boolean(errors._email)}
          helperText={touched._email && errors._email}
          disabled={isReadOnly}
        />

        {showUrlTextField() && (
          <Box sx={{ mt: 2 }}>
            <TextField
              name="_url"
              id="outlined-basic"
              label="URL"
              variant="outlined"
              size="small"
              fullWidth={true}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values._url}
              error={Boolean(touched._url && errors._url)}
              helperText={touched._url && errors._url}
              disabled={isReadOnly}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      //disabled={isReadOnly}
                      onClick={() => {
                        window.open(values._url, "_blank");
                      }}
                      edge="end"
                    >
                      <OpenInBrowser />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        <Box mt={2}>
          {!isReadOnly && (
            <FormControlLabel
              control={
                <Checkbox
                  name="_enabled"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._enabled}
                  checked={values._enabled}
                  error={Boolean(touched._enabled && errors._enabled)}
                  helperText={touched._enabled && errors._enabled}
                  disabled={isReadOnly}
                />
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 32,
                  height: 32,
                },
                "& .Mui-checked": {
                  "& .MuiSvgIcon-root": {
                    width: 32,
                    height: 32,
                  },
                },
              }}
              label="Habilitado?"
            />
          )}
        </Box>

        <Grid item lg={7} sx={{ position: "relative" }}>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <SeccionCompromisosAdheridosMobile
              assignedCommitments={assignedCommitments}
              setAssignedCommitments={setAssignedCommitments}
              compromisosSeleccionados={compromisosSeleccionados}
              setCompromisosSeleccionados={setCompromisosSeleccionados}
              isReadOnly={isReadOnly}
              mostrarTitulo={true}
              isCreatingService={true}
            />
          )}
        </Grid>

        <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
          {!isReadOnly && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {servicio ? "Guardar" : "Registrar"}
            </Button>
          )}

          <CancelButton
            label={isReadOnly ? "Cerrar" : "Cancelar"}
            onClick={() => {
              handleClose(true, null, false);
              resetForm();
            }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};
