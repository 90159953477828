import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Typography,
  Radio,
  RadioGroup,
  Chip,
  TextField,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Web3Manager from "../../support/Web3Manager";
import moment from "moment/moment";
import { CheckCircle } from "@mui/icons-material";
import * as Constants from "../../support/Constants";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalEstadoPropuestaForm = ({
  open,
  handleClose,
  propuestaEditing,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const formData = {
    _status: propuestaEditing?.status,
    _statusMessage: "",
  };

  const formPropuesta = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const { _status, _statusMessage } = values;

      setLoading(true);
      setDisableButton(true);
      openSpinner();

      Web3Manager.callContract(
        Web3Manager.CONTRACT_PROPOSALS,
        "updateProposalStatus",
        propuestaEditing?.code,
        _status,
        _statusMessage
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
          closeSpinner();
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          handleClose(false, error, false);
          closeSpinner();
        });
    },
  });

  const { handleSubmit, values, handleChange } = formPropuesta;

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Propuesta " + propuestaEditing?.title}
              </Typography>

              <Chip
                label={Constants.getNombreEstadoPropuesta(
                  propuestaEditing?.status
                )}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Proponente: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {propuestaEditing?.memberName}
                </Typography>
                <Chip
                  label={memberTypeMap[propuestaEditing?.memberType]}
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[
                        propuestaEditing?.memberType
                      ],
                  }}
                />
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Fecha de creación: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(propuestaEditing?.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box
                  mt={1}
                  sx={{
                    maxHeight: "6em",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1">
                    {propuestaEditing ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: propuestaEditing.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ width: "100%" }}>
              <Box mt={2}>
                <Typography variant="h6" component="h2">
                  {" "}
                  Estado:{" "}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="estado de la propuesta"
                    name="_status"
                    value={values._status}
                    defaultValue={propuestaEditing?.status}
                    onChange={(event) => {
                      formPropuesta.setFieldValue(
                        "_status",
                        event.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      key={1}
                      value={1}
                      control={<Radio />}
                      label="Pendiente"
                    />
                    <FormControlLabel
                      key={2}
                      value={2}
                      control={<Radio />}
                      label="Admitir"
                    />
                    <FormControlLabel
                      key={3}
                      value={3}
                      control={<Radio />}
                      label="No admitir"
                    />
                    <FormControlLabel
                      key={4}
                      value={4}
                      control={<Radio />}
                      label="Activar"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mt={2}>
                {formPropuesta.values._status === "3" && (
                  <TextField
                    name="_statusMessage"
                    id="outlined-basic"
                    label="Mensaje de estado (opcional)"
                    variant="outlined"
                    size="small"
                    width="100%"
                    fullWidth={true}
                    multiline
                    rows={4}
                    onChange={handleChange}
                    value={values._statusMessage}
                  />
                )}
              </Box>

              <Box
                mt={2}
                sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={disableButton}
                  endIcon={<CheckCircle />}
                >
                  Confirmar
                </Button>
                <CancelButton
                  label="Cancelar"
                  onClick={() => {
                    handleClose(true, null, false);
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
