import React, { useState } from "react";
import { AddCircle, Clear, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import CompromisosFiltroAplicaA from "./CompromisosFiltroAplicaA";
import CompromisosFiltroDimensiones from "./CompromisosFiltroDimensiones";
import CompromisosFiltroODS from "./CompromisosFiltroODS";

export const CompromisosFilterMobile = ({
  filtroCodigo,
  filtroTitulo,
  setFiltroCodigo,
  setFiltroTitulo,
  handleBuscarCompromisos,
  handleAgregarCompromiso,
  isReadOnly,
  isLoading,
  filtroComunidad,
  setFiltroComunidad,
  filtroAplicaA,
  setFiltroAplicaA,
  filtroDimensiones,
  setFiltroDimensiones,
  filtroODS,
  setFiltroODS,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
      {!isReadOnly && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<AddCircle />}
            onClick={() => handleAgregarCompromiso()}
            fullWidth
          >
            Agregar Compromiso
          </Button>
        </Grid>
      )}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
        sx={{ mt: 2, mb: 2, width: "100%" }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: 48,
            "&.Mui-expanded": {
              minHeight: 48,
              height: 48,
            },
          }}
          expandIcon={
            <ExpandMore sx={{ color: theme.palette.primary.contrastText }} />
          }
        >
          <Typography>Filtrar compromisos</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!isReadOnly && (
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Código"
                  variant="outlined"
                  size="small"
                  value={filtroCodigo}
                  onChange={(newValue) =>
                    setFiltroCodigo(Number(newValue.target.value))
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                variant="outlined"
                size="small"
                value={filtroTitulo}
                onChange={(e) => setFiltroTitulo(e.target.value)}
                InputProps={{
                  endAdornment: filtroTitulo && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear filter"
                        onClick={() => setFiltroTitulo("")}
                        edge="end"
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Comunidad"
                variant="outlined"
                size="small"
                value={filtroComunidad}
                onChange={(e) => setFiltroComunidad(e.target.value)}
                InputProps={{
                  endAdornment: filtroComunidad && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear filter"
                        onClick={() => setFiltroComunidad("")}
                        edge="end"
                        size="small"
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CompromisosFiltroAplicaA
                filtroAplicaA={filtroAplicaA}
                setFiltroAplicaA={setFiltroAplicaA}
              />
            </Grid>
            <Grid item xs={12}>
              <CompromisosFiltroDimensiones
                filtroDimensiones={filtroDimensiones}
                setFiltroDimensiones={setFiltroDimensiones}
              />
            </Grid>
            <Grid item xs={12}>
              <CompromisosFiltroODS
                filtroODS={filtroODS}
                setFiltroODS={setFiltroODS}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "right", display: "flex" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleBuscarCompromisos();
                  handleAccordionChange("panel1")(null, false);
                }}
                disabled={isLoading}
                startIcon={<Search />}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
