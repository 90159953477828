import React, { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Slide,
  useTheme,
  useMediaQuery,
  Toolbar,
  Grid,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { Delete } from "@mui/icons-material";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalEliminarCompromisoMobile = ({
  open,
  handleCloseEliminarCompromiso,
  compromiso,
  eliminarCompromiso,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const formData = {
    _code: compromiso?.code,
  };

  const formEliminarCompromiso = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setDisableButton(true);
      openSpinner();
      eliminarCompromiso(compromiso?.code)
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleCloseEliminarCompromiso(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          handleCloseEliminarCompromiso(false, error, false);
        });
    },
  });

  const { handleSubmit, values } = formEliminarCompromiso;

  return (
    <Dialog
      open={open}
      onClose={() => handleCloseEliminarCompromiso(true, null, false)}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Eliminar compromiso
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleCloseEliminarCompromiso(true, null, false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom>
          {compromiso?.title}
        </Typography>
        <Typography
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
            height: "3em",
          }}
          gutterBottom
        >
          {compromiso?.description}
        </Typography>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert
            severity="warning"
            sx={{
              fontSize: "12px",
            }}
          >
            <AlertTitle>
              ¿Estás seguro que deseas eliminar el compromiso?
            </AlertTitle>
          </Alert>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              color="error"
              type="submit"
              disabled={disableButton}
              endIcon={<Delete />}
            >
              Eliminar
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                handleCloseEliminarCompromiso(true, null, false);
              }}
            />
          </Stack>
        </Grid>
      </Box>
    </Dialog>
  );
};
