import React from "react";

import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";
import * as Constants from "../../support/Constants";
import moment from "moment";
import Linkify from "linkify-react";

const Comentario = ({ comentario }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor:
              Constants.MEMBER_TYPE_COLORS[comentario.memberType],
          }}
        >
          {Constants.obtenerSiglas(comentario.memberName)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            {" "}
            <Typography variant="subtitle1">{comentario.memberName}</Typography>
            <Typography variant="caption">
              {moment(comentario.commentDate).format("DD-MM-YYYY")}
            </Typography>
          </>
        }
        secondary={
          <>
            {" "}
            <Typography variant="body1" sx={{ mt: 0.5 }}>
              <Linkify>{comentario.comment}</Linkify>
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default Comentario;
