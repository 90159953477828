import React, { useContext, useState } from "react";
import { useFormik } from "formik";

import { Unpublished } from "@mui/icons-material";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";

import SpinnerContext from "../../context/spinner/SpinnerContext";
import SociosContext from "../../context/socios/SociosContext";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalEliminarAdhesionCompromiso = ({
  open,
  compromiso,
  handleClose,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner } = useContext(SpinnerContext);
  const { eliminarAdhesionCompromiso } = useContext(SociosContext);

  const formData = {
    _socioCode: Web3Manager.MEMBER_DATA.memberCode,
    _socioType: Web3Manager.MEMBER_DATA.memberType,
    _compromisoCode: compromiso?.commitmentCode,
  };

  const formEliminarAdhesionCompromiso = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const { _socioCode, _socioType, _compromisoCode } = values;

      setDisableButton(true);
      openSpinner();
      eliminarAdhesionCompromiso(_socioCode, _socioType, _compromisoCode)
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const { handleSubmit } = formEliminarAdhesionCompromiso;

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Compromiso " + compromiso?.title}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Alert
              severity="warning"
              sx={{
                fontSize: "12px",
              }}
            >
              <AlertTitle>
                ¿Estás seguro que deseas eliminar la adhesión al compromiso?
              </AlertTitle>
            </Alert>
          </Grid>

          <Grid item>
            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={disableButton}
                endIcon={<Unpublished />}
              >
                Eliminar Adhesión
              </Button>
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleClose(true, null, false);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
