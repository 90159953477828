import {
  Box,
  Button,
  Dialog,
  Slide,
  Typography,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useState } from "react";
import * as Web3Manager from "../../support/Web3Manager";
import * as Decimals from "../../support/Decimals";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalHorasFormMobile = ({
  open,
  handleClose,
  isEditing,
  pagoEditing,
  socio,
  setLoading,
}) => {
  const [initialDate, setInitialDate] = useState(moment().format("YYYY-MM-DD"));
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _value: Yup.number()
      .typeError("Debes ingresar un número")
      .required("Debes ingresar un número")
      .positive("Debes ingresar un número mayor a 0"),

    _hoursDateFrom: Yup.string().test(
      "_hoursDateFrom",
      "La fecha ingresada no puede ser una fecha futura",
      (value) => {
        const _hoursDateFrom = moment(value, "YYYY-MM-DD")
          .startOf("day")
          .unix();
        const _tomorrow = moment().add(1, "days").startOf("day").unix();
        return _hoursDateFrom < _tomorrow;
      }
    ),
    _hoursDateTo: Yup.string().test(
      "_hoursDateTo",
      "La fecha ingresada no puede ser una fecha futura",
      (value) => {
        const _hoursDateTo = moment(value, "YYYY-MM-DD").startOf("day").unix();
        const _tomorrow = moment().add(1, "days").startOf("day").unix();
        return _hoursDateTo < _tomorrow;
      }
    ),
    _hoursDescription: Yup.string()
      .required("La descripción no puede estar vacía")
      .test(
        "_hoursDescription",
        "La descripción no puede estar vacía",
        (value) => {
          return value !== "";
        }
      ),
  });

  const initialState = {
    _hourRegisteredCode: 0,
    _value: 0,
    _hoursDateFrom: initialDate,
    _hoursDateTo: initialDate,
    _hoursDescription: "",
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _hourRegisteredCode: pagoEditing._hourRegisteredCode,
        _value: Decimals.format(pagoEditing._value),
        _hoursDateFrom: moment
          .unix(pagoEditing._hoursDateFrom)
          .format("YYYY-MM-DD"),
        _hoursDateTo: moment
          .unix(pagoEditing._hoursDateTo)
          .format("YYYY-MM-DD"),
        _hoursDescription: pagoEditing._hoursDescription,
      };
    }

    return formData;
  };

  const formPago = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _hourRegisteredCode,
        _value,
        _hoursDateFrom,
        _hoursDateTo,
        _hoursDescription,
      } = values;

      let _hoursDateFromUnix;
      let _hoursDateToUnix;

      _hoursDateFromUnix = moment(_hoursDateFrom, "YYYY-MM-DD")
        .startOf("day")
        .unix();
      _hoursDateToUnix = moment(_hoursDateTo, "YYYY-MM-DD")
        .startOf("day")
        .unix();

      if (_hoursDateFromUnix > _hoursDateToUnix) {
        handleClose(
          false,
          "La fecha de inicio no puede ser mayor a la fecha de fin",
          false
        );
        return;
      }

      const _valueParsed = Decimals.parse(_value);

      setDisableButton(true);
      setLoading(true);
      openSpinner();

      if (!isEditing) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_HOURSREGISTER,
          "addHours",
          socio._memberCode,
          socio._memberType,
          _valueParsed,
          _hoursDateFromUnix,
          _hoursDateToUnix,
          _hoursDescription
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_HOURSREGISTER,
          "modifyHours",
          socio._memberCode,
          pagoEditing._hourRegisteredCode,
          _valueParsed,
          _hoursDateFromUnix,
          _hoursDateToUnix,
          _hoursDescription
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formPago;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Editar Horas
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: "1rem" }} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Datos de la donación de horas
        </Typography>

        <Box mt={2}>
          <TextField
            name="_value"
            id="outlined-basic"
            label={"Cantidad de horas"}
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._value}
            //value={Decimals.normalize(values._value)}
            error={Boolean(touched._value && errors._value)}
            helperText={touched._value && errors._value}
          />
        </Box>

        <Box mt={2} sx={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ flexGrow: 1 }}
              name="_hoursDateFrom"
              label="Fecha Desde"
              inputFormat="DD-MM-YYYY"
              value={values._hoursDateFrom}
              onChange={(newValue) => {
                formPago.setFieldValue(
                  "_hoursDateFrom",
                  newValue.format("YYYY-MM-DD"),
                  true
                );
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth={true}
                  {...params}
                  error={Boolean(
                    touched._hoursDateFrom && errors._hoursDateFrom
                  )}
                  helperText={touched._hoursDateFrom && errors._hoursDateFrom}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box mt={2} sx={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ flexGrow: 1 }}
              name="_hoursDateTo"
              label="Fecha Hasta"
              inputFormat="DD-MM-YYYY"
              value={values._hoursDateTo}
              onChange={(newValue) => {
                formPago.setFieldValue(
                  "_hoursDateTo",
                  newValue.format("YYYY-MM-DD"),
                  true
                );
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  fullWidth={true}
                  {...params}
                  error={Boolean(touched._hoursDateTo && errors._hoursDateTo)}
                  helperText={touched._hoursDateTo && errors._hoursDateTo}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box mt={2}>
          <TextField
            name="_hoursDescription"
            id="outlined-basic"
            label={"Descripción de las tareas"}
            variant="outlined"
            size="small"
            fullWidth={true}
            multiline={true}
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._hoursDescription}
            error={Boolean(
              touched._hoursDescription && errors._hoursDescription
            )}
            helperText={touched._hoursDescription && errors._hoursDescription}
          />
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
