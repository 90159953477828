import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getMemberHours = async (code, fromDate, toDate) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/hoursRegister?code=${code}&fromDate=${fromDate}&toDate=${toDate}`,
    });

    return response.data.memberHours[0];
  } catch (error) {
    throw error;
  }
};

export const getListOfHours = async (code, fromDate, toDate) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/hoursRegister/listofhours?code=${code}&fromDate=${fromDate}&toDate=${toDate}`,
    });

    return response.data.memberHours;
  } catch (error) {
    throw error;
  }
};
