import React, { useContext, useState, useEffect } from "react";
import ReactQuill from "react-quill";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import CustomTextEditor from "../ui/CustomTextEditor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalPrincipioForm = ({
  open,
  handleClose,
  isEditing,
  principioEditing,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _enabled: Yup.bool().required(
      "Debes indicar si el principio esta o no habilitado"
    ),
    _title: Yup.string().required("Debes ingresar un titulo para el principio"),
    _description: Yup.string()
      .required("Debes ingresar una descripción para el principio")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para el principio",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
  });

  const initialState = {
    _code: 0,
    _title: "",
    _description: "",
    _enabled: true,
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _code: principioEditing.id,
        _title: principioEditing.title,
        _description: principioEditing.description,
        _enabled: principioEditing.enabled,
      };
    }

    return formData;
  };

  const formPrincipio = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _title, _description, _enabled } = values;

      setLoading(true);
      setDisableButton(true);
      openSpinner();

      if (!isEditing) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_PRINCIPLES,
          "addPrinciple",
          _title,
          _description,
          _enabled
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
            closeSpinner();
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
            closeSpinner();
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_PRINCIPLES,
          "modifyPrinciple",
          principioEditing.id,
          _title,
          _description,
          _enabled
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formPrincipio;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Principio
        </Typography>

        <Box mt={2}>
          <TextField
            name="_title"
            id="outlined-basic"
            label="Título"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._title}
            error={Boolean(touched._title && errors._title)}
            helperText={touched._title && errors._title}
          />
        </Box>
        <Box mt={2} sx={{ borderRadius: "4px" }}>
          <CustomTextEditor
            name="_description"
            placeholder="Descripción"
            value={values._description}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
        {touched._description && errors._description && (
          <Typography
            sx={{
              fontSize: "0.75rem",
              marginLeft: "14px",
              marginRight: "14px",
              marginTop: "4px",
            }}
            variant="body2"
            color="error"
          >
            {errors._description}
          </Typography>
        )}
        <br />

        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="_enabled"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values._enabled}
                checked={values._enabled}
                error={Boolean(touched._enabled && errors._enabled)}
                helperText={touched._enabled && errors._enabled}
              />
            }
            label="Habilitado?"
          />
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Confirmar
          </Button>

          <CancelButton
            label="Cancelar"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
