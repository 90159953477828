import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Search, ZoomIn, Delete, Edit } from "@mui/icons-material";

import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import moment from "moment";
import { PropuestasFilterMobile } from "./PropuestasFilterMobile";
import { ModalPropuestaFormMobile } from "./ModalPropuestaFormMobile";
import { ModalVotarPropuestaMobile } from "./ModalVotarPropuestaMobile";
import { ModalEliminarPropuestaMobile } from "./ModalEliminarPropuestaMobile";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

export const PropuestasCard = ({
  handleEditarPropuesta,
  handleEliminarPropuesta,
  handleOpenVoteModal,
  handleCloseModalPropuesta,
  handleCloseModalVotar,
  handleCloseModalEliminar,
  openModalPropuesta,
  openModalEliminarPropuesta,
  openVoteModal,
  modeEdit,
  roles,
  categoriasPropuesta,
  propuestasData,
  loading,
  setLoading,
  propuestaSelected,
  showSpinner,
  setFiltroCategoria,
  filtroCategoria,
  setFiltroTitulo,
  filtroTitulo,
  setFiltroEstado,
  filtroEstado,
  getPropuestas,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <PropuestasFilterMobile
        filtroTitulo={filtroTitulo}
        setFiltroTitulo={setFiltroTitulo}
        filtroEstado={filtroEstado}
        setFiltroEstado={setFiltroEstado}
        filtroCategoria={filtroCategoria}
        setFiltroCategoria={setFiltroCategoria}
        categoriasPropuesta={categoriasPropuesta}
        loading={loading}
        getPropuestas={getPropuestas}
        Search={Search}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {loading && !showSpinner ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : propuestasData.length === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography align="center" variant="body1" color="text.secondary">
              No hay propuestas para mostrar.
            </Typography>
          </Box>
        ) : (
          propuestasData.map((propuestas) => {
            const isActive =
              propuestas.status === Constants.PROPOSAL_STATUS_ACTIVE;
            const canVote =
              isActive &&
              Web3Manager.MEMBER_DATA.memberCategory ===
                Constants.CATEGORY_ACTIVO &&
              (propuestas.role === 0 ||
                Web3Manager.MEMBER_DATA.memberRoles.includes(propuestas.role));

            const isCreator =
              propuestas.status === Constants.PROPOSAL_STATUS_PENDING &&
              propuestas.memberAddress.toLowerCase() ===
                Web3Manager.getAccountToDisplay().toLowerCase();
            return (
              <Grid item xs={12} sm={6} md={4} key={propuestas.code}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {propuestas.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Estado:{" "}
                      {Constants.getNombreEstadoPropuesta(propuestas.status) ||
                        "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Categoria: {propuestas.category}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fecha apertura:{" "}
                      {moment(propuestas.openingDate).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fecha Cierre:{" "}
                      {moment(propuestas.closingDate).format("DD-MM-YYYY")}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={canVote ? <HowToVoteIcon /> : <ZoomIn />}
                          onClick={() => handleOpenVoteModal(propuestas)}
                          color="primary"
                          disabled={loading}
                          size="small"
                        >
                          {canVote ? "Votar" : "Detalle"}
                        </Button>
                      </Grid>
                      {isCreator && (
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<Edit />}
                            onClick={() => handleEditarPropuesta(propuestas)}
                            color="primary"
                            disabled={loading}
                            size="small"
                          >
                            Editar
                          </Button>
                        </Grid>
                      )}
                      {isCreator && (
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<Delete />}
                            onClick={() => handleEliminarPropuesta(propuestas)}
                            color="primary"
                            disabled={loading}
                            size="small"
                          >
                            Eliminar
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        )}
      </Box>
      {roles && (
        <ModalPropuestaFormMobile
          open={openModalPropuesta}
          handleClose={handleCloseModalPropuesta}
          categoriasPropuesta={categoriasPropuesta}
          isEditing={modeEdit}
          propuestaEditing={propuestaSelected}
          roles={roles}
          setLoading={setLoading}
        />
      )}
      {propuestaSelected && (
        <ModalVotarPropuestaMobile
          open={openVoteModal}
          propuesta={propuestaSelected}
          handleClose={handleCloseModalVotar}
          setLoading={setLoading}
          isReadOnly={false}
        />
      )}
      <ModalEliminarPropuestaMobile
        open={openModalEliminarPropuesta}
        propuesta={propuestaSelected}
        handleClose={handleCloseModalEliminar}
        setLoading={setLoading}
      />
    </Box>
  );
};
