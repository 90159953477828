import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";

const CompromisosFiltroAplicaA = ({ filtroAplicaA, setFiltroAplicaA }) => {
  const opcionesAplicaA = ["Personas", "Empresas", "Aplicaciones"];

  const handleChangeFiltroAplicaA = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "Seleccionar todas") {
      setFiltroAplicaA(opcionesAplicaA);
    } else if (value[value.length - 1] === "Limpiar selección") {
      setFiltroAplicaA([]);
    } else {
      setFiltroAplicaA(value);
    }
  };

  return (
    <TextField
      id="filtro-aplica-a"
      select
      label="Aplicable a"
      size="small"
      value={filtroAplicaA ?? []}
      onChange={handleChangeFiltroAplicaA}
      SelectProps={{
        multiple: true,
        renderValue: (selected) => selected.join(", "),
      }}
      variant="outlined"
      fullWidth
      sx={{ minWidth: 200 }}
    >
      {opcionesAplicaA.map((opcion) => (
        <MenuItem key={opcion} value={opcion}>
          <Checkbox checked={filtroAplicaA?.indexOf(opcion) > -1} />
          <ListItemText primary={opcion} />
        </MenuItem>
      ))}
      <MenuItem value="Seleccionar todas">
        <ListItemText primary="Seleccionar todas" />
      </MenuItem>
      <MenuItem value="Limpiar selección">
        <ListItemText primary="Limpiar selección" />
      </MenuItem>
    </TextField>
  );
};

export default CompromisosFiltroAplicaA;
