import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGrid } from "@mui/x-data-grid";

import Edit from "@mui/icons-material/Edit";
import {
  Delete,
  DeleteForever,
  NotInterested,
  Restore,
  Search,
} from "@mui/icons-material";
import { ModalPrincipioForm } from "../../components/principios/ModalPrincipioForm";
import TextField from "@mui/material/TextField";
import { useNotification } from "../../hooks/useNotification";
import { ModalPrincipioDetalle } from "../../components/principios/ModalPrincipioDetalle";
import { ModalEliminarPrincipio } from "../../components/principios/ModalEliminarPrincipio";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { getPrinciples } from "../../services/PrinciplesServices";

const PrincipiosPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [filtroCodigo, setFiltroCodigo] = useState(0);
  const [filtroTitulo, setFiltroTitulo] = useState("");
  const [principios, setPrincipios] = useState([]);
  const [openModalPrincipio, setOpenModalPrincipio] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [principio, setPrincipio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModalDetallePrincipio, setOpenModalDetallePrincipio] =
    useState(false);
  const [openEliminarPrincipio, setOpenEliminarPrincipio] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const handleOpenModalDetallePrincipio = (principio) => {
    setOpenModalDetallePrincipio(true);
    setPrincipio(principio);
  };

  const handleCloseModalDetallePrincipio = () => {
    setOpenModalDetallePrincipio(false);
    setPrincipio(null);
  };

  const getPrincipios = () => {
    setLoading(true);
    getPrinciples(filtroCodigo, filtroTitulo)
      .then((response) => {
        setPrincipios(response);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const handleAgregarPrincipio = () => {
    setOpenModalPrincipio(true);
    setModeEdit(false);
    setPrincipio(null);
  };

  const handleEditarPrincipio = (principioSelected) => {
    setOpenModalPrincipio(true);
    setModeEdit(true);
    setPrincipio(principioSelected);
  };

  const handleCloseModalPrincipio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPrincipios();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenModalPrincipio(false);
    setPrincipio(null);
    setModeEdit(false);
  };

  const handleHabilitarPrincipio = (principioSelected) => {
    setLoading(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_PRINCIPLES,
      "enablePrinciple",
      principioSelected.id
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getPrincipios();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
        closeSpinner();
      });
  };

  const handleDeshabilitarPrincipio = (principioSelected) => {
    setLoading(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_PRINCIPLES,
      "disablePrinciple",
      principioSelected.id
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getPrincipios();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
        closeSpinner();
      });
  };

  const handleEliminarPrincipio = (principioSelected) => {
    setOpenEliminarPrincipio(true);
    setPrincipio(principioSelected);
  };

  const handleCloseEliminarPrincipio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPrincipios();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenEliminarPrincipio(false);
    setPrincipio(null);
  };

  const columnsPrincipios = [
    {
      field: "code",
      headerName: "Cod.",
      flex: 0.2,
      minWidth: 15,
      renderCell: (params) => {
        return params.row.id;
      },
    },
    {
      field: "title",
      headerName: "Título",
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 2.5,
      minWidth: 300,
      renderCell: (params) => {
        const removeHTMLTags = (text) => {
          const regex = /(<([^>]+)>)/gi;
          return text.replace(regex, " ");
        };
        return removeHTMLTags(params.value);
      },
    },
    {
      field: "enabled",
      headerName: "Habilitado?",
      flex: 0.5,
      minWidth: 15,
      valueGetter: (params) => {
        return params.value ? "SI" : "NO";
      },
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              disabled={loading}
              icon={<Search />}
              label="Ver"
              onClick={() => handleOpenModalDetallePrincipio(params.row)}
            />
            <GridActionItem
              disabled={loading}
              icon={<Edit />}
              label="Editar"
              onClick={() => handleEditarPrincipio(params.row)}
            />

            {params.row.enabled && (
              <GridActionItem
                disabled={loading}
                icon={<NotInterested />}
                label="Deshabilitar"
                onClick={() => handleDeshabilitarPrincipio(params.row)}
              />
            )}
            {!params.row.enabled && (
              <GridActionItem
                disabled={loading}
                icon={<Restore />}
                label="Habilitar"
                onClick={() => handleHabilitarPrincipio(params.row)}
              />
            )}

            <GridActionItem
              disabled={loading}
              icon={<Delete />}
              label="Eliminar"
              onClick={() => handleEliminarPrincipio(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getPrincipios();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Gestión de principios
        </Typography>
      </Box>

      <Grid container>
        <Grid item lg={4}>
          <Box sx={{ justifyContent: "left", display: "flex" }} pb={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAgregarPrincipio()}
              startIcon={<AddCircleIcon />}
              disabled={showSpinner}
            >
              Agregar
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ justifyContent: "right", display: "flex" }} pb={3}>
            <TextField
              sx={{ width: 100 }}
              id="outlined-basic"
              label="Código"
              variant="outlined"
              size="small"
              value={filtroCodigo}
              onChange={(newValue) =>
                setFiltroCodigo(Number(newValue.target.value))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              sx={{ marginLeft: 1, width: 300 }}
              id="outlined-basic"
              label="Título"
              variant="outlined"
              size="small"
              value={filtroTitulo}
              onChange={(newValue) => setFiltroTitulo(newValue.target.value)}
            />

            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              onClick={() => getPrincipios()}
              disabled={loading}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>

      <DataGrid
        rows={principios}
        pageSize={50}
        autoHeight={true}
        loading={loading && !showSpinner}
        columns={columnsPrincipios}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[50]}
      />

      <br />

      <ModalPrincipioForm
        open={openModalPrincipio}
        handleClose={handleCloseModalPrincipio}
        isEditing={modeEdit}
        principioEditing={principio}
        setLoading={setLoading}
      />

      {openModalDetallePrincipio && (
        <ModalPrincipioDetalle
          open={openModalDetallePrincipio}
          handleClose={handleCloseModalDetallePrincipio}
          principio={principio}
        />
      )}

      {openEliminarPrincipio && (
        <ModalEliminarPrincipio
          open={openEliminarPrincipio}
          principio={principio}
          handleClose={handleCloseEliminarPrincipio}
          setLoading={setLoading}
        />
      )}
    </Container>
  );
};

export default withRouter(PrincipiosPage);
