import React from "react";
import * as Web3Manager from "../../support/Web3Manager";
import { Box, Chip, Grid, Stack, Tooltip } from "@mui/material";
import {
  Accessibility,
  AccountBalance,
  AssignmentTurnedIn,
  Business,
  Groups,
  PendingActions,
  PhoneAndroid,
  Public,
  Unpublished,
  ZoomIn,
} from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { DataGrid, esES } from "@mui/x-data-grid";

import { GridActionItem } from "../../components/ui/GridActionItem";
import * as Constants from "../../support/Constants";

export const MisCompromisosTable = ({
  getEstadoCompromisoSocio,
  compromisosSocio,
  isLoading,
  getDeadlineDate,
  handleIrDetalleCompromiso,
  setCompromisoEditing,
  setOpenModalAdherirse,
  setOpenModalEliminarAdhesionCompromiso,
  showSpinner,
}) => {
  const columnsCompromisos = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "applicationName",
      headerName: "Aplicación",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "community",
      headerName: "Comunidad",
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: "appliesTo",
      headerName: "Aplicable a",
      flex: 1,
      minWidth: 175,
      renderCell: (params) => {
        const { appliesToPersons, appliesToCompanies, appliesToServices } =
          params.row;

        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title="Personas" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <Accessibility
                    color={appliesToPersons ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Empresas" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <Business
                    color={appliesToCompanies ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Aplicaciones" placement="top" arrow>
                <Box bgcolor="#F5F5F5" borderRadius={4} p={1}>
                  <PhoneAndroid
                    color={appliesToServices ? "primary" : "disabled"}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "ESGDimensions",
      headerName: "Dimensiones ESG",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        const { dimensionE, dimensionS, dimensionG } = params.row;

        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title="Environment" placement="top" arrow>
              <div>
                <Chip
                  icon={<Public />}
                  label="(E)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionE}
                />
              </div>
            </Tooltip>
            <Tooltip title="Social" placement="top" arrow>
              <div>
                <Chip
                  icon={<Groups />}
                  label="(S)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionS}
                />
              </div>
            </Tooltip>
            <Tooltip title="Governance" placement="top" arrow>
              <div>
                <Chip
                  icon={<AccountBalance />}
                  label="(G)"
                  color="primary"
                  variant="outlined"
                  disabled={!dimensionG}
                />
              </div>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "statusCompromiso",
      headerName: "Estado",
      flex: 1,
      minWidth: 175,
      renderCell: (params) => {
        const status = getEstadoCompromisoSocio(params.row);

        // Función para determinar el icono y el texto del chip basado en el estado
        const getChipInfo = () => {
          switch (status) {
            case "Cumple":
              return {
                icon: <AssignmentTurnedIn style={{ color: "green" }} />,
                label: "Cumple",
              };
            case "Tiene intención":
              return {
                icon: <PendingActions style={{ color: "orange" }} />,
                label: "Tiene intención",
              };
            default:
              return { icon: null, label: "Desconocido" };
          }
        };

        const { icon, label } = getChipInfo();

        return (
          <Chip
            sx={{ p: 2 }}
            icon={icon}
            label={label}
            variant="outlined"
            size="small"
          />
        );
      },
    },
    {
      field: "deadlineDate",
      headerName: "Fecha límite",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        return getDeadlineDate(params.row);
      },
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={isLoading}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleIrDetalleCompromiso(params.row)}
            />
            {params.row.applicationId == 0 && (
              <>
                <GridActionItem
                  disabled={isLoading}
                  icon={<Edit />}
                  label="Editar"
                  onClick={() => {
                    setOpenModalAdherirse(true);
                    setCompromisoEditing(params.row);
                  }}
                />
                <GridActionItem
                  disabled={isLoading}
                  icon={<Unpublished />}
                  label="Eliminar Adhesión"
                  onClick={() => {
                    setOpenModalEliminarAdhesionCompromiso(true);
                    setCompromisoEditing(params.row);
                  }}
                />
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  if (Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_PERSONA) {
    columnsCompromisos.splice(1, 1); // remove application column if user is person
  }

  return (
    <>
      <DataGrid
        rows={compromisosSocio ? compromisosSocio : []}
        pageSize={50}
        autoHeight={true}
        loading={isLoading && !showSpinner}
        columns={columnsCompromisos}
        getRowId={(row) => row.commitmentCode + "," + row.applicationId}
        rowsPerPageOptions={[50]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />
    </>
  );
};
