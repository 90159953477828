import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ErrorNotFound from "../components/error/ErrorNotFound";
import ErrorConnection from "../components/error/ErrorConnection";
import ErrorBoundary from "../components/error/ErrorBoundary";
import ErrorAuthorization from "../components/error/ErrorAuthorization";
import ErrorRegistroIncompatible from "../components/error/ErrorRegistroIncompatible";
import ErrorSocioInhabilitado from "../components/error/ErrorSocioInhabilitado";
import ErrorSocioPendiente from "../components/error/ErrorSocioPendiente";
import ErrorSocioNoRegistrado from "../components/error/ErrorSocioNoRegistrado";

import { default as HomePage } from "../view/HomePage";
import { default as ContractsPage } from "../view/ContractsPage";

import { default as AdminSociosPage } from "../view/administracion/AdminSociosPage";
import { default as PersonaPage } from "../view/socio/PersonaPage";
import { default as EmpresaPage } from "../view/socio/EmpresaPage";
import { default as ServiciosPage } from "../view/socio/ServiciosPage";
import { default as HorasPage } from "../view/socio/HorasPage";

import { default as PropuestasPage } from "../view/propuestas/PropuestasPage";

import { default as ParametrosPage } from "../view/administracion/ParametrosPage";
import { default as PrincipiosPage } from "../view/administracion/PrincipiosPage";
import { default as AdminCompromisosPage } from "../view/administracion/AdminCompromisosPage";
import { default as AdminPropuestasPage } from "../view/administracion/AdminPropuestasPage";
import { default as AdminHorasPage } from "../view/administracion/AdminHorasPage";
import { default as ColaboracionesPage } from "../view/colaboraciones/ColaboracionesPage";
import { default as ReportesPage } from "../view/administracion/ReportesPage";
import { default as NotificacionesSocioPage } from "../view/administracion/NotificacionesSocioPage";
import CompromisosState from "../context/compromisos/CompromisosState";
import ServiciosState from "../context/servicios/ServiciosState";
import SociosState from "../context/socios/SociosState";
import SociosPage from "../view/socios/SociosPage";
import MisCompromisosPage from "../view/socio/MisCompromisosPage";
import CompromisosPage from "../view/compromisos/CompromisosPage";
import CompromisoSociosAdheridosPage from "../view/compromisos/CompromisoSociosAdheridosPage";
import AplicacionesPage from "../view/aplicaciones/AplicacionesPage";
import ScrollToTop from "../components/utiles/ScrollToTop";

const RoutesConfig = ({ member }) => (
  <Switch>
    <Route path={"/"} exact>
      <Redirect to={"/view/homePage"} />
    </Route>

    <Route
      path={"/errorAuthorization"}
      exact
      render={() => <ErrorAuthorization />}
    />

    <Route path={"/errorConnection"} exact render={() => <ErrorConnection />} />

    <Route
      path={"/errorRegistroIncompatible"}
      exact
      render={() => <ErrorRegistroIncompatible />}
    />

    <Route
      path={"/errorSocioInhabilitado"}
      exact
      render={() => <ErrorSocioInhabilitado />}
    />

    <Route
      path={"/errorSocioPendiente"}
      exact
      render={() => <ErrorSocioPendiente />}
    />

    <Route
      path={"/errorSocioNoRegistrado"}
      exact
      render={() => <ErrorSocioNoRegistrado />}
    />

    <Route
      path={"/view/HomePage"}
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <HomePage member={member} />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/ContractsPage"}
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <ContractsPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/PersonaPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <PersonaPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/EmpresaPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <EmpresaPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/HorasPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <HorasPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/MisColaboracionesPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <CompromisosState>
              <ScrollToTop>
                <ColaboracionesPage />
              </ScrollToTop>
            </CompromisosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/ServiciosPage/:empresaId"}
      exact
      render={(match) => (
        <ErrorBoundary>
          <ServiciosState>
            <CompromisosState>
              <ScrollToTop>
                <ServiciosPage />
              </ScrollToTop>
            </CompromisosState>
          </ServiciosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/socio/MisCompromisosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <CompromisosState>
            <SociosState>
              <ServiciosState>
                <ScrollToTop>
                  <MisCompromisosPage />
                </ScrollToTop>
              </ServiciosState>
            </SociosState>
          </CompromisosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/PropuestasPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <PropuestasPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/ColaboracionesPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <CompromisosState>
            <SociosState>
              <ScrollToTop>
                <ColaboracionesPage />
              </ScrollToTop>
            </SociosState>
          </CompromisosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/SociosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <CompromisosState>
              <ServiciosState>
                <ScrollToTop>
                  <SociosPage />
                </ScrollToTop>
              </ServiciosState>
            </CompromisosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/CompromisosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <CompromisosState>
              <ScrollToTop>
                <CompromisosPage />
              </ScrollToTop>
            </CompromisosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/compromiso/CompromisoSociosAdheridosPage/:compromisoCode"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <CompromisosState>
              <ServiciosState>
                <ScrollToTop>
                  <CompromisoSociosAdheridosPage />
                </ScrollToTop>
              </ServiciosState>
            </CompromisosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/AplicacionesPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <ServiciosState>
              <CompromisosState>
                <ScrollToTop>
                  <AplicacionesPage />
                </ScrollToTop>
              </CompromisosState>
            </ServiciosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/ParametrosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <ParametrosPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/PrincipiosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <PrincipiosPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/AdminCompromisosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <CompromisosState>
            <ScrollToTop>
              <AdminCompromisosPage />
            </ScrollToTop>
          </CompromisosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/SociosPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <CompromisosState>
              <ServiciosState>
                <ScrollToTop>
                  <AdminSociosPage />
                </ScrollToTop>
              </ServiciosState>
            </CompromisosState>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/PropuestasPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <AdminPropuestasPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/ReportesPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <SociosState>
            <ScrollToTop>
              <ReportesPage />
            </ScrollToTop>
          </SociosState>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/NotificacionesSocioPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <NotificacionesSocioPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route
      path={"/view/administracion/HorasPage"}
      exact
      render={() => (
        <ErrorBoundary>
          <ScrollToTop>
            <AdminHorasPage />
          </ScrollToTop>
        </ErrorBoundary>
      )}
    />

    <Route component={ErrorNotFound} />
  </Switch>
);

export default RoutesConfig;
