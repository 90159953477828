import React from 'react';

import { Box, Button, Grid, Stack, useMediaQuery } from '@mui/material';

import * as Decimals from '../../support/Decimals';
import TextField from '@mui/material/TextField';
import { Delete, Search } from '@mui/icons-material';
import moment from 'moment';
import Edit from '@mui/icons-material/Edit';
import { DataGrid, esES } from '@mui/x-data-grid';

import { ModalHorasForm } from '../../components/horas/ModalHorasForm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { GridActionItem } from '../../components/ui/GridActionItem';
import { HorasFilterMobile } from './HorasFilterMobile';

export const HorasTable = ({
  pagos,
  loading,
  handleEditarPago,
  handleEliminarPago,
  openModalPago,
  filtroDesde,
  setFiltroDesde,
  filtroHasta,
  setFiltroHasta,
  getHoras,
  showSpinner,
  CustomFooter,
  handleCloseModalPago,
  modeEdit,
  pago,
  socio,
  setLoading,
}) => {
  const columnsPagos = [
    {
      field: '_value',
      headerName: 'Horas',
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return Decimals.format(params.row._value);
      },
    },
    {
      field: '_dateInfo',
      headerName: 'Fecha(s)',
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        return (
          moment.unix(params.row._hoursDateFrom).format('DD-MM-YYYY') +
          ' - ' +
          moment.unix(params.row._hoursDateTo).format('DD-MM-YYYY')
        );
      },
    },
    {
      field: '_hoursDescription',
      headerName: 'Descripción de tareas',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'action',
      headerName: 'Acciones',
      sortable: false,
      flex: 0.3,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            <GridActionItem
              icon={<Edit />}
              label='Editar'
              onClick={() => handleEditarPago(params.row)}
              disabled={loading}
            />

            <GridActionItem
              icon={<Delete />}
              label='Eliminar'
              onClick={() => handleEliminarPago(params.row)}
              disabled={loading}
            />
          </Stack>
        );
      },
    },
  ];
  const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      <Grid container>
        {matches ? (
          <HorasFilterMobile
            filtroDesde={filtroDesde}
            setFiltroDesde={setFiltroDesde}
            filtroHasta={filtroHasta}
            setFiltroHasta={setFiltroHasta}
            loading={loading}
            getHoras={getHoras}
          />
        ) : (
          <Grid item lg={12}>
            <Box
              mb={2.5}
              mt={1}
              sx={{ justifyContent: 'right', display: 'flex' }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label='Fecha Desde'
                  inputFormat='DD-MM-YYYY'
                  value={filtroDesde}
                  onChange={(newValue) =>
                    setFiltroDesde(newValue.format('YYYY-MM-DD'))
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: 180 }}
                      size='small'
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label='Fecha Hasta'
                  inputFormat='DD-MM-YYYY'
                  value={filtroHasta}
                  onChange={(newValue) =>
                    setFiltroHasta(newValue.format('YYYY-MM-DD'))
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginLeft: 1, width: 180 }}
                      size='small'
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
              <Button
                sx={{ marginLeft: 1 }}
                variant='contained'
                color='primary'
                disabled={loading}
                onClick={() => getHoras()}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <DataGrid
        rows={pagos}
        pageSize={50}
        rowsPerPageOptions={[50]}
        autoHeight={true}
        columns={columnsPagos}
        loading={loading && !showSpinner}
        getRowId={(row) => row._hourRegisteredCode}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        components={{ Footer: CustomFooter }}
      />

      <br />

      {openModalPago && (
        <ModalHorasForm
          open={openModalPago}
          handleClose={handleCloseModalPago}
          isEditing={modeEdit}
          pagoEditing={pago}
          socio={socio}
          setLoading={setLoading}
        />
      )}
    </>
  );
};
