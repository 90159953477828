import React from "react";
import {Grid, Typography} from "@mui/material";
import Container from "@mui/material/Container";

export default () =>
    <Container maxWidth="lg">
        <Grid container>
            <Grid item md={12}>
                <Typography variant='h4' align='center' p={5}>
                    Página no encontrada
                </Typography>
            </Grid>
        </Grid>
    </Container>