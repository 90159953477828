import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const HtmlContentDisplay = ({ content }) => {
  const style = {
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "18.5px 14px",
    fontSize: "0.875rem",
    overflowY: "auto",
    maxHeight: "200px",
  };

  return (
    <Box component="div" sx={style}>
      <Typography variant="body1" color={"#005A59"}>
        {content ? <div dangerouslySetInnerHTML={{ __html: content }} /> : ""}
      </Typography>
    </Box>
  );
};

export default HtmlContentDisplay;
