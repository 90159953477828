import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getApplications = async (companyId, code, name) => {
  try {
    // Construir la URL dinámicamente con parámetros opcionales
    let url = `${process.env.REACT_APP_BACKEND_URL}/applications`;

    // Añadir parámetros solo si están definidos
    if (companyId !== undefined) {
      url += `?companyId=${companyId}`;
    }
    if (code !== undefined) {
      url += `${companyId !== undefined ? "&" : "?"}code=${code}`;
    }
    if (name !== undefined) {
      url += `${
        companyId !== undefined || code !== undefined ? "&" : "?"
      }name=${name}`;
    }

    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    });

    return response.data.applications;
  } catch (error) {
    throw error;
  }
};
