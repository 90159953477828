import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TextField,
  Stack,
  Grid,
  Button,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { useTheme, useMediaQuery } from "@mui/material";
import { GridActionItem } from "../ui/GridActionItem";
import { Edit } from "@mui/icons-material";
import { ModalSustainableDevelopmentGoalsMobile } from "./ModalSustainableDevelopmentGoalsMobile";
import { ModalComunidad } from "./ModalComunidad";
import CustomTextEditor from "../ui/CustomTextEditor";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalCompromisoFormMobile = ({
  open,
  handleCloseModalCompromiso,
  compromiso,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [disableButton, setDisableButton] = useState(false);
  const [openDevelompentGoalsModal, setOpenDevelopmentGoalsModal] =
    useState(false);
  const [selectedDevelopmentGoals, setSelectedDevelopmentGoals] = useState([]);
  const [communities, setCommunities] = useState([]);

  const [openModalComunidad, setOpenModalComunidad] = useState(false);

  const { isLoading, crearCompromiso, modificarCompromiso, getComunidades } =
    useContext(CompromisosContext);

  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    getComunidades().then((result) => {
      setCommunities(result);
    });
  }, []);

  const handleOpenDevelopmentGoalsModal = () => {
    setSelectedDevelopmentGoals(
      formCompromiso.values._sustainableDevelopmentGoals
    );
    setOpenDevelopmentGoalsModal(true);
  };

  const handleOpenModalComunidad = () => {
    setOpenModalComunidad(true);
  };

  const handleCloseDevelopmentGoalsModal = () => {
    setOpenDevelopmentGoalsModal(false);
  };

  const handleCloseModalComunidad = () => {
    setOpenModalComunidad(false);
  };

  const handleSubmitComunidad = (comunidad) => {
    const comunidades = [...new Set(communities.concat(comunidad))];
    setCommunities(comunidades);
    formCompromiso.setFieldValue("_community", comunidad);
    setOpenModalComunidad(false);
  };

  const validationSchema = Yup.object({
    _title: Yup.string().required(
      "Debes ingresar un titulo para el compromiso"
    ),
    _description: Yup.string()
      .required("Debes ingresar una descripción para el compromiso")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para el compromiso",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
    _community: Yup.string().required(
      "Debes ingresar una comunidad para el compromiso"
    ),

    _enabled: Yup.bool().required(
      "Debes indicar si el compromiso esta o no habilitado"
    ),
    _dimensionS: Yup.boolean(),
    _dimensionG: Yup.boolean(),
    _dimensionE: Yup.boolean(),
    _appliesToPersons: Yup.boolean(),
    _appliesToCompanies: Yup.boolean(),
    _appliesToServices: Yup.boolean(),
  })
    .test("_dimensionsCheck", null, (values) => {
      if (values._dimensionE || values._dimensionS || values._dimensionG) {
        return true;
      }

      return new Yup.ValidationError(
        "Debes seleccionar al menos una dimensión (E, S, G)",
        null,
        "_dimensionsCheck"
      );
    })
    .test("_apliesToCheck", null, (values) => {
      if (
        values._appliesToPersons ||
        values._appliesToCompanies ||
        values._appliesToServices
      ) {
        return true;
      }

      return new Yup.ValidationError(
        "Debes seleccionar al menos una entidad que aplica",
        null,
        "_apliesToCheck"
      );
    });

  const initialState = {
    _code: 0,
    _title: "",
    _description: "",
    _community: "",
    _enabled: true,
    _appliesToPersons: false,
    _appliesToCompanies: false,
    _appliesToServices: false,
    _dimensionE: false,
    _dimensionS: false,
    _dimensionG: false,
    _sustainableDevelopmentGoals: [],
  };

  const getFormData = () => {
    let formData;

    if (!compromiso) {
      formData = initialState;
    } else {
      formData = {
        _code: compromiso.code,
        _title: compromiso.title,
        _description: compromiso.description,
        _community: compromiso.community,
        _enabled: compromiso.enabled,
        _appliesToPersons: compromiso.appliesToPersons,
        _appliesToCompanies: compromiso.appliesToCompanies,
        _appliesToServices: compromiso.appliesToServices,
        _dimensionE: compromiso.dimensionE,
        _dimensionS: compromiso.dimensionS,
        _dimensionG: compromiso.dimensionG,
        _sustainableDevelopmentGoals: compromiso.sustainableDevelopmentGoals,
      };
    }

    return formData;
  };

  const formCompromiso = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _title,
        _description,
        _community,
        _enabled,
        _appliesToPersons,
        _appliesToCompanies,
        _appliesToServices,
        _dimensionE,
        _dimensionS,
        _dimensionG,
        _sustainableDevelopmentGoals,
      } = values;

      setDisableButton(true);
      openSpinner();

      if (!compromiso) {
        crearCompromiso(
          _title,
          _description,
          _community,
          _enabled,
          [_appliesToPersons, _appliesToCompanies, _appliesToServices],
          [_dimensionE, _dimensionS, _dimensionG],
          _sustainableDevelopmentGoals
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleCloseModalCompromiso(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            handleCloseModalCompromiso(false, error, false);
          });
      } else {
        modificarCompromiso(
          compromiso.code,
          _title,
          _description,
          _community,
          _enabled,
          [_appliesToPersons, _appliesToCompanies, _appliesToServices],
          [_dimensionE, _dimensionS, _dimensionG],
          _sustainableDevelopmentGoals
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleCloseModalCompromiso(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            handleCloseModalCompromiso(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formCompromiso;

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => {
          resetForm();
          handleCloseModalCompromiso(true, null, false);
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {compromiso ? "Editar Compromiso" : "Nuevo Compromiso"}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleCloseModalCompromiso(true, null, false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            id="_title"
            name="_title"
            label="Título"
            variant="outlined"
            value={values._title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched._title && Boolean(errors._title)}
            helperText={touched._title && errors._title}
          />

          <Box mt={2} sx={{ borderRadius: "4px" }}>
            <CustomTextEditor
              name="_description"
              placeholder="Descripción"
              value={values._description}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          {touched._description && errors._description && (
            <Typography
              sx={{
                fontSize: "0.75rem",
                marginLeft: "14px",
                marginRight: "14px",
                marginTop: "4px",
              }}
              variant="body2"
              color="error"
            >
              {errors._description}
            </Typography>
          )}
          <br />
          <TextField
            margin="normal"
            id="_community"
            name="_community"
            label="Comunidad"
            variant="outlined"
            fullWidth={true}
            select={true}
            defaultValue=""
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._community ?? ""}
            error={touched._community && Boolean(errors._community)}
            helperText={touched._community && errors._community}
          >
            {communities?.map((comunidad) => (
              <MenuItem key={comunidad} value={comunidad}>
                {comunidad}
              </MenuItem>
            ))}
          </TextField>
          <Button
            sx={{ marginTop: 1 }}
            variant="contained"
            color="primary"
            onClick={handleOpenModalComunidad}
            startIcon={<AddCircleIcon />}
          >
            Agregar
          </Button>
          <br />

          <FormControlLabel
            control={
              <Checkbox
                name="_enabled"
                checked={values._enabled}
                onChange={handleChange}
                sx={{
                  "& .MuiSvgIcon-root": {
                    width: 32,
                    height: 32,
                  },
                  "& .Mui-checked": {
                    "& .MuiSvgIcon-root": {
                      width: 32,
                      height: 32,
                    },
                  },
                }}
              />
            }
            label="Habilitado"
          />
          <Box mt={2}>
            <Grid container alignItems="center" mt={2}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: "1.0rem" }}
                  >
                    ODS en los que impacta
                  </Typography>
                </Grid>
                <Grid item ml={2}>
                  <GridActionItem
                    label="Editar"
                    icon={<Edit />}
                    onClick={() => {
                      handleOpenDevelopmentGoalsModal();
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item>
                  <>
                    <Typography>
                      {values._sustainableDevelopmentGoals.join(", ")}
                    </Typography>
                    {touched._sustainableDevelopmentGoals &&
                      errors._sustainableDevelopmentGoals && (
                        <Typography variant="body2" color="error">
                          {errors._sustainableDevelopmentGoals}
                        </Typography>
                      )}
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              {" "}
              Dimensiones ESG:{" "}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="_dimensionE"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._dimensionE}
                  checked={values._dimensionE}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 32,
                      height: 32,
                    },
                    "& .Mui-checked": {
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    },
                  }}
                />
              }
              label="E"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="_dimensionS"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._dimensionS}
                  checked={values._dimensionS}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 32,
                      height: 32,
                    },
                    "& .Mui-checked": {
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    },
                  }}
                />
              }
              label="S"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="_dimensionG"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._dimensionG}
                  checked={values._dimensionG}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 32,
                      height: 32,
                    },
                    "& .Mui-checked": {
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    },
                  }}
                />
              }
              label="G"
            />
            {errors._dimensionsCheck ? (
              <Typography variant="body2" color="error">
                {errors._dimensionsCheck}
              </Typography>
            ) : null}
          </Box>

          <Box mt={2}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{ fontWeight: "bold", fontSize: "1.0rem" }}
            >
              {" "}
              Aplica para:{" "}
            </Typography>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="_appliesToPersons"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values._appliesToPersons}
                    checked={values._appliesToPersons}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                      "& .Mui-checked": {
                        "& .MuiSvgIcon-root": {
                          width: 32,
                          height: 32,
                        },
                      },
                    }}
                  />
                }
                label="Personas"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="_appliesToCompanies"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values._appliesToCompanies}
                    checked={values._appliesToCompanies}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                      "& .Mui-checked": {
                        "& .MuiSvgIcon-root": {
                          width: 32,
                          height: 32,
                        },
                      },
                    }}
                  />
                }
                label="Empresas"
              />
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  name="_appliesToServices"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._appliesToServices}
                  checked={values._appliesToServices}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 32,
                      height: 32,
                    },
                    "& .Mui-checked": {
                      "& .MuiSvgIcon-root": {
                        width: 32,
                        height: 32,
                      },
                    },
                  }}
                />
              }
              label="Aplicaciones"
            />
            {errors._apliesToCheck ? (
              <Typography variant="body2" color="error">
                {errors._apliesToCheck}
              </Typography>
            ) : null}
          </Box>
          <Grid>
            <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
              <SubmitButton
                label="Confirmar"
                disabled={formCompromiso.isSubmitting || isLoading}
              />
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleCloseModalCompromiso(true, null, false);
                  resetForm();
                }}
                disabled={formCompromiso.isSubmitting || isLoading}
              />
            </Stack>
          </Grid>
        </Box>
      </Dialog>
      {openDevelompentGoalsModal && (
        <ModalSustainableDevelopmentGoalsMobile
          open={openDevelompentGoalsModal}
          handleClose={handleCloseDevelopmentGoalsModal}
          handleConfirm={() => {
            formCompromiso.setFieldValue(
              "_sustainableDevelopmentGoals",
              selectedDevelopmentGoals
            );
          }}
          selectedDevelopmentGoals={selectedDevelopmentGoals}
          setSelectedDevelopmentGoals={setSelectedDevelopmentGoals}
        />
      )}

      {openModalComunidad && (
        <ModalComunidad
          open={openModalComunidad}
          handleClose={handleCloseModalComunidad}
          handleSubmitComunidad={handleSubmitComunidad}
        />
      )}
    </>
  );
};
