///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Global imports

import { createBrowserHistory } from "history";
import { v4 as uuidv4 } from "uuid";

import * as Web3Manager from "./Web3Manager";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Global configuration

export async function configure(callback) {
  // Configuramos el Web3 Manager con todos los smart contracts que tengamos
  await Web3Manager.configure();

  // Invocamos el callback que incializa ahora si la aplicacion
  if (callback) {
    callback();
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Navigation

// History object for programmatic access
export const history = createBrowserHistory();

/**
 * Navega a la url indicada, cargando los parametros enviados.
 * Los parametros son una lista
 * Se colocan siempre en la url, en la forma /param/param ....
 */
export function goForward(url, params = null) {
  let targetUrl = url;

  // Removemos el / final, si existe
  if (targetUrl.endsWith("/")) {
    targetUrl = targetUrl.slice(0, -1);
  }

  if (params && params.length > 0) {
    for (const param of params) {
      targetUrl = targetUrl + "/" + param;
    }
  }

  // Navegamos usando el soporte de history
  history.push(targetUrl);
}

/**
 * Vuelve a la pagina anterior segun el history
 */
export function goBack() {
  history.goBack();
}

/**
 * Indica si hay historia generada
 */
export function canGoBack() {
  return history.length > 0;
}

/**
 * Navega a la url indicada, cargando los parametros enviados.
 * Los parametros son una lista
 * Se colocan siempre en la url, en la forma /param/param ....
 */
export function goNewTab(url, params = null) {
  let targetUrl = url;

  // Removemos el / final, si existe
  if (targetUrl.endsWith("/")) {
    targetUrl = targetUrl.slice(0, -1);
  }

  if (params && params.length > 0) {
    for (const param of params) {
      targetUrl = targetUrl + "/" + param;
    }
  }

  // Navegamos en una nueva tab
  window.open(targetUrl, "_blank", "noopener,noreferrer");
}

/**
 * Anula la historia y va al home
 */
export function goHome() {
  history.entries = [];
  history.index = -1;
  history.push("/view/HomePage");
}

// Importante >>> Esto requiere una redireccion a nivel del servidor web.
// El error 404 en las aplicaciones react debe ser redirigido al index.html o al /
export function reloadRoot(idSocio) {
  const newUrl =
    window.location.protocol + "//" + window.location.host + "/view/homePage";

  window.location = idSocio ? `${newUrl}?user_id=${idSocio}` : newUrl;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Storage functions

/**
 * Creates GUID for user based session
 */
export function guid() {
  var sessionId = window.sessionStorage.getItem("__SESSION_ID__");
  if (sessionId == null) {
    sessionId = uuidv4();
    window.sessionStorage.setItem("__SESSION_ID__", sessionId);
  }

  return sessionId;
}

/**
 * Removes the current GUID
 */
export function clearGuid() {
  window.sessionStorage.removeItem("__SESSION_ID__");
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Object utility functions

// Convierte un objeto en una lista de arrays con nombre - valor
export function flattenProperties(objectValue) {
  if (!objectValue) {
    return [];
  }

  // Si el valor indicado, no es un objeto complejo, devolvemos un array vacio
  if (typeof objectValue !== "object") {
    return [];
  }

  const result = [];

  // Obtenemos las propiedades del objeto, las iteramos y las agregamos al resultado.
  // Si alguna de estas es un objeto, recursivamente lo procesamos y agregamos sus propiedades al resultado
  for (const [key, value] of Object.entries(objectValue)) {
    if (typeof value === "object") {
      const subProperties = flattenProperties(value);
      subProperties.forEach((subProperty) => {
        result.push([key + "." + subProperty[0], subProperty[1]]);
      });
    } else {
      result.push([key, value]);
    }
  }

  return result;
}
