import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Autocomplete,
  IconButton,
  useTheme,
  useMediaQuery,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Chip,
} from "@mui/material";
import {
  AssignmentLate,
  AssignmentTurnedIn,
  PendingActions,
} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";
import CloseIcon from "@mui/icons-material/Close";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import CustomTextEditor from "../ui/CustomTextEditor";

import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalColaboracionesFormMobile = ({
  open,
  handleClose,
  isEditing,
  colaboracionEditing,
  personas,
  empresas,
  principios,
  compromisos,
  categoriasColaboracion,
  setLoading,
  initData,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [nombreReceptor, setNombreReceptor] = useState("");
  const [nombreAplicacion, setNombreAplicacion] = useState("");
  const [nombreCompromiso, setNombreCompromiso] = useState("");
  const [tipoSocio, setTipoSocio] = useState("");
  const [statusCompromiso, setStatusCompromiso] = useState("");
  const [deadlineCompromiso, setDeadlineCompromiso] = useState("");
  const { openSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _description: Yup.string()
      .required("Debes ingresar una descripción para la colaboración")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para la colaboración",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
    _category: Yup.string().required("Debes seleccionar una categoría"),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (colaboracionEditing || initData) {
        const data = colaboracionEditing ? colaboracionEditing : initData;

        const socio =
          data.targetMemberType == Constants.MEMBER_TYPE_PERSONA
            ? await personas.find(
                (p) =>
                  p.memberAddress?.toLowerCase() ==
                  data.targetMemberAddress?.toLowerCase()
              )
            : await empresas.find(
                (e) =>
                  e.memberAddress?.toLowerCase() ==
                  data.targetMemberAddress?.toLowerCase()
              );
        setTipoSocio(getTipoSocio(data.targetMemberType));
        setNombreReceptor(socio.name);

        const compromiso = await compromisos.find(
          (c) => c.code === data.collaborationTypeCode
        );
        setNombreCompromiso(compromiso.title);

        let adhesion;
        if (data.applicationId && data.applicationId !== 0) {
          const aplicacion = await socio.services.find(
            (s) => s.code === data.applicationId
          );
          adhesion = await aplicacion.commitments.find(
            (c) => c.commitmentCode === data.collaborationTypeCode
          );
          setNombreAplicacion(aplicacion.name);
        } else {
          adhesion = await socio.commitments.find(
            (c) => c.commitmentCode === data.collaborationTypeCode
          );
        }

        if (adhesion) {
          setStatusCompromiso(
            Constants.obtenerNombreEstadoCompromiso(adhesion.status)
          );
          if (adhesion.status === Constants.HAS_INTENTION) {
            setDeadlineCompromiso(adhesion.deadlineDate);
          }
        } else {
          setStatusCompromiso("No adhiere");
        }
      }
    };

    fetchData().catch(console.error);
  }, []);

  const initialState = {
    _sourceMemberAddress: Web3Manager.MEMBER_DATA.memberAddress,
    _sourceMemberType: Web3Manager.MEMBER_DATA.memberType,
    _sourceMemberCode: Web3Manager.MEMBER_DATA.memberCode,
    _targetMemberAddress: initData ? initData.targetMemberAddress : "",
    _targetMemberCode: initData ? initData.targetMemberCode : "",
    _targetMemberType: initData
      ? initData.targetMemberType
      : Constants.MEMBER_TYPE_PERSONA,
    _title: "",
    _description: "",
    _category: "",
    _collaborationType: initData
      ? initData.collaborationType
      : Constants.COLLABORATION_COMMITMENT,
    _collaborationTypeCode: initData ? initData.collaborationTypeCode : 0,
    _applicationId: initData ? initData.applicationId : 0,
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _code: colaboracionEditing.code,
        _sourceMemberAddress: colaboracionEditing.sourceMemberAddress,
        _sourceMemberCode: colaboracionEditing.sourceMemberCode,
        _sourceMemberType: colaboracionEditing.sourceMemberType,
        _targetMemberAddress: colaboracionEditing.targetMemberAddress,
        _targetMemberCode: colaboracionEditing.targetMemberCode,
        _targetMemberType: colaboracionEditing.targetMemberType,
        _title: colaboracionEditing.title,
        _description: colaboracionEditing.description,
        _category: colaboracionEditing.category,
        _collaborationType: colaboracionEditing.collaborationType,
        _collaborationTypeCode: colaboracionEditing.collaborationTypeCode,
        _applicationId: colaboracionEditing.applicationId,
      };
    }
    return formData;
  };

  const formColaboracion = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _sourceMemberAddress,
        _sourceMemberCode,
        _sourceMemberType,
        _targetMemberAddress,
        _targetMemberCode,
        _targetMemberType,
        _description,
        _category,
        _collaborationType,
        _collaborationTypeCode,
        _applicationId,
      } = values;

      let _title;
      if (_collaborationType == Constants.COLLABORATION_PRINCIPLE) {
        const principioSeleccionado = principios?.find(
          (p) => p?.code == values._collaborationTypeCode
        );
        _title = principioSeleccionado.title;
      } else {
        const compromisoSeleccionado = compromisos?.find(
          (c) => c?.code == values._collaborationTypeCode
        );
        _title = compromisoSeleccionado.title;
      }

      setDisableButton(true);
      setLoading(true);
      openSpinner(true);
      if (!isEditing) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_COLLABORATIONS,
          "addCollaboration",
          _sourceMemberAddress,
          _sourceMemberCode,
          _sourceMemberType,
          _targetMemberAddress,
          _targetMemberCode,
          _targetMemberType,
          _title,
          _description,
          _category,
          _collaborationType,
          _collaborationTypeCode,
          _applicationId
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_COLLABORATIONS,
          "modifyCollaboration",
          colaboracionEditing.code,
          _sourceMemberAddress,
          _sourceMemberCode,
          _sourceMemberType,
          _targetMemberAddress,
          _targetMemberCode,
          _targetMemberType,
          _title,
          _description,
          _category,
          _collaborationType,
          _collaborationTypeCode,
          _applicationId
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formColaboracion;

  const getTipoSocio = (tipo) => {
    if (tipo === Constants.MEMBER_TYPE_PERSONA) {
      return "Persona";
    } else if (tipo === Constants.MEMBER_TYPE_EMPRESA) {
      return "Empresa";
    } else {
      return "N/A";
    }
  };

  const getChipInfo = (status) => {
    let icon;
    let label;
    if (status === "Cumple") {
      icon = <AssignmentTurnedIn style={{ color: "green" }} />;
      label = "Cumple";
    } else if (status === "Tiene intención") {
      icon = <PendingActions style={{ color: "orange" }} />;
      label = "Tiene intención";
    } else if (status === "No adhiere") {
      icon = <AssignmentLate style={{ color: "red" }} />;
      label = "Ya no adhiere";
    } else {
      icon = null;
      label = "Desconocido";
    }

    return (
      <Chip
        sx={{ p: 2, ml: 1 }}
        icon={icon}
        label={label}
        variant="outlined"
        size="small"
      />
    );
  };

  const getDeadlineDate = (date) => {
    return <strong>{moment(date).format("DD-MM-YYYY")}</strong>;
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {colaboracionEditing ? "Modificar" : "Nueva"} {" colaboración "}
            {nombreReceptor ? "con " + nombreReceptor : ""}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: "2rem" }} component="form" onSubmit={handleSubmit}>
        <Grid
          sx={{
            bgcolor: "#F5F5F5",
            "& svg": {
              fontSize: {
                lg: "1.5rem",
                xs: "1.25rem",
              },
            },
            "& .MuiTypography-root": {
              fontSize: {
                lg: "0.875rem",
                xs: "0.8rem",
              },
            },
          }}
          container
          spacing={1}
          p={0.5}
          mb={2}
          justifyContent="space-between"
        >
          <Grid item>
            {nombreCompromiso && (
              <Typography variant="body2" sx={{ p: 1 }}>
                COMPROMISO: <strong>{nombreCompromiso}</strong>
                <br />
              </Typography>
            )}
            <Typography variant="body2" sx={{ p: 1 }}>
              TIPO SOCIO: <strong>{tipoSocio}</strong>
              <br />
            </Typography>
            {nombreAplicacion && (
              <Typography variant="body2" sx={{ p: 1 }}>
                APLICACIÓN: <strong>{nombreAplicacion}</strong>
                <br />
              </Typography>
            )}
            <Typography variant="body2" sx={{ p: 1 }}>
              ADHESIÓN:
              {getChipInfo(statusCompromiso)}
            </Typography>
            {deadlineCompromiso && (
              <Typography variant="body2" sx={{ p: 1 }}>
                FECHA LÍMITE: {getDeadlineDate(deadlineCompromiso)}
                <br />
              </Typography>
            )}
          </Grid>
        </Grid>

        <Box container mt={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Elige la categoría
          </Typography>
          <br />
          <Grid container alignItems="center" spacing={2}>
            <Grid item sx={{ width: "100%" }}>
              <Autocomplete
                options={categoriasColaboracion}
                name="_category"
                getOptionLabel={(option) => option && `${option.category}`}
                defaultValue={
                  colaboracionEditing
                    ? categoriasColaboracion.find(
                        (c) => c.category === colaboracionEditing.category
                      )
                    : null
                }
                onChange={(event, value) => {
                  formColaboracion.setFieldValue(
                    "_category",
                    value ? value.category : null
                  );
                }}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categoría"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    error={Boolean(touched._category && errors._category)}
                    helperText={touched._category && errors._category}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mt={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Agrega una descripción de tu colaboración
          </Typography>
          <br />
          <CustomTextEditor
            name="_description"
            placeholder="Descripción"
            value={values._description}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
        {touched._description && errors._description && (
          <Typography
            sx={{
              fontSize: "0.75rem",
              marginLeft: "14px",
              marginRight: "14px",
              marginTop: "4px",
            }}
            variant="body2"
            color="error"
          >
            {errors._description}
          </Typography>
        )}

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={() => {
              handleClose(true, null, false);

              resetForm();
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
