import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import Delete from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGrid } from "@mui/x-data-grid";
import { ModalCategoriaVotacionForm } from "../../components/parametros/ModalCategoriaVotacionForm";
import { ModalCategoriaColaboracionForm } from "../../components/parametros/ModalCategoriaColaboracionForm";
import { ModalTipoServicioForm } from "../../components/parametros/ModalTipoServicioForm";
import { useNotification } from "../../hooks/useNotification";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { GridActionItem } from "../../components/ui/GridActionItem";
import {
  getApplicationTypes,
  getBallotCategories,
  getCollaborationCategories,
} from "../../services/ParametersServices";

const ParametrosPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [tiposServicio, setTiposServicio] = useState([]);
  const [categoriasColaboracion, setCategoriasColaboracion] = useState([]);
  const [categoriasVotacion, setCategoriasVotacion] = useState([]);
  const [openModalTipoDocumento, setOpenModalTipoDocumento] = useState(false);
  const [openModalCategoriaColaboracion, setOpenModalCategoriaColaboracion] =
    useState(false);
  const [openModalCategoriaVotacion, setOpenModalCategoriaVotacion] =
    useState(false);
  const [openModalUnidadPlanPago, setOpenModalUnidadPlanPago] = useState(false);
  const [openModalTipoServicio, setOpenModalTipoServicio] = useState(false);

  const [loadingCategoriasVotacion, setLoadingCategoriasVotacion] =
    useState(false);
  const [loadingCategoriasColaboracion, setLoadingCategoriasColaboracion] =
    useState(false);
  const [loadingTiposDeServicio, setLoadingTiposDeServicio] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const handleOpenModalTipoServicio = () => {
    setOpenModalTipoServicio(true);
  };

  const handleCloseModalTipoServicio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getTiposServicio();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return;
      }
    }

    setOpenModalTipoServicio(false);
  };

  const handleOpenModalCategoriaColaboracion = () => {
    setOpenModalCategoriaColaboracion(true);
  };

  const handleCloseModalCategoriaColaboracion = (
    canceled,
    txResponse,
    isSuccess
  ) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getCategoriasColaboracion();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return;
      }
    }

    setOpenModalCategoriaColaboracion(false);
  };

  const handleOpenModalCategoriaVotacion = () => {
    setOpenModalCategoriaVotacion(true);
  };

  const handleCloseModalCategoriaVotacion = (
    canceled,
    txResponse,
    isSuccess
  ) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getCategoriasVotacion();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return;
      }
    }

    setOpenModalCategoriaVotacion(false);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const dgTiposServicio = tiposServicio.map((item, index) => {
    return {
      tipoServicio: item.type,
    };
  });

  const dgCategoriasColaboracion = categoriasColaboracion.map((item, index) => {
    return {
      categoria: item.category,
    };
  });

  const dgCategoriasVotacion = categoriasVotacion.map((item, index) => {
    return {
      categoria: item.category,
    };
  });

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const columnsTiposServicio = [
    {
      field: "tipoServicio",
      headerName: "Tipo aplicación",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GridActionItem
              disabled={loadingTiposDeServicio}
              icon={<Delete />}
              label="Eliminar"
              onClick={() => handleDeleteTipoServicio(params.row.tipoServicio)}
            />
          </Stack>
        );
      },
    },
  ];

  const columnsCategoriasColaboracion = [
    {
      field: "categoria",
      headerName: "Categoria colaboracion",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GridActionItem
              disabled={loadingCategoriasColaboracion}
              icon={<Delete />}
              label="Eliminar"
              onClick={() =>
                handleDeleteCategoriaColaboracion(params.row.categoria)
              }
            />
          </Stack>
        );
      },
    },
  ];

  const columnsCategoriasVotacion = [
    {
      field: "categoria",
      headerName: "Categoria votacion",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <GridActionItem
              disabled={loadingCategoriasVotacion}
              icon={<Delete />}
              label="Eliminar"
              onClick={() =>
                handleDeleteCategoriaVotacion(params.row.categoria)
              }
            />
          </Stack>
        );
      },
    },
  ];

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getTiposServicio = () => {
    setLoadingTiposDeServicio(true);
    getApplicationTypes()
      .then((response) => {
        setTiposServicio(response);
        setLoadingTiposDeServicio(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoadingTiposDeServicio(false);
      });
  };

  const getCategoriasColaboracion = () => {
    setLoadingCategoriasColaboracion(true);
    getCollaborationCategories()
      .then((response) => {
        setCategoriasColaboracion(response);
        setLoadingCategoriasColaboracion(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoadingCategoriasColaboracion(false);
      });
  };

  const getCategoriasVotacion = () => {
    setLoadingCategoriasVotacion(true);
    getBallotCategories()
      .then((response) => {
        setLoadingCategoriasVotacion(false);
        setCategoriasVotacion(response);
      })
      .catch((error) => {
        setLoadingCategoriasVotacion(false);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDeleteTipoServicio = (_serviceType) => {
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_PARAMETERS,
      "removeApplicationType",
      _serviceType
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getTiposServicio();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDeleteCategoriaColaboracion = (_categoria) => {
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_PARAMETERS,
      "removeCollaborationCategory",
      _categoria
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getCategoriasColaboracion();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDeleteCategoriaVotacion = (_categoria) => {
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_PARAMETERS,
      "removeBallotCategory",
      _categoria
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getCategoriasVotacion();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getTiposServicio();
    getCategoriasColaboracion();
    getCategoriasVotacion();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Gestión de par&aacute;metros
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4}>
          <Card variant="outlined">
            <Box>
              <Typography variant="h6" color={"black"} align="center" p={3}>
                Categor&iacute;as de votaci&oacute;n
              </Typography>
            </Box>

            <Box sx={{ justifyContent: "center", display: "flex" }} pb={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={loadingCategoriasVotacion}
                onClick={handleOpenModalCategoriaVotacion}
                startIcon={<AddCircleIcon />}
              >
                Agregar
              </Button>
            </Box>

            <DataGrid
              rows={dgCategoriasVotacion}
              pageSize={3}
              autoHeight={true}
              loading={loadingCategoriasVotacion}
              columns={columnsCategoriasVotacion}
              getRowId={(row) => row.categoria}
            />
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card variant="outlined">
            <Box>
              <Typography variant="h6" color={"black"} align="center" p={3}>
                Categor&iacute;as de colaboraci&oacute;n
              </Typography>
            </Box>

            <Box sx={{ justifyContent: "center", display: "flex" }} pb={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModalCategoriaColaboracion}
                disabled={loadingCategoriasColaboracion}
                startIcon={<AddCircleIcon />}
              >
                Agregar
              </Button>
            </Box>

            <DataGrid
              rows={dgCategoriasColaboracion}
              pageSize={3}
              autoHeight={true}
              loading={loadingCategoriasColaboracion}
              columns={columnsCategoriasColaboracion}
              getRowId={(row) => row.categoria}
            />
          </Card>
        </Grid>

        <Grid item md={4}>
          <Card variant="outlined">
            <Box>
              <Typography variant="h6" color={"black"} align="center" p={3}>
                Tipos de aplicación
              </Typography>
            </Box>

            <Box sx={{ justifyContent: "center", display: "flex" }} pb={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModalTipoServicio}
                startIcon={<AddCircleIcon />}
                disabled={loadingTiposDeServicio}
              >
                Agregar
              </Button>
            </Box>

            <DataGrid
              rows={dgTiposServicio}
              pageSize={3}
              autoHeight={true}
              loading={loadingTiposDeServicio}
              columns={columnsTiposServicio}
              getRowId={(row) => row.tipoServicio}
            />
          </Card>
        </Grid>
      </Grid>

      <br />

      <ModalTipoServicioForm
        open={openModalTipoServicio}
        handleClose={handleCloseModalTipoServicio}
        setLoading={setLoadingTiposDeServicio}
      />

      <ModalCategoriaColaboracionForm
        open={openModalCategoriaColaboracion}
        handleClose={handleCloseModalCategoriaColaboracion}
        setLoading={setLoadingCategoriasColaboracion}
      />

      <ModalCategoriaVotacionForm
        open={openModalCategoriaVotacion}
        handleClose={handleCloseModalCategoriaVotacion}
        setLoading={setLoadingCategoriasVotacion}
      />
    </Container>
  );
};

export default withRouter(ParametrosPage);
