import React, { useState } from "react";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { AddCircle, Clear, ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Button,
  Box,
  MenuItem,
} from "@mui/material";

export const AplicacionesFilterMobile = ({
  filtroTipo,
  filtroEmpresa,
  setFiltroTipo,
  setFiltroEmpresa,
  isReadOnly,
  handleAgregarServicio,
  empresasConAplicaciones,
  filterAplicaciones,
  loadingServicios,
  tiposServicio,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
      {Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_EMPRESA && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            startIcon={<AddCircle />}
            onClick={() => handleAgregarServicio()}
            fullWidth
          >
            Agregar aplicacion
          </Button>
        </Grid>
      )}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleAccordionChange("panel1")}
        sx={{ mt: 2, mb: 2, width: "100%" }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: 48,
            "&.Mui-expanded": {
              minHeight: 48,
              height: 48,
            },
          }}
          expandIcon={
            <ExpandMore sx={{ color: theme.palette.primary.contrastText }} />
          }
        >
          <Typography>Filtrar aplicaciones</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Empresa"
                variant="outlined"
                size="small"
                value={filtroEmpresa}
                select={true}
                onChange={(newValue) => setFiltroEmpresa(newValue.target.value)}
              >
                <MenuItem value={undefined}>Todas</MenuItem>
                {empresasConAplicaciones.map((empresa) => (
                  <MenuItem key={empresa} value={empresa}>
                    {empresa}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Tipo"
                variant="outlined"
                size="small"
                value={filtroTipo}
                select={true}
                onChange={(newValue) => setFiltroTipo(newValue.target.value)}
              >
                <MenuItem value={undefined}>Todos</MenuItem>
                {tiposServicio.map((item) => (
                  <MenuItem key={item.id} value={item.type}>
                    {item.type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: "right", display: "flex" }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  filterAplicaciones();
                  handleAccordionChange("panel1")(null, false);
                }}
                disabled={loadingServicios}
                startIcon={<Search />}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
