import React from "react";
import { Router } from "react-router";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from "./App";
import * as Common from "./support/Common";
import * as serviceWorker from "./serviceWorker";
import SpinnerState from "./context/spinner/SpinnerState";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#005A59",

      contrastText: "#fff",
      hover: "#005A59",
    },
    secondary: {
      main: "#f44336",
    },
  },
  /*typography: {
    h1: {
      fontSize: "2.5rem", // Tamaño de fuente para encabezados h1
    },
    h2: {
      fontSize: "2rem", // Tamaño de fuente para encabezados h2
    },
    h3: {
      fontSize: "1rem",
    },
    // Agrega más definiciones de tamaño de fuente según sea necesario para otros elementos
  },*/
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input:disabled": {
            "-webkit-text-fill-color": "#0000009e",
            cursor: "not-allowed",
          },
          "& textarea:disabled": {
            "-webkit-text-fill-color": "#0000009e",
            cursor: "not-allowed",
          },
          "& div.Mui-disabled": {
            "-webkit-text-fill-color": "#0000009e",
            cursor: "not-allowed",
          },
        },
      },
    },
  },
});

Common.configure(() => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <Router history={Common.history}>
      <ThemeProvider theme={theme}>
        <SpinnerState>
          <App />
        </SpinnerState>
      </ThemeProvider>
    </Router>
  );
});

serviceWorker.unregister();
