import React, { useState } from 'react';
import { AddCircle, Clear, ExpandMore, Search } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
  Button,
  Box,
} from '@mui/material';

export const CompromisoSociosAdheridosFilterMobile = ({
  filtroNombre,
  setFiltroNombre,
  buscarAdhesiones,
  showSpinner,
  isLoading,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleAccordionChange('panel1')}
        sx={{ mt: 2, mb: 2, width: '100%' }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            height: 48,
            '&.Mui-expanded': {
              minHeight: 48,
              height: 48,
            },
          }}
          expandIcon={
            <ExpandMore sx={{ color: theme.palette.primary.contrastText }} />
          }
        >
          <Typography>Filtrar por nombre de socio</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='outlined-basic'
                label='Nombre completo'
                variant='outlined'
                size='small'
                value={filtroNombre}
                onChange={(newValue) => setFiltroNombre(newValue.target.value)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'right', display: 'flex' }}
            >
              <Button
                sx={{ marginLeft: 1 }}
                variant='contained'
                color='primary'
                onClick={() => buscarAdhesiones()}
                disabled={isLoading || showSpinner}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
