import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  Slide,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ConfirmationNumber, Edit, Save, ZoomIn } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { GridActionItem } from "../ui/GridActionItem";
import { ModalCompromisoDetalle } from "./ModalCompromisoDetalle";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { ModalCompromisoDetalleMobile } from "./ModalCompromisoDetalleMobile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalCompromisosSeleccionMobile = ({
  open,
  handleClose,
  compromisos,
  compromisosSeleccionados,
  setCompromisosSeleccionados,
  isCreatingService,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDetalleCompromiso, setOpenDetalleCompromiso] = useState(false);
  const [compromiso, setCompromiso] = useState(null);

  const handleRowSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleAceptar = () => {
    const nuevosCompromisosSeleccionados = compromisos.filter((compromiso) =>
      selectedRows.includes(compromiso.code)
    );

    handleClose(nuevosCompromisosSeleccionados);
  };

  const handleDetalleCompromiso = (compromisoSelected) => {
    setOpenDetalleCompromiso(true);
    setCompromiso(compromisoSelected);
  };

  const handleCloseDetalleCompromiso = () => {
    setOpenDetalleCompromiso(false);
    setCompromiso(null);
  };

  const columnsCompromisos = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetalleCompromiso(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setSelectedRows(
      compromisosSeleccionados?.map((compromiso) => compromiso.code) || []
    );
  }, []);

  useEffect(() => {
    setSelectedRows(
      compromisosSeleccionados?.map((compromiso) => compromiso.code) || []
    );
  }, []);

  const filterCompromisos = (compromisos) => {
    if (isCreatingService) {
      return compromisos.filter((c) => c.appliesToServices);
    }
    if (Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_PERSONA) {
      return compromisos.filter((c) => c.appliesToPersons);
    }

    if (Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_EMPRESA) {
      return compromisos.filter((c) => c.appliesToCompanies);
    }

    return [];
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={() => handleClose(compromisosSeleccionados)}
        TransitionComponent={Transition}
      >
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Selección de compromisos
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ mt: 2 }}>
          <DataGrid
            getRowId={(row) => row.code}
            rows={filterCompromisos(compromisos)}
            pageSize={10}
            autoHeight={true}
            loading={false}
            columns={columnsCompromisos}
            onSelectionModelChange={handleRowSelectionChange}
            selectionModel={selectedRows}
            rowsPerPageOptions={[10]}
            checkboxSelection
            hideFooter
          />
        </Box>
        <Grid>
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "flex-end" }}
            p={3}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleAceptar();
              }}
              color="primary"
              sx={{ mb: 2 }}
              size="small"
            >
              Aceptar
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                border: "0.5px #000",
                borderRadius: "4px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)",
                "&:hover": {
                  backgroundColor: "#D5D8DC",
                  color: "#fff",
                },
              }}
              size="small"
              onClick={() => handleClose(compromisosSeleccionados)}
            >
              Cancelar
            </Button>
          </Stack>
        </Grid>
      </Dialog>
      {openDetalleCompromiso && (
        <ModalCompromisoDetalleMobile
          open={openDetalleCompromiso}
          handleClose={handleCloseDetalleCompromiso}
          compromiso={compromiso}
        />
      )}
    </>
  );
};
