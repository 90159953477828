import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Chip,
  Card,
  CardHeader,
  Grid,
  Divider,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";

import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import IndicadorSatisfaccion from "../colaboraciones/IndicadorSatisfaccion";
import moment from "moment";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalReporteForm = ({
  open,
  handleClose,
  reporte,
  setLoading,
}) => {
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _analysis: Yup.string().max(
      1000,
      "El análisis no puede tener más de 1000 caracteres"
    ),
    _conclusions: Yup.string().max(
      1000,
      "Las conclusiones no pueden tener más de 1000 caracteres"
    ),
  });

  const [disableButton, setDisableButton] = useState(false);

  const initialState = {
    _code: reporte.code.toNumber(),
    _analysis: reporte.analysis,
    _conclusions: reporte.conclusions,
  };

  const formReporte = useFormik({
    initialValues: initialState,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _code, _analysis, _conclusions } = values;

      setLoading(true);
      openSpinner();
      setDisableButton(true);

      Web3Manager.callContract(
        Web3Manager.CONTRACT_REPORTS,
        "modifyReport",
        _code,
        _analysis,
        _conclusions
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formReporte;

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(true, null, false);
        resetForm();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Reporte {reporte.collaborationTitle}
              <Chip
                label={Constants.getNombreEstadoReporte(reporte.status)}
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.REPORT_STATUS_COLORS[reporte.status],
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <strong>Código reporte:</strong> {reporte.code.toNumber()}
            </Typography>{" "}
            <Typography variant="subtitle1">
              <strong>Código colaboración:</strong>{" "}
              {reporte.collaborationCode.toNumber()}
            </Typography>{" "}
            <Typography variant="subtitle1">
              <strong>Reportada el:</strong>{" "}
              {moment
                .unix(reporte.creationDate.toNumber())
                .format("DD-MM-YYYY")}
            </Typography>{" "}
            <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
              <Typography variant="subtitle1">
                <strong>{"Emisor: "}</strong>
              </Typography>
              &nbsp;
              <Typography variant="subtitle1">
                {reporte.collaboration.sourceMemberName} (
                {reporte.collaboration.sourceMemberAddress})
              </Typography>
              <Chip
                label={
                  reporte.collaboration[2] === Constants.MEMBER_TYPE_PERSONA
                    ? `PERSONA`
                    : `EMPRESA`
                }
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.MEMBER_TYPE_COLORS[reporte.collaboration[2]],
                }}
              />
              <IndicadorSatisfaccion
                colaboracion={reporte.collaboration}
                isSource={true}
              />
            </Box>
            <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
              <Typography variant="subtitle1">
                <strong>{"Receptor: "}</strong>
              </Typography>
              &nbsp;
              <Typography variant="subtitle1">
                {reporte.collaboration.targetMemberName} (
                {reporte.collaboration.targetMemberAddress})
              </Typography>
              <Chip
                label={
                  reporte.collaboration[5] === Constants.MEMBER_TYPE_PERSONA
                    ? `PERSONA`
                    : `EMPRESA`
                }
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.MEMBER_TYPE_COLORS[reporte.collaboration[5]],
                }}
              />
              <IndicadorSatisfaccion
                colaboracion={reporte.collaboration}
                isSource={true}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Divider />
            <br />

            <Typography variant="subtitle1" component="h2">
              <strong> Ingresa el análisis y las conclusiones:</strong>
            </Typography>
            <Box mt={2}>
              <TextField
                name="_analysis"
                id="outlined-basic"
                label="Análisis"
                variant="outlined"
                size="small"
                fullWidth={true}
                multiline={true}
                rows={6}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values._analysis}
                error={Boolean(touched._analysis && errors._analysis)}
                helperText={
                  errors._analysis
                    ? touched._analysis && errors._analysis // Mostrar errores cuando los haya
                    : `${values._analysis.length}/1000 caracteres` // Mostrar contador de caracteres si no hay errores
                }
              />
            </Box>
            <Box mt={2}>
              <TextField
                name="_conclusions"
                id="outlined-basic"
                label="Conclusiones"
                variant="outlined"
                size="small"
                fullWidth={true}
                multiline={true}
                rows={6}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values._conclusions}
                error={Boolean(touched._conclusions && errors._conclusions)}
                helperText={
                  errors._conclusions
                    ? touched._conclusions && errors._conclusions // Mostrar errores cuando los haya
                    : `${values._conclusions.length}/1000 caracteres` // Mostrar contador de caracteres si no hay errores
                }
              />
            </Box>
          </Grid>
          <Box
            mt={2}
            sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disableButton}
            >
              Confirmar
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                handleClose(true, null, false);
                resetForm();
              }}
            />
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};
