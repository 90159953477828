import React from 'react';
import Container from '@mui/material/Container';
import { Grid, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem',
          marginTop: matches ? '1rem' : '0',
          width: '100%',
          boxShadow:
            '0px -1px 1px -1px rgba(0,0,0,0.2),0px -1px 4px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
        <Container component='main' style={{ flex: '1' }}>
          <Grid container direction='column' alignItems='center'>
            <Grid item xs={12}>
              <Typography color='black' variant='h6'>
                Fibras
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color='textSecondary' variant='subtitle2'>
                © Copyright {currentYear} - Sincron&iacute;a
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Footer;
