import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import ServiciosContext from "../../context/servicios/ServiciosContext";
import { CancelButton } from "../ui/CustomButtons";
import { SeccionCompromisosAdheridos } from "../compromisos/SeccionCompromisosAdheridos";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { OpenInBrowser } from "@mui/icons-material";
import CustomTextEditor from "../ui/CustomTextEditor";
import HtmlContentDisplay from "../ui/HtmlContentDisplay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalServicioForm = ({
  open,
  handleClose,
  servicio,
  tiposServicio,
  compromisosEnabled,
  isReadOnly,
}) => {
  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const { crearServicio, isLoading, modificarServicio } =
    useContext(ServiciosContext);

  useEffect(() => {
    //Se setean los compromisos seleccionados
    //getCompromisosEnabled();

    if (servicio) {
      const compromisosSeleccionadosList = compromisosEnabled.filter(
        (compromiso) => {
          // Verificar si el código del compromiso está presente en la lista de commitmentCode
          return servicio?.commitments?.some(
            (commitment) => commitment.commitmentCode == compromiso.code
          );
        }
      );

      setAssignedCommitments(servicio?.commitments);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    } else {
      setAssignedCommitments([]);
    }
  }, [servicio]);

  const showUrlTextField = () => {
    if (isReadOnly && values._url) {
      return true;
    }
    if (!isReadOnly) {
      return true;
    }
    return false;
  };

  const validationSchema = Yup.object({
    _name: Yup.string().required("Debes ingresar un nombre para la aplicación"),
    _description: Yup.string()
      .required("Debes ingresar una descripción para la aplicación")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para la aplicación",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
    _email: Yup.string()
      .email("Debes ingresar un correo electrónico válido")
      .required("Debes ingresar un correo electrónico"),
    _tipoServicio: Yup.string().required(
      "Debes ingresar un tipo de aplicación"
    ),
  });

  const initialState = {
    _name: "",
    _description: "",
    _tipoServicio: "",
    _email: "",
    _url: "",
    _enabled: true,
    _commitments: [],
  };

  const getFormData = () => {
    let formData;

    if (!servicio) {
      formData = initialState;
    } else {
      formData = {
        _name: servicio.name,
        _description: servicio.description,
        _tipoServicio: servicio.applicationType,
        _email: servicio.email,
        _url: servicio.url,
        _enabled: servicio.enabled,
        _commitments: servicio.commitments,
      };
    }

    return formData;
  };

  const form = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _name, _description, _tipoServicio, _email, _url, _enabled } =
        values;

      const newListWithUnixDates = assignedCommitments.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate, "YYYY-MM-DD").unix(), // Convertimos el campo a unix
          };
        } else {
          return item;
        }
      });

      openSpinner();
      if (!servicio) {
        crearServicio(
          _name,
          _description,
          _tipoServicio,
          _email,
          _url,
          _enabled,
          newListWithUnixDates
        )
          .then((response) => {
            handleClose(false, response, true);
            setAssignedCommitments([]);
            setCompromisosSeleccionados([]);
            resetForm();
          })
          .catch((error) => {
            handleClose(false, error, false);
          });
      } else {
        modificarServicio(
          servicio.code,
          _name,
          _description,
          _tipoServicio,
          _email,
          _url,
          _enabled,
          newListWithUnixDates
        )
          .then((response) => {
            resetForm();
            handleClose(false, response, true);
            setAssignedCommitments([]);
            setCompromisosSeleccionados([]);
          })
          .catch((error) => {
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = form;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
        setAssignedCommitments([]);
        setCompromisosSeleccionados([]);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {servicio
            ? isReadOnly
              ? "Detalle aplicación"
              : "Modificar aplicación"
            : "Nueva aplicación"}
        </Typography>
        <br />

        <Grid item lg={12}>
          <Grid container spacing={6}>
            <Grid item lg={5}>
              <Typography variant="subtitle1" component="h2">
                Datos registro
              </Typography>

              <Box mt={2}>
                <TextField
                  name="_name"
                  id="outlined-basic"
                  label="Nombre"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._name}
                  error={Boolean(touched._name && errors._name)}
                  helperText={touched._name && errors._name}
                  disabled={isReadOnly}
                />
              </Box>

              {!isReadOnly ? (
                <Box mt={2} sx={{ borderRadius: "4px" }}>
                  <CustomTextEditor
                    name="_description"
                    placeholder="Descripción"
                    value={values._description}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
              ) : (
                <Box mt={2}>
                  <HtmlContentDisplay content={values._description} />
                </Box>
              )}
              {touched._description && errors._description && (
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    marginLeft: "14px",
                    marginRight: "14px",
                    marginTop: "4px",
                  }}
                  variant="body2"
                  color="error"
                >
                  {errors._description}
                </Typography>
              )}
              <br />

              <Box mt={2}>
                <TextField
                  name="_tipoServicio"
                  id="outlined-basic"
                  label="Tipo de aplicación"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  select={true}
                  defaultValue={0}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._tipoServicio ?? ""}
                  error={Boolean(touched._tipoServicio && errors._tipoServicio)}
                  helperText={touched._tipoServicio && errors._tipoServicio}
                  disabled={isReadOnly}
                >
                  {tiposServicio.map((item) => (
                    <MenuItem key={item.id} value={item.type}>
                      {item.type}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box mt={2}>
                <TextField
                  name="_email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._email}
                  error={Boolean(touched._email && errors._email)}
                  helperText={touched._email && errors._email}
                  disabled={isReadOnly}
                />
              </Box>

              {showUrlTextField() && (
                <Box mt={2}>
                  <TextField
                    name="_url"
                    id="outlined-basic"
                    label="URL"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values._url}
                    error={Boolean(touched._url && errors._url)}
                    helperText={touched._url && errors._url}
                    disabled={isReadOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            //disabled={isReadOnly}
                            onClick={() => {
                              window.open(values._url, "_blank");
                            }}
                            edge="end"
                          >
                            <OpenInBrowser />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}

              <Box mt={2}>
                {!isReadOnly && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="_enabled"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values._enabled}
                        checked={values._enabled}
                        error={Boolean(touched._enabled && errors._enabled)}
                        helperText={touched._enabled && errors._enabled}
                        disabled={isReadOnly}
                      />
                    }
                    label="Habilitado?"
                  />
                )}
              </Box>
            </Grid>
            <Grid item lg={7} sx={{ position: "relative" }}>
              {isLoading ? (
                <CircularProgress
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              ) : (
                <SeccionCompromisosAdheridos
                  assignedCommitments={assignedCommitments}
                  setAssignedCommitments={setAssignedCommitments}
                  compromisosSeleccionados={compromisosSeleccionados}
                  setCompromisosSeleccionados={setCompromisosSeleccionados}
                  isReadOnly={isReadOnly}
                  mostrarTitulo={true}
                  isCreatingService={true}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item lg={12}>
            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              {!isReadOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {servicio ? "Guardar" : "Registrar"}
                </Button>
              )}

              <CancelButton
                label={isReadOnly ? "Cerrar" : "Cancelar"}
                onClick={() => {
                  handleClose(true, null, false);
                  resetForm();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
