import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Edit from "@mui/icons-material/Edit";
import { Delete, Search, Sync, ZoomIn } from "@mui/icons-material";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { ModalGestionarPropuesta } from "../../components/propuestas/ModalGestionarPropuesta";
import { ModalEstadoPropuestaForm } from "../../components/propuestas/ModalEstadoPropuestaForm";
import { ModalEliminarPropuesta } from "../../components/propuestas/ModalEliminarPropuesta";
import { useNotification } from "../../hooks/useNotification";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { getProposals } from "../../services/ProposalServices";
import { ModalVotarPropuesta } from "../../components/propuestas/ModalVotarPropuesta";
import { getBallotCategories } from "../../services/ParametersServices";

const AdminPropuestasPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();
  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const [filtroTitulo, setFiltroTitulo] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState("");

  const [filtroEstado, setFiltroEstado] = useState(
    Constants.PROPOSAL_STATUS_ANY
  );
  const [openModalPropuesta, setOpenModalPropuesta] = useState(false);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openModalEstadoPropuesta, setOpenModalEstadoPropuesta] =
    useState(false);
  const [openModalEliminarPropuesta, setOpenModalEliminarPropuesta] =
    useState(false);

  const [modeEdit, setModeEdit] = useState(false);
  const [roles, setRoles] = useState([]);
  const [categoriasPropuesta, setCategoriasPropuesta] = useState([]);
  const [propuestasData, setPropuestasData] = useState([]);
  const [propuestaSelected, setPropuestaSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditarPropuesta = (propuestaSelected) => {
    setOpenModalPropuesta(true);
    setModeEdit(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleCambiarEstadoPropuesta = (propuestaSelected) => {
    setOpenModalEstadoPropuesta(true);
    setModeEdit(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleEliminarPropuesta = (propuestaSelected) => {
    setOpenModalEliminarPropuesta(true);
    setModeEdit(false);
    setPropuestaSelected(propuestaSelected);
  };

  const handleOpenDetailModal = (propuestaSelected) => {
    setOpenDetailModal(true);
    setPropuestaSelected(propuestaSelected);
  };

  const handleCloseDetailModal = () => {
    setOpenDetailModal(false);
    setPropuestaSelected(null);
  };

  const handleCloseModalPropuesta = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenModalPropuesta(false);
    setPropuestaSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalEstadoPropuesta = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenModalEstadoPropuesta(false);
    setPropuestaSelected(null);
    setModeEdit(false);
  };

  const handleCloseEliminarPropuesta = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getPropuestas();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenModalEliminarPropuesta(false);
    setPropuestaSelected(null);
    setModeEdit(false);
  };

  const getPropuestas = () => {
    const categoriaParsed = filtroCategoria === "TODAS" ? "" : filtroCategoria;
    setLoading(true);
    getProposals(categoriaParsed, filtroTitulo, filtroEstado)
      .then((response) => {
        setPropuestasData([...response.sort((a, b) => b.code - a.code)]);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const getCategoriasVotacion = async () => {
    getBallotCategories()
      .then((response) => {
        setCategoriasPropuesta(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getRoles = async () => {
    Web3Manager.CONTRACT_ACCESS_CONTROL.getAvailableRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const columnsPropuestas = [
    {
      field: "memberName",
      headerName: "Proponente",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        return Constants.getNombreEstadoPropuesta(params.value) || "N/A";
      },
    },
    {
      field: "category",
      headerName: "Categoría",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "openingDate",
      headerName: "Fecha apertura",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return moment(params.row.openingDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "closingDate",
      headerName: "Fecha cierre",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return moment(params.row.closingDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "role",
      headerName: "Dirigida a:",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        const rolesMap = {
          0: "General",
          1: "Admin",
          2: "Gestión",
          3: "Directiva",
          4: "Validador",
        };
        return rolesMap[params.value] || "N/A";
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={loading}
              icon={<ZoomIn />}
              label={"Detalle"}
              onClick={() => handleOpenDetailModal(params.row)}
            />

            {/*solo puede editar si propuesta está pendiente*/}
            {params.row.status !== Constants.PROPOSAL_STATUS_ACTIVE &&
              params.row.status !== Constants.PROPOSAL_STATUS_APPROVED &&
              params.row.status !== Constants.PROPOSAL_STATUS_REJECTED && (
                <GridActionItem
                  disabled={loading}
                  icon={<Edit />}
                  label="Editar"
                  onClick={() => handleEditarPropuesta(params.row)}
                />
              )}
            {(params.row.status === Constants.PROPOSAL_STATUS_PENDING ||
              params.row.status === Constants.PROPOSAL_STATUS_ADMITTED ||
              params.row.status === Constants.PROPOSAL_STATUS_NOT_ADMITTED) && (
              <GridActionItem
                disabled={loading}
                icon={<Sync />}
                label="Estado"
                onClick={() => handleCambiarEstadoPropuesta(params.row)}
              />
            )}

            {params.row.status === Constants.PROPOSAL_STATUS_PENDING && (
              <GridActionItem
                disabled={loading}
                icon={<Delete />}
                label="Eliminar"
                onClick={() => handleEliminarPropuesta(params.row)}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getCategoriasVotacion();
    getRoles();
    getPropuestas();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Gestión de propuestas
        </Typography>
      </Box>
      <Grid container sx={{ justifyContent: "flex-end" }}>
        <Grid item>
          <Box m={2} sx={{ display: "flex" }} pb={3}>
            <TextField
              sx={{ width: 300 }}
              id="outlined-basic"
              label="Título"
              variant="outlined"
              size="small"
              value={filtroTitulo}
              onChange={(newValue) => setFiltroTitulo(newValue.target.value)}
            />

            <TextField
              sx={{ marginLeft: 1, width: 150 }}
              id="outlined-basic"
              label="Estado"
              variant="outlined"
              size="small"
              value={filtroEstado}
              select={true}
              defaultValue={Constants.PROPOSAL_STATUS_ANY}
              onChange={(newValue) => setFiltroEstado(newValue.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ANY}
                value={Constants.PROPOSAL_STATUS_ANY}
              >
                Todas
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_PENDING}
                value={Constants.PROPOSAL_STATUS_PENDING}
              >
                Pendiente
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ADMITTED}
                value={Constants.PROPOSAL_STATUS_ADMITTED}
              >
                Admitida
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_NOT_ADMITTED}
                value={Constants.PROPOSAL_STATUS_REJECTED}
              >
                No admitida
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ACTIVE}
                value={Constants.PROPOSAL_STATUS_ACTIVE}
              >
                Activa
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_APPROVED}
                value={Constants.PROPOSAL_STATUS_APPROVED}
              >
                Aprobada
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_REJECTED}
                value={Constants.PROPOSAL_STATUS_REJECTED}
              >
                Rechazada
              </MenuItem>
            </TextField>

            <TextField
              sx={{ marginLeft: 1, width: 150 }}
              id="outlined-basic"
              label="Categoría"
              variant="outlined"
              size="small"
              value={filtroCategoria}
              select={true}
              defaultValue={filtroCategoria}
              onChange={(newValue) => setFiltroCategoria(newValue.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key="_status_todos" value={"TODAS"}>
                TODAS
              </MenuItem>
              {categoriasPropuesta?.map((item) => (
                <MenuItem key={"_status_" + item.id} value={item.category}>
                  {item.category}
                </MenuItem>
              ))}
            </TextField>

            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              onClick={() => getPropuestas()}
              disabled={loading || showSpinner}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DataGrid
        rows={propuestasData}
        pageSize={50}
        autoHeight={true}
        loading={loading && !showSpinner}
        columns={columnsPropuestas}
        getRowId={(row) => row.code}
        rowsPerPageOptions={[50]}
      />
      <br />
      {roles && (
        <ModalGestionarPropuesta
          open={openModalPropuesta}
          handleClose={handleCloseModalPropuesta}
          categoriasPropuesta={categoriasPropuesta}
          isEditing={modeEdit}
          propuestaEditing={propuestaSelected}
          roles={roles}
          setLoading={setLoading}
        />
      )}
      <ModalEstadoPropuestaForm
        open={openModalEstadoPropuesta}
        handleClose={handleCloseModalEstadoPropuesta}
        propuestaEditing={propuestaSelected}
        setLoading={setLoading}
      />
      <ModalEliminarPropuesta
        open={openModalEliminarPropuesta}
        handleClose={handleCloseEliminarPropuesta}
        propuesta={propuestaSelected}
        setLoading={setLoading}
      />
      {openDetailModal && (
        <ModalVotarPropuesta
          open={openDetailModal}
          propuesta={propuestaSelected}
          handleClose={handleCloseDetailModal}
          setLoading={setLoading}
          isReadOnly={true}
        />
      )}
    </Container>
  );
};

export default AdminPropuestasPage;
