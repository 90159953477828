import React from "react";

import { Box } from "@mui/material";

export const VoteBar = ({ ballotDataParam }) => {
  const totalVotes =
    ballotDataParam.yesVote +
    ballotDataParam.noVote +
    ballotDataParam.abstainVote;
  const positivePercentage = (
    (ballotDataParam.yesVote / totalVotes) *
    100
  ).toFixed(1);
  const negativePercentage = (
    (ballotDataParam.noVote / totalVotes) *
    100
  ).toFixed(1);
  const abstentionPercentage = (
    (ballotDataParam.abstainVote / totalVotes) *
    100
  ).toFixed(1);

  return totalVotes > 0 ? (
    <Box sx={{ width: "100%" }}>
      {/*barra*/}
      <Box
        display="flex"
        alignItems="center"
        sx={{ width: "100%", borderRadius: 10, overflow: "hidden" }}
      >
        <Box width={`${positivePercentage}%`} bgcolor="#1ABC9C" height={10} />
        <Box width={`${negativePercentage}%`} bgcolor="#E67E22" height={10} />
        <Box width={`${abstentionPercentage}%`} bgcolor="#A6ACAF" height={10} />
      </Box>
      {/*desglose*/}
      <Box sx={{ display: "flex", width: "100%", mt: 0.5 }}>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#1ABC9C",
              marginRight: "5px",
            }}
          />

          <Box
            sx={{ fontSize: "12px", marginRight: "2px" }}
          >{`SI: ${positivePercentage}%`}</Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#E67E22",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          />
          <Box
            sx={{ fontSize: "12px", marginRight: "2px" }}
          >{`NO: ${negativePercentage}%`}</Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: "#A6ACAF",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          />
          <Box sx={{ fontSize: "12px", marginRight: "2px" }}>
            {`ABS: ${abstentionPercentage}%`}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};
