import React, { useContext, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Stack,
  useTheme,
  Chip,
  CircularProgress,
} from "@mui/material";
import * as Constants from "../../support/Constants";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  AccountBalance,
  Groups,
  NotInterested,
  Public,
  Restore,
  ZoomIn,
} from "@mui/icons-material";
import { ModalCompromisoDetalleMobile } from "./ModalCompromisoDetalleMobile";
import { ModalCompromisoFormMobile } from "./ModalCompromisoFormMobile";
import { ModalEliminarCompromisoMobile } from "./ModalEliminarCompromisoMobile";
import { CompromisosFilterMobile } from "./CompromisosFilterMobile";
import SpinnerContext from "../../context/spinner/SpinnerContext";

export const CompromisoCard = ({
  isReadOnly,
  compromisos,
  isLoading,
  eliminarCompromiso,
  handleAgregarCompromiso,
  handleBuscarCompromisos,
  handleCloseDetalleCompromiso,
  handleEliminarCompromiso,
  handleCloseEliminarCompromiso,
  handleCloseModalCompromiso,
  handleDeshabilitarCompromiso,
  handleEditarCompromiso,
  handleHabilitarCompromiso,
  handleIrSociosAdheridosCompromiso,
  openModalCompromiso,
  compromiso,
  openDetalleCompromiso,
  openEliminarCompromiso,
  filtroCodigo,
  filtroTitulo,
  setFiltroCodigo,
  setFiltroTitulo,
  filtroComunidad,
  setFiltroComunidad,
  filtroAplicaA,
  setFiltroAplicaA,
  filtroDimensiones,
  setFiltroDimensiones,
  filtroODS,
  setFiltroODS,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { showSpinner } = useContext(SpinnerContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CompromisosFilterMobile
        filtroCodigo={filtroCodigo}
        filtroTitulo={filtroTitulo}
        setFiltroCodigo={setFiltroCodigo}
        setFiltroTitulo={setFiltroTitulo}
        handleBuscarCompromisos={handleBuscarCompromisos}
        handleAgregarCompromiso={handleAgregarCompromiso}
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        filtroComunidad={filtroComunidad}
        setFiltroComunidad={setFiltroComunidad}
        filtroAplicaA={filtroAplicaA}
        setFiltroAplicaA={setFiltroAplicaA}
        filtroDimensiones={filtroDimensiones}
        setFiltroDimensiones={setFiltroDimensiones}
        filtroODS={filtroODS}
        setFiltroODS={setFiltroODS}
      />

      {isLoading && !showSpinner ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : compromisos.filter((compromiso) => !isReadOnly || compromiso.enabled)
          .length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" align="center">
            No hay compromisos para mostrar.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {compromisos
            .filter((compromiso) => !isReadOnly || compromiso.enabled)
            .sort((a, b) => {
              // Ordenar por comunidad
              if (a.community.trim() !== b.community.trim()) {
                return a.community.trim().localeCompare(b.community.trim());
              } else {
                // Si las comunidades son iguales, ordenar por título
                return a.title.trim().localeCompare(b.title.trim());
              }
            })
            .map((compromiso) => (
              <Grid item xs={12} sm={6} md={4} key={compromiso.code}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {compromiso.title}
                    </Typography>
                    {!isReadOnly && (
                      <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Código: {compromiso.code}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ mt: 1.5 }}>
                      <strong>Comunidad:</strong> {compromiso.community}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Aplicable a:</strong>{" "}
                      {compromiso.appliesToPersons && "Personas"}
                      {compromiso.appliesToPersons &&
                        (compromiso.appliesToCompanies ||
                          compromiso.appliesToServices) &&
                        ", "}
                      {compromiso.appliesToCompanies && "Empresas"}
                      {compromiso.appliesToCompanies &&
                        compromiso.appliesToServices &&
                        ", "}
                      {compromiso.appliesToServices && "Aplicaciones"}
                    </Typography>

                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Dimensiones ESG:</strong>
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {/* Dimension E */}
                        <Chip
                          icon={<Public />}
                          label="(E)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso.dimensionE}
                        />
                        {/* Dimension S */}
                        <Chip
                          icon={<Groups />}
                          label="(S)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso.dimensionS}
                        />
                        {/* Dimension G */}
                        <Chip
                          icon={<AccountBalance />}
                          label="(G)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso.dimensionG}
                        />
                      </Stack>
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<ZoomIn />}
                          onClick={() =>
                            handleIrSociosAdheridosCompromiso(compromiso)
                          }
                          color="primary"
                          disabled={isLoading}
                          size="small"
                        >
                          Detalle
                        </Button>
                      </Grid>

                      {!isReadOnly && (
                        <>
                          {true && ( // replace `true` with `!compromiso.hasAssignees` to roll back temp changes
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() =>
                                  handleEditarCompromiso(compromiso)
                                }
                                color="primary"
                                disabled={isLoading}
                              >
                                Editar
                              </Button>
                            </Grid>
                          )}
                          {compromiso.enabled ? (
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                startIcon={<NotInterested />}
                                onClick={() =>
                                  handleDeshabilitarCompromiso(compromiso)
                                }
                                color="primary"
                                disabled={isLoading}
                              >
                                Deshabilitar
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                size="small"
                                startIcon={<Restore />}
                                onClick={() =>
                                  handleHabilitarCompromiso(compromiso)
                                }
                                color="primary"
                                disabled={isLoading}
                              >
                                Habilitar
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              size="small"
                              startIcon={<DeleteIcon />}
                              onClick={() => {
                                handleEliminarCompromiso(compromiso);
                              }}
                              sx={{
                                backgroundColor: "#FF0000",
                                color: "#FFFFFF",
                                "&:hover": {
                                  backgroundColor: "#CC0000",
                                },
                              }}
                              disabled={isLoading}
                            >
                              Eliminar
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
      {openDetalleCompromiso && (
        <ModalCompromisoDetalleMobile
          open={openDetalleCompromiso}
          handleClose={handleCloseDetalleCompromiso}
          compromiso={compromiso}
        />
      )}
      {openModalCompromiso && (
        <ModalCompromisoFormMobile
          open={openModalCompromiso}
          handleCloseModalCompromiso={handleCloseModalCompromiso}
          compromiso={compromiso}
        />
      )}

      {openEliminarCompromiso && (
        <ModalEliminarCompromisoMobile
          open={openEliminarCompromiso}
          handleCloseEliminarCompromiso={handleCloseEliminarCompromiso}
          compromiso={compromiso}
          eliminarCompromiso={eliminarCompromiso}
        />
      )}
    </Box>
  );
};
