import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import TextField from "@mui/material/TextField";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { useNotification } from "../../hooks/useNotification";
import { getPerson } from "../../services/PersonsServices";

const PersonaPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [personData, setPersonData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPersonData = () => {
    setIsLoading(true);
    getPerson(Web3Manager.MEMBER_DATA.memberAddress)
      .then((currentPersonList) => {
        if (currentPersonList && currentPersonList.length > 0) {
          const currentPersonData = {
            _address: currentPersonList[0].memberAddress,
            _firstName: currentPersonList[0].firstName,
            _lastName: currentPersonList[0].lastName,

            _email: currentPersonList[0].email,
            _category: Constants.getNombreCategoria(
              currentPersonList[0].category
            ),
            _roles: currentPersonList[0].roles.filter(
              (role) =>
                role !== Constants.ROLE_ADMIN && role !== Constants.ROLE_SOCIO
            ),
          };

          setPersonData(currentPersonData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    const memberData = Web3Manager.MEMBER_DATA;
    if (memberData) {
      if (memberData.memberType === Constants.MEMBER_TYPE_PERSONA) {
        // Verificamos el estado de la persona. Para entrar a operar, debe estar approved
        if (Web3Manager.isAccountDisabled()) {
          Common.goForward("/errorSocioInhabilitado");
        }
        getPersonData();
      } else if (memberData.memberType === Constants.MEMBER_TYPE_EMPRESA) {
        // El registro es imcompatible, porque estamos entrando a una pantalla de gestion de personas
        Common.goForward("/errorRegistroIncompatible");
      }
    } else {
      // El socio no esta registrado, porque no encontramos nada asociado a esta cuenta
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Mis datos
        </Typography>
      </Box>

      <Box>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item lg={4}>
            {isLoading ? (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box mt={2}>
                  <TextField
                    name="_address"
                    id="outlined-basic"
                    label="Dirección"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={personData?._address}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
                  <TextField
                    name="_firstName"
                    id="outlined-basic"
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={personData?._firstName}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    sx={{ marginLeft: 1 }}
                    name="_lastName"
                    id="outlined-basic"
                    label="Apellido"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={personData?._lastName}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    name="_email"
                    id="outlined-basic"
                    label="Correo Electrónico"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={personData?._email}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    name="_category"
                    id="outlined-basic"
                    label="Categoría"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={personData?._category}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                {personData &&
                  personData._roles &&
                  personData._roles.length > 0 && (
                    <Box mt={2}>
                      <TextField
                        id="outlined-basic"
                        label="Roles Asignados"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        disabled={true}
                        value={personData._roles
                          .map((roleCode) => Constants.getNombreRol(roleCode))
                          .filter((nombreRol) => nombreRol)
                          .join(", ")}
                      />
                    </Box>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <br />
    </Container>
  );
};

export default withRouter(PersonaPage);
