import React from "react";

import { CircularProgress, Modal } from "@mui/material";

const SpinnerModal = ({ open, size = 100 }) => {


  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <CircularProgress id="spinner-modal" color="primary" size={size} />
      </div>
    </Modal>
  );
};

export default SpinnerModal;
