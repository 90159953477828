import { Box, Button, Modal, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useContext, useState } from 'react';
import * as Web3Manager from '../../support/Web3Manager';
import * as Decimals from '../../support/Decimals';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { CancelButton } from '../ui/CustomButtons';
import SpinnerContext from '../../context/spinner/SpinnerContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const ModalHorasForm = ({
  open,
  handleClose,
  isEditing,
  pagoEditing,
  socio,
  setLoading,
}) => {
  const [initialDate, setInitialDate] = useState(moment().format('YYYY-MM-DD'));
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _value: Yup.number()
      .typeError('Debes ingresar un número')
      .required('Debes ingresar un número')
      .positive('Debes ingresar un número mayor a 0'),

    _hoursDateFrom: Yup.string().test(
      '_hoursDateFrom',
      'La fecha ingresada no puede ser una fecha futura',
      (value) => {
        const _hoursDateFrom = moment(value, 'YYYY-MM-DD')
          .startOf('day')
          .unix();
        const _tomorrow = moment().add(1, 'days').startOf('day').unix();
        return _hoursDateFrom < _tomorrow;

        /*  const _paymentPlanCategory = formPago.values._paymentPlanCategory;
        if (_paymentPlanCategory === Constants.CATEGORY_ACTIVO) {
          const _hoursDateFrom = moment(value, "YYYY-MM-DD")
            .startOf("day")
            .unix();
          const _tomorrow = moment().add(1, "days").startOf("day").unix();
          return _hoursDateFrom < _tomorrow;
        } else {
          return true;
        } */
      }
    ),
    _hoursDateTo: Yup.string().test(
      '_hoursDateTo',
      'La fecha ingresada no puede ser una fecha futura',
      (value) => {
        const _hoursDateTo = moment(value, 'YYYY-MM-DD').startOf('day').unix();
        const _tomorrow = moment().add(1, 'days').startOf('day').unix();
        return _hoursDateTo < _tomorrow;
        /* 
        const _paymentPlanCategory = formPago.values._paymentPlanCategory;
        if (_paymentPlanCategory === Constants.CATEGORY_ACTIVO) {
          const _hoursDateTo = moment(value, "YYYY-MM-DD")
            .startOf("day")
            .unix();
          const _tomorrow = moment().add(1, "days").startOf("day").unix();
          return _hoursDateTo < _tomorrow;
        } else {
          return true;
        } */
      }
    ),
    _hoursDescription: Yup.string()
      .required('La descripción no puede estar vacía')
      .test(
        '_hoursDescription',
        'La descripción no puede estar vacía',
        (value) => {
          return value !== '';

          /*           const _paymentPlanCategory = formPago.values._paymentPlanCategory;
          if (_paymentPlanCategory === Constants.CATEGORY_ACTIVO) {
            return value !== "";
          } else {
            return true;
          }
 */
        }
      ),
  });

  const initialState = {
    _hourRegisteredCode: 0,
    _value: 0,
    _hoursDateFrom: initialDate,
    _hoursDateTo: initialDate,
    _hoursDescription: '',
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _hourRegisteredCode: pagoEditing._hourRegisteredCode,
        _value: Decimals.format(pagoEditing._value),
        _hoursDateFrom: moment
          .unix(pagoEditing._hoursDateFrom)
          .format('YYYY-MM-DD'),
        _hoursDateTo: moment
          .unix(pagoEditing._hoursDateTo)
          .format('YYYY-MM-DD'),
        _hoursDescription: pagoEditing._hoursDescription,
      };
    }

    /*  if (planesPago) {
      let planPago = planesPago.find(
        (plan) => plan.code.toNumber() === formData._paymentPlanCode
      );
      if (!planPago && socio) {
        planPago = planesPago.find(
          (plan) => plan.code.toNumber() === socio._paymentPlanCode
        );
      }

      // Con el plan de pago inferido, cargamos los datos que faltan
      if (planPago) {
        formData._paymentPlanCode = planPago.code.toNumber();
        formData._paymentPlanCategory = planPago.category;
        formData._paymentPlanName = planPago.name;
        formData._paymentPlanUnit = planPago.unit;
        formData._paymentPlanEnabled = planPago.enabled;

        if (planPago.category === Constants.CATEGORY_SUSCRIPTOR) {
          // Necesario para que funcione la llamada al alta/modificacion
          formData._hoursDescription = "Registro de pago";
        }

        // Si estamos dando de alta el pago, cargamos el valor del plan de pago, como ofrecimiento por defecto
        if (!isEditing) {
          formData._value = Decimals.format(planPago.value);
        }
      }
    }*/

    return formData;
  };

  const formPago = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _hourRegisteredCode,
        _value,
        _hoursDateFrom,
        _hoursDateTo,
        _hoursDescription,
      } = values;

      let _hoursDateFromUnix;
      let _hoursDateToUnix;

      _hoursDateFromUnix = moment(_hoursDateFrom, 'YYYY-MM-DD')
        .startOf('day')
        .unix();
      _hoursDateToUnix = moment(_hoursDateTo, 'YYYY-MM-DD')
        .startOf('day')
        .unix();

      if (_hoursDateFromUnix > _hoursDateToUnix) {
        handleClose(
          false,
          'La fecha de inicio no puede ser mayor a la fecha de fin',
          false
        );
        return;
      }

      const _valueParsed = Decimals.parse(_value);

      setDisableButton(true);
      setLoading(true);
      openSpinner();

      if (!isEditing) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_HOURSREGISTER,
          "addHours",
          socio._memberCode,
          socio._memberType,
          _valueParsed,
          _hoursDateFromUnix,
          _hoursDateToUnix,
          _hoursDescription
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_HOURSREGISTER,
          "modifyHours",
          socio._memberCode,
          pagoEditing._hourRegisteredCode,
          _valueParsed,
          _hoursDateFromUnix,
          _hoursDateToUnix,
          _hoursDescription
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formPago;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={style} component='form' onSubmit={handleSubmit}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Datos de la donación de horas
        </Typography>

        {/*<Box mt={2}>
          <TextField
            name="_paymentPlanName"
            id="outlined-basic"
            label="Plan de pagos"
            variant="outlined"
            size="small"
            fullWidth={true}
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._paymentPlanName}
            error={Boolean(touched._paymentPlanName && errors._paymentPlanName)}
            helperText={touched._paymentPlanName && errors._paymentPlanName}
          />
    </Box>*/}
        <Box mt={2}>
          <TextField
            name='_value'
            id='outlined-basic'
            label={'Cantidad de horas'}
            variant='outlined'
            size='small'
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._value}
            //value={Decimals.normalize(values._value)}
            error={Boolean(touched._value && errors._value)}
            helperText={touched._value && errors._value}
          />
        </Box>
        {/*values._paymentPlanCategory === Constants.CATEGORY_SUSCRIPTOR && (
          <Box mt={2} sx={{ display: "flex" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ flexGrow: 1 }}
                name="_paymentDate"
                label="Fecha de pago"
                inputFormat="DD-MM-YYYY"
                value={values._paymentDate}
                onChange={(newValue) => {
                  formPago.setFieldValue(
                    "_paymentDate",
                    newValue.format("YYYY-MM-DD"),
                    true
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth={true}
                    {...params}
                    error={Boolean(touched._paymentDate && errors._paymentDate)}
                    helperText={touched._paymentDate && errors._paymentDate}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
                )*/}

        <Box mt={2} sx={{ display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ flexGrow: 1 }}
              name='_hoursDateFrom'
              label='Fecha Desde'
              inputFormat='DD-MM-YYYY'
              value={values._hoursDateFrom}
              onChange={(newValue) => {
                formPago.setFieldValue(
                  '_hoursDateFrom',
                  newValue.format('YYYY-MM-DD'),
                  true
                );
              }}
              renderInput={(params) => (
                <TextField
                  size='small'
                  fullWidth={true}
                  {...params}
                  error={Boolean(
                    touched._hoursDateFrom && errors._hoursDateFrom
                  )}
                  helperText={touched._hoursDateFrom && errors._hoursDateFrom}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box mt={2} sx={{ display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              sx={{ flexGrow: 1 }}
              name='_hoursDateTo'
              label='Fecha Hasta'
              inputFormat='DD-MM-YYYY'
              value={values._hoursDateTo}
              onChange={(newValue) => {
                formPago.setFieldValue(
                  '_hoursDateTo',
                  newValue.format('YYYY-MM-DD'),
                  true
                );
              }}
              renderInput={(params) => (
                <TextField
                  size='small'
                  fullWidth={true}
                  {...params}
                  error={Boolean(touched._hoursDateTo && errors._hoursDateTo)}
                  helperText={touched._hoursDateTo && errors._hoursDateTo}
                  onKeyDown={(e) => e.preventDefault()}
                />
              )}
            />
          </LocalizationProvider>
        </Box>

        <Box mt={2}>
          <TextField
            name='_hoursDescription'
            id='outlined-basic'
            label={'Descripción de las tareas'}
            variant='outlined'
            size='small'
            fullWidth={true}
            multiline={true}
            rows={4}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._hoursDescription}
            error={Boolean(
              touched._hoursDescription && errors._hoursDescription
            )}
            helperText={touched._hoursDescription && errors._hoursDescription}
          />
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: 'left', display: 'flex', gap: '10px' }}
        >
          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={disableButton}
          >
            Confirmar
          </Button>
          <CancelButton
            label='Cancelar'
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
