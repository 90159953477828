import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";

const ContractsPage = () => {
  const [contractsData, setContractsData] = useState([]);

  const contracts = [
    { name: "AccessControl", description: "Manejo de roles y permisos" },
    {
      name: "Parameters",
      description: "Parametros generales de la plataforma",
    },
    { name: "Principles", description: "Gestión de Principios" },
    { name: "Commitments", description: "Gestión de Compromisos" },
    { name: "Persons", description: "Gestión de usuarios tipo Persona" },
    { name: "Companies", description: "Gestión de usuarios tipo Empresa" },
    { name: "Applications", description: "Manejo de Aplicaciones del sistema" },
    {
      name: "HoursRegister",
      description: "Manejo de horas registradas por los Usuarios",
    },
    { name: "Proposals", description: "Manejo de Propuestas del sistema" },
    {
      name: "Ballots",
      description: "Manejo de Votos para Propuestas del sistema",
    },
    {
      name: "Collaborations",
      description: "Manejo de Colaboraciones del sistema",
    },
    {
      name: "Reports",
      description: "Gestión de Reportes para Colaboraciones de la plataforma",
    },
  ];

  const initializeContracts = async () => {
    const contractsData = [];
    for (let contract of contracts) {
      const contractData = {};
      const contractDefinition = await import(
        "../configuration/contracts/" +
          process.env.REACT_APP_SUPPORTED_CHAIN_ID +
          "/" +
          contract.name +
          ".json"
      );
      contractData.name = contract.name;
      contractData.description = contract.description;
      contractData.definition = contractDefinition;
      contractsData.push(contractData);
    }
    return contractsData;
  };

  useEffect(() => {
    initializeContracts().then((contractsData) => {
      setContractsData(contractsData);
    });
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Contratos Blockchain
        </Typography>
      </Box>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="body1"
              color={"#005A59"}
              align="center"
              p={3}
              sx={{
                fontWeight: 300,
                fontSize: {
                  xs: "18px",
                  sm: "20px",
                  md: "20px",
                },
              }}
            >
              La plataforma de Fibras utiliza tecnología blockchain, la cual
              garantiza transparencia, trazabilidad y gestión descentralizada de
              datos, proporcionando así un entorno fiable para acceder y
              utilizar la información.
              <br />
              <br />
              En esta página encontrarás información detallada sobre los
              contratos inteligentes que almacenan los datos y la implementación
              de nuestra solución, utilizando la red de blockchain compatible
              con EVM de Polygon.
            </Typography>
          </Grid>

          {contractsData.map((contract) => (
            <Grid xs={12} sx={{ marginBottom: 8 }} key={contract?.name}>
              <Grid item justifyContent="center">
                <Typography
                  variant="h5"
                  align="center"
                  sx={{ marginBottom: 4 }}
                >
                  {contract?.name}
                </Typography>
              </Grid>
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography align="left">Descripción</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography align="left">{contract?.description}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography align="left">Dirección</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{ overflowWrap: "break-word", overflow: "hidden" }}
                    align="left"
                  >
                    {contract?.definition?.address}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default withRouter(ContractsPage);
