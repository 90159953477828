import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalComunidad = ({ open, handleClose, handleSubmitComunidad }) => {
  const [disableButton, setDisableButton] = useState(false);

  const initialState = {
    _comunidad: "",
  };

  const validationSchema = Yup.object({
    _comunidad: Yup.string().required("Debe ingresar una comunidad"),
  });

  const formik = useFormik({
    initialValues: initialState,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _comunidad } = values;

      setDisableButton(true);
      handleSubmitComunidad(_comunidad);
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Comunidad
        </Typography>
        <Box mt={2}>
          <TextField
            name="_comunidad"
            id="outlined-basic"
            label="Comunidad"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._comunidad}
            error={Boolean(touched._comunidad && errors._comunidad)}
            helperText={touched._comunidad && errors._comunidad}
          />
        </Box>
        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Agregar
          </Button>
          <CancelButton
            label="Cerrar"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
