import React from 'react';
import Container from "@mui/material/Container";
import {Grid, Typography} from "@mui/material";

export default () =>
    <Container maxWidth="lg">
        <Grid container>
            <Grid item md={12}>
                <Typography variant='h4' align='center' p={5}>
                    Usted no se encuentra registrado para operar con la plataforma.
                    Por favor realice el proceso de registro para poder utilizar los servicios de Fibras
                </Typography>
            </Grid>
        </Grid>
    </Container>