import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Modal,
  Typography,
  Chip,
  Grid,
} from "@mui/material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";
import moment from "moment/moment";
import { CheckCircle } from "@mui/icons-material";

import IndicadorSatisfaccion from "../colaboraciones/IndicadorSatisfaccion";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { getPerson } from "../../services/PersonsServices";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalFinalizarReporte = ({
  open,
  handleClose,
  reporte,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [validatorData, setValidatorData] = useState([]);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const handleFinalizarReporte = (code) => {
    setLoading(true);
    setDisableButton(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_REPORTS,
      "finalizeReport",
      code
    )
      .then((response) => {
        setDisableButton(false);
        handleClose(false, response, true);
      })
      .catch((error) => {
        setLoading(false);
        setDisableButton(false);
        handleClose(false, error, false);
      });
  };

  const getValidatorData = (address) => {
    getPerson(address)
      .then((response) => {
        setValidatorData(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getValidatorData(reporte.validatorAddress);
  }, [reporte]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              Reporte {reporte.collaborationTitle}
              <Chip
                label={Constants.getNombreEstadoReporte(reporte.status)}
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.REPORT_STATUS_COLORS[reporte.status],
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <strong>Código reporte:</strong> {reporte.code.toNumber()}
            </Typography>{" "}
            <Typography variant="subtitle1">
              <strong>Código colaboración:</strong>{" "}
              {reporte.collaborationCode.toNumber()}
            </Typography>{" "}
            <Typography variant="subtitle1">
              <strong>Reportada el:</strong>{" "}
              {moment
                .unix(reporte.creationDate.toNumber())
                .format("DD-MM-YYYY")}
            </Typography>{" "}
            <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
              <Typography variant="subtitle1">
                <strong>{"Emisor: "}</strong>
              </Typography>
              &nbsp;
              <Typography variant="subtitle1">
                {reporte.collaboration.sourceMemberName} (
                {reporte.collaboration.sourceMemberAddress})
              </Typography>
              <Chip
                label={
                  reporte.collaboration[2] === Constants.MEMBER_TYPE_PERSONA
                    ? `PERSONA`
                    : `EMPRESA`
                }
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.MEMBER_TYPE_COLORS[reporte.collaboration[2]],
                }}
              />
              <IndicadorSatisfaccion
                colaboracion={reporte.collaboration}
                isSource={true}
              />
            </Box>
            <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
              <Typography variant="subtitle1">
                <strong>{"Receptor: "}</strong>
              </Typography>
              &nbsp;
              <Typography variant="subtitle1">
                {reporte.collaboration.targetMemberName} (
                {reporte.collaboration.targetMemberAddress})
              </Typography>
              <Chip
                label={
                  reporte.collaboration[5] === Constants.MEMBER_TYPE_PERSONA
                    ? `PERSONA`
                    : `EMPRESA`
                }
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.MEMBER_TYPE_COLORS[reporte.collaboration[5]],
                }}
              />
              <IndicadorSatisfaccion
                colaboracion={reporte.collaboration}
                isSource={true}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            {reporte.analysis !== "" ? (
              <Box mt={2}>
                <Typography variant="subtitle1">
                  <strong>Análisis:</strong>
                </Typography>
                <Box sx={{ overflowY: "auto", maxHeight: 200, mt: 1 }}>
                  <Typography variant="body1" component="span">
                    {reporte.analysis}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography variant="subtitle1">
                <strong>Aún no se ha realizado un análisis del reporte</strong>
              </Typography>
            )}
            {reporte.conclusions !== "" ? (
              <Box mt={2}>
                <Typography variant="subtitle1">
                  <strong>Conclusiones:</strong>
                </Typography>

                <Box sx={{ overflowY: "auto", maxHeight: 200, mt: 1 }}>
                  <Typography variant="body1" component="span">
                    {reporte.conclusions}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box item mt={2}>
                <Typography variant="subtitle1">
                  <strong>El validador no ha emitido conclusiones</strong>
                </Typography>
              </Box>
            )}
            {reporte.status === Constants.REPORT_STATUS_FINALIZED &&
              validatorData.length > 0 && (
                <Box container mt={2}>
                  <Box item>
                    {" "}
                    <Typography variant="subtitle1" fontWeight="bold">
                      {" "}
                      Validador: {validatorData[0].firstName}{" "}
                      {validatorData[0].lastName} ({reporte.validatorAddress})
                    </Typography>
                  </Box>
                </Box>
              )}
          </Grid>

          <Grid item xs={12}>
            {reporte.status === Constants.REPORT_STATUS_ACTIVE && (
              <Box container>
                <Box>
                  {reporte.analysis !== "" && reporte.conclusions !== "" ? (
                    <Alert
                      severity="info"
                      sx={{
                        fontSize: "12px",
                        mt: 2,
                        width: "100%",
                      }}
                    >
                      <AlertTitle>
                        ¿Estás seguro que deseas finalizar el reporte?
                      </AlertTitle>
                      Una vez finalizado, ya no podrás seguir editando y tanto
                      el análisis como las conclusiones serán visibles para
                      todos los usuarios.
                    </Alert>
                  ) : (
                    <Alert
                      severity="warning"
                      sx={{
                        fontSize: "12px",
                        mt: 2,
                        width: "100%",
                      }}
                    >
                      <AlertTitle>Faltan análisis y/o conclusiones</AlertTitle>
                      No puedes finalizar el reporte hasta que se haya realizado
                      el análisis y emitido las conclusiones correspondientes
                    </Alert>
                  )}
                </Box>

                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "left", gap: "10px" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={<CheckCircle />}
                    onClick={() => handleFinalizarReporte(reporte.code)}
                    disabled={
                      reporte.analysis === "" ||
                      reporte.conclusions === "" ||
                      disableButton
                    }
                  >
                    Finalizar
                  </Button>
                  <CancelButton
                    label="Cancelar"
                    onClick={() => {
                      handleClose(true, null, false);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
