import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import MenuItem from "@mui/material/MenuItem";
import { CancelButton } from "../ui/CustomButtons";
import Linkify from "linkify-react";
import ReactQuill from "react-quill";
import HtmlContentDisplay from "../ui/HtmlContentDisplay";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalPrincipioDetalle = ({ open, handleClose, principio }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Principio{" "}
        </Typography>

        <Box mt={2}>
          <TextField
            id="outlined-basic"
            label="Título"
            variant="outlined"
            size="small"
            disabled={true}
            fullWidth={true}
            value={principio ? principio.title : ""}
          />
        </Box>
        <Box mt={2}>
          <HtmlContentDisplay
            content={principio ? principio.description : ""}
          />
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
