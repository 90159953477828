import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { useNotification } from "../../hooks/useNotification";
import SociosContext from "../../context/socios/SociosContext";
import { SociosTable } from "../../components/socios/SociosTable";
import { useMediaQuery } from "@mui/material";
import { SocioCard } from "../../components/socios/SocioCard";

const AdminSociosPage = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const {
    socios,
    getSocios,
    isLoading,
    setLoading,
    eliminarSocio,
    habilitacionSocio,
  } = useContext(SociosContext);

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getSocios(
      Constants.MEMBER_TYPE_SIN_REGISTRO,
      Constants.CATEGORY_ANY,
      "",
      Constants.MEMBER_STATUS_ANY
    );
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Gesti&oacute;n de socios
        </Typography>
      </Box>
      <>
        {matches ? (
          <SocioCard
            socios={socios}
            getSocios={getSocios}
            isLoading={isLoading}
            isReadOnly={false}
            setLoading={setLoading}
            eliminarSocio={eliminarSocio}
            habilitacionSocio={habilitacionSocio}
          />
        ) : (
          <SociosTable
            socios={socios}
            getSocios={getSocios}
            isLoading={isLoading}
            isReadOnly={false}
            setLoading={setLoading}
            eliminarSocio={eliminarSocio}
            habilitacionSocio={habilitacionSocio}
          />
        )}
      </>
    </Container>
  );
};

export default withRouter(AdminSociosPage);
