export function format(decimalNumber) {
    if (decimalNumber) {
        let whole = decimalNumber.whole;
        let fractional = decimalNumber.fractional;

        if (typeof whole !== "number") {
            whole = whole.toNumber();
        }
        if (typeof fractional !== "number") {
            fractional = fractional.toNumber();
        }

        if (fractional === 0) {
            return String(whole);
        } else {
            return whole + "." + fractional;
        }

    } else {
        return "";
    }
}

export function normalize(decimalNumber) {
    if (decimalNumber) {
        let whole = decimalNumber.whole;
        let fractional = decimalNumber.fractional;

        if (typeof whole !== "number") {
            whole = whole.toNumber();
        }
        if (typeof fractional !== "number") {
            fractional = fractional.toNumber();
        }

        return {
            "whole": whole,
            "fractional": fractional
        };

    } else {
        return null;
    }
}

export function parse(value) {
    if (value && value.indexOf(".") >= 0) {
        const whole = Number(value.substring(0, value.indexOf(".")));
        const fractional = Number(value.substring(value.indexOf(".") + 1, value.length));

        return {
            "whole": whole,
            "fractional": fractional
        };
    } else if (value) {
        return {
            "whole": Number(value),
            "fractional": 0
        };
    } else {
        return {
            "whole": 0,
            "fractional": 0
        };
    }
}