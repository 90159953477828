import React from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Web3Manager from "../../../support/Web3Manager";

import * as Constants from "../../../support/Constants";
import { DataGrid } from "@mui/x-data-grid";
import { ModalDetallePersonaForm } from "../../socios/ModalDetallePersonaForm";
import {
  AssignmentTurnedIn,
  Handshake,
  PendingActions,
  Search,
  ZoomIn,
} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import { ModalDetalleEmpresaForm } from "../../socios/ModalDetalleEmpresaForm";

import { ModalColaboracionesForm } from "../../colaboraciones/ModalColaboracionesForm";

import { GridActionItem } from "../../ui/GridActionItem";

import { CompromisoSociosAdheridosFilterMobile } from "./CompromisoSociosAdheridosFilterMobile";

export const CompromisoSociosAdheridosTable = ({
  adhesiones,
  isLoading,
  showSpinner,
  getEstadoCompromisoSocio,
  getDeadlineDate,
  getApplicationNameToShow,
  handleDetalleSocio,
  handleColaborarConSocio,
  openModalDetallePersona,
  handleCloseModalDetallePersona,
  personaSeleccionada,
  openModalDetalleEmpresa,
  handleCloseModalDetalleEmpresa,
  empresaSeleccionada,
  openModalColaboracion,
  handleCloseModalColaboracion,
  socios,
  principios,
  compromisos,
  categoriasColaboracion,
  setLoading,
  modalColaboracionInitData,
  filtroNombre,
  setFiltroNombre,
  buscarAdhesiones,
}) => {
  const columnsSocios = [
    {
      field: "name",
      headerName: "Socio",
      flex: 1,
    },
    {
      field: "type",
      headerName: "Tipo de socio",
      flex: 1,
      valueGetter: (params) => {
        if (params.value === Constants.MEMBER_TYPE_PERSONA) {
          return "Persona";
        } else if (params.value === Constants.MEMBER_TYPE_EMPRESA) {
          return "Empresa";
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "application",
      headerName: "Aplicación",
      flex: 1,
      valueGetter: (params) => {
        return getApplicationNameToShow(params.row);
      },
    },
    {
      field: "statusCompromiso",
      headerName: "Estado",
      flex: 1,
      renderCell: (params) => {
        const status = getEstadoCompromisoSocio(params.row);

        // Función para determinar el icono y el texto del chip basado en el estado
        const getChipInfo = () => {
          switch (status) {
            case "Cumple":
              return {
                icon: <AssignmentTurnedIn style={{ color: "green" }} />,
                label: "Cumple",
              };
            case "Tiene intención":
              return {
                icon: <PendingActions style={{ color: "orange" }} />,
                label: "Tiene intención",
              };
            default:
              return { icon: null, label: "Desconocido" };
          }
        };

        const { icon, label } = getChipInfo();

        return (
          <Chip
            sx={{ p: 2 }}
            icon={icon}
            label={label}
            variant="outlined"
            size="small"
          />
        );
      },
    },
    {
      field: "deadlineDate",
      headerName: "Fecha límite",
      flex: 1,
      valueGetter: (params) => {
        return getDeadlineDate(params.row);
      },
    },

    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              disabled={isLoading || showSpinner}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetalleSocio(params.row)}
            />

            {params?.row?.memberAddress.toLowerCase() !=
              Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() && (
              <GridActionItem
                disabled={isLoading || showSpinner}
                icon={<Handshake />}
                label="Colaborar"
                onClick={() => handleColaborarConSocio(params.row)}
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        );
      },
    },
  ];
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const CustomNoRows = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography variant="subtitle1" component="div">
          No hay socios adheridos para mostrar.
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container>
        {matches ? (
          <CompromisoSociosAdheridosFilterMobile
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            buscarAdhesiones={buscarAdhesiones}
            showSpinner={showSpinner}
          />
        ) : (
          <Grid item lg={12}>
            <Box m={2} sx={{ justifyContent: "right", display: "flex" }}>
              <TextField
                sx={{ width: 300 }}
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                size="small"
                value={filtroNombre}
                onChange={(newValue) => setFiltroNombre(newValue.target.value)}
              />

              <Button
                sx={{ marginLeft: 1 }}
                variant="contained"
                color="primary"
                onClick={() => buscarAdhesiones()}
                disabled={isLoading || showSpinner}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <DataGrid
        rows={adhesiones}
        pageSize={10}
        autoHeight={true}
        loading={isLoading && !showSpinner}
        columns={columnsSocios}
        getRowId={(row) => row.memberId + "," + row.applicationId}
        rowsPerPageOptions={[10]}
        components={{
          NoRowsOverlay: CustomNoRows,
        }}
      />

      <br />

      {openModalDetallePersona && (
        <ModalDetallePersonaForm
          open={openModalDetallePersona}
          handleClose={handleCloseModalDetallePersona}
          persona={personaSeleccionada}
        />
      )}

      {openModalDetalleEmpresa && (
        <ModalDetalleEmpresaForm
          open={openModalDetalleEmpresa}
          handleClose={handleCloseModalDetalleEmpresa}
          empresa={empresaSeleccionada}
        />
      )}

      {openModalColaboracion && (
        <ModalColaboracionesForm
          colaboracionEditing={null}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          isEditing={false}
          personas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_PERSONA
          )}
          empresas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_EMPRESA
          )}
          principios={principios}
          compromisos={compromisos}
          categoriasColaboracion={categoriasColaboracion}
          setLoading={setLoading}
          initData={modalColaboracionInitData}
        />
      )}
    </>
  );
};
