import { showSuccessMessage, showErrorMessage, showInfoMessage } from "../components/ui/toast";

export const useNotification = () => {
  const handleOpenNotificacionError = (message) => {
    if (message.type === "info"){
    showInfoMessage(message.text);
  } 
  showErrorMessage(message);
  };

  const handleOpenNotificacionTx = (msg) => {
    showSuccessMessage(
      "La transacción se está procesando. Una vez confirmada, puede demorar unos minutos en reflejarse el cambio en la plataforma"
    );
  };

  const handleOpenNotificacionTxMsg = (msg) => {
    showSuccessMessage(msg);
  };

  return {
    handleOpenNotificacionError,
    handleOpenNotificacionTx,
    handleOpenNotificacionTxMsg,
  };
};
