import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import {
  Accessibility,
  AccountBalance,
  Business,
  Groups,
  PhoneAndroid,
  Public,
} from "@mui/icons-material";

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useNotification } from "../../hooks/useNotification";
import { withRouter } from "react-router-dom";

import CompromisosContext from "../../context/compromisos/CompromisosContext";
import SociosContext from "../../context/socios/SociosContext";
import SpinnerContext from "../../context/spinner/SpinnerContext";

import { getCompany } from "../../services/CompaniesServices";
import { getPerson } from "../../services/PersonsServices";
import { getCollaborationCategories } from "../../services/ParametersServices";
import { getPrinciples } from "../../services/PrinciplesServices";

import { CompromisoSociosAdheridosCard } from "../../components/compromisos/CompromisoSociosAdheridos/CompromisoSociosAdheridosCard";
import { CompromisoSociosAdheridosTable } from "../../components/compromisos/CompromisoSociosAdheridos/CompromisoSociosAdheridosTable";
import ModalAdherirCompromiso from "../../components/compromisos/ModalAdherirCompromiso";
import ModalAdherirCompromisoMobile from "../../components/compromisos/ModalAdherirCompromisoMobile";

const CompromisoSociosAdheridosPage = ({ match }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const compromisoCode = match.params.compromisoCode;

  const {
    handleOpenNotificacionError,
    handleOpenNotificacionTx,
    handleOpenNotificacionTxMsg,
  } = useNotification();

  const [adhesiones, setAdhesiones] = useState([]);
  const { compromisos, getCompromisos } = useContext(CompromisosContext);
  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);
  const { socios, getSocios, isLoading, setLoading } =
    useContext(SociosContext);
  const [filtroNombre, setFiltroNombre] = useState("");
  const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
  const [openModalDetallePersona, setOpenModalDetallePersona] = useState(false);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [openModalDetalleEmpresa, setOpenModalDetalleEmpresa] = useState(false);
  const [roles, setRoles] = useState([]);
  const [principios, setPrincipios] = useState([]);
  const [compromiso, setCompromiso] = useState(null);
  const [categoriasColaboracion, setCategoriasColaboracion] = useState([]);
  const [openModalColaboracion, setOpenModalColaboracion] = useState(false);
  const [modalColaboracionInitData, setModalColaboracionInitData] =
    useState(null);
  const [openModalAdherirse, setOpenModalAdherirse] = useState(false);

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getCompromisos(0, "", "");
    getSocios(
      Constants.MEMBER_TYPE_SIN_REGISTRO,
      Constants.CATEGORY_ANY,
      "",
      Constants.MEMBER_STATUS_ANY,
      true
    );
    getRoles();
    getPrincipios();
    //Se necesitan para el modal de colaboraciones
    getCategoriasColaboracion();
  }, []);

  useEffect(() => {
    buscarAdhesiones();
  }, [socios]);

  useEffect(() => {
    const compromiso = compromisos.find((c) => c.code == compromisoCode);

    if (compromiso) {
      setCompromiso(compromiso);
    } else {
      //TODO: mandar a error?
    }
  }, [compromisos]);

  const getCategoriasColaboracion = () => {
    getCollaborationCategories()
      .then((response) => {
        setCategoriasColaboracion(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const buscarAdhesiones = () => {
    const adhesiones = [];

    const adhesionesSocios = socios.filter((socio) => {
      return (
        socio.commitments.some(
          (commitment) => commitment.commitmentCode == compromisoCode
        ) &&
        (filtroNombre == "" ||
          (filtroNombre != "" &&
            socio.name.toLowerCase().includes(filtroNombre.toLowerCase())))
      );
    });
    adhesionesSocios.forEach((socio) => {
      socio.commitments.forEach((commitment) => {
        if (commitment.commitmentCode == compromisoCode) {
          adhesiones.push({
            memberId: socio.memberId,
            memberAddress: socio.memberAddress,
            code: socio.code,
            name: socio.name,
            type: socio.type,
            applicationId: 0,
            applicationName: "-",
            commitmentCode: commitment.commitmentCode,
            title: commitment.title,
            status: commitment.status,
            deadlineDate: commitment.deadlineDate,
            modificationDate: commitment.modificationDate,
          });
        }
      });
    });

    const adhesionesAplicaciones = socios.filter((socio) => {
      return (
        socio.services?.some((s) =>
          s.commitments?.some((c) => c.commitmentCode == compromisoCode)
        ) &&
        (filtroNombre == "" ||
          (filtroNombre != "" &&
            socio.name.toLowerCase().includes(filtroNombre.toLowerCase())))
      );
    });
    adhesionesAplicaciones.forEach((socio) => {
      socio.services.forEach((service) => {
        service.commitments.forEach((commitment) => {
          if (commitment.commitmentCode == compromisoCode) {
            adhesiones.push({
              memberId: socio.memberId,
              memberAddress: socio.memberAddress,
              code: socio.code,
              name: socio.name,
              type: socio.type,
              applicationId: service.code,
              applicationName: service.name,
              commitmentCode: commitment.commitmentCode,
              title: commitment.title,
              status: commitment.status,
              deadlineDate: commitment.deadlineDate,
              modificationDate: commitment.modificationDate,
            });
          }
        });
      });
    });

    setAdhesiones(adhesiones);
  };

  const getPrincipios = () => {
    getPrinciples()
      .then((response) => {
        setPrincipios(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getRoles = () => {
    Web3Manager.CONTRACT_ACCESS_CONTROL.getAvailableRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDetalleSocio = (socio) => {
    // si el socio es una persona, vamos con la recuperacion de esa persona, sino, vamos con empresa
    if (socio.type === Constants.MEMBER_TYPE_PERSONA) {
      getPerson(socio.memberAddress)
        .then((personas) => {
          if (personas && personas.length > 0) {
            setPersonaSeleccionada(personas[0]);
            setOpenModalDetallePersona(true);
          }
        })
        .catch((error) => {
          handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        });
    } else if (socio.type === Constants.MEMBER_TYPE_EMPRESA) {
      getCompany(socio.memberAddress)
        .then((empresas) => {
          if (empresas && empresas.length > 0) {
            setEmpresaSeleccionada(empresas[0]);
            setOpenModalDetalleEmpresa(true);
          }
        })
        .catch((error) => {
          handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        });
    }
  };

  const handleColaborarConSocio = (socio) => {
    if (
      socio.memberAddress.toLowerCase() ==
      Web3Manager.MEMBER_DATA.memberAddress.toLowerCase()
    ) {
      handleOpenNotificacionError("No es posible colaborar con uno mismo");
    } else {
      const initData = {
        targetMemberAddress: socio.memberAddress,
        targetMemberCode: socio.code,
        targetMemberType: socio.type,
        collaborationType: Constants.COLLABORATION_COMMITMENT,
        collaborationTypeCode: compromiso.code,
        applicationId: socio.applicationId,
      };
      setModalColaboracionInitData(initData);
      setOpenModalColaboracion(true);
    }
  };

  const handleCloseModalColaboracion = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        Web3Manager.processSuccessResponse(txResponse, () => {
          handleOpenNotificacionTxMsg(
            "La colaboración ha sido creada exitosamente y está siendo procesada. El cambio puede demorar unos minutos en reflejarse en la sección de 'Colaboraciones'."
          );

          setLoading(false);
          closeSpinner();
        });
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        setLoading(false);
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalColaboracion(false);
  };

  const handleCloseModalDetallePersona = () => {
    setOpenModalDetallePersona(false);
    setPersonaSeleccionada(null);
  };

  const handleCloseModalDetalleEmpresa = () => {
    setOpenModalDetalleEmpresa(false);
    setEmpresaSeleccionada(null);
  };

  const handleCloseModalAdherirse = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getSocios(
              Constants.MEMBER_TYPE_SIN_REGISTRO,
              Constants.CATEGORY_ANY,
              "",
              Constants.MEMBER_STATUS_ANY,
              true
            );
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenModalAdherirse(false);
  };

  const getEstadoCompromisoSocio = (row) => {
    return Constants.obtenerNombreEstadoCompromiso(row.status);
  };

  const getDeadlineDate = (row) => {
    if (row.status === Constants.HAS_INTENTION) {
      return moment(row.deadlineDate).format("DD-MM-YYYY");
    } else {
      return "-";
    }
  };

  const getApplicationNameToShow = (row) => {
    return row.applicationName;
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "40px",
              sm: "32px",
              xs: "28px",
            },
          }}
        >
          {compromiso ? compromiso.title : ""}
        </Typography>
        <Grid container spacing={3} p={3}>
          <Grid item xs={12} lg={6}>
            <Typography
              variant="body1"
              color={"#005A59"}
              align="left"
              sx={{
                fontSize: {
                  lg: "18px",
                  sm: "18px",
                  xs: "14px",
                },
                width: isLg ? "80%" : "100%",
              }}
            >
              {compromiso ? (
                <div
                  dangerouslySetInnerHTML={{ __html: compromiso.description }}
                />
              ) : (
                ""
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid
              sx={{
                bgcolor: "#F5F5F5",
                "& svg": {
                  fontSize: {
                    lg: "1.5rem",
                    xs: "1.25rem",
                  },
                },
                "& .MuiTypography-root": {
                  fontSize: {
                    lg: "0.875rem",
                    xs: "0.8rem",
                  },
                },
              }}
              container
              spacing={1}
              borderRadius={4}
              p={3}
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="body2" sx={{ p: 1 }}>
                  <strong>DIMENSIONES ESG:</strong>
                  <Stack direction="row" spacing={1} marginTop={1.5}>
                    <Tooltip title="Environment" placement="top" arrow>
                      <div>
                        <Chip
                          icon={<Public />}
                          label="(E)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso?.dimensionE}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Social" placement="top" arrow>
                      <div>
                        <Chip
                          icon={<Groups />}
                          label="(S)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso?.dimensionS}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Governance" placement="top" arrow>
                      <div>
                        <Chip
                          icon={<AccountBalance />}
                          label="(G)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromiso?.dimensionG}
                        />
                      </div>
                    </Tooltip>
                  </Stack>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ p: 1 }}>
                  <strong>APLICABLE A:</strong>
                  <Stack direction="row" spacing={1} marginTop={1.5}>
                    <Grid item>
                      <Tooltip title="Personas" placement="top" arrow>
                        <Box
                          bgcolor="#F5F5F5"
                          borderRadius={4}
                          p={1}
                          sx={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <Accessibility
                            color={
                              compromiso?.appliesToPersons
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Empresas" placement="top" arrow>
                        <Box
                          bgcolor="#F5F5F5"
                          borderRadius={4}
                          p={1}
                          sx={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <Business
                            color={
                              compromiso?.appliesToCompanies
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Aplicaciones" placement="top" arrow>
                        <Box
                          bgcolor="#F5F5F5"
                          borderRadius={4}
                          p={1}
                          sx={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <PhoneAndroid
                            color={
                              compromiso?.appliesToServices
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Stack>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ p: 1 }}>
                  <strong>COMUNIDAD:</strong>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ p: 1, fontSize: "1.2rem !important" }}
                >
                  {compromiso?.community}
                </Typography>
              </Grid>
              {compromiso?.sustainableDevelopmentGoals.length > 0 && (
                <Grid item>
                  <Typography variant="body2" sx={{ p: 1 }}>
                    <strong>ODS EN LOS QUE IMPACTA:</strong>
                  </Typography>
                  <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                    {compromiso?.sustainableDevelopmentGoals.map(
                      (goal, index) => (
                        <Grid item key={index}>
                          <Tooltip
                            title={Constants.getGoalTitle(goal.split(".")[0])}
                            placement="top"
                            arrow
                          >
                            <a
                              href={Constants.getGoalLink(goal.split(".")[0])}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <Chip
                                label={goal}
                                sx={{
                                  backgroundColor: Constants.getGoalColor(
                                    goal.split(".")[0]
                                  ),
                                  color: "#FFFFFF",
                                  fontWeight: "bold",
                                  border: "1px solid",
                                  borderColor: Constants.getGoalColor(
                                    goal.split(".")[0]
                                  ),
                                  fontSize: "1rem",
                                }}
                              />
                            </a>
                          </Tooltip>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {((compromiso?.appliesToPersons &&
                Web3Manager.MEMBER_DATA.memberType ===
                  Constants.MEMBER_TYPE_PERSONA) ||
                (compromiso?.appliesToCompanies &&
                  Web3Manager.MEMBER_DATA.memberType ===
                    Constants.MEMBER_TYPE_EMPRESA)) && (
                <Grid item xs={12} mt={2}>
                  {(() => {
                    const isMemberAdhered = adhesiones.some(
                      (socio) =>
                        socio.memberAddress.toLowerCase() ===
                          Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() &&
                        socio.applicationId === 0
                    );
                    const buttonText = isMemberAdhered
                      ? "Editar adhesión"
                      : "Adherir al compromiso";
                    return (
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: "#FFD700",
                          color: "#000000",
                          "&:hover": {
                            color: "#FFFFFF",
                          },
                        }}
                        onClick={() => setOpenModalAdherirse(true)}
                      >
                        {buttonText}
                      </Button>
                    );
                  })()}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="h6" color={"#005A59"} align="center" p={3}>
          {"Socios adheridos al compromiso"}
        </Typography>
      </Box>
      <Grid container>
        {matches ? (
          <CompromisoSociosAdheridosCard
            adhesiones={adhesiones}
            isLoading={isLoading}
            showSpinner={showSpinner}
            getEstadoCompromisoSocio={getEstadoCompromisoSocio}
            getDeadlineDate={getDeadlineDate}
            getApplicationNameToShow={getApplicationNameToShow}
            handleDetalleSocio={handleDetalleSocio}
            handleColaborarConSocio={handleColaborarConSocio}
            openModalDetallePersona={openModalDetallePersona}
            handleCloseModalDetallePersona={handleCloseModalDetallePersona}
            personaSeleccionada={personaSeleccionada}
            openModalDetalleEmpresa={openModalDetalleEmpresa}
            handleCloseModalDetalleEmpresa={handleCloseModalDetalleEmpresa}
            empresaSeleccionada={empresaSeleccionada}
            openModalColaboracion={openModalColaboracion}
            handleCloseModalColaboracion={handleCloseModalColaboracion}
            socios={socios}
            principios={principios}
            compromisos={compromisos}
            categoriasColaboracion={categoriasColaboracion}
            setLoading={setLoading}
            modalColaboracionInitData={modalColaboracionInitData}
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            buscarAdhesiones={buscarAdhesiones}
          />
        ) : (
          <CompromisoSociosAdheridosTable
            adhesiones={adhesiones}
            isLoading={isLoading}
            showSpinner={showSpinner}
            getEstadoCompromisoSocio={getEstadoCompromisoSocio}
            getDeadlineDate={getDeadlineDate}
            getApplicationNameToShow={getApplicationNameToShow}
            handleDetalleSocio={handleDetalleSocio}
            handleColaborarConSocio={handleColaborarConSocio}
            openModalDetallePersona={openModalDetallePersona}
            handleCloseModalDetallePersona={handleCloseModalDetallePersona}
            personaSeleccionada={personaSeleccionada}
            openModalDetalleEmpresa={openModalDetalleEmpresa}
            handleCloseModalDetalleEmpresa={handleCloseModalDetalleEmpresa}
            empresaSeleccionada={empresaSeleccionada}
            openModalColaboracion={openModalColaboracion}
            handleCloseModalColaboracion={handleCloseModalColaboracion}
            socios={socios}
            principios={principios}
            compromisos={compromisos}
            categoriasColaboracion={categoriasColaboracion}
            setLoading={setLoading}
            modalColaboracionInitData={modalColaboracionInitData}
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            buscarAdhesiones={buscarAdhesiones}
          />
        )}
      </Grid>
      {openModalAdherirse &&
        (matches ? (
          <ModalAdherirCompromisoMobile
            open={openModalAdherirse}
            compromiso={compromiso}
            handleClose={handleCloseModalAdherirse}
            compromisoEditing={adhesiones.find(
              (socio) =>
                socio.memberId === Web3Manager.MEMBER_DATA.memberId &&
                socio.applicationId === 0 &&
                socio.commitmentCode === compromiso.code
            )}
          />
        ) : (
          <ModalAdherirCompromiso
            open={openModalAdherirse}
            compromiso={compromiso}
            handleClose={handleCloseModalAdherirse}
            compromisoEditing={adhesiones.find(
              (socio) =>
                socio.memberId === Web3Manager.MEMBER_DATA.memberId &&
                socio.applicationId === 0 &&
                socio.commitmentCode === compromiso.code
            )}
          />
        ))}
    </Container>
  );
};

export default withRouter(CompromisoSociosAdheridosPage);
