import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNotification } from "../../hooks/useNotification";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { getPerson } from "../../services/PersonsServices";
import { getCompany } from "../../services/CompaniesServices";
import { getMemberHours } from "../../services/HoursRegisterServices";
import { HorasTable } from "../../components/horas/HorasTable";
import { HorasCard } from "../../components/horas/HorasCard";

const HorasPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentYear = moment().format("YYYY");
  const initialFiltroDesde = `${currentYear}-01-01`;
  const initialFiltroHasta = `${currentYear}-12-31`;

  const [filtroDesde, setFiltroDesde] = useState(initialFiltroDesde);
  const [filtroHasta, setFiltroHasta] = useState(initialFiltroHasta);

  const [socio, setSocio] = useState(null);
  const [pagos, setPagos] = useState([]);
  const [openModalPago, setOpenModalPago] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [pago, setPago] = useState(null);
  const [loading, setLoading] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const [totalHoras, setTotalHoras] = useState(0);

  const handleAgregarPago = () => {
    setOpenModalPago(true);
    setModeEdit(false);
    setPago(null);
  };

  const handleEditarPago = (pagoSelected) => {
    setOpenModalPago(true);
    setModeEdit(true);
    setPago(pagoSelected);
  };

  const handleCloseModalPago = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getHoras();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No cerramos el modal
      }
    }

    setOpenModalPago(false);
    setModeEdit(false);
    setPago(null);
  };

  const handleEliminarPago = (pagoSelected) => {
    setLoading(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_HOURSREGISTER,
      "deleteHours",
      Web3Manager.MEMBER_DATA.memberCode,
      pagoSelected._hourRegisteredCode
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getHoras();
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        setLoading(false);
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getHoras = () => {
    setLoading(true);
    // Transformamos los filtros de fechas a timestamp unix (esperado por unix)

    let _fromDate = 0;
    if (filtroDesde) {
      _fromDate = moment(filtroDesde, "YYYY-MM-DD").startOf("day").unix();
    }
    let _toDate = 0;
    if (filtroHasta) {
      _toDate = moment(filtroHasta, "YYYY-MM-DD").endOf("day").unix();
    }

    // Debemos recuperar socios empresas y socios personas
    getMemberHours(Web3Manager.MEMBER_DATA.memberCode, _fromDate, _toDate)
      .then((payments) => {
        const pagos = [];

        if (payments && payments.hourRegisterData) {
          payments.hourRegisterData.forEach((payment) => {
            pagos.push({
              _hourRegisteredCode: payment.hourRegisteredCode,
              _value: payment.value,
              _hoursDateFrom: payment.dateFrom,
              _hoursDateTo: payment.dateTo,
              _hoursDescription: payment.hoursDescription,
              _dateInfo: "",
            });
          });
        }
        setPagos(pagos);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getPersonData = () => {
    getPerson(Web3Manager.MEMBER_DATA.memberAddress)
      .then((currentPersonList) => {
        if (currentPersonList && currentPersonList.length > 0) {
          setSocio({
            _memberCode: currentPersonList[0].code,
            _memberAddress: currentPersonList[0].memberAddress,
            _memberType: Constants.MEMBER_TYPE_PERSONA,
            _memberName:
              currentPersonList[0].firstName +
              " " +
              currentPersonList[0].lastName,
          });
        }
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getCompanyData = () => {
    getCompany(Web3Manager.MEMBER_DATA.memberAddress)
      .then((currentCompanyList) => {
        if (currentCompanyList && currentCompanyList.length > 0) {
          setSocio({
            _memberCode: currentCompanyList[0].code,
            _memberAddress: currentCompanyList[0].memberAddress,
            _memberType: Constants.MEMBER_TYPE_EMPRESA,
            _memberName: currentCompanyList[0].businessName,
          });
        }
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getHoras();

    // Recuperamos (o no) a la persona. Si ya esta registrada, se traen los datos y se cambia a modo edicion
    const memberData = Web3Manager.MEMBER_DATA;
    if (memberData) {
      if (memberData.memberType === Constants.MEMBER_TYPE_PERSONA) {
        // Verificamos el estado de la persona. Para entrar a operar, debe estar approved
        if (
          memberData.memberStatus !== Constants.MEMBER_STATUS_WALLETASSIGNED
        ) {
          Common.goForward("/errorSocioInhabilitado");
        } else {
          getPersonData();
        }
      } else if (memberData.memberType === Constants.MEMBER_TYPE_EMPRESA) {
        // Verificamos el estado de la persona. Para entrar a operar, debe estar approved
        if (
          memberData.memberStatus !== Constants.MEMBER_STATUS_WALLETASSIGNED
        ) {
          Common.goForward("/errorSocioInhabilitado");
        } else {
          getCompanyData();
        }
      } else {
        // No encontramos datos, asi que no estamos registrado, el registro no esta terminado
        Common.goForward("/errorSocioNoRegistrado");
      }
    } else {
      // No encontramos datos, asi que no estamos registrado
      Common.goForward("/errorSocioNoRegistrado");
    }
  }, []);

  useEffect(() => {
    if (pagos && pagos.length > 0) {
      const horasRegistradas = pagos.reduce((total, pago) => {
        const { whole, fractional } = pago._value;
        return total + whole + fractional / 10;
      }, 0);

      setTotalHoras(horasRegistradas);
    }
  }, [pagos]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Grid sx={{ marginLeft: 2 }}>
          <strong>Total de horas: </strong> {totalHoras}
        </Grid>

        <GridFooter />
      </GridFooterContainer>
    );
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Mis horas donadas
        </Typography>
      </Box>

      <>
        <Grid item lg={6}>
          <Box mb={1} sx={{ justifyContent: "left", display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAgregarPago()}
              startIcon={<AddCircleIcon />}
              disabled={loading || showSpinner}
            >
              Agregar
            </Button>
          </Box>
        </Grid>
        {matches ? (
          <HorasCard
            pagos={pagos}
            loading={loading}
            handleEditarPago={handleEditarPago}
            handleEliminarPago={handleEliminarPago}
            openModalPago={openModalPago}
            filtroDesde={filtroDesde}
            setFiltroDesde={setFiltroDesde}
            filtroHasta={filtroHasta}
            setFiltroHasta={setFiltroHasta}
            getHoras={getHoras}
            showSpinner={showSpinner}
            CustomFooter={CustomFooter}
            handleCloseModalPago={handleCloseModalPago}
            modeEdit={modeEdit}
            pago={pago}
            socio={socio}
            setLoading={setLoading}
          />
        ) : (
          <HorasTable
            pagos={pagos}
            loading={loading}
            handleEditarPago={handleEditarPago}
            handleEliminarPago={handleEliminarPago}
            openModalPago={openModalPago}
            filtroDesde={filtroDesde}
            setFiltroDesde={setFiltroDesde}
            filtroHasta={filtroHasta}
            setFiltroHasta={setFiltroHasta}
            getHoras={getHoras}
            showSpinner={showSpinner}
            CustomFooter={CustomFooter}
            handleCloseModalPago={handleCloseModalPago}
            modeEdit={modeEdit}
            pago={pago}
            socio={socio}
            setLoading={setLoading}
          />
        )}
      </>
    </Container>
  );
};

export default withRouter(HorasPage);
