import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Chip,
  Grid,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Web3Manager from "../../support/Web3Manager";
import moment from "moment/moment";
import { Delete } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import * as Constants from "../../support/Constants";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { useTheme, useMediaQuery } from "@mui/material";
const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalEliminarPropuestaMobile = ({
  open,
  handleClose,
  propuesta,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const formData = {
    _code: propuesta?.code,
  };

  const formPropuesta = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setDisableButton(true);
      openSpinner();
      Web3Manager.callContract(
        Web3Manager.CONTRACT_PROPOSALS,
        "deleteProposal",
        propuesta?.code
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
          closeSpinner();
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          handleClose(false, error, false);
          closeSpinner();
        });
    },
  });

  const { handleSubmit, resetForm } = formPropuesta;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Eliminar Propuesta
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box container component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Propuesta " + propuesta?.title}
              </Typography>

              <Chip
                label={Constants.getNombreEstadoPropuesta(propuesta?.status)}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Box
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Proponente: "}
                </Typography>
                <Typography variant="subtitle1">
                  {`${propuesta?.memberName}`}
                  <br />
                </Typography>
                <Chip
                  label={memberTypeMap[propuesta?.memberType]}
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[propuesta?.memberType],
                  }}
                />
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Fecha de creación: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(propuesta?.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box mt={1}>
                  <Typography variant="subtitle1">
                    {propuesta ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: propuesta?.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={disableButton}
                endIcon={<Delete />}
              >
                Eliminar
              </Button>
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleClose(true, null, false);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
