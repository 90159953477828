import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TextField,
  MenuItem,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import { CheckCircle } from "@mui/icons-material";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import SociosContext from "../../context/socios/SociosContext";
import moment from "moment";

import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalAdherirCompromisoMobile = ({
  open,
  handleClose,
  compromiso,
  compromisoEditing,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);
  const {
    adherirACompromiso,
    editarAdhesionCompromiso,
    eliminarAdhesionCompromiso,
    isLoading,
  } = useContext(SociosContext);

  const isEditing = compromisoEditing !== undefined;
  const initialState = {
    _socioType: Web3Manager.MEMBER_DATA.memberType,
    _socioCode: Web3Manager.MEMBER_DATA.memberCode,
    _commitmentCode: compromiso?.code,
    _status: Constants.HAS_INTENTION,
    _deadlineDate: moment().add(3, "months").format("YYYY-MM-DD"),
    _modificationDate: moment().format("YYYY-MM-DD"),
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _socioType: Web3Manager.MEMBER_DATA.memberType,
        _socioCode: Web3Manager.MEMBER_DATA.memberCode,
        _commitmentCode: compromisoEditing.commitmentCode,
        _status: compromisoEditing?.status,
        _deadlineDate:
          compromisoEditing?.status === Constants.HAS_INTENTION
            ? moment(compromisoEditing.deadlineDate).format("YYYY-MM-DD")
            : moment().add(3, "months").format("YYYY-MM-DD"),
        _modificationDate: moment(compromisoEditing?.modificationDate).format(
          "YYYY-MM-DD"
        ),
      };
    }

    return formData;
  };

  const formAdherirCompromiso = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const {
        _socioType,
        _socioCode,
        _commitmentCode,
        _status,
        _deadlineDate,
        _modificationDate,
      } = values;

      let _deadlineDateParsed = moment(_deadlineDate, "YYYY-MM-DD").unix();
      const _modificationDateParsed = moment(
        _modificationDate,
        "YYYY-MM-DD"
      ).unix();

      if (_status === Constants.COMPLIES) {
        _deadlineDateParsed = 0;
      }

      setDisableButton(true);
      openSpinner();
      if (!isEditing) {
        adherirACompromiso(
          _socioType,
          _socioCode,
          _commitmentCode,
          _status,
          _deadlineDateParsed,
          _modificationDateParsed
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            handleClose(false, error, false);
          });
      } else {
        if (_status === Constants.REMOVE_COMMITMENT) {
          eliminarAdhesionCompromiso(_socioCode, _socioType, _commitmentCode)
            .then((response) => {
              setDisableButton(false);
              resetForm();
              handleClose(false, response, true);
            })
            .catch((error) => {
              setDisableButton(false);
              handleClose(false, error, false);
            });
        } else {
          editarAdhesionCompromiso(
            _socioType,
            _socioCode,
            _commitmentCode,
            _status,
            _deadlineDateParsed,
            _modificationDateParsed
          )
            .then((response) => {
              setDisableButton(false);
              resetForm();
              handleClose(false, response, true);
            })
            .catch((error) => {
              setDisableButton(false);
              handleClose(false, error, false);
            });
        }
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formAdherirCompromiso;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {!isEditing
              ? "Adherir al compromiso"
              : "Editar adhesión al compromiso"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(true, null, false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              name="_status"
              label="Estado del compromiso"
              value={values._status}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              size="small"
              variant="outlined"
            >
              <MenuItem value={Constants.COMPLIES}>Cumple</MenuItem>
              <MenuItem value={Constants.HAS_INTENTION}>
                Tiene intención
              </MenuItem>
              {isEditing && (
                <MenuItem value={Constants.REMOVE_COMMITMENT}>
                  Eliminar adhesión
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {values._status === Constants.HAS_INTENTION && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha límite"
                  inputFormat="DD-MM-YYYY"
                  value={values._deadlineDate}
                  onChange={(newValue) => {
                    formAdherirCompromiso.setFieldValue(
                      "_deadlineDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }}
                  disablePast
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </Grid>
          {!isEditing && (
            <Grid xs={12} mt={3} mb={2}>
              <Alert
                severity="info"
                sx={{
                  fontSize: "12px",
                }}
              >
                <AlertTitle>
                  ¿Está seguro que desea adherirse al compromiso{" "}
                  <strong>{compromiso.title}</strong>?
                </AlertTitle>
              </Alert>
            </Grid>
          )}

          <Box
            mt={2}
            sx={{ justifyContent: "right", display: "flex", gap: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<CheckCircle />}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Confirmar
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                resetForm();
                handleClose(true, null, false);
              }}
              disabled={isLoading}
            />
          </Box>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ModalAdherirCompromisoMobile;
