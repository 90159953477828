import React, { useState, useContext, useEffect } from "react";

import moment from "moment/moment";

import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";

import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  ZoomIn,
  AccessTime,
  Restore,
  DeleteForever,
  ManageAccounts,
  NotInterested,
} from "@mui/icons-material";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import SociosContext from "../../context/socios/SociosContext";
import { useNotification } from "../../hooks/useNotification";
import { ModalConfirmacion } from "../utiles/ModalConfirmacion";
import { ModalRolesSocioForm } from "./ModalRolesSocioForm";
import { DetallePersonaFormMobile } from "./DetallePersonaFormMobile";
import { ModalHorasSocioFormMobile } from "./ModalHorasSocioFormMobile";
import { DetalleEmpresaFormMobile } from "./DetalleEmpresaFormMobile";

export const SocioCard = ({
  socios,
  getSocios,
  isLoading,
  setLoading,
  isReadOnly,
  eliminarSocio,
  habilitacionSocio,
}) => {
  const [roles, setRoles] = useState([]);
  const [socioSeleccionado, setSocioSeleccionado] = useState(null);
  const [openModalRolesSocio, setOpenModalRolesSocio] = useState(false);
  const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
  const [openModalDetallePersona, setOpenModalDetallePersona] = useState(false);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [openModalDetalleEmpresa, setOpenModalDetalleEmpresa] = useState(false);
  const [openModalPagosSocio, setOpenModalPagosSocio] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [sortedSocios, setSortedSocios] = useState([]);

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();
  const { closeSpinner } = useContext(SpinnerContext);
  const { cleanCompromisosSocio } = useContext(SociosContext);
  const getRoles = () => {
    Web3Manager.CONTRACT_ACCESS_CONTROL.getAvailableRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDetalleSocio = async (socio) => {
    if (socio.type === Constants.MEMBER_TYPE_PERSONA) {
      setPersonaSeleccionada(socio);
      setOpenModalDetallePersona(true);
    } else if (socio.type === Constants.MEMBER_TYPE_EMPRESA) {
      setEmpresaSeleccionada(socio);
      setOpenModalDetalleEmpresa(true);
    }
  };

  const handleCloseModalDetallePersona = async () => {
    setOpenModalDetallePersona(false);
    setPersonaSeleccionada(null);
    await cleanCompromisosSocio();
  };

  const handleCloseModalDetalleEmpresa = async () => {
    setOpenModalDetalleEmpresa(false);
    setEmpresaSeleccionada(null);
  };

  const handleOpenModalRolesSocio = (socio) => {
    setOpenModalRolesSocio(true);
    setSocioSeleccionado(socio);
  };

  const handleCloseModalRolesSocio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getSocios(
              Constants.MEMBER_TYPE_SIN_REGISTRO,
              Constants.CATEGORY_ANY,
              "",
              Constants.MEMBER_STATUS_ANY
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return;
      }
    }

    setOpenModalRolesSocio(false);
    setSocioSeleccionado(null);
  };

  const handleOpenModalPagosSocio = (socio) => {
    setSocioSeleccionado(socio);
    setOpenModalPagosSocio(true);
  };

  const handleCloseModalPagosSocio = (error) => {
    if (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    }

    setOpenModalPagosSocio(false);
    setSocioSeleccionado(null);
  };

  const handleConfirmarEliminarSocio = (socioSelected) => {
    setOpenConfirmModal(true);
    setSocioSeleccionado(socioSelected);
  };

  const handleCerrarConfirmarEliminarSocio = () => {
    setOpenConfirmModal(false);
    setSocioSeleccionado(null);
  };

  const handleEliminarSocio = () => {
    eliminarSocio(socioSeleccionado.type, socioSeleccionado.code);
    handleCerrarConfirmarEliminarSocio();
  };

  const handleHabilitacionSocio = (socioSelected, habilitar) => {
    habilitacionSocio(socioSelected.type, socioSelected.code, habilitar);
  };

  const getRoleNames = (roleCodes) => {
    return roleCodes
      .map((code) => {
        const roleFound = roles.find((r) => r.code === code);
        return roleFound ? roleFound.name : null;
      })
      .join(", ");
  };

  useEffect(() => {
    if (Web3Manager.isAccountConnected()) {
      getRoles();
      getSocios(
        Constants.MEMBER_TYPE_SIN_REGISTRO,
        Constants.CATEGORY_ANY,
        "",
        Constants.MEMBER_STATUS_ANY
      );
    }
  }, []);

  useEffect(() => {
    const filteredSocios = [
      ...socios.sort((a, b) => {
        const nameA = a.name ? a.name : a.businessName;
        const nameB = b.name ? b.name : b.businessName;
        return nameA.localeCompare(nameB);
      }),
    ];
    setSortedSocios(
      !isReadOnly
        ? filteredSocios
        : filteredSocios.filter(
            (socio) => socio.status != Constants.MEMBER_STATUS_DISABLED
          )
    );
  }, [socios]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : sortedSocios.length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <Typography align="center" variant="body1" color="text.secondary">
            No hay socios para mostrar.
          </Typography>
        </Box>
      ) : (
        sortedSocios.map((socio) => (
          <Card key={socio.memberId} sx={{ maxWidth: "100%" }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {socio.name || socio.businessName}
              </Typography>
              {!isReadOnly && (
                <Typography variant="body2" color="text.secondary">
                  Dirección:{" "}
                  {socio.memberAddress
                    ? `${socio.memberAddress.substring(
                        0,
                        6
                      )}...${socio.memberAddress.substring(
                        socio.memberAddress.length - 4
                      )}`
                    : "Sin asignar"}
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary">
                Tipo:{" "}
                {socio.type === Constants.MEMBER_TYPE_PERSONA
                  ? "Persona"
                  : "Empresa"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Fecha registro:{" "}
                {moment(socio.registerDate).format("DD-MM-YYYY")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Categoría: {Constants.getNombreCategoria(socio.category)}
              </Typography>
              {socio.roles.some((role) => [2, 3, 4].includes(role)) && (
                <Typography variant="body2" color="text.secondary">
                  Roles: {getRoleNames(socio.roles)}
                </Typography>
              )}

              {!isReadOnly && (
                <Typography variant="body2" color="text.secondary">
                  Estado: {Constants.getNombreEstado(socio.status)}
                </Typography>
              )}
            </CardContent>
            <CardActions sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<ZoomIn />}
                    onClick={() => handleDetalleSocio(socio)}
                    color="primary"
                    disabled={isLoading}
                    size="small"
                  >
                    Detalle
                  </Button>
                </Grid>
                {socio.category === Constants.CATEGORY_ACTIVO &&
                  socio.status == Constants.MEMBER_STATUS_WALLETASSIGNED && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<AccessTime />}
                        onClick={() => handleOpenModalPagosSocio(socio)}
                        color="primary"
                        disabled={isLoading}
                        size="small"
                      >
                        Horas
                      </Button>
                    </Grid>
                  )}
                {!isReadOnly && (
                  <>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<ManageAccounts />}
                        onClick={() => handleOpenModalRolesSocio(socio)}
                        color="primary"
                        disabled={isLoading}
                        size="small"
                      >
                        Roles
                      </Button>
                    </Grid>

                    {socio.status !== Constants.MEMBER_STATUS_DISABLED ? (
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<NotInterested />}
                          onClick={() => habilitacionSocio(socio, false)}
                          color="primary"
                          disabled={isLoading}
                          size="small"
                        >
                          Deshabilitar
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<Restore />}
                          onClick={() => habilitacionSocio(socio, true)}
                          color="primary"
                          disabled={isLoading}
                          size="small"
                        >
                          Habilitar
                        </Button>
                      </Grid>
                    )}

                    {socio.isRemovable && (
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<DeleteForever />}
                          onClick={() => eliminarSocio(socio)}
                          color="primary"
                          disabled={isLoading}
                          size="small"
                        >
                          Eliminar
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </CardActions>
          </Card>
        ))
      )}
      {socioSeleccionado && (
        <ModalHorasSocioFormMobile
          open={openModalPagosSocio}
          handleClose={handleCloseModalPagosSocio}
          socio={socioSeleccionado}
        />
      )}

      {openModalRolesSocio && (
        <ModalRolesSocioForm
          open={openModalRolesSocio}
          handleClose={handleCloseModalRolesSocio}
          socio={socioSeleccionado}
          roles={roles}
          setLoading={setLoading}
        />
      )}

      {openModalDetallePersona && (
        <DetallePersonaFormMobile
          open={openModalDetallePersona}
          handleClose={handleCloseModalDetallePersona}
          persona={personaSeleccionada}
        />
      )}

      {openModalDetalleEmpresa && (
        <DetalleEmpresaFormMobile
          open={openModalDetalleEmpresa}
          handleClose={handleCloseModalDetalleEmpresa}
          empresa={empresaSeleccionada}
        />
      )}

      {openConfirmModal && (
        <ModalConfirmacion
          open={openConfirmModal}
          titulo="¿Está seguro que desea eliminar el socio?"
          mensaje="Al confirmar se eliminará el socio, esta acción no puede ser revertida."
          handleConfirm={handleEliminarSocio}
          handleCancel={handleCerrarConfirmarEliminarSocio}
          disableConfirmarButton={false}
        />
      )}
    </Box>
  );
};
