import { sustainableDevelopmentGoals } from "./sustainableDevelopmentGoals";

// Tipos de miembro
export const MEMBER_TYPE_SIN_REGISTRO = 0;
export const MEMBER_TYPE_PERSONA = 1;
export const MEMBER_TYPE_EMPRESA = 2;

// Tipos de principio
export const PRINCIPLE_ANY = 0;
export const PRINCIPLE_MANDATORY = 1;
export const PRINCIPLE_INTENTIONAL = 2;

//Tipos de colaboracion
export const COLLABORATION_ANY = 0;
export const COLLABORATION_PRINCIPLE = 1;
export const COLLABORATION_COMMITMENT = 2;

// Tipos de cumplimiento de principio
export const DOES_NOT_COMPLIES = 0;
export const HAS_INTENTION = 1;
export const COMPLIES = 2;
export const REMOVE_COMMITMENT = 3;

// Status de un miembro
export const MEMBER_STATUS_ANY = 0; // Para las busquedas
export const MEMBER_STATUS_NOWALLET = 1;
export const MEMBER_STATUS_DISABLED = 2;
export const MEMBER_STATUS_WALLETASSIGNED = 3;

// Status de una propuesta
export const PROPOSAL_STATUS_ANY = 0; // Para las busquedas
export const PROPOSAL_STATUS_PENDING = 1;
export const PROPOSAL_STATUS_ADMITTED = 2;
export const PROPOSAL_STATUS_NOT_ADMITTED = 3;
export const PROPOSAL_STATUS_ACTIVE = 4;
export const PROPOSAL_STATUS_APPROVED = 5;
export const PROPOSAL_STATUS_REJECTED = 6;

// Status de una colaboracion
export const COLLABORATION_STATUS_ANY = 0;
export const COLLABORATION_STATUS_PENDING = 1;
export const COLLABORATION_STATUS_ACTIVE = 2;
export const COLLABORATION_STATUS_FINALIZED = 3;
export const COLLABORATION_STATUS_REPORTED = 4;

// grado de satisfaccion colaboracion
export const COLLABORATION_SATISFACTION_NONE = 0;
export const COLLABORATION_SATISFACTION_SATISFIED = 1;
export const COLLABORATION_SATISFACTION_NOT_SATISFIED = 2;

// status de un reporte

export const REPORT_STATUS_ANY = 0;
export const REPORT_STATUS_ACTIVE = 1;
export const REPORT_STATUS_FINALIZED = 2;

// Categorias de socios
export const CATEGORY_ANY = 0; // Para las busquedas
export const CATEGORY_SUSCRIPTOR = 1;
export const CATEGORY_ACTIVO = 2;

// Codigos de rol
export const ROLE_NOROLE = 0; // Indicador de que no hay rol
export const ROLE_ADMIN = 1;
export const ROLE_GESTION = 2;
export const ROLE_DIRECTIVA = 3;
export const ROLE_VALIDADOR = 4;
export const ROLE_SOCIO = 5;

// Codigo planes de pago
export const BASICO_1 = 1;
export const BASICO_2 = 2;
export const ACTIVO_1 = 3;
export const ACTIVO_2 = 4;

export const POLLING_INTERVAL = 1000;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Funciones de ayuda generales

export function getNombreCategoria(codigo) {
  if (codigo === CATEGORY_SUSCRIPTOR) return "Suscriptor";
  if (codigo === CATEGORY_ACTIVO) return "Activo";
  return "Todos";
}

export function getNombreRol(rolCode) {
  if (rolCode === ROLE_GESTION) return "Gestión";
  if (rolCode === ROLE_DIRECTIVA) return "Directiva";
  if (rolCode === ROLE_VALIDADOR) return "Validador";
  return "";
}

export function getNombreEstado(value) {
  if (value === MEMBER_STATUS_WALLETASSIGNED) {
    return "Billetera asignada";
  } else if (value === MEMBER_STATUS_DISABLED) {
    return "Deshabilitado";
  } else if (value === MEMBER_STATUS_NOWALLET) {
    return "Sin billetera";
  } else {
    return "Indeterminado";
  }
}

export function getNombreTipoColaboracion(value) {
  if (value === COLLABORATION_PRINCIPLE) {
    return "Principio";
  }
  if (value === COLLABORATION_COMMITMENT) {
    return "Compromiso";
  }
  return "";
}

export function getNombreEstadoPropuesta(value) {
  if (value === PROPOSAL_STATUS_PENDING) {
    return "Pendiente";
  }
  if (value === PROPOSAL_STATUS_ADMITTED) {
    return "Admitida";
  }
  if (value === PROPOSAL_STATUS_NOT_ADMITTED) {
    return "No admitida";
  }
  if (value === PROPOSAL_STATUS_ACTIVE) {
    return "Activa";
  }
  if (value === PROPOSAL_STATUS_APPROVED) {
    return "Aprobada";
  }
  if (value === PROPOSAL_STATUS_REJECTED) {
    return "Rechazada";
  }
  return "";
}

export function getNombreEstadoColaboracion(value) {
  if (value === COLLABORATION_STATUS_PENDING) {
    return "Pendiente";
  }
  if (value === COLLABORATION_STATUS_ACTIVE) {
    return "En curso";
  }
  if (value === COLLABORATION_STATUS_FINALIZED) {
    return "Finalizada";
  }
  if (value === COLLABORATION_STATUS_REPORTED) {
    return "Reportada";
  }
  return "";
}

export function getNombreEstadoReporte(value) {
  if (value === REPORT_STATUS_ACTIVE) {
    return "En curso";
  }
  if (value === REPORT_STATUS_FINALIZED) {
    return "Finalizado";
  }
  return "";
}

export function getNombrePlanPago(value) {
  if (value === BASICO_1) {
    return "Básico 1";
  }

  if (value === BASICO_2) {
    return "Básico 2";
  }

  if (value === ACTIVO_1) {
    return "Activo 1";
  }

  if (value === ACTIVO_2) {
    return "Activo 2";
  }
  return "";
}

export const obtenerSiglas = (nombreCompleto) => {
  if (nombreCompleto) {
    const palabras = nombreCompleto.split(" ");
    let iniciales = [];
    if (palabras.length > 1) {
      iniciales.push(palabras[0][0]);
      iniciales.push(palabras[palabras.length - 1][0]);
    } else {
      iniciales.push(palabras[0][0]);
    }
    return iniciales.join("").toUpperCase();
  }
  return "";
};

export function obtenerNombreEstadoCompromiso(value) {
  if (value == HAS_INTENTION) {
    return "Tiene intención";
  } else if (value == COMPLIES) {
    return "Cumple";
  }
}

export function getNombreRed(chainId) {
  if (chainId == 1337) {
    return "localhost";
  } else if (chainId == 80001) {
    return "Mumbai";
  } else if (chainId == 137) {
    return "Polygon";
  }
  return chainId;
}

export function getGoalColor(goalValue) {
  const goal = sustainableDevelopmentGoals.find((goal) => goal.id == goalValue);

  if (goal) {
    return goal.color;
  }

  return "#CCCCCC";
}

export function getGoalLink(goalValue) {
  const goal = sustainableDevelopmentGoals.find((goal) => goal.id == goalValue);

  if (goal) {
    return goal.link;
  }

  return "";
}

export function getGoalTitle(goalValue) {
  const goal = sustainableDevelopmentGoals.find((goal) => goal.id == goalValue);

  if (goal) {
    return goal.title;
  }

  return "";
}
//mapas colores

export const MEMBER_TYPE_COLORS = {
  0: "#607D8B",
  1: "#55EfC4",
  2: "#3CB371",
};

export const COLLABORATION_CATEGORY_COLORS = {
  AYUDA: "#EFE4ED",
  MEJORA: "#DFC9E8",
  INTERCAMBIO: "#BAAFE2",
};

export const COLLABORATION_STATUS_COLORS = {
  1: "#FFC107",
  2: "#4CAF50",
  3: "#2196F3",
  4: "#FF5722",
};

export const PROPOSALS_CATEGORY_COLORS = {
  PROPUESTA: "#A3D9A5",
  MEJORA: " #4CAF50",
  INICIATIVA: "#8AA96D",
};

export const REPORT_STATUS_COLORS = {
  1: "#FFC107",
  2: "#2196F3",
};
