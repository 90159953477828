import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import * as Decimals from "../../support/Decimals";
import TextField from "@mui/material/TextField";
import { Search, ZoomIn } from "@mui/icons-material";
import moment from "moment";
import {
  DataGrid,
  esES,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuidv4 } from "uuid";
import { ModalHorasDetalle } from "../../components/horas/ModalHorasDetalle";
import { useNotification } from "../../hooks/useNotification";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { getListOfHours } from "../../services/HoursRegisterServices";

const AdminHorasPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [filtroNombre, setFiltroNombre] = useState("");
  const currentYear = moment().format("YYYY");
  const initialFiltroDesde = `${currentYear}-01-01`;
  const initialFiltroHasta = `${currentYear}-12-31`;

  const [filtroDesde, setFiltroDesde] = useState(initialFiltroDesde);
  const [filtroHasta, setFiltroHasta] = useState(initialFiltroHasta);

  const [socio, setSocio] = useState(null);
  const [pagos, setPagos] = useState([]);
  const [openModalDetallePago, setOpenModalDetallePago] = useState(false);
  const [pago, setPago] = useState(null);
  const [loading, setLoading] = useState(false);

  const [totalHoras, setTotalHoras] = useState(0);

  const handleDetallePago = (pago) => {
    setPago(pago);
    setOpenModalDetallePago(true);
  };

  const handleCloseModalDetallePago = () => {
    setOpenModalDetallePago(false);
    setPago(null);
  };

  const getPagos = () => {
    setLoading(true);
    let _fromDate = 0;
    if (filtroDesde) {
      _fromDate = moment(filtroDesde, "YYYY-MM-DD").startOf("day").unix();
    }
    let _toDate = 0;
    if (filtroHasta) {
      _toDate = moment(filtroHasta, "YYYY-MM-DD").endOf("day").unix();
    }

    // Debemos recuperar socios empresas y socios personas
    getListOfHours(0, _fromDate, _toDate)
      .then((payments) => {
        // Construimos la lista de pagos combinando informacion del plan de pagos y el pago
        const pagos = [];

        if (payments && payments.length > 0) {
          payments
            .filter((payment) =>
              payment.memberName
                .toLowerCase()
                .includes(filtroNombre.toLowerCase())
            )
            .forEach((payment) => {
              pagos.push({
                _rowId: uuidv4(),
                _memberCode: payment.memberCode,
                _memberAddress: payment.memberAddress,
                _memberType: payment.memberType,
                _memberName: payment.memberName,
                _hourRegisteredCode: payment.hourRegisteredCode,
                _value: Decimals.normalize(payment.value),
                _hoursDateFrom: payment.hoursDateFrom,
                _hoursDateTo: payment.hoursDateTo,
                _hoursDescription: payment.hoursDescription,
                _dateInfo: "",
              });
            });
        }

        setPagos(pagos);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const columnsPagos = [
    {
      field: "_memberAddress",
      headerName: "Dirección",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="csutable-cell-trucate">
            {params.value.substring(0, 6) +
              "..." +
              params.value.substring(
                params.value.length - 4,
                params.value.length
              )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "_memberType",
      headerName: "Tipo",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value === Constants.MEMBER_TYPE_PERSONA) {
          return "Persona";
        } else if (params.value === Constants.MEMBER_TYPE_EMPRESA) {
          return "Empresa";
        }
        return "N/A";
      },
    },
    {
      field: "_memberName",
      headerName: "Nombre",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "_hourRegisteredCode",
      headerName: "Código",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "_value",
      headerName: "Horas",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return Decimals.format(params.value);
      },
    },
    {
      field: "_dateInfo",
      headerName: "Fecha(s)",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return (
          moment.unix(params.row._hoursDateFrom).format("DD-MM-YYYY") +
          " - " +
          moment.unix(params.row._hoursDateTo).format("DD-MM-YYYY")
        );
      },
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetallePago(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getPagos();
  }, []);

  useEffect(() => {
    if (pagos && pagos.length > 0) {
      const horasRegistradas = pagos.reduce((total, pago) => {
        const { whole, fractional } = pago._value;
        return total + whole + fractional / 10;
      }, 0);

      setTotalHoras(horasRegistradas);
    }
  }, [pagos]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Grid sx={{ marginLeft: 2 }}>
          <strong>Total de horas donadas: </strong> {totalHoras}
        </Grid>

        <GridFooter />
      </GridFooterContainer>
    );
  };

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Horas donadas
        </Typography>
      </Box>

      <Grid container>
        <Grid item lg={3}></Grid>
        <Grid item lg={9}>
          <Box m={2} sx={{ justifyContent: "right", display: "flex" }}>
            <TextField
              sx={{ marginLeft: 1, width: 200 }}
              id="outlined-basic"
              label="Nombre (socio)"
              variant="outlined"
              size="small"
              value={filtroNombre}
              onChange={(newValue) => setFiltroNombre(newValue.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Desde"
                inputFormat="DD-MM-YYYY"
                value={filtroDesde}
                onChange={(newValue) =>
                  setFiltroDesde(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ marginLeft: 1, width: 180 }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Hasta"
                inputFormat="DD-MM-YYYY"
                value={filtroHasta}
                onChange={(newValue) =>
                  setFiltroHasta(moment(newValue).format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ marginLeft: 1, width: 180 }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              onClick={() => getPagos()}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>

      <DataGrid
        rows={pagos}
        pageSize={50}
        autoHeight={true}
        loading={loading}
        columns={columnsPagos}
        getRowId={(row) => row._rowId}
        rowsPerPageOptions={[50]}
        components={{ Footer: CustomFooter }}
      />

      <br />

      {pago && (
        <ModalHorasDetalle
          open={openModalDetallePago}
          handleClose={handleCloseModalDetallePago}
          pago={pago}
        />
      )}
    </Container>
  );
};

export default withRouter(AdminHorasPage);
