import * as React from "react";
import { useEffect, useState, useContext } from "react";

import { withRouter } from "react-router";
import { useKeycloak } from "@react-keycloak/web";

import * as Common from "../support/Common";
import * as Web3Manager from "../support/Web3Manager";
import * as Constants from "../support/Constants";

import MenuConfig from "../configuration/Menu.json";

import SociosContext from "../context/socios/SociosContext";
import SpinnerContext from "../context/spinner/SpinnerContext";

import { ModalSocioSinAutenticar } from "../components/socios/ModalSocioSinAutenticar";
import { ModalSocioSinRegistrar } from "../components/socios/ModalSocioSinRegistrar";
import { ModalIdNoCorresponde } from "../components/socios/ModalIdNoCorresponde";
import { ModalRegistroSocio } from "../components/socios/ModalRegistroSocio";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { ListItemButton } from "@mui/material";
import { useMediaQuery } from "@mui/material";

const Header = ({ member, setMember }) => {
  // media queries
  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const showLogoMobile = useMediaQuery((theme) =>
    theme.breakpoints.between("xs", "lg")
  );

  // component state
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [idSocio, setIdSocio] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openModalRegistroSocio, setOpenModalRegistroSocio] = useState(false);
  const [openModalIdNoCorresponde, setOpenModalIdNoCorresponde] =
    useState(false);
  const [openModalSocioSinRegistrar, setOpenModalSocioSinRegistrar] =
    useState(false);
  const [openModalSocioSinAutenticar, setOpenModalSocioSinAutenticar] =
    useState(false);

  // contexts
  const { setSocioAddress, isLoading } = useContext(SociosContext);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  // other
  const { keycloak } = useKeycloak();
  const theme = useTheme();

  // handlers
  const handleOpenModalSinRegistro = () => {
    setOpenModalSocioSinRegistrar(true);
  };

  const handleOpenModalIdNoCorresponde = () => {
    setOpenModalIdNoCorresponde(true);
  };

  const handleCloseModalIdNoCorresponde = () => {
    setOpenModalIdNoCorresponde(false);
    window.location.href = process.env.REACT_APP_URL_REDIRECT_ALEPH;
  };

  const handleOpenModalRegistroSocio = () => {
    setOpenModalRegistroSocio(true);
  };

  const handleConfirmModalRegistro = async () => {
    openSpinner(true);
    const { memberCode, memberAddress, memberType } = Web3Manager.MEMBER_DATA;
    try {
      await setSocioAddress(memberCode, memberAddress, memberType);
      setOpenModalRegistroSocio(false);
      await Web3Manager.connect(idSocio, keycloak, () => {
        setMember(Web3Manager.MEMBER_DATA);
      });
    } catch (e) {
      console.log("error al registrar", e);
      Web3Manager.disconnect(() => {
        setMember(null);
      });
    } finally {
      closeSpinner();
    }
  };

  const handleCancelModalConfirmacion = () => {
    Web3Manager.disconnect(() => {
      setMember(null);
    });
    setOpenModalRegistroSocio(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    !matches && setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event?.type === "keydown" &&
      (event?.key === "Tab" || event?.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const renderMobileMenu = (menuItems) => {
    return (
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: "auto" }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Avatar
              alt="USER"
              src="/images/user.png"
              sx={{ width: 30, height: 30, marginRight: 1 }}
            />
            <Typography variant="body1" noWrap>
              {member?.memberName || "User"}
            </Typography>
          </Box>
          <Divider sx={{ borderWidth: 1.5 }} />
          <List sx={{ backgroundColor: "#f5f5f5" }}>
            {menuItems.map((menuItem, index) => {
              if (!memberHasAccessToMenu(member, menuItem)) {
                return null;
              }
              const isParent = menuItem.childs && menuItem.childs.length > 0;
              return (
                <React.Fragment key={index}>
                  {isParent ? (
                    <Accordion sx={{ backgroundColor: "#f5f5f5" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {menuItem.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List
                          sx={{ backgroundColor: "#f5f5f5" }}
                          component="div"
                          disablePadding
                        >
                          {menuItem.childs.map((childItem, childIndex) => {
                            if (!memberHasAccessToMenu(member, childItem)) {
                              return null;
                            }
                            return (
                              <ListItemButton
                                sx={{ backgroundColor: "#f5f5f5" }}
                                key={childIndex}
                                onClick={() => {
                                  Common.goForward(childItem.link);
                                  toggleDrawer(false)();
                                }}
                              >
                                <ListItemText
                                  primary={childItem.name}
                                  sx={{ textAlign: "left" }}
                                />
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <ListItemButton
                      onClick={() => {
                        toggleDrawer(false)();
                        Common.goForward(menuItem.link);
                      }}
                    >
                      <ListItemText
                        primary={menuItem.name}
                        sx={{ textAlign: "left" }}
                      />
                    </ListItemButton>
                  )}
                </React.Fragment>
              );
            })}
          </List>
          <Divider sx={{ borderWidth: 1.5 }} />
          <List>
            {!Web3Manager.isAccountDisabled() &&
              member?.memberType === Constants.MEMBER_TYPE_PERSONA &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET && (
                <ListItemButton
                  key={"menuMisDatosPersona"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward("/view/socio/PersonaPage");
                  }}
                >
                  <ListItemText
                    primary="Mis datos"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            {!Web3Manager.isAccountDisabled() &&
              member?.memberType === Constants.MEMBER_TYPE_EMPRESA &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET && (
                <ListItemButton
                  key={"menuMisDatosEmpresa"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward("/view/socio/EmpresaPage");
                  }}
                >
                  <ListItemText
                    primary="Mis datos"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            {!Web3Manager.isAccountDisabled() &&
              member?.memberType === Constants.MEMBER_TYPE_EMPRESA &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET && (
                <ListItemButton
                  key={"menuMisserviciosEmpresa"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward(
                      "/view/socio/serviciosPage/" + member?.memberCode
                    );
                  }}
                >
                  <ListItemText
                    primary="Mis aplicaciones"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            {!Web3Manager.isAccountDisabled() &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET &&
              member.memberCategory === Constants.CATEGORY_ACTIVO && (
                <ListItemButton
                  key={"menuMisHoras"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward("/view/socio/HorasPage");
                  }}
                >
                  <ListItemText
                    primary="Mis horas"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            {!Web3Manager.isAccountDisabled() &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET && (
                <ListItemButton
                  key={"menuMisCompromisos"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward("/view/socio/MisCompromisosPage");
                  }}
                >
                  <ListItemText
                    primary="Mis compromisos"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            {!Web3Manager.isAccountDisabled() &&
              member?.memberStatus !== Constants.MEMBER_STATUS_NOWALLET && (
                <ListItemButton
                  key={"menuMisColaboraciones"}
                  onClick={() => {
                    toggleDrawer(false)();
                    Common.goForward("/view/socio/MisColaboracionesPage");
                  }}
                >
                  <ListItemText
                    primary="Mis colaboraciones"
                    sx={{ textAlign: "left" }}
                  />
                </ListItemButton>
              )}

            <ListItemButton
              key={"menuContratos"}
              onClick={() => {
                toggleDrawer(false)();
                Common.goForward("/view/ContractsPage");
              }}
            >
              <ListItemText
                primary="Contratos Blockchain"
                sx={{ textAlign: "left" }}
              />
            </ListItemButton>

            <ListItemButton
              key={"menuDisconnect"}
              onClick={() => {
                toggleDrawer(false)();
                handleWalletDisconnect();
              }}
            >
              <ListItemText
                primary="Volver al portal"
                sx={{ textAlign: "left" }}
              />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    );
  };

  const handleWalletConnect = async () => {
    openSpinner(true);
    try {
      await Web3Manager.connect(idSocio, keycloak, () => {
        setMember(Web3Manager.MEMBER_DATA);
        if (window.location.pathname === "/errorConnection" && Common.canGoBack()) {
          Common.goBack();
        }
      });
    } catch (e) {
      // not logged in, redirect to aleph for sign-in
      if (e.name === "UserUnauthenticated") {
        setOpenModalSocioSinAutenticar(true);
      }
      console.log("error al conectar", e);
    } finally {
      closeSpinner();
    }
  };

  const handleWalletDisconnect = () => {
    Web3Manager.disconnect(() => {
      setMember(null);
      handleCloseUserMenu();
      window.location.href = process.env.REACT_APP_URL_REDIRECT_ALEPH;
    });
  };

  const memberHasAccessToMenu = (currentMember, currentMenuItem) => {
    // currentMenuItem nunca es nulo. Si lo es, nos vamos
    if (currentMenuItem == null) {
      return false;
    }

    // A partir de este punto, currentMenuItem nunca es nulo

    if (
      currentMember == null ||
      currentMember.memberType === Constants.MEMBER_TYPE_SIN_REGISTRO ||
      currentMember.memberStatus === Constants.MEMBER_STATUS_NOWALLET
    ) {
      // El menu necesita un miembro, pero no hay ninguno
      if (currentMenuItem.requiresMember) {
        return false;
      } else {
        // El menu no requiere un miembro, entonces verificamos si requiere roles. Si no requiere roles, lo dejamos entrar
        if (
          currentMenuItem.restrictedToRolesCodes == null ||
          currentMenuItem.restrictedToRolesCodes.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }

    // A partir de este punto, currentMember nunca es nulo, tenemos potencialmente roles de ambos lados

    // Si el currentMember esta bloqueado o dado de baja, debemos frenar el acceso
    if (
      currentMember.memberStatus === Constants.MEMBER_STATUS_DISABLED ||
      currentMember.memberStatus === Constants.MEMBER_STATUS_NOWALLET
    ) {
      return false;
    }

    const menuRoles = currentMenuItem.restrictedToRolesCodes
      ? currentMenuItem.restrictedToRolesCodes
      : [];
    const memberRoles = currentMember.memberRoles
      ? currentMember.memberRoles
      : [];

    // Si menuRoles tiene algun valor, entonces verificamos si alguno de esos valores esta incluido en el array memberRoles
    if (menuRoles.length > 0) {
      for (let i = 0; i < menuRoles.length; i++) {
        if (memberRoles.includes(menuRoles[i])) {
          return true;
        }
      }
      // El menu requiere roles, pero no hay ninguno en el conjunto de roles del miembro
      return false;
    } else {
      // El menu no requiere ningun rol
      return true;
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Construimos el menu para el desktop

  const menuDesktop = [];

  const menuItems = MenuConfig.definition;

  if (menuItems != null) {
    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];

      if (menuItem.childs != null && menuItem.childs.length > 0) {
        // menu drop down, construimos el item que abre el menu y los items

        const menuComponentsChild = [];
        for (let j = 0; j < menuItem.childs.length; j++) {
          if (member?.memberType === Constants.MEMBER_TYPE_SIN_REGISTRO) {
            if (
              menuItem.childs[j].name === "Mis datos" &&
              menuItem.childs[j].link === "/view/socio/PersonaPage"
            ) {
              menuItem.childs[j].name = "Persona";
            } else if (
              menuItem.childs[j].name === "Mis datos" &&
              menuItem.childs[j].link === "/view/socio/EmpresaPage"
            ) {
              menuItem.childs[j].name = "Empresa";
            }
          }
          if (
            member?.memberType === Constants.MEMBER_TYPE_PERSONA &&
            menuItem.childs[j].link === "/view/socio/EmpresaPage"
          ) {
            menuItem.childs.splice(j, 1);
          }
          if (
            member?.memberType === Constants.MEMBER_TYPE_EMPRESA &&
            menuItem.childs[j].link === "/view/socio/PersonaPage"
          ) {
            menuItem.childs.splice(j, 1);
          }
          if (menuItem.childs[j].link === "#") {
            menuComponentsChild.push(
              memberHasAccessToMenu(member, menuItem.childs[j]) && (
                <MenuItem
                  key={menuItem.childs[j].name}
                  onClick={() => {
                    handleCloseNavMenu();
                    alert("Funcion aun no soportada");
                  }}
                >
                  <Typography textAlign="center">
                    {menuItem.childs[j].name}
                  </Typography>
                </MenuItem>
              )
            );
          } else {
            menuComponentsChild.push(
              memberHasAccessToMenu(member, menuItem.childs[j]) && (
                <MenuItem
                  key={menuItem.childs[j].name}
                  onClick={() => {
                    handleCloseNavMenu();
                    Common.goForward(menuItem.childs[j].link);
                  }}
                >
                  <Typography textAlign="center">
                    {menuItem.childs[j].name}
                  </Typography>
                </MenuItem>
              )
            );
          }
        }

        const idButton = "button" + menuItem.name;
        const idMenu = "menu" + menuItem.name;

        menuDesktop.push(
          memberHasAccessToMenu(member, menuItem) && (
            <>
              <Button
                id={idButton}
                aria-controls={idMenu}
                aria-haspopup="true"
                aria-expanded={true}
                onClick={handleOpenNavMenu}
                sx={{
                  fontSize: {
                    lg: "1rem",
                    md: "0.75rem",
                  },
                }}
              >
                {menuItem.name}
              </Button>
              <Menu
                id={idMenu}
                sx={{ flexGrow: 1, display: { md: "flex" } }}
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav) && idButton === anchorElNav.id}
                onClose={handleCloseNavMenu}
                MenuListProps={{
                  "aria-labelledby": idButton,
                }}
              >
                {menuComponentsChild}
              </Menu>
            </>
          )
        );
      } else {
        // Es un menu simple, construimos solo el boton
        if (menuItem.link === "#") {
          menuDesktop.push(
            memberHasAccessToMenu(member, menuItem) && (
              <Button
                key={menuItem.name}
                onClick={() => {
                  handleCloseNavMenu();
                  alert("Funcion aun no soportada");
                }}
                sx={{
                  my: 2,
                  color: "black",
                  display: "block",
                  fontSize: {
                    lg: "1rem",
                    md: "0.75rem",
                  },
                }}
              >
                {menuItem.name}
              </Button>
            )
          );
        } else {
          menuDesktop.push(
            memberHasAccessToMenu(member, menuItem) && (
              <Button
                key={menuItem.name}
                onClick={() => {
                  handleCloseNavMenu();
                  Common.goForward(menuItem.link);
                }}
                sx={{
                  my: 2,
                  color: "black",
                  display: "block",
                  fontSize: {
                    lg: "1rem",
                    md: "0.75rem",
                  },
                }}
              >
                {menuItem.name}
              </Button>
            )
          );
        }
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const urlIdSocio = new URLSearchParams(window.location.search).get(
      "user_id"
    );
    setIdSocio(urlIdSocio);
  }, []);

  useEffect(() => {
    if (member) {
      if (member.memberError) {
        handleOpenModalIdNoCorresponde();
        Web3Manager.disconnect(() => {
          setMember(null);
        });
      } else {
        if (member.memberStatus === Constants.MEMBER_STATUS_DISABLED) {
          Common.goForward("/errorSocioInhabilitado");
        }

        // Si el socio que se conecto, se encuentra en estado NOWALLET, abrimos el modal para asignarle address
        if (member.memberStatus === Constants.MEMBER_STATUS_NOWALLET) {
          handleOpenModalRegistroSocio();
        }

        if (!member.memberCode) {
          handleOpenModalSinRegistro();
        }
      }
    }
  }, [member]);

  return (
    <>
      <AppBar position="static" sx={{ background: "#fff", color: "black" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* Logo principal en modo desktop */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => Common.goForward("/view/HomePage")}
              >
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    display: "flex",
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={
                      showLogoMobile
                        ? "/images/fibras-logo-mobile.png"
                        : "/images/fibras-logo.png"
                    }
                    alt="LOGO"
                    width={showLogoMobile ? "50" : "100"}
                  />
                </Typography>
              </Box>

              {/* Mobile Menu Toggle */}

              {member &&
                member.memberAddress &&
                renderMobileMenu(MenuConfig.definition)}

              {/* Menu del usuario en mnodo desktop */}

              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                {member && menuDesktop}
              </Box>

              {member && member.memberAddress ? (
                <Box
                  sx={{
                    flexGrow: 0,
                    ...(theme.breakpoints.down("md") && {
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                >
                  {/* En este caso, la wallet esta asociada a un socio */}
                  {member.memberType !== Constants.MEMBER_TYPE_SIN_REGISTRO && (
                    <Tooltip title={member.memberAddress}>
                      <Button
                        variant="text"
                        sx={{
                          color: (theme) =>
                            Web3Manager.isAccountDisabled() ? "red" : "black",
                        }}
                      >
                        {member.memberName}
                      </Button>
                    </Tooltip>
                  )}

                  {/* En este caso, la wallet NO esta asociada a un socio, ya que no se encontraron datos de registro */}
                  {member.memberType === Constants.MEMBER_TYPE_SIN_REGISTRO && (
                    <Tooltip title={member.memberAddress}>
                      <Button variant="text">
                        {member.memberAddress.substring(0, 10) + "..."}
                      </Button>
                    </Tooltip>
                  )}

                  {matches ? (
                    <IconButton sx={{ p: 0, cursor: "default" }}>
                      {!Web3Manager.isAccountDisabled() ? (
                        <Avatar
                          alt="USER"
                          src="/images/user.png"
                          sx={{
                            width: {
                              xs: 30,
                            },
                            height: {
                              xs: 30,
                            },
                          }}
                        />
                      ) : (
                        <Avatar
                          alt="USER"
                          src="/images/forbidden-user.png"
                          sx={{ width: 35, height: 35 }}
                        />
                      )}
                    </IconButton>
                  ) : (
                    <Tooltip title="Abrir opciones del usuario">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {!Web3Manager.isAccountDisabled() ? (
                          <Avatar alt="USER" src="/images/user.png" />
                        ) : (
                          <Avatar alt="USER" src="/images/forbidden-user.png" />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                  {member && member.memberAddress && (
                    <Box
                      sx={{
                        flexGrow: 1,
                        display: { xs: "flex", md: "none" },
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        size="large"
                        aria-label="menu del usuario"
                        onClick={toggleDrawer(true)}
                        color="inherit"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Box>
                  )}

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-profile"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberType === Constants.MEMBER_TYPE_PERSONA &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET && (
                        <MenuItem
                          key={"menuMisDatosPersona"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward("/view/socio/PersonaPage");
                          }}
                        >
                          <Typography textAlign="center">Mis datos</Typography>
                        </MenuItem>
                      )}

                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberType === Constants.MEMBER_TYPE_EMPRESA &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET && (
                        <MenuItem
                          key={"menuMisDatosEmpresa"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward("/view/socio/EmpresaPage");
                          }}
                        >
                          <Typography textAlign="center">Mis datos</Typography>
                        </MenuItem>
                      )}

                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberType === Constants.MEMBER_TYPE_EMPRESA &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET && (
                        <MenuItem
                          key={"menuMisserviciosEmpresa"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward(
                              "/view/socio/serviciosPage/" + member?.memberCode
                            );
                          }}
                        >
                          <Typography textAlign="center">
                            Mis aplicaciones
                          </Typography>
                        </MenuItem>
                      )}

                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET &&
                      member.memberCategory === Constants.CATEGORY_ACTIVO && (
                        <MenuItem
                          key={"menuMisHoras"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward("/view/socio/HorasPage");
                          }}
                        >
                          <Typography textAlign="center">Mis horas</Typography>
                        </MenuItem>
                      )}

                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET && (
                        <MenuItem
                          key={"menuMisCompromisos"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward("/view/socio/MisCompromisosPage");
                          }}
                        >
                          <Typography textAlign="center">
                            Mis compromisos
                          </Typography>
                        </MenuItem>
                      )}

                    {!Web3Manager.isAccountDisabled() &&
                      member?.memberStatus !==
                        Constants.MEMBER_STATUS_NOWALLET && (
                        <MenuItem
                          key={"menuMisColaboraciones"}
                          onClick={() => {
                            handleCloseUserMenu();
                            Common.goForward(
                              "/view/socio/MisColaboracionesPage"
                            );
                          }}
                        >
                          <Typography textAlign="center">
                            Mis colaboraciones
                          </Typography>
                        </MenuItem>
                      )}

                    <MenuItem
                      key={"menuContratos"}
                      onClick={() => {
                        handleCloseUserMenu();
                        Common.goForward("/view/ContractsPage");
                      }}
                    >
                      <Typography textAlign="center">
                        Contratos Blockchain
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      key={"menuDisconnect"}
                      onClick={handleWalletDisconnect}
                    >
                      <Typography textAlign="center">
                        Volver al portal
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Box sx={{ flexGrow: 0 }}>
                  <Button
                    variant="contained"
                    onClick={async () => handleWalletConnect()}
                  >
                    Conectar red blockchain
                  </Button>
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <ModalRegistroSocio
        open={openModalRegistroSocio}
        handleConfirmRegister={handleConfirmModalRegistro}
        handleCancel={handleCancelModalConfirmacion}
        isLoading={isLoading}
      />

      <ModalSocioSinRegistrar open={openModalSocioSinRegistrar} />
      <ModalIdNoCorresponde
        open={openModalIdNoCorresponde}
        handleCancel={handleCloseModalIdNoCorresponde}
      />
      <ModalSocioSinAutenticar open={openModalSocioSinAutenticar} />
    </>
  );
};

export default withRouter(Header);
