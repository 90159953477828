import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Container from '@mui/material/Container';
import * as Web3Manager from '../../support/Web3Manager';
import * as Common from '../../support/Common';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, esES } from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import {
  Delete,
  DeleteForever,
  NotInterested,
  Restore,
  Search,
  ZoomIn,
} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { ModalServicioForm } from '../../components/servicios/ModalServicioForm';
import moment from 'moment';
import { useNotification } from '../../hooks/useNotification';
import ServiciosContext from '../../context/servicios/ServiciosContext';
import { ModalEliminarSerivio } from '../../components/servicios/ModalEliminarServicio';
import SpinnerContext from '../../context/spinner/SpinnerContext';
import { GridActionItem } from '../../components/ui/GridActionItem';
import { getApplicationTypes } from '../../services/ParametersServices';
import * as Constants from '../../support/Constants';
import CompromisosContext from '../../context/compromisos/CompromisosContext';
import { ServiciosFilterMobile } from './ServiciosFilterMobile';

export const ServiciosTable = ({
  modeEdit,
  isLoading,
  handleDetalleServicio,
  showSpinner,
  handleEditarServicio,
  handleDeshabilitarServicio,
  handleHabilitarServicio,
  handleEliminarServicio,
  filtroNombre,
  setFiltroNombre,
  getServiciosEmpresa,
  empresaId,
  servicios,
  openModalServicio,
  handleCloseModalServicio,
  servicioSeleccionada,
  tiposServicio,
  isReadOnlyModalFormServicio,
  compromisos,
  openEliminarServicio,
  handleCloseEliminarServicio,
}) => {
  const columnsServicios = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'applicationType',
      headerName: 'Tipo',
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'url',
      headerName: 'Url',
      flex: 1.5,
      minWidth: 250,
    },
    {
      field: 'creationDate',
      headerName: 'Fch. Registro',
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return 'N/A';
        }
      },
    },

    {
      field: 'action',
      headerName: 'Acciones',
      sortable: false,
      flex: modeEdit ? 1 : 0.5,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            <GridActionItem
              icon={<ZoomIn />}
              label='Detalle'
              onClick={() => handleDetalleServicio(params.row)}
              disabled={isLoading || showSpinner}
            />
            {modeEdit && (
              <>
                <GridActionItem
                  icon={<Edit />}
                  label='Editar'
                  onClick={() => handleEditarServicio(params.row)}
                  disabled={isLoading || showSpinner}
                />

                {params.row.enabled ? (
                  <GridActionItem
                    icon={<NotInterested />}
                    label='Deshabilitar'
                    onClick={() => handleDeshabilitarServicio(params.row)}
                    disabled={isLoading || showSpinner}
                  />
                ) : (
                  <GridActionItem
                    icon={<Restore />}
                    label='Habilitar'
                    onClick={() => handleHabilitarServicio(params.row)}
                    disabled={isLoading || showSpinner}
                  />
                )}
                <GridActionItem
                  icon={<Delete />}
                  label='Eliminar'
                  onClick={() => handleEliminarServicio(params.row)}
                  disabled={isLoading || showSpinner}
                />
              </>
            )}
          </Stack>
        );
      },
    },
  ];
  const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      <Grid container>
        {matches ? (
          <ServiciosFilterMobile
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            showSpinner={showSpinner}
            isLoading={isLoading}
            getServiciosEmpresa={getServiciosEmpresa}
            empresaId={empresaId}
          />
        ) : (
          <Grid item lg={12}>
            <Box sx={{ justifyContent: 'right', display: 'flex' }} pb={3}>
              <TextField
                sx={{ marginLeft: 1, width: 300 }}
                id='outlined-basic'
                label='Nombre'
                variant='outlined'
                size='small'
                value={filtroNombre}
                onChange={(newValue) => setFiltroNombre(newValue.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                sx={{ marginLeft: 1 }}
                variant='contained'
                color='primary'
                disabled={isLoading || showSpinner}
                onClick={() => getServiciosEmpresa(empresaId, 0, filtroNombre)}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <DataGrid
        rows={modeEdit ? servicios : servicios.filter((s) => s.enabled)}
        pageSize={10}
        autoHeight={true}
        loading={isLoading && !showSpinner}
        columns={columnsServicios}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.code}
      />

      <br />

      <ModalServicioForm
        open={openModalServicio}
        handleClose={handleCloseModalServicio}
        servicio={servicioSeleccionada}
        tiposServicio={tiposServicio}
        isReadOnly={isReadOnlyModalFormServicio}
        compromisosEnabled={compromisos}
      />
      <ModalEliminarSerivio
        open={openEliminarServicio}
        servicio={servicioSeleccionada}
        handleClose={handleCloseEliminarServicio}
      />
    </>
  );
};
