import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Typography,
  Radio,
  RadioGroup,
  Stack,
  Chip,
  Grid,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Web3Manager from "../../support/Web3Manager";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment/moment";
import { CheckCircle } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Constants from "../../support/Constants";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalGestionarPropuesta = ({
  open,
  handleClose,
  propuestaEditing,
  categoriasPropuesta,
  roles,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _category: Yup.string().required("Debes seleccionar una categoría"),

    _minimumVotesPct: Yup.number()
      .typeError("Debes ingresar únicamente números")
      .min(0, "El porcentaje mínimo de votos debe ser mayor o igual a 0")
      .max(100, "El porcentaje mínimo de votos deber ser menor o igual a 100"),

    _passingVotesPct: Yup.number()
      .typeError("Debes ingresar únicamente números")
      .min(
        0,
        "El porcentaje mínimo de votos para que se apruebe debe ser mayor o igual a 0"
      )
      .max(
        100,
        "El porcentaje mínimo de votos para que se apruebe deber ser menor o igual a 100"
      ),
  });

  const formData = {
    _code: propuestaEditing?.code,
    _title: propuestaEditing?.title,
    _description: propuestaEditing?.description,
    _category: propuestaEditing?.category,
    _creationDate: propuestaEditing?.creationDate,
    _openingDate: moment(propuestaEditing?.openingDate).format("YYYY-MM-DD"),
    _closingDate: moment(propuestaEditing?.closingDate).format("YYYY-MM-DD"),
    _minimumVotesPct: propuestaEditing?.minimumVotesPct,
    _passingVotesPct: propuestaEditing?.passingVotesPct,
    _role: propuestaEditing?.role,
  };

  const formPropuesta = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _title,
        _description,
        _category,
        _openingDate,
        _closingDate,
        _minimumVotesPct,
        _passingVotesPct,
        _role,
      } = values;

      const _openingDateParsed = moment(_openingDate, "YYYY-MM-DD").unix();
      const _closingDateParsed = moment(_closingDate, "YYYY-MM-DD").unix();

      setLoading(true);
      openSpinner();
      setDisableButton(true);

      Web3Manager.callContract(
        Web3Manager.CONTRACT_PROPOSALS,
        "modifyProposal",
        propuestaEditing.code,
        _title,
        _description,
        _category,
        _openingDateParsed,
        _closingDateParsed,
        _minimumVotesPct,
        _passingVotesPct,
        _role
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          resetForm();
          handleClose(false, error, false);
        });
    },
  });

  const { handleSubmit, handleChange, handleBlur, touched, errors, values } =
    formPropuesta;

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Propuesta " + propuestaEditing?.title}
              </Typography>

              <Chip
                label={Constants.getNombreEstadoPropuesta(
                  propuestaEditing?.status
                )}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Proponente: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {propuestaEditing?.memberName}
                </Typography>
                <Chip
                  label={memberTypeMap[propuestaEditing?.memberType]}
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[
                        propuestaEditing?.memberType
                      ],
                  }}
                />
              </Box>

              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box
                  mt={1}
                  sx={{
                    maxHeight: "6em",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1">
                    {propuestaEditing ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: propuestaEditing.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box container display="flex">
              <Box item mt={2} mr={2}>
                <TextField
                  name="_category"
                  id="outlined-basic"
                  label="Categoría"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  select={true}
                  defaultValue=""
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._category ?? ""}
                  error={Boolean(touched._category && errors._category)}
                  helperText={touched._category && errors._category}
                >
                  {categoriasPropuesta?.map((item) => (
                    <MenuItem
                      key={"categoriaPropuesta" + item.id}
                      value={item.category}
                    >
                      {item.category}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box mt={2} mr={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Fecha de apertura"
                    inputFormat="DD-MM-YYYY"
                    value={values._openingDate}
                    onChange={(newValue) => {
                      formPropuesta.setFieldValue("_openingDate", newValue);
                      if (newValue.format("YYYY-MM-DD") > values._closingDate) {
                        formPropuesta.setFieldValue(
                          "_closingDate",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box mt={2} mr={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Fecha de cierre"
                    inputFormat="DD-MM-YYYY"
                    value={values._closingDate}
                    onChange={(newValue) => {
                      formPropuesta.setFieldValue("_closingDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" fontWeight="bold">
                {" "}
                Configuración votos:{" "}
              </Typography>
            </Box>

            <Box mt={2}>
              <TextField
                name="_minimumVotesPct"
                id="outlined-basic"
                label="Porcentaje mínimo de votos necesarios (en relación al universo de votantes) para que la votación sea válida"
                variant="outlined"
                size="small"
                fullWidth={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values._minimumVotesPct}
                error={Boolean(
                  touched._minimumVotesPct && errors._minimumVotesPct
                )}
                helperText={touched._minimumVotesPct && errors._minimumVotesPct}
              />
            </Box>

            <Box mt={2}>
              <TextField
                name="_passingVotesPct"
                id="outlined-basic"
                label="Porcentaje mínimo de votos positivos (en relación a la cantidad de socios que votaron) para que se apruebe"
                variant="outlined"
                size="small"
                fullWidth={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values._passingVotesPct}
                error={Boolean(
                  touched._passingVotesPct && errors._passingVotesPct
                )}
                helperText={touched._passingVotesPct && errors._passingVotesPct}
              />
            </Box>

            <Box mt={2}>
              <Typography variant="h6" component="h2">
                {" "}
                Dirigida a:{" "}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="_role"
                  name="_role"
                  value={values._role}
                  defaultValue={propuestaEditing?.role}
                  onChange={(event) => {
                    formPropuesta.setFieldValue("_role", event.target.value);
                  }}
                >
                  <FormControlLabel
                    key={0}
                    value={0}
                    control={<Radio />}
                    label="General"
                  />
                  {roles?.map(([id]) => (
                    <FormControlLabel
                      key={id}
                      value={parseInt(id)}
                      control={<Radio />}
                      label={Constants.getNombreRol(id)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box mt={2}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={<CheckCircle />}
                  disabled={disableButton}
                >
                  Guardar
                </Button>
                <CancelButton
                  label="Cancelar"
                  onClick={() => {
                    handleClose(true, null, false);
                  }}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
