import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";

import moment from "moment";

import { HorasFilterMobile } from "./HorasFilterMobile";
import * as Decimals from "../../support/Decimals";
import { ModalHorasFormMobile } from "./ModalHorasFormMobile";
export const HorasCard = ({
  pagos,
  loading,
  handleEditarPago,
  handleEliminarPago,
  openModalPago,
  filtroDesde,
  setFiltroDesde,
  filtroHasta,
  setFiltroHasta,
  getHoras,
  showSpinner,
  CustomFooter,
  handleCloseModalPago,
  modeEdit,
  pago,
  socio,
  setLoading,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <HorasFilterMobile
        filtroDesde={filtroDesde}
        setFiltroDesde={setFiltroDesde}
        filtroHasta={filtroHasta}
        setFiltroHasta={setFiltroHasta}
        loading={loading}
        getHoras={getHoras}
      />
      <Grid container spacing={2}>
        {pagos.map((pago) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={pago.code}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {pago.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      textOverflow: "ellipsis",
                    }}
                  >
                    Horas: {Decimals.format(pago._value)}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`Fecha(s): ${moment
                      .unix(pago._hoursDateFrom)
                      .format("DD-MM-YYYY")} ${moment
                      .unix(pago._hoursDateTo)
                      .format("DD-MM-YYYY")}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      textOverflow: "ellipsis",
                    }}
                  >
                    Descripción de tareas: {pago._hoursDescription}
                  </Typography>
                </CardContent>
                <CardActions sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<Edit />}
                        onClick={() => handleEditarPago(pago)}
                        color="primary"
                        disabled={loading}
                        size="small"
                      >
                        Editar
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<Delete />}
                        onClick={() => handleEliminarPago(pago)}
                        color="primary"
                        disabled={loading}
                        size="small"
                      >
                        Eliminar
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {openModalPago && (
        <ModalHorasFormMobile
          open={openModalPago}
          handleClose={handleCloseModalPago}
          isEditing={modeEdit}
          pagoEditing={pago}
          socio={socio}
          setLoading={setLoading}
        />
      )}
    </Box>
  );
};
