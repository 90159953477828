import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { Search, TuneOutlined } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as Constants from "../../support/Constants";
import moment from "moment";

const FiltrosReportes = ({
  filtros,
  setFiltros,
  reportesConColaboraciones,
  targetPersonas,
  targetEmpresas,
  setIsFiltered,
  setFilteredData,
  loading,
}) => {
  const [mostrarMasFiltros, setMostrarMasFiltros] = useState(false);

  const { titulo, estado, fechaDesde, fechaHasta, emisor, receptor } = filtros;

  const [mergedTargets, setMergedTargets] = useState([]);

  useEffect(() => {
    const mergedArray = [...targetPersonas, ...targetEmpresas].map((item) => {
      return { name: item.name, memberAddress: item.memberAddress };
    });
    setMergedTargets(mergedArray);
  }, [targetEmpresas, targetPersonas]);

  const getFilteredData = (data, filtros) => {
    const { titulo, estado, fechaDesde, fechaHasta, emisor, receptor } =
      filtros;

    let filteredData = data;

    if (
      titulo === "" &&
      emisor === "0x0000000000000000000000000000000000000000" &&
      receptor === "0x0000000000000000000000000000000000000000" &&
      estado === 0 &&
      fechaDesde === moment().startOf("day").subtract(1, "months") &&
      fechaHasta === moment().endOf("day")
    ) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }

    if (titulo !== "") {
      filteredData = filteredData.filter((element) =>
        element.collaborationTitle.toLowerCase().includes(titulo.toLowerCase())
      );
    }

    if (estado !== Constants.REPORT_STATUS_ANY) {
      filteredData = filteredData.filter(
        (element) => element.status === estado
      );
    }

    if (emisor !== "") {
      filteredData = filteredData.filter(
        (element) => element.collaboration.sourceMemberAddress === emisor
      );
    }

    if (receptor !== "") {
      filteredData = filteredData.filter(
        (element) => element.collaboration.targetMemberAddress === receptor
      );
    }

    filteredData = filteredData.filter((element) => {
      return (
        element.creationDate.toNumber() >= fechaDesde.unix() &&
        element.creationDate.toNumber() <= fechaHasta.unix()
      );
    });

    setFilteredData(filteredData);
  };

  return (
    <Grid container justifyContent="right" marginRight={1}>
      <Grid item lg={mostrarMasFiltros ? 12 : 6}>
        <Box sx={{ justifyContent: "right", display: "flex" }} pb={3}>
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Título"
            variant="outlined"
            size="small"
            value={titulo}
            onChange={(event) =>
              setFiltros({ ...filtros, titulo: event.target.value })
            }
          />
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Estado"
            variant="outlined"
            size="small"
            value={estado}
            select={true}
            defaultValue={Constants.REPORT_STATUS_ANY}
            onChange={(event) =>
              setFiltros({ ...filtros, estado: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="_status_todos" value={Constants.REPORT_STATUS_ANY}>
              TODOS
            </MenuItem>
            <MenuItem
              key="_status_en_curso"
              value={Constants.REPORT_STATUS_ACTIVE}
            >
              En curso
            </MenuItem>
            <MenuItem
              key="_status_en_curso"
              value={Constants.REPORT_STATUS_FINALIZED}
            >
              Finalizado
            </MenuItem>
          </TextField>
          {mostrarMasFiltros && (
            <>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="DD-MM-YYYY"
                  value={fechaDesde}
                  onChange={(newValue) =>
                    setFiltros({
                      ...filtros,
                      fechaDesde: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginLeft: 1, width: 180 }}
                      size="small"
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="DD-MM-YYYY"
                  value={fechaHasta}
                  onChange={(newValue) =>
                    setFiltros({
                      ...filtros,
                      fechaHasta: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginLeft: 1, width: 180 }}
                      size="small"
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>

              {mergedTargets && (
                <Autocomplete
                  name="emisor"
                  options={mergedTargets}
                  getOptionLabel={(option) =>
                    `${option?.name} (${option?.memberAddress?.substring(
                      0,
                      20
                    )}...)`
                  }
                  onChange={(event, value) => {
                    setFiltros({
                      ...filtros,
                      emisor: value
                        ? value.memberAddress
                        : "0x0000000000000000000000000000000000000000",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Socio emisor"
                      variant="outlined"
                      size="small"
                      sx={{ marginLeft: 1, width: 150 }}
                      fullWidth={true}
                    />
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 250,
                      },
                    },
                  }}
                />
              )}
              {mergedTargets && (
                <Autocomplete
                  name="receptor"
                  options={mergedTargets}
                  getOptionLabel={(option) =>
                    `${option?.name} (${option?.memberAddress?.substring(
                      0,
                      20
                    )}...)`
                  }
                  onChange={(event, value) => {
                    setFiltros({
                      ...filtros,
                      receptor: value
                        ? value.memberAddress
                        : "0x0000000000000000000000000000000000000000",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Socio receptor"
                      variant="outlined"
                      size="small"
                      sx={{ marginLeft: 1, width: 150 }}
                      fullWidth={true}
                    />
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 250,
                      },
                    },
                  }}
                />
              )}
            </>
          )}
          <Tooltip
            placement="top"
            arrow
            title={
              !mostrarMasFiltros ? "Mostrar más filtros" : "Ocultar filtros"
            }
          >
            <IconButton
              aria-label="Mostrar más filtros"
              data-testid="button-tune"
              onClick={() => setMostrarMasFiltros(!mostrarMasFiltros)}
            >
              <TuneOutlined />
            </IconButton>
          </Tooltip>
          <Button
            sx={{ marginLeft: 1 }}
            variant="contained"
            color="primary"
            onClick={() => getFilteredData(reportesConColaboraciones, filtros)}
            disabled={loading}
            endIcon={<Search />}
          >
            Buscar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FiltrosReportes;
