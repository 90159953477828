import React, { useContext, useState } from "react";
import { Box, Button, Modal, Typography, Chip, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Web3Manager from "../../support/Web3Manager";
import moment from "moment/moment";
import { Delete } from "@mui/icons-material";
import * as Constants from "../../support/Constants";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalEliminarPropuesta = ({
  open,
  handleClose,
  propuesta,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const formData = {
    _code: propuesta?.code,
  };

  const formPropuesta = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setDisableButton(true);
      openSpinner();
      Web3Manager.callContract(
        Web3Manager.CONTRACT_PROPOSALS,
        "deleteProposal",
        propuesta?.code
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
          closeSpinner();
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          handleClose(false, error, false);
          closeSpinner();
        });
    },
  });

  const { handleSubmit, values } = formPropuesta;

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Propuesta " + propuesta?.title}
              </Typography>

              <Chip
                label={Constants.getNombreEstadoPropuesta(propuesta?.status)}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Proponente: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {propuesta?.memberName}
                </Typography>
                <Chip
                  label={memberTypeMap[propuesta?.memberType]}
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[propuesta?.memberType],
                  }}
                />
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Fecha de creación: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(propuesta?.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box
                  mt={1}
                  sx={{
                    maxHeight: "6em",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1">
                    {propuesta ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: propuesta.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={disableButton}
                endIcon={<Delete />}
              >
                Eliminar
              </Button>
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleClose(true, null, false);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
