import { Box, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import * as Decimals from "../../support/Decimals";
import moment from "moment";

import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalHorasDetalle = ({ open, handleClose, pago }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Detalle de horas donadas
        </Typography>

        <Box mt={2}>
          <TextField
            id="outlined-basic"
            label={"Horas"}
            variant="outlined"
            size="small"
            fullWidth={true}
            disabled={true}
            value={pago ? Decimals.format(pago._value) : ""}
          />
        </Box>

        <Box mt={2} sx={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label={"Fecha desde"}
            variant="outlined"
            size="small"
            disabled={true}
            fullWidth={true}
            value={
              pago ? moment.unix(pago._hoursDateFrom).format("DD-MM-YYYY") : ""
            }
          />
        </Box>

        <Box mt={2} sx={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            label={"Fecha hasta"}
            variant="outlined"
            size="small"
            disabled={true}
            fullWidth={true}
            value={
              pago ? moment.unix(pago._hoursDateTo).format("DD-MM-YYYY") : ""
            }
          />
        </Box>

        <Box mt={2}>
          <TextField
            id="outlined-basic"
            label={"Descripción de las tareas"}
            variant="outlined"
            size="small"
            fullWidth={true}
            multiline={true}
            disabled={true}
            rows={4}
            value={pago ? pago._hoursDescription : ""}
          />
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cancelar"
            onClick={() => {
              handleClose();
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
