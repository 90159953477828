import React from "react";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import { sustainableDevelopmentGoals } from "../../support/sustainableDevelopmentGoals";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      overflowY: "auto",
      width: 250,
      whiteSpace: "normal",
    },
  },
};

const CompromisosFiltroODS = ({ filtroODS, setFiltroODS }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleChangeFiltroODS = (event) => {
    const { value: selectedValues } = event.target;
    if (selectedValues.includes("Seleccionar todos")) {
      setFiltroODS(sustainableDevelopmentGoals.map((goal) => goal.id));
    } else if (selectedValues.includes("Limpiar selección")) {
      setFiltroODS([]);
    } else {
      setFiltroODS(selectedValues);
    }
  };

  return (
    <TextField
      id="filtro-ods"
      select
      label="ODS en los que impacta"
      size="small"
      value={filtroODS ?? []}
      onChange={handleChangeFiltroODS}
      SelectProps={{
        multiple: true,
        renderValue: (selected) =>
          selected
            .map((s) => {
              const selectedGoal = sustainableDevelopmentGoals.find(
                (goal) => goal.id === s
              );
              return selectedGoal ? selectedGoal.id : s;
            })
            .join(", "),
        MenuProps: MenuProps,
      }}
      MenuProps={MenuProps}
      variant="outlined"
      fullWidth={matches}
      sx={{ width: !matches ? 250 : "100%" }}
    >
      {sustainableDevelopmentGoals.map((goal) => (
        <MenuItem key={goal.id} value={goal.id}>
          <Checkbox checked={filtroODS?.includes(goal.id)} />
          <Tooltip title={goal.title} placement="top" arrow>
            <ListItemText
              primaryTypographyProps={{ noWrap: true, fontSize: "1rem" }}
            >
              {goal.id} - {goal.title}
            </ListItemText>
          </Tooltip>
        </MenuItem>
      ))}
      <MenuItem value="Seleccionar todos">
        <ListItemText primary="Seleccionar todos" />
      </MenuItem>
      <MenuItem value="Limpiar selección">
        <ListItemText primary="Limpiar selección" />
      </MenuItem>
    </TextField>
  );
};

export default CompromisosFiltroODS;
