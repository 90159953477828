import React, { useEffect, useState } from "react";

import moment from "moment/moment";

import * as Web3Manager from "../../support/Web3Manager";
import * as Decimals from "../../support/Decimals";

import { Search } from "@mui/icons-material";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { CancelButton } from "../ui/CustomButtons";
import { getMemberHours } from "../../services/HoursRegisterServices";
import { useNotification } from "../../hooks/useNotification";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalHorasSocioForm = ({ open, handleClose, socio }) => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();
  const currentYear = moment().format("YYYY");
  const initialFiltroDesde = `${currentYear}-01-01`;
  const initialFiltroHasta = `${currentYear}-12-31`;

  const [filtroDesde, setFiltroDesde] = useState(initialFiltroDesde);
  const [filtroHasta, setFiltroHasta] = useState(initialFiltroHasta);
  const [pagos, setPagos] = useState([]);
  const [totalHoras, setTotalHoras] = useState(0);

  const getPagos = () => {
    // Transformamos los filtros de fechas a timestamp unix (esperado por unix)

    let _fromDate = 0;
    if (filtroDesde) {
      _fromDate = moment(filtroDesde, "YYYY-MM-DD").startOf("day").unix();
    }
    let _toDate = 0;
    if (filtroHasta) {
      _toDate = moment(filtroHasta, "YYYY-MM-DD").endOf("day").unix();
    }

    getMemberHours(socio.code, _fromDate, _toDate)
      .then((payments) => {
        const pagos = [];

        if (payments && payments.hourRegisterData) {
          payments.hourRegisterData.forEach((payment) => {
            pagos.push({
              _hourRegisteredCode: payment.hourRegisteredCode,
              _value: payment.value,
              _hoursDateFrom: payment.dateFrom,
              _hoursDateTo: payment.dateTo,
              _hoursDescription: payment.hoursDescription,
              _dateInfo: "",
            });
          });
        }
        setPagos(pagos);
      })
      .catch((error) => {
        //console.error(error);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const columnsPagos = [
    {
      field: "_value",
      headerName: "Horas",
      flex: 1,
      valueGetter: (params) => {
        return Decimals.format(params.value);
      },
    },
    {
      field: "_dateInfo",
      headerName: "Fecha(s)",
      flex: 1,
      valueGetter: (params) => {
        return (
          moment.unix(params.row._hoursDateFrom).format("DD-MM-YYYY") +
          " - " +
          moment.unix(params.row._hoursDateTo).format("DD-MM-YYYY")
        );
      },
    },
    {
      field: "_hoursDescription",
      headerName: "Descripción de tareas",
      flex: 1,
    },
  ];

  useEffect(() => {
    getPagos();
  }, [socio]);

  useEffect(() => {
    const horasRegistradas = pagos.reduce((total, pago) => {
      const { whole, fractional } = pago._value;
      return total + whole + fractional / 10;
    }, 0);
    setTotalHoras(horasRegistradas);
  }, [pagos]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Grid sx={{ marginLeft: 2 }}>
          <strong>Total de horas: </strong> {totalHoras}
        </Grid>

        <GridFooter />
      </GridFooterContainer>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setPagos([]);
        handleClose(null);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Horas donadas por el socio
        </Typography>
        <Box mt={2} mb={2} display="flex" alignItems="center" columnGap={2}>
          <Box>
            <TextField
              id="outlined-basic"
              label="Dirección (@BLK)"
              variant="outlined"
              size="small"
              disabled={true}
              value={socio ? socio.memberAddress : ""}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: 450 }}
            />
          </Box>
          <Box>
            <TextField
              id="outlined-basic"
              label="Nombre completo"
              variant="outlined"
              size="small"
              fullWidth={true}
              disabled={true}
              value={socio ? socio.name : ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
        <Box
          mt={2}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="right"
          columnGap={2}
        >
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Desde"
                inputFormat="DD-MM-YYYY"
                value={filtroDesde}
                onChange={(newValue) =>
                  setFiltroDesde(newValue.format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: 180 }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha Hasta"
                inputFormat="DD-MM-YYYY"
                value={filtroHasta}
                onChange={(newValue) =>
                  setFiltroHasta(newValue.format("YYYY-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: 180 }}
                    size="small"
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={getPagos}
            endIcon={<Search />}
          >
            Buscar
          </Button>
        </Box>

        <DataGrid
          rows={pagos}
          pageSize={5}
          autoHeight={true}
          columns={columnsPagos}
          getRowId={(row) => row._hourRegisteredCode}
          components={{ Footer: CustomFooter }}
        />

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(null);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
