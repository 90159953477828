import React, { useState, useEffect, useContext } from "react";
import { Container, Box, Typography, Button, Grid, Stack } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

import {
  CheckCircle,
  ReportOutlined,
  Engineering,
  ZoomIn,
  Article,
} from "@mui/icons-material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import moment from "moment";

import { ModalTrabajarColaboracion } from "../../components/colaboraciones/ModalTrabajarColaboracion";
import { ModalReporteForm } from "../../components/reportes/ModalReporteForm";
import { ModalFinalizarReporte } from "../../components/reportes/ModalFinalizarReporte";
import FiltrosReportes from "../../components/reportes/FiltrosReportes";
import { useNotification } from "../../hooks/useNotification";

import SpinnerContext from "../../context/spinner/SpinnerContext";
import SpinnerModal from "../../components/ui/SpinnerModal";
import { getPersons } from "../../services/PersonsServices";
import { getCompanies } from "../../services/CompaniesServices";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { getCollaborations } from "../../services/CollaborationsServices";
import SociosContext from "../../context/socios/SociosContext";

const ReportesPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  //filtros
  const [filtros, setFiltros] = useState({
    titulo: "",
    estado: 0,
    fechaDesde: moment().startOf("day").subtract(1, "months"),
    fechaHasta: moment().endOf("day"),
    emisor: "",
    receptor: "",
  });

  const [modeEdit, setModeEdit] = useState(false);
  const [targetPersonas, setTargetPersonas] = useState([]);
  const [targetEmpresas, setTargetEmpresas] = useState([]);

  const [reportesData, setReportesData] = useState([]);
  const [colaboracionesData, setColaboracionesData] = useState([]);
  const [reportesConColaboraciones, setReportesConColaboraciones] = useState(
    []
  );
  const [colaboracionSelected, setColaboracionSelected] = useState(null);
  const [comentarionsColaboracion, setComentarionsColaboracion] = useState([]);
  const [reporteSelected, setReporteSelected] = useState(null);
  const [openModalColaboracion, setOpenModalColaboracion] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [openModalFinalizar, setOpenModalFinalizar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState([reportesConColaboraciones]);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);
  const {
    socios,
    getSocios,
    isLoading: loadingSocios,
  } = useContext(SociosContext);

  const getReports = () => {
    setLoading(true);
    Web3Manager.CONTRACT_REPORTS.getReports(
      moment().startOf("day").subtract(1, "months").unix(),
      moment().endOf("day").unix()
    )
      .then((response) => {
        setReportesData(response);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const getColaboraciones = () => {
    setLoading(true);
    getCollaborations(
      "",
      "",
      "",
      "",
      0,
      0,
      filtros.fechaDesde,
      filtros.fechaHasta
    )
      .then((response) => {
        setColaboracionesData(response);
        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const handleEditarReporte = (reporteSelected) => {
    setOpenModalEditar(true);
    setModeEdit(true);
    setReporteSelected(reporteSelected);
  };

  const handleFinalizarReporte = (reporteSelected) => {
    setOpenModalFinalizar(true);
    setModeEdit(false);
    setReporteSelected(reporteSelected);
  };

  const handleVerColaboracion = (colaboracionSelected) => {
    setOpenModalColaboracion(true);
    setModeEdit(false);
    setColaboracionSelected(colaboracionSelected);
    setComentarionsColaboracion(colaboracionSelected.comments);
  };

  const handleCloseModalColaboracion = () => {
    setOpenModalColaboracion(false);
    setModeEdit(false);
    setColaboracionSelected(null);
    setComentarionsColaboracion([]);
  };

  const handleCloseModalEditar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getReports();
            getColaboraciones();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalEditar(false);
    setReporteSelected(null);
    setModeEdit(false);
  };

  const handleCloseModalFinalizar = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getReports();
            getColaboraciones();
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return; // No se cierra el modal
      }
    }

    setOpenModalFinalizar(false);
    setReporteSelected(null);
    setModeEdit(false);
  };

  const columnsReportes = [
    {
      field: "collaborationTitle",
      headerName: "Título",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sourceMemberName",
      headerName: "Emisor",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.collaboration && params.row.collaboration.sourceMemberName,
    },
    {
      field: "targetMemberName",
      headerName: "Receptor",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.collaboration && params.row.collaboration.targetMemberName,
    },

    {
      field: "status",
      headerName: "Estado",
      flex: 0.5,
      minWidth: 100,
      valueGetter: (params) =>
        Constants.getNombreEstadoReporte(params.row.status),
    },
    {
      field: "creationDate",
      headerName: "Fecha de creación ",
      flex: 0.5,
      minWidth: 100,
      valueGetter: (params) =>
        moment.unix(params.row.creationDate.toNumber()).format("DD-MM-YYYY"),
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.7,
      minWidth: 150,
      renderCell: (params) => {
        const isActive = params.row.status === Constants.REPORT_STATUS_ACTIVE;
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              disabled={loading}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() =>
                handleVerColaboracion(
                  params.row.collaboration && params.row.collaboration
                )
              }
            />
            {isActive && (
              <GridActionItem
                disabled={loading}
                icon={<Engineering />}
                label="Trabajar"
                onClick={() => handleEditarReporte(params.row)}
              />
            )}

            <GridActionItem
              disabled={loading}
              icon={isActive ? <CheckCircle /> : <Article />}
              label={isActive ? "Finalizar" : "Reporte"}
              onClick={() => handleFinalizarReporte(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getReports();
    getColaboraciones();
    getSocios(
      Constants.MEMBER_TYPE_SIN_REGISTRO,
      Constants.CATEGORY_ANY,
      "",
      Constants.MEMBER_STATUS_ANY
    );
  }, []);

  useEffect(() => {
    const reporteConColaboracion = reportesData.map((reporte) => {
      let codigoColaboracion = reporte.collaborationCode.toNumber();

      let colaboracion = colaboracionesData.find(
        (colaboracion) => colaboracion.code === codigoColaboracion
      );

      return {
        ...reporte,
        collaboration: colaboracion ? colaboracion : null,
      };
    });

    setReportesConColaboraciones(reporteConColaboracion);
  }, [reportesData, colaboracionesData]);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Gestión de reportes
        </Typography>
      </Box>
      <Grid container>
        {socios && (
          <FiltrosReportes
            filtros={filtros}
            setFiltros={setFiltros}
            reportesConColaboraciones={reportesConColaboraciones}
            setReportesConColaboraciones={setReportesConColaboraciones}
            targetPersonas={socios.filter(
              (s) =>
                s.type === Constants.MEMBER_TYPE_PERSONA &&
                s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
            )}
            targetEmpresas={socios.filter(
              (s) =>
                s.type === Constants.MEMBER_TYPE_EMPRESA &&
                s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
            )}
            setIsFiltered={setIsFiltered}
            setFilteredData={setFilteredData}
            loading={loading || loadingSocios}
          />
        )}
      </Grid>
      {reportesConColaboraciones && (
        <DataGrid
          rows={isFiltered ? filteredData : reportesConColaboraciones}
          loading={loading && !showSpinner}
          pageSize={50}
          autoHeight={true}
          columns={columnsReportes}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(row) => row.code.toNumber()}
          rowsPerPageOptions={[50]}
        />
      )}

      <br />

      {openModalEditar && (
        <ModalReporteForm
          reporte={reporteSelected}
          open={openModalEditar}
          handleClose={handleCloseModalEditar}
          setLoading={setLoading}
        />
      )}

      {openModalColaboracion && (
        <ModalTrabajarColaboracion
          colaboracion={colaboracionSelected}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          comentarios={comentarionsColaboracion}
        />
      )}

      {openModalFinalizar && (
        <ModalFinalizarReporte
          reporte={reporteSelected}
          open={openModalFinalizar}
          handleClose={handleCloseModalFinalizar}
          setLoading={setLoading}
        />
      )}
    </Container>
  );
};

export default ReportesPage;
