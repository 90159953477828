import { Box, Button, Modal, Typography } from "@mui/material";

import React from "react";

import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70%", sm: "70%", md: "50%", lg: "30%" },
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalIdNoCorresponde = ({ open, handleCancel }) => {
  return (
    <Modal
      open={open}
      onClose={handleCancel}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Box mt={2}>
          <Typography variant="subtitle1">
            No se pudo completar la operación. Esto podría deberse a que:
            <ol>
              <li>
                La billetera proporcionada ya está registrada con otra cuenta.
              </li>

              <li>
                Se ha realizado un registro previo con una billetera diferente.
              </li>
            </ol>
            Por favor, verifique los datos ingresados e intente nuevamente.
          </Typography>
        </Box>
        <Box
          mt={2}
          mb={1}
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          <CancelButton label="Cerrar" onClick={handleCancel} />
        </Box>
      </Box>
    </Modal>
  );
};
