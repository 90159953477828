import React, { useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";

export const VoteBreakdown = ({ ballotData }) => {
  const [totalVotes, setTotalVotes] = useState();
  const [positiveVotes, setPositiveVotes] = useState();
  const [negativeVotes, setNegativeVotes] = useState();
  const [abstentionVotes, setAbstentionVotes] = useState();

  useEffect(() => {
    if (ballotData?.voteCount) {
      const allVotes = ballotData.voteCount.reduce(
        (acc, count) => acc + count,
        0
      );
      setTotalVotes(allVotes);
      setPositiveVotes(ballotData.voteCount[0]);
      setNegativeVotes(ballotData.voteCount[1]);
      setAbstentionVotes(ballotData.voteCount[2]);
    }
  }, [ballotData]);

  //const totalVotes = positive + negative + abstention;
  const positivePercentage = ((positiveVotes / totalVotes) * 100).toFixed(1);
  const negativePercentage = ((negativeVotes / totalVotes) * 100).toFixed(1);
  const abstentionPercentage = ((abstentionVotes / totalVotes) * 100).toFixed(
    1
  );

  if (totalVotes === 0) {
    return (
      <Box>
        <Typography variant="subtitle1">No se han emitido votos aún</Typography>
      </Box>
    );
  }

  return (
    <>
      {totalVotes && (
        <Box container display="flex" flexDirection="column">
          <Box container display="flex" alignItems="center">
            <Box
              item
              bgcolor="#F6F6F6"
              height={10}
              xs={4}
              sx={{ width: "50%", borderRadius: 10, overflow: "hidden" }}
            >
              <Box
                width={`${positivePercentage}%`}
                bgcolor="#1ABC9C"
                height={10}
              />
            </Box>
            <Box item sx={{ ml: 1 }}>
              <Typography variant="caption">{`SI: ${positiveVotes} (${positivePercentage}%)`}</Typography>
            </Box>
          </Box>
          <Box container display="flex" alignItems="center">
            <Box
              item
              bgcolor="#F6F6F6"
              height={10}
              xs={4}
              sx={{ width: "50%", borderRadius: 10, overflow: "hidden" }}
            >
              <Box
                width={`${negativePercentage}%`}
                bgcolor="#E67E22"
                height={10}
              />
            </Box>
            <Box item sx={{ ml: 1 }}>
              <Typography variant="caption">{`NO: ${negativeVotes} (${negativePercentage}%)`}</Typography>
            </Box>
          </Box>
          <Box container display="flex" alignItems="center">
            <Box
              item
              bgcolor="#F6F6F6"
              height={10}
              xs={4}
              sx={{ width: "50%", borderRadius: 10, overflow: "hidden" }}
            >
              <Box
                width={`${abstentionPercentage}%`}
                bgcolor="#A6ACAF"
                height={10}
              />
            </Box>
            <Box item sx={{ ml: 1 }}>
              <Typography variant="caption">{`ABSTENCIÓN: ${abstentionVotes} (${abstentionPercentage}%)`}</Typography>
            </Box>
          </Box>
          <Box item sx={{ mt: 1 }}>
            <Typography
              variant="body2"
              component="span"
              sx={{ fontStyle: "italic" }}
            >
              Total de votos emitidos: {totalVotes}
            </Typography>{" "}
          </Box>
        </Box>
      )}
    </>
  );
};
