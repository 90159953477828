import React from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import Edit from "@mui/icons-material/Edit";
import { Delete, Search, ZoomIn } from "@mui/icons-material";
import { ModalPropuestaForm } from "../../components/propuestas/ModalPropuestaForm";
import { ModalVotarPropuesta } from "../../components/propuestas/ModalVotarPropuesta";
import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { VoteBar } from "../../components/propuestas/VoteBar";
import { ModalEliminarPropuesta } from "../../components/propuestas/ModalEliminarPropuesta";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { PropuestasFilterMobile } from "./PropuestasFilterMobile";

export const PropuestasTable = ({
  roles,
  categoriasPropuesta,
  propuestasData,
  getPropuestas,
  filtroTitulo,
  setFiltroTitulo,
  filtroEstado,
  setFiltroEstado,
  filtroCategoria,
  setFiltroCategoria,
  loading,
  setLoading,
  showSpinner,
  openModalPropuesta,
  handleCloseModalPropuesta,
  modeEdit,
  propuestaSelected,
  openVoteModal,
  handleCloseModalVotar,
  openModalEliminarPropuesta,
  handleCloseModalEliminar,
  handleOpenVoteModal,
  handleEditarPropuesta,
  handleEliminarPropuesta,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const columnsPropuestas = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 0.5,
      minWidth: 100,
      valueGetter: (params) => {
        return Constants.getNombreEstadoPropuesta(params.value) || "N/A";
      },
    },
    {
      field: "category",
      headerName: "Categoría",
      flex: 0.5,
      minWidth: 100,
    },
    {
      field: "openingDate",
      headerName: "Fecha apertura",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return moment(params.row.openingDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "closingDate",
      headerName: "Fecha cierre",
      flex: 0.5,
      minWidth: 100,
      renderCell: (params) => {
        return moment(params.row.closingDate).format("DD-MM-YYYY");
      },
    },
    {
      field: "voteBreakdown",
      headerName: "Votos",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        const isActive = params.row.status === Constants.PROPOSAL_STATUS_ACTIVE;
        const isApproved =
          params.row.status === Constants.PROPOSAL_STATUS_APPROVED;
        const isRejected =
          params.row.status === Constants.PROPOSAL_STATUS_REJECTED;
        if (isActive || isApproved || isRejected){
          const ballotData = {};
          ballotData.yesVote = params.row.yesVote;
          ballotData.noVote = params.row.noVote;
          ballotData.abstainVote = params.row.abstainVote;
          return <VoteBar ballotDataParam={ballotData} />;
        }
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        const isActive = params.row.status === Constants.PROPOSAL_STATUS_ACTIVE;
        const canVote =
          isActive &&
          Web3Manager.MEMBER_DATA.memberCategory ===
            Constants.CATEGORY_ACTIVO &&
          (params.row.role === 0 ||
            Web3Manager.MEMBER_DATA.memberRoles.includes(params.row.role));
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              disabled={loading}
              icon={canVote ? <HowToVoteIcon /> : <ZoomIn />}
              label={canVote ? "Votar" : "Detalle"}
              onClick={() => handleOpenVoteModal(params.row)}
            />
            {/*solo puede editar usuario creador si propuesta está pendiente*/}
            {params.row.status === Constants.PROPOSAL_STATUS_PENDING &&
              params.row.memberAddress.toLowerCase() ===
                Web3Manager.getAccountToDisplay().toLowerCase() && (
                <GridActionItem
                  disabled={loading}
                  icon={<Edit />}
                  label="Editar"
                  onClick={() => handleEditarPropuesta(params.row)}
                />
              )}
            {/*solo puede eliminar usuario creador si propuesta está pendiente*/}
            {params.row.status === Constants.PROPOSAL_STATUS_PENDING &&
              params.row.memberAddress.toLowerCase() ===
                Web3Manager.getAccountToDisplay().toLowerCase() && (
                <GridActionItem
                  disabled={loading}
                  icon={<Delete />}
                  label="Eliminar"
                  onClick={() => handleEliminarPropuesta(params.row)}
                />
              )}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <Grid container>
        {matches ? (
          <PropuestasFilterMobile
            filtroTitulo={filtroTitulo}
            setFiltroTitulo={setFiltroTitulo}
            filtroEstado={filtroEstado}
            setFiltroEstado={setFiltroEstado}
            filtroCategoria={filtroCategoria}
            setFiltroCategoria={setFiltroCategoria}
            categoriasPropuesta={categoriasPropuesta}
            loading={loading}
            getPropuestas={getPropuestas}
            Search={Search}
          />
        ) : (
          <Grid item lg={12}>
            <Box sx={{ justifyContent: "right", display: "flex" }} pb={3}>
              <TextField
                sx={{ width: 300 }}
                id="outlined-basic"
                label="Título"
                variant="outlined"
                size="small"
                value={filtroTitulo}
                onChange={(newValue) => setFiltroTitulo(newValue.target.value)}
              />

              <TextField
                sx={{ marginLeft: 1, width: 150 }}
                id="outlined-basic"
                label="Estado"
                variant="outlined"
                size="small"
                value={filtroEstado}
                select={true}
                defaultValue={Constants.PROPOSAL_STATUS_ANY}
                onChange={(newValue) => setFiltroEstado(newValue.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_ANY}
                  value={Constants.PROPOSAL_STATUS_ANY}
                >
                  Todas
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_PENDING}
                  value={Constants.PROPOSAL_STATUS_PENDING}
                >
                  Pendiente
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_ADMITTED}
                  value={Constants.PROPOSAL_STATUS_ADMITTED}
                >
                  Admitida
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_NOT_ADMITTED}
                  value={Constants.PROPOSAL_STATUS_REJECTED}
                >
                  No admitida
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_ACTIVE}
                  value={Constants.PROPOSAL_STATUS_ACTIVE}
                >
                  Activa
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_APPROVED}
                  value={Constants.PROPOSAL_STATUS_APPROVED}
                >
                  Aprobada
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.PROPOSAL_STATUS_REJECTED}
                  value={Constants.PROPOSAL_STATUS_REJECTED}
                >
                  Rechazada
                </MenuItem>
              </TextField>

              <TextField
                sx={{ marginLeft: 1, width: 150 }}
                id="outlined-basic"
                label="Categoría"
                variant="outlined"
                size="small"
                value={filtroCategoria}
                select={true}
                defaultValue={"TODAS"}
                onChange={(newValue) =>
                  setFiltroCategoria(newValue.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key="_status_todos" value={"TODAS"}>
                  TODAS
                </MenuItem>
                {categoriasPropuesta?.map((item) => (
                  <MenuItem key={"_status_" + item.id} value={item.category}>
                    {item.category}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                sx={{ marginLeft: 1 }}
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => getPropuestas()}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
      <DataGrid
        rows={propuestasData}
        pageSize={50}
        loading={loading && !showSpinner}
        autoHeight={true}
        columns={columnsPropuestas}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.code}
        rowsPerPageOptions={[50]}
      />
      <br />
      {roles && (
        <ModalPropuestaForm
          open={openModalPropuesta}
          handleClose={handleCloseModalPropuesta}
          categoriasPropuesta={categoriasPropuesta}
          isEditing={modeEdit}
          propuestaEditing={propuestaSelected}
          roles={roles}
          setLoading={setLoading}
        />
      )}
      {propuestaSelected && (
        <ModalVotarPropuesta
          open={openVoteModal}
          propuesta={propuestaSelected}
          handleClose={handleCloseModalVotar}
          setLoading={setLoading}
          isReadOnly={false}
        />
      )}
      <ModalEliminarPropuesta
        open={openModalEliminarPropuesta}
        propuesta={propuestaSelected}
        handleClose={handleCloseModalEliminar}
        setLoading={setLoading}
      />
    </>
  );
};
