import React, { useReducer } from "react";
import SpinnerContext from "./SpinnerContext";
import SpinnerReducer from "./SpinnerReducer";

const SpinnerState = (props) => {
  const initialState = {
    showSpinner: false
  };

  const [state, dispatch] = useReducer(SpinnerReducer, initialState);
  const { showSpinner } = state;

  
  const openSpinner = async (
  ) => {
    dispatch({ type: "SET_SHOW_SPINNER", payload: { showSpinner: true } });
    
  };

  const closeSpinner = async (
    ) => {
      dispatch({ type: "SET_SHOW_SPINNER", payload: { showSpinner: false } });
  
      
    };


  return (
    <SpinnerContext.Provider
      value={{
        showSpinner,
        openSpinner,
        closeSpinner
      }}
    >
      {props.children}
    </SpinnerContext.Provider>
  );
};
export default SpinnerState;
