import React from "react";
import { withRouter } from "react-router";
import { Box, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/system";
import * as Constants from "../support/Constants";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const HomePage = withRouter(({ member, history }) => {
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  const handleVerFibras = () => {
    history.push("/view/CompromisosPage?filtroComunidad=Fibras");
  };
  const handleVerImpact = () => {
    history.push("/view/CompromisosPage?filtroComunidad=Impact");
  };

  const CustomRectangle = styled(Box)(({ theme }) => ({
    backgroundColor: "#003C3D",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "100%",
    width: 400,
    overflow: "hidden",
    borderRadius: 10,
    padding: 16,
    transition: "background-color 0.5s",
    "&:hover": {
      backgroundColor: "#095653",
    },
  }));

  const YellowButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#FFD700",
    color: "black",
    "&:hover": {
      backgroundColor: "#FFC107",
    },
    position: "absolute",
    right: 25,
    bottom: 30,
    padding: "5px 24px",
    minWidth: "100px",
  }));

  return (
    <Grid lg={12} m={4}>
      {member &&
      member.memberStatus === Constants.MEMBER_STATUS_WALLETASSIGNED ? (
        <>
          <Box mt={4} mb={2}>
            <Typography
              variant={isMobileSize ? "h4" : "h3"}
              color="#005A59"
              sx={{
                fontWeight: 500,
                fontSize: { xs: "23px", md: "26px" },
                marginTop: 2,
              }}
              gutterBottom
            >
              Accede y Adhiere a Compromisos por Comunidad
            </Typography>
          </Box>

          <Grid spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "20px",
                  },
                  maxWidth: "70ch",
                  marginBottom: 5,
                }}
              >
                Descubre cómo <strong>colaborar</strong> con{" "}
                <strong>personas, empresas y organizaciones </strong>
                que declaran cumplir con <strong>compromisos</strong> o tener
                intención de cumplimiento en un período de tiempo.
              </Typography>
            </Grid>

            <Grid container spacing={5} alignItems={"center"}>
              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
              >
                <CustomRectangle>
                  <img
                    src="/images/img-fibras.png"
                    alt="Fondo"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      imageRendering: "optimizeQuality",
                    }}
                  />
                  <Box
                    position="absolute"
                    width={"70%"}
                    style={{
                      top: "42%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <img
                      src="/images/logo-fibra-blanco.png"
                      alt="Logo"
                      style={{ width: 70, imageRendering: "optimizeQuality" }}
                    />
                    <Typography
                      variant="h2"
                      color={"white"}
                      sx={{
                        fontSize: "31px",
                        fontWeight: 500,
                        maxWidth: "9ch",
                      }}
                    >
                      Comunidad Fibras
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"white"}
                      sx={{
                        fontSize: "13px",
                        fontWeight: 400,
                        marginBottom: 2,
                        maxWidth: "30ch",
                        textTransform: "uppercase",
                      }}
                    >
                      Compromisos Sostenibilidad Tecnología para el Bien
                    </Typography>
                  </Box>
                  <YellowButton
                    style={{ width: "auto" }}
                    onClick={handleVerFibras}
                  >
                    Ver{" "}
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "12px", marginLeft: "7px" }}
                    />
                  </YellowButton>
                </CustomRectangle>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <CustomRectangle>
                  <img
                    src="/images/img-impact.png"
                    alt="Fondo"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      imageRendering: "optimizeQuality",
                    }}
                  />
                  <Box
                    position="absolute"
                    width={"70%"}
                    style={{
                      top: "42%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <img
                      src="/images/logo-impact-blanco.png"
                      alt="Logo"
                      style={{ width: 60, imageRendering: "optimizeQuality" }}
                    />
                    <Typography
                      variant="h2"
                      color={"white"}
                      sx={{
                        fontSize: "31px",
                        fontWeight: 500,
                        maxWidth: "11ch",
                      }}
                    >
                      Comunidad Impact Trade
                    </Typography>
                    <Typography
                      variant="body1"
                      color={"white"}
                      sx={{
                        fontSize: "13px",
                        fontWeight: 400,
                        marginBottom: 2,
                        maxWidth: "30ch",
                        textTransform: "uppercase",
                      }}
                    >
                      COMPROMISOS GENERALES COMPROMISOS PARTICIPANTES
                    </Typography>
                  </Box>
                  <YellowButton
                    style={{ width: "auto" }}
                    onClick={handleVerImpact}
                  >
                    Ver
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "12px", marginLeft: "7px" }}
                    />
                  </YellowButton>
                </CustomRectangle>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Box mt={4} mb={2}>
            <Typography
              variant={isMobileSize ? "h4" : "h3"}
              color="#005A59"
              sx={{
                fontWeight: 500,
                fontSize: { xs: "23px", md: "26px" },
                marginTop: 2,
              }}
              gutterBottom
            >
              Plataforma Blockchain - Compromisos en Sincronía
            </Typography>
          </Box>

          <Grid spacing={3}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 300,
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "20px",
                  },
                  maxWidth: "70ch",
                  marginBottom: 5,
                }}
              >
                Para ingresar en la plataforma debes{" "}
                <strong>conectar a la red blockchain</strong>, asegurando
                inmutabilidad, transparencia, trazabilidad y seguridad mediante
                validación descentralizada de cada transacción.
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
});

export default withRouter(HomePage);
