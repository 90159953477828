// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
      case "SET_SHOW_SPINNER":
        return {
          ...state,
          showSpinner: action.payload.showSpinner,
        };
     
      default:
        return state;
    }
  };
  