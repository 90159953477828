import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import {
  Delete,
  Edit,
  CheckCircle,
  Article,
  PlayCircleOutline,
  Engineering,
  ZoomIn,
  Feedback,
} from "@mui/icons-material";
import * as Constants from "../../support/Constants";
import moment from "moment";
import * as Web3Manager from "../../support/Web3Manager";
import FiltrosColaboracionesMobile from "./FiltrosColaboracionesMobile";
import { ModalTrabajarColaboracionMobile } from "./ModalTrabajarColaboracionMobile";
import { ModalFinalizarColaboracionMobile } from "./ModalFinalizarColaboracionMobile";
import { ModalReporteColaboracionMobile } from "./ModalReporteColaboracionMobile";
import { ModalColaboracionesFormMobile } from "./ModalColaboracionesFormMobile";
import { ModalIniciarColaboracionMobile } from "./ModalIniciarColaboracionMobile";

export const ColaboracionesCard = ({
  loading,
  handleEditarColaboracion,
  handleTrabajarColaboracion,
  handleIniciarColaboracion,
  handleFinalizarColaboracion,
  handleEliminarColaboracion,
  handleVerReporte,
  handleAgregarColaboracion,
  colaboracionesData,
  pathname,
  openModalColaboracion,
  colaboracionSelected,
  handleCloseModalColaboracion,
  modeEdit,
  socios,
  targetPrincipios,
  categoriasColaboracion,
  compromisos,
  setLoading,
  openModalIniciar,
  openModalTrabajar,
  openModalFinalizar,
  openModalReporte,
  handleCloseModalIniciar,
  handleCloseModalTrabajar,
  handleCloseModalFinalizar,
  handleCloseModalReporte,
  comentariosColaboracion,
  filtros,
  setFiltros,
  getColaboraciones,
  filterColaborations,
  showSpinner,
}) => {
  const filterColaboration = filterColaborations(colaboracionesData, pathname);
  const personas = socios.filter(
    (s) =>
      s.type === Constants.MEMBER_TYPE_PERSONA &&
      s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
  );
  const empresas = socios.filter(
    (s) =>
      s.type === Constants.MEMBER_TYPE_EMPRESA &&
      s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <FiltrosColaboracionesMobile
        filtros={filtros}
        setFiltros={setFiltros}
        getColaboraciones={getColaboraciones}
        categoriasColaboracion={categoriasColaboracion}
        targetPersonas={personas}
        targetEmpresas={empresas}
        loading={loading}
        showSourceAndTargetFilters={!pathname.includes("MisColaboraciones")}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {loading && !showSpinner ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : filterColaboration.length === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography align="center" variant="body1" color="text.secondary">
              No hay colaboraciones para mostrar.
            </Typography>
          </Box>
        ) : (
          filterColaboration.map((colaboraciones) => {
            const {
              sourceMemberAddress,
              targetMemberAddress,
              sourceSatisfaction,
              targetSatisfaction,
              status,
            } = colaboraciones;
            const userIsSource =
              sourceMemberAddress?.toLowerCase() ===
              Web3Manager.MEMBER_DATA?.memberAddress?.toLowerCase();
            const userIsTarget =
              targetMemberAddress?.toLowerCase() ===
              Web3Manager.MEMBER_DATA?.memberAddress?.toLowerCase();
            const isEditable =
              userIsSource && status === Constants.COLLABORATION_STATUS_PENDING;
            const isWorkable =
              status === Constants.COLLABORATION_STATUS_ACTIVE &&
              (userIsSource || userIsTarget);
            const oneUserNeedsToUpdateSatisfaction =
              (status === Constants.COLLABORATION_STATUS_FINALIZED ||
                status === Constants.COLLABORATION_STATUS_REPORTED) &&
              ((userIsSource &&
                sourceSatisfaction ===
                  Constants.COLLABORATION_SATISFACTION_NONE) ||
                (userIsTarget &&
                  targetSatisfaction ===
                    Constants.COLLABORATION_SATISFACTION_NONE));
            const isReported =
              status === Constants.COLLABORATION_STATUS_REPORTED;

            return (
              <Grid item xs={12} sm={6} md={4} key={colaboraciones.code}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {colaboraciones.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Estado:{" "}
                      {Constants.getNombreEstadoColaboracion(
                        colaboraciones.status
                      ) || "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Emisor: {colaboraciones.sourceMemberName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Receptor: {colaboraciones.targetMemberName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Categoria: {colaboraciones.category}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Tipo:{" "}
                      {Constants.getNombreTipoColaboracion(
                        colaboraciones.collaborationType
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fecha de creación:{" "}
                      {colaboraciones.creationDate
                        ? moment(colaboraciones.creationDate).format(
                            "DD-MM-YYYY"
                          )
                        : "N/A"}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Button
                        fullWidth
                        sx={{
                          width: "100%",
                          margin: "0.5rem 1rem 0.5rem 1rem",
                        }}
                        variant="contained"
                        startIcon={
                          isEditable ? (
                            <Edit />
                          ) : isWorkable ? (
                            <Engineering />
                          ) : (
                            <ZoomIn />
                          )
                        }
                        onClick={() =>
                          isEditable
                            ? handleEditarColaboracion(colaboraciones)
                            : handleTrabajarColaboracion(colaboraciones)
                        }
                        color="primary"
                        disabled={loading}
                        size="small"
                      >
                        {isEditable
                          ? "Editar"
                          : isWorkable
                          ? "Trabajar"
                          : "Detalle"}
                      </Button>

                      {(isEditable || isWorkable) &&
                        (isEditable ? (
                          <Button
                            disabled={loading}
                            fullWidth
                            sx={{
                              width: "100%",
                              margin: "0.5rem 1rem 0.5rem 1rem",
                            }}
                            startIcon={<PlayCircleOutline />}
                            label="Iniciar"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleIniciarColaboracion(colaboraciones)
                            }
                          >
                            Iniciar
                          </Button>
                        ) : isWorkable ? (
                          <Button
                            disabled={loading}
                            fullWidth
                            sx={{
                              width: "100%",
                              margin: "0.5rem 1rem 0.5rem 1rem",
                            }}
                            color="primary"
                            variant="contained"
                            size="small"
                            startIcon={<CheckCircle />}
                            label="Finalizar"
                            onClick={() => {
                              handleFinalizarColaboracion(colaboraciones);
                            }}
                          >
                            Finalizar
                          </Button>
                        ) : (
                          <></>
                        ))}

                      {isEditable && (
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            width: "100%",
                            margin: "0.5rem 1rem 0.5rem 1rem",
                          }}
                          startIcon={<Delete />}
                          onClick={() =>
                            handleEliminarColaboracion(colaboraciones)
                          }
                          color="primary"
                          disabled={loading}
                          size="small"
                        >
                          Eliminar
                        </Button>
                      )}

                      {isReported && (
                        <Button
                          fullWidth
                          sx={{
                            width: "100%",
                            margin: "0.5rem 1rem 0.5rem 1rem",
                          }}
                          variant="contained"
                          startIcon={<Article />}
                          onClick={() => handleVerReporte(colaboraciones)}
                          color="primary"
                          disabled={loading}
                          size="small"
                        >
                          Reporte
                        </Button>
                      )}

                      {oneUserNeedsToUpdateSatisfaction && (
                        <Button
                          sx={{
                            width: "100%",
                            margin: "0.5rem 1rem 0.5rem 1rem",
                          }}
                          fullWidth
                          variant="contained"
                          startIcon={<Feedback />}
                          onClick={() =>
                            handleFinalizarColaboracion(colaboraciones)
                          }
                          color="primary"
                          disabled={loading}
                          size="small"
                        >
                          Feedback
                        </Button>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        )}
      </Box>
      {openModalColaboracion && (
        <ModalColaboracionesFormMobile
          colaboracionEditing={colaboracionSelected}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          isEditing={modeEdit}
          personas={socios.filter(
            (s) =>
              s.type === Constants.MEMBER_TYPE_PERSONA &&
              s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
          )}
          empresas={socios.filter(
            (s) =>
              s.type === Constants.MEMBER_TYPE_EMPRESA &&
              s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
          )}
          principios={targetPrincipios}
          compromisos={compromisos}
          categoriasColaboracion={categoriasColaboracion}
          setLoading={setLoading}
        />
      )}
      {openModalIniciar && (
        <ModalIniciarColaboracionMobile
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalIniciar}
          handleClose={handleCloseModalIniciar}
          setLoading={setLoading}
        />
      )}

      {openModalTrabajar && (
        <ModalTrabajarColaboracionMobile
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalTrabajar}
          handleClose={handleCloseModalTrabajar}
          setLoading={setLoading}
          comentarios={comentariosColaboracion}
        />
      )}

      {openModalFinalizar && (
        <ModalFinalizarColaboracionMobile
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalFinalizar}
          handleClose={handleCloseModalFinalizar}
          setLoading={setLoading}
          comentarios={comentariosColaboracion}
        />
      )}

      {openModalReporte && (
        <ModalReporteColaboracionMobile
          colaboracion={colaboracionSelected}
          open={openModalReporte}
          handleClose={handleCloseModalReporte}
        />
      )}
    </Box>
  );
};
