import React, { useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CancelButton } from "../ui/CustomButtons";
import { WarningAmber } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex", flexDirection: "column"
};

export const ModalConfirmacion = ({
  open,
  titulo,
  mensaje,
  handleConfirm,
  handleCancel,
  disableConfirmarButton,
}) => {
  const validationSchema = Yup.object({
  });

  const initialData = {
  };

  const form = useFormik({
    initialValues: initialData,
    onSubmit: (values, { resetForm }) => {

      handleConfirm();
      //resetForm();
    },
  });

  const {
    handleSubmit,
    resetForm,
  } = form;

  return (
    <Modal
    open={open}
    onClose={() => {
      resetForm();
      handleCancel();
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box sx={style} component="form" onSubmit={handleSubmit}>
     
      <Box>
      <Typography id="modal-modal-title" variant="h6" component="h2" >
        {titulo}
      </Typography>
      </Box>
      <Box>
      <Typography variant="subtitle1" mt={2}>
        {mensaje}
      </Typography>

      </Box>
  
      <Box
       mt={2}
       sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={disableConfirmarButton}
        >
          Confirmar
        </Button>
  
        <CancelButton
          label="Cancelar"
          onClick={() => {
            handleCancel();
          }}
        />
      </Box>
    </Box>
  </Modal>
  
  
  );
};
