import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, useMediaQuery } from "@mui/material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { DataGrid, esES } from "@mui/x-data-grid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { ModalDetallePersonaForm } from "../../components/socios/ModalDetallePersonaForm";
import {
  Search,
  ZoomIn,
  AccessTime,
  Delete,
  NotInterested,
  Restore,
} from "@mui/icons-material";
import { ModalRolesSocioForm } from "./ModalRolesSocioForm";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { ModalDetalleEmpresaForm } from "../../components/socios/ModalDetalleEmpresaForm";
import moment from "moment/moment";
import Tooltip from "@mui/material/Tooltip";
import { useNotification } from "../../hooks/useNotification";

import { ModalHorasSocioForm } from "./ModalHorasSocioForm";
import { ModalConfirmacion } from "../utiles/ModalConfirmacion";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { GridActionItem } from "../ui/GridActionItem";
import SociosContext from "../../context/socios/SociosContext";
import SociosFilterMobile from "./SociosFilterMobile";

export const SociosTable = ({
  socios,
  isLoading,
  setLoading,
  getSocios,
  isReadOnly,
  eliminarSocio,
  habilitacionSocio,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroCategoria, setFiltroCategoria] = useState(
    Constants.CATEGORY_ANY
  );
  const [filtroEstado, setFiltroEstado] = useState(Constants.MEMBER_STATUS_ANY);
  const [filtroTipo, setFiltroTipo] = useState(
    Constants.MEMBER_TYPE_SIN_REGISTRO
  );
  const [roles, setRoles] = useState([]);
  const [socioSeleccionado, setSocioSeleccionado] = useState(null);
  const [openModalRolesSocio, setOpenModalRolesSocio] = useState(false);
  const [personaSeleccionada, setPersonaSeleccionada] = useState(null);
  const [openModalDetallePersona, setOpenModalDetallePersona] = useState(false);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null);
  const [openModalDetalleEmpresa, setOpenModalDetalleEmpresa] = useState(false);
  const [openModalPagosSocio, setOpenModalPagosSocio] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [sortedSocios, setSortedSocios] = useState([]);

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();
  const { showSpinner, closeSpinner } = useContext(SpinnerContext);
  const { cleanCompromisosSocio } = useContext(SociosContext);

  useEffect(() => {
    if (Web3Manager.isAccountConnected()) {
      getRoles();
      getSocios(
        Constants.MEMBER_TYPE_SIN_REGISTRO,
        Constants.CATEGORY_ANY,
        "",
        Constants.MEMBER_STATUS_ANY
      );
    }
  }, []);

  useEffect(() => {
    setSortedSocios([
      ...socios.sort((a, b) => {
        const nameA = a.name ? a.name : a.businessName;
        const nameB = b.name ? b.name : b.businessName;
        return nameA.localeCompare(nameB);
      }),
    ]);
  }, [socios]);

  const getRoles = () => {
    Web3Manager.CONTRACT_ACCESS_CONTROL.getAvailableRoles()
      .then((response) => {
        setRoles(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDetalleSocio = async (socio) => {
    if (socio.type === Constants.MEMBER_TYPE_PERSONA) {
      setPersonaSeleccionada(socio);
      setOpenModalDetallePersona(true);
    } else if (socio.type === Constants.MEMBER_TYPE_EMPRESA) {
      setEmpresaSeleccionada(socio);
      setOpenModalDetalleEmpresa(true);
    }
  };

  const handleCloseModalDetallePersona = async () => {
    setOpenModalDetallePersona(false);
    setPersonaSeleccionada(null);
    await cleanCompromisosSocio();
  };

  const handleCloseModalDetalleEmpresa = async () => {
    setOpenModalDetalleEmpresa(false);
    setEmpresaSeleccionada(null);
  };

  const handleOpenModalRolesSocio = (socio) => {
    setOpenModalRolesSocio(true);
    setSocioSeleccionado(socio);
  };

  const handleCloseModalRolesSocio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getSocios(
              Constants.MEMBER_TYPE_SIN_REGISTRO,
              Constants.CATEGORY_ANY,
              "",
              Constants.MEMBER_STATUS_ANY
            );
            closeSpinner();
          })
        );
      } else {
        handleOpenNotificacionError(
          Web3Manager.processErrorResponse(txResponse)
        );
        closeSpinner();
        return;
      }
    }

    setOpenModalRolesSocio(false);
    setSocioSeleccionado(null);
  };

  const handleOpenModalPagosSocio = (socio) => {
    setSocioSeleccionado(socio);
    setOpenModalPagosSocio(true);
  };

  const handleCloseModalPagosSocio = (error) => {
    if (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    }

    setOpenModalPagosSocio(false);
    setSocioSeleccionado(null);
  };

  const handleConfirmarEliminarSocio = (socioSelected) => {
    setOpenConfirmModal(true);
    setSocioSeleccionado(socioSelected);
  };

  const handleCerrarConfirmarEliminarSocio = () => {
    setOpenConfirmModal(false);
    setSocioSeleccionado(null);
  };

  const handleEliminarSocio = () => {
    eliminarSocio(socioSeleccionado.type, socioSeleccionado.code);
    handleCerrarConfirmarEliminarSocio();
  };

  const handleHabilitacionSocio = (socioSelected, habilitar) => {
    habilitacionSocio(socioSelected.type, socioSelected.code, habilitar);
  };

  let columnsSocios = [
    {
      field: "name",
      headerName: "Nombre completo",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.type === Constants.MEMBER_TYPE_PERSONA) {
          return params.row.name;
        } else {
          return params.row.businessName;
        }
      },
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        if (params.value === Constants.MEMBER_TYPE_PERSONA) {
          return "Persona";
        } else if (params.value === Constants.MEMBER_TYPE_EMPRESA) {
          return "Empresa";
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Fecha registro",
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format("DD-MM-YYYY");
        } else {
          return "N/A";
        }
      },
    },

    {
      field: "category",
      headerName: "Categoría",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return Constants.getNombreCategoria(params.value);
      },
    },

    {
      field: "roles",
      headerName: "Roles",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value && params.value.length > 0) {
          const roleNames = [];

          params.value.forEach((roleCode) => {
            const roleFound = roles.find((r) => r.code === roleCode);
            if (roleFound) {
              roleNames.push(roleFound.name);
            }
          });

          return roleNames.join(",");
        } else {
          return "";
        }
      },
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: isReadOnly ? 0.5 : 1.5,
      minWidth: isReadOnly ? 100 : 200,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={isLoading}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetalleSocio(params.row)}
            />
            {params.row?.category === Constants.CATEGORY_ACTIVO &&
              params.row?.status == Constants.MEMBER_STATUS_WALLETASSIGNED && (
                <GridActionItem
                  disabled={isLoading}
                  icon={<AccessTime />}
                  label="Horas"
                  onClick={() => handleOpenModalPagosSocio(params.row)}
                />
              )}
            {!isReadOnly &&
            params.row?.category == Constants.CATEGORY_ACTIVO &&
            params.row?.status == Constants.MEMBER_STATUS_WALLETASSIGNED ? (
              <GridActionItem
                disabled={isLoading}
                icon={<ManageAccountsIcon />}
                label="Roles"
                onClick={() => handleOpenModalRolesSocio(params.row)}
              />
            ) : (
              <></>
            )}

            {!isReadOnly &&
            params.row?.status != Constants.MEMBER_STATUS_DISABLED ? (
              <GridActionItem
                disabled={isLoading}
                icon={<NotInterested />}
                label="Deshabilitar"
                onClick={() => handleHabilitacionSocio(params.row, false)}
              />
            ) : (
              <></>
            )}

            {!isReadOnly &&
            params.row?.status == Constants.MEMBER_STATUS_DISABLED ? (
              <GridActionItem
                disabled={isLoading}
                icon={<Restore />}
                label="Habilitar"
                onClick={() => handleHabilitacionSocio(params.row, true)}
              />
            ) : (
              <></>
            )}

            {!isReadOnly && params.row?.isRemovable ? (
              <GridActionItem
                disabled={isLoading}
                icon={<Delete />}
                label="Eliminar"
                onClick={() => handleConfirmarEliminarSocio(params.row)}
              />
            ) : (
              <></>
            )}
          </Stack>
        );
      },
    },
  ];

  if (!isReadOnly) {
    columnsSocios.splice(0, 0, {
      field: "memberAddress",
      headerName: "Dirección",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="csutable-cell-trucate">
            {params.value
              ? params.value.substring(0, 6) +
                "..." +
                params.value.substring(
                  params.value.length - 4,
                  params.value.length
                )
              : "Sin asignar"}
          </span>
        </Tooltip>
      ),
    });
    columnsSocios.splice(4, 0, {
      field: "status",
      headerName: "Estado",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return Constants.getNombreEstado(params.value);
      },
    });
  }

  const index = 2;
  const newColumn = {
    field: "memberId",
    headerName: "Id socio",
    flex: 1,
    minWidth: 150,
  };

  if (!isReadOnly) {
    columnsSocios = [
      ...columnsSocios.slice(0, index),
      newColumn,
      ...columnsSocios.slice(index),
    ];
  }

  return (
    <>
      <Grid container>
        {matches ? (
          <SociosFilterMobile
            getSocios={getSocios}
            isLoading={isLoading}
            setLoading={setLoading}
            isReadOnly={true}
          />
        ) : (
          <Grid item lg={12}>
            <Box
              mb={2.5}
              mt={1}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            >
              <TextField
                sx={{ width: 300 }}
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                size="small"
                value={filtroNombre}
                onChange={(newValue) => setFiltroNombre(newValue.target.value)}
              />

              <TextField
                sx={{ marginLeft: 1, width: 150 }}
                id="outlined-basic"
                label="Estado"
                variant="outlined"
                size="small"
                value={filtroEstado}
                select={true}
                defaultValue={Constants.MEMBER_STATUS_ANY}
                onChange={(newValue) => setFiltroEstado(newValue.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem
                  key={"_status_" + Constants.MEMBER_STATUS_ANY}
                  value={Constants.MEMBER_STATUS_ANY}
                >
                  Todos
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.MEMBER_STATUS_NOWALLET}
                  value={Constants.MEMBER_STATUS_NOWALLET}
                >
                  Sin billetera
                </MenuItem>
                <MenuItem
                  key={"_status_" + Constants.MEMBER_STATUS_WALLETASSIGNED}
                  value={Constants.MEMBER_STATUS_WALLETASSIGNED}
                >
                  Billetera asignada
                </MenuItem>
                {!isReadOnly && (
                  <MenuItem
                    key={"_status_" + Constants.MEMBER_STATUS_DISABLED}
                    value={Constants.MEMBER_STATUS_DISABLED}
                  >
                    Deshabilitado
                  </MenuItem>
                )}
              </TextField>

              <TextField
                sx={{ marginLeft: 1, width: 150 }}
                id="outlined-basic"
                label="Categoría"
                variant="outlined"
                size="small"
                value={filtroCategoria}
                select={true}
                defaultValue={Constants.CATEGORY_ANY}
                onChange={(newValue) =>
                  setFiltroCategoria(newValue.target.value)
                }
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem key={"_status_todos"} value={Constants.CATEGORY_ANY}>
                  {Constants.getNombreCategoria(Constants.CATEGORY_ANY)}
                </MenuItem>
                <MenuItem
                  key={"_status_suscriptor"}
                  value={Constants.CATEGORY_SUSCRIPTOR}
                >
                  {Constants.getNombreCategoria(Constants.CATEGORY_SUSCRIPTOR)}
                </MenuItem>
                <MenuItem
                  key={"_status_activo"}
                  value={Constants.CATEGORY_ACTIVO}
                >
                  {Constants.getNombreCategoria(Constants.CATEGORY_ACTIVO)}
                </MenuItem>
              </TextField>

              <TextField
                sx={{ marginLeft: 1, width: 150 }}
                id="outlined-basic"
                label="Tipo"
                variant="outlined"
                size="small"
                value={filtroTipo}
                select={true}
                defaultValue={Constants.MEMBER_TYPE_SIN_REGISTRO}
                onChange={(newValue) => setFiltroTipo(newValue.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem
                  key={"_tipo_todos"}
                  value={Constants.MEMBER_TYPE_SIN_REGISTRO}
                >
                  Todos
                </MenuItem>
                <MenuItem
                  key={"_tipo_persona"}
                  value={Constants.MEMBER_TYPE_PERSONA}
                >
                  Persona
                </MenuItem>
                <MenuItem
                  key={"_tipo_empresa"}
                  value={Constants.MEMBER_TYPE_EMPRESA}
                >
                  Empresa
                </MenuItem>
              </TextField>

              <Button
                sx={{ marginLeft: 1 }}
                variant="contained"
                color="primary"
                onClick={() =>
                  getSocios(
                    filtroTipo,
                    filtroCategoria,
                    filtroNombre,
                    filtroEstado
                  )
                }
                disabled={isLoading}
                endIcon={<Search />}
              >
                Buscar
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>

      <DataGrid
        rows={
          !isReadOnly
            ? sortedSocios
            : sortedSocios.filter(
                (socio) => socio.status != Constants.MEMBER_STATUS_DISABLED
              )
        }
        sx={{ mt: matches && 2 }}
        pageSize={50}
        autoHeight={true}
        loading={isLoading && !showSpinner}
        columns={columnsSocios}
        getRowId={(row) => row.memberId}
        rowsPerPageOptions={[50]}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      />

      <br />

      {socioSeleccionado && (
        <ModalHorasSocioForm
          open={openModalPagosSocio}
          handleClose={handleCloseModalPagosSocio}
          socio={socioSeleccionado}
        />
      )}

      {openModalRolesSocio && (
        <ModalRolesSocioForm
          open={openModalRolesSocio}
          handleClose={handleCloseModalRolesSocio}
          socio={socioSeleccionado}
          roles={roles}
          setLoading={setLoading}
        />
      )}

      {openModalDetallePersona && (
        <ModalDetallePersonaForm
          open={openModalDetallePersona}
          handleClose={handleCloseModalDetallePersona}
          persona={personaSeleccionada}
          roles={roles}
        />
      )}

      {openModalDetalleEmpresa && (
        <ModalDetalleEmpresaForm
          open={openModalDetalleEmpresa}
          handleClose={handleCloseModalDetalleEmpresa}
          empresa={empresaSeleccionada}
        />
      )}

      {openConfirmModal && (
        <ModalConfirmacion
          open={openConfirmModal}
          titulo="¿Está seguro que desea eliminar el socio?"
          mensaje="Al confirmar se eliminará el socio, esta acción no puede ser revertida."
          handleConfirm={handleEliminarSocio}
          handleCancel={handleCerrarConfirmarEliminarSocio}
          disableConfirmarButton={false}
        />
      )}
    </>
  );
};
