import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomTextEditor = ({
  value,
  handleChange,
  name,
  placeholder,
  handleBlur,
}) => {
  const onBlur = () => {
    handleBlur({
      target: {
        name,
      },
    });
  };
  const editorRef = useRef(null);
  const editorContainerStyle = {
    borderRadius: "4px",
    border: "1px solid #ddd",
    overflow: "hidden",
  };

  useEffect(() => {
    if (editorRef.current) {
      const qlContainer = editorRef.current.querySelector(".ql-container");
      if (qlContainer) {
        qlContainer.style.height = "190px";
        qlContainer.style.overflowY = "auto";
      }
    }
  }, []);

  return (
    <div ref={editorRef} style={editorContainerStyle}>
      <ReactQuill
        value={value}
        onChange={(content) => {
          handleChange({
            target: { name: name, value: content },
          });
        }}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
          ],
        }}
        onBlur={onBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomTextEditor;
