import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useFormik } from "formik";

import { Delete } from "@mui/icons-material";

import Linkify from "linkify-react";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalEliminarCompromiso = ({
  open,
  handleClose,
  compromiso,
  eliminarCompromiso,
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const { openSpinner, closeSpinner} = useContext(SpinnerContext);


  const formData = {
    _code: compromiso?.code,
  };

  const formEliminarCompromiso = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setDisableButton(true);
      openSpinner();
      eliminarCompromiso(compromiso?.code)
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const { handleSubmit, values } = formEliminarCompromiso;

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style} component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="h2">
                {"Compromiso " + compromiso?.title}
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box
                  mt={1}
                  sx={{
                    maxHeight: "6em",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1">
                    <Linkify>{compromiso?.description}</Linkify>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Alert
              severity="warning"
              sx={{
                fontSize: "12px",
              }}
            >
              <AlertTitle>
                ¿Estás seguro que deseas eliminar el compromiso?
              </AlertTitle>
            </Alert>
          </Grid>

          <Grid item>
            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={disableButton}
                endIcon={<Delete />}
              >
                Eliminar
              </Button>
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleClose(true, null, false);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
