import React, { useReducer } from "react";
import * as Web3Manager from "../../support/Web3Manager";
import { useNotification } from "../../hooks/useNotification";
import CompromisosContext from "./CompromisosContext";
import CompromisosReducer from "./CompromisosReducer";
import {
  getCommitments,
  getCommunities,
  getAssignedCommitments,
} from "../../services/CommitmentsServices";

const CompromisosState = (props) => {
  const initialState = {
    compromisos: [],
    compromiso: {},
    isLoading: false,
  };

  const [state, dispatch] = useReducer(CompromisosReducer, initialState);
  const { compromisos, compromiso, isLoading } = state;

  const { handleOpenNotificacionError } = useNotification();

  const getCompromisos = async (
    _filtroCodigo,
    _filtroTitulo,
    _community,
    _appliesTo,
    _dimensions,
    _sdgs
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const result = [];

      const compromisos = await getCommitments(
        _filtroCodigo,
        _filtroTitulo,
        _community,
        _appliesTo,
        _dimensions,
        _sdgs
      );

      if (compromisos && compromisos.length > 0) {
        compromisos.forEach((elemento) => {
          result.push({
            code: elemento.code,
            title: elemento.title,
            description: elemento.description,
            enabled: elemento.enabled,
            community: elemento.community,
            appliesToPersons: elemento.appliesToPersons,
            appliesToCompanies: elemento.appliesToCompanies,
            appliesToServices: elemento.appliesToServices,
            dimensionE: elemento.dimensionE,
            dimensionS: elemento.dimensionS,
            dimensionG: elemento.dimensionG,
            sustainableDevelopmentGoals: elemento.sustainableDevelopmentGoals,
          });
        });
      }
      dispatch({
        type: "SET_COMPROMISOS",
        payload: { compromisos: result },
      });
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const getCompromisosAsignados = async (
    _filtroCodigo,
    _filtroTitulo,
    _community,
    _appliesTo,
    _dimensions,
    _sdgs
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const result = [];

      const compromisos = await getAssignedCommitments(
        _filtroCodigo,
        _filtroTitulo,
        _community,
        _appliesTo,
        _dimensions,
        _sdgs
      );

      if (compromisos && compromisos.length > 0) {
        compromisos.forEach((elemento) => {
          result.push({
            code: elemento.code,
            title: elemento.title,
            description: elemento.description,
            enabled: elemento.enabled,
            community: elemento.community,
            appliesToPersons: elemento.appliesToPersons,
            appliesToCompanies: elemento.appliesToCompanies,
            appliesToServices: elemento.appliesToServices,
            dimensionE: elemento.dimensionE,
            dimensionS: elemento.dimensionS,
            dimensionG: elemento.dimensionG,
            sustainableDevelopmentGoals: elemento.sustainableDevelopmentGoals,
            hasAssignees: elemento.hasAssignees,
          });
        });
      }

      dispatch({
        type: "SET_COMPROMISOS",
        payload: { compromisos: result },
      });
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const getComunidades = async () => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const communities = await getCommunities();
      return communities;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const getCompromisosEnabled = async () => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const result = [];

      const compromisos = await getCommitments(0, "", "");

      if (compromisos && compromisos.length > 0) {
        compromisos.forEach((elemento) => {
          if (elemento.enabled) {
            result.push({
              code: elemento.code,
              title: elemento.title,
              description: elemento.description,
              enabled: elemento.enabled,
              community: elemento.community,
              appliesToPersons: elemento.appliesToPersons,
              appliesToCompanies: elemento.appliesToCompanies,
              appliesToServices: elemento.appliesToServices,
              dimensionE: elemento.dimensionE,
              dimensionS: elemento.dimensionS,
              dimensionG: elemento.dimensionG,
              sustainableDevelopmentGoals: elemento.sustainableDevelopmentGoals,
            });
          }
        });
      }
      dispatch({
        type: "SET_COMPROMISOS",
        payload: { compromisos: result },
      });
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const cambiarHabilitacionCompromiso = async (habilitar, code) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      let response;
      if (habilitar) {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMMITMENTS,
          "enableCommitment",
          code
        );
      } else {
        response = await Web3Manager.callContract(
          Web3Manager.CONTRACT_COMMITMENTS,
          "disableCommitment",
          code
        );
      }

      return response;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const eliminarCompromiso = async (code) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const response = await Web3Manager.callContract(
        Web3Manager.CONTRACT_COMMITMENTS,
        "deleteCommitment",
        code
      );

      return response;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const crearCompromiso = async (
    title,
    description,
    community,
    enabled,
    appliesTo,
    ESG,
    sustainableDevelopmentGoals
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const response = await Web3Manager.callContract(
        Web3Manager.CONTRACT_COMMITMENTS,
        "addCommitment",
        title,
        description,
        community,
        enabled,
        appliesTo,
        ESG,
        sustainableDevelopmentGoals
      );

      return response;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const modificarCompromiso = async (
    code,
    title,
    description,
    community,
    enabled,
    appliesTo,
    ESG,
    sustainableDevelopmentGoals
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const response = await Web3Manager.callContract(
        Web3Manager.CONTRACT_COMMITMENTS,
        "modifyCommitment",
        code,
        title,
        description,
        community,
        enabled,
        appliesTo,
        ESG,
        sustainableDevelopmentGoals
      );

      return response;
    } catch (error) {
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const cleanCompromisos = () => {
    dispatch({
      type: "SET_COMPROMISOS",
      payload: { compromisos: [] },
    });
  };

  return (
    <CompromisosContext.Provider
      value={{
        compromisos,
        compromiso,
        getCompromisos,
        getComunidades,
        cambiarHabilitacionCompromiso,
        eliminarCompromiso,
        modificarCompromiso,
        crearCompromiso,
        getCompromisosEnabled,
        getCompromisosAsignados,
        isLoading,
        cleanCompromisos,
      }}
    >
      {props.children}
    </CompromisosContext.Provider>
  );
};
export default CompromisosState;
