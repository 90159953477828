import {Box, Button, Checkbox, FormControlLabel, Modal, Typography} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import React from 'react';
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import MenuItem from "@mui/material/MenuItem";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

export const ModalTexto = ({ open, handleClose, titulo, texto }) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={style}>
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                    {titulo}
                </Typography>

                <Box mt={2}>
                    <TextField
                        id='outlined-basic'
                        label='Información'
                        variant='outlined'
                        size='small'
                        fullWidth={true}
                        multiline={true}
                        rows={6}
                        disabled={true}
                        value={texto}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
