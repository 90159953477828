import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Chip,
  TextField,
  ListItem,
  ListItemAvatar,
  Avatar,
  Alert,
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  CheckCircle,
  AssignmentLate,
  AssignmentTurnedIn,
  PendingActions,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";
import * as Yup from "yup";
import moment from "moment/moment";
import ComentariosList from "./ComentariosList";
import IndicadorSatisfaccion from "./IndicadorSatisfaccion";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { CancelButton } from "../ui/CustomButtons";
import { useFormik } from "formik";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalTrabajarColaboracionMobile = ({
  open,
  handleClose,
  colaboracion,
  personas,
  empresas,
  setLoading,
  comentarios,
}) => {
  const { openSpinner } = useContext(SpinnerContext);
  const [disableButton, setDisableButton] = useState(false);
  const [nombreAplicacion, setNombreAplicacion] = useState("");
  const [statusCompromiso, setStatusCompromiso] = useState("");
  const [deadlineCompromiso, setDeadlineCompromiso] = useState("");

  const isWorkable =
    colaboracion.status === Constants.COLLABORATION_STATUS_ACTIVE &&
    (colaboracion.sourceMemberAddress.toLowerCase() ===
      Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() ||
      colaboracion.targetMemberAddress.toLowerCase() ===
        Web3Manager.MEMBER_DATA.memberAddress.toLowerCase());

  const isUserTurn =
    (colaboracion.sourceMemberAddress.toLowerCase() ===
      Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() &&
      colaboracion.turn === 1) ||
    (colaboracion.targetMemberAddress.toLowerCase() ===
      Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() &&
      colaboracion.turn === 2);

  const formData = {
    _code: colaboracion.code,
    _comment: "",
  };

  const validationSchema = Yup.object({
    _comment: Yup.string().required("No puedes enviar un comentario vacío"),
  });

  const formComentario = useFormik({
    initialValues: formData,
    validateOnChange: true,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setDisableButton(true);
      openSpinner();
      const { _code, _comment } = values;
      Web3Manager.callContract(
        Web3Manager.CONTRACT_COLLABORATIONS,
        "addCollaborationComment",
        _code,
        Web3Manager.MEMBER_DATA.memberCode,
        Web3Manager.MEMBER_DATA.memberType,
        _comment
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          setLoading(false);
          handleClose(false, error, false);
        });
    },
  });

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    resetForm,
  } = formComentario;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getChipInfo = (status) => {
    let icon;
    let label;
    if (status === "Cumple") {
      icon = <AssignmentTurnedIn style={{ color: "green" }} />;
      label = "Cumple";
    } else if (status === "Tiene intención") {
      icon = <PendingActions style={{ color: "orange" }} />;
      label = "Tiene intención";
    } else if (status === "No adhiere") {
      icon = <AssignmentLate style={{ color: "red" }} />;
      label = "Ya no adhiere";
    } else {
      icon = null;
      label = "Desconocido";
    }

    return (
      <Chip
        sx={{ p: 2, ml: 1 }}
        icon={icon}
        label={label}
        variant="outlined"
        size="small"
      />
    );
  };

  const getDeadlineDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  useEffect(() => {
    const fetchData = async () => {
      const socio =
        colaboracion.targetMemberType == Constants.MEMBER_TYPE_PERSONA
          ? await personas.find(
              (p) =>
                p.memberAddress?.toLowerCase() ==
                colaboracion.targetMemberAddress?.toLowerCase()
            )
          : await empresas.find(
              (e) =>
                e.memberAddress?.toLowerCase() ==
                colaboracion.targetMemberAddress?.toLowerCase()
            );

      let adhesion;
      if (colaboracion.applicationId && colaboracion.applicationId !== 0) {
        const aplicacion = await socio.services.find(
          (s) => s.code === colaboracion.applicationId
        );
        adhesion = await aplicacion.commitments.find(
          (c) => c.commitmentCode === colaboracion.collaborationTypeCode
        );
        setNombreAplicacion(aplicacion.name);
      } else {
        adhesion = await socio.commitments.find(
          (c) => c.commitmentCode === colaboracion.collaborationTypeCode
        );
      }

      if (adhesion) {
        setStatusCompromiso(
          Constants.obtenerNombreEstadoCompromiso(adhesion.status)
        );
        if (adhesion.status === Constants.HAS_INTENTION) {
          setDeadlineCompromiso(adhesion.deadlineDate);
        }
      } else {
        setStatusCompromiso("No adhiere");
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={() => handleClose(true, null, false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Colaboración
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box container component="form" onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Chip
                label={Constants.getNombreEstadoColaboracion(
                  colaboracion.status
                )}
                sx={{
                  backgroundColor:
                    Constants.COLLABORATION_STATUS_COLORS[colaboracion.status],
                }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box container direction="column">
              <Box
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>Compromiso: </strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {colaboracion.tipoDeColaboracionTitulo}
                  </Typography>
                </Box>
              </Box>

              <Box
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  mt: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography variant="subtitle1">
                    <strong>{"Emisor: "}</strong>
                  </Typography>
                  <Typography variant="subtitle1">
                    {colaboracion.sourceMemberName}
                    <Chip
                      label={
                        colaboracion.sourceMemberType ===
                        Constants.MEMBER_TYPE_PERSONA
                          ? `PERSONA`
                          : `EMPRESA`
                      }
                      sx={{
                        marginLeft: "0.5rem",
                        width: "100px",
                        backgroundColor:
                          Constants.MEMBER_TYPE_COLORS[
                            colaboracion.sourceMemberType
                          ],
                      }}
                    />
                  </Typography>
                </Box>
                <IndicadorSatisfaccion
                  colaboracion={colaboracion}
                  isSource={true}
                />
              </Box>

              <Box
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1.5,
                  flexWrap: "wrap",
                }}
              >
                <Typography variant="subtitle1">
                  <strong>{"Receptor: "}</strong>
                </Typography>
                &nbsp;
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-all", width: "100%" }}
                >
                  {colaboracion.targetMemberName}
                  <Chip
                    label={
                      colaboracion.targetMemberType ===
                      Constants.MEMBER_TYPE_PERSONA
                        ? `PERSONA`
                        : `EMPRESA`
                    }
                    sx={{
                      marginLeft: "0.5rem",
                      backgroundColor:
                        Constants.MEMBER_TYPE_COLORS[
                          colaboracion.targetMemberType
                        ],
                    }}
                  />
                </Typography>
                <IndicadorSatisfaccion
                  colaboracion={colaboracion}
                  isSource={false}
                />
              </Box>

              {nombreAplicacion && (
                <Box
                  item
                  sx={{ display: "flex", alignItems: "center", mt: 1.5 }}
                >
                  <Typography variant="subtitle1">
                    <strong>Aplicación:</strong>
                  </Typography>
                  &nbsp;
                  <Typography variant="subtitle1">
                    {nombreAplicacion}
                  </Typography>
                </Box>
              )}

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>Adhesión:</strong>
                  {getChipInfo(statusCompromiso)}
                  <br />
                </Typography>
              </Box>

              {deadlineCompromiso && (
                <Box
                  item
                  sx={{ display: "flex", alignItems: "center", mt: 1.5 }}
                >
                  <Typography variant="subtitle1">
                    <strong>Fecha Límite: </strong>
                    {getDeadlineDate(deadlineCompromiso)}
                    <br />
                  </Typography>
                </Box>
              )}

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>{"Fecha de creación: "}</strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(colaboracion.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>Categoría: </strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {colaboracion.category}
                </Typography>
              </Box>

              <Box mt={1.5}>
                <Typography variant="subtitle1">
                  <strong>{"Descripción: "}</strong>
                </Typography>
                <Box mt={1}>
                  <Typography variant="subtitle1">
                    {colaboracion ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: colaboracion.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="subtitle1">
                <strong>Comentarios:</strong>
              </Typography>
              {comentarios.length > 0 ? (
                <>
                  <ComentariosList comentarios={comentarios} />
                </>
              ) : colaboracion.status ===
                  Constants.COLLABORATION_STATUS_FINALIZED ||
                colaboracion.status ===
                  Constants.COLLABORATION_STATUS_REPORTED ? (
                <Typography variant="subtitle1">
                  Finalizada sin comentarios
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  Aún no hay comentarios
                </Typography>
              )}
            </Box>
          </Grid>

          {isWorkable && isUserTurn && (
            <Grid item>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      backgroundColor:
                        Constants.MEMBER_TYPE_COLORS[
                          Web3Manager.MEMBER_DATA.memberType
                        ],
                    }}
                  >
                    {Constants.obtenerSiglas(
                      Web3Manager.MEMBER_DATA.memberName
                    )}
                  </Avatar>
                </ListItemAvatar>
                <TextField
                  name="_comment"
                  id="outlined-basic"
                  label="Escribe un comentario"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values._comment}
                  error={Boolean(touched._comment && errors._comment)}
                  helperText={touched._comment && errors._comment}
                />
              </ListItem>
            </Grid>
          )}

          {isWorkable && !isUserTurn && (
            <Grid item>
              <Alert
                severity="info"
                sx={{
                  fontSize: "12px",
                  mt: 1,
                }}
              >
                Debes esperar respuesta para poder comentar
              </Alert>
            </Grid>
          )}

          <Grid
            item
            sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
          >
            {isWorkable && isUserTurn && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={disableButton}
                endIcon={<CheckCircle />}
              >
                Enviar
              </Button>
            )}

            <CancelButton
              label={isWorkable ? "Cancelar" : "Cerrar"}
              onClick={() => {
                handleClose(true, null, false);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
