import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Constants from "../../support/Constants";
import moment from "moment";
import SociosContext from "../../context/socios/SociosContext";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { CancelButton } from "../ui/CustomButtons";
import { SeccionCompromisosAdheridosMobile } from "../compromisos/SeccionCompromisosAdheridosMobile";
import * as Common from "../../support/Common";
import { MEMBER_DATA } from "../../support/Web3Manager";
import ServiciosContext from "../../context/servicios/ServiciosContext";
import { ExpandMore } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DetalleEmpresaFormMobile = ({ open, handleClose, empresa }) => {
  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const {
    servicios,
    getServiciosEmpresa,
    cleanServicios,
    isLoading: loadingServicios,
  } = useContext(ServiciosContext);

  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);

  useEffect(() => {
    if (empresa && empresa.code) {
      getCompromisosEnabled();
      getServiciosEmpresa(empresa.id);
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa) {
      const compromisosSeleccionadosList = compromisos?.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return empresa.commitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      });

      const newListWithDates = empresa.commitments?.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate).format("YYYY-MM-DD"), // Convertimos el campo a unix
          };
        } else {
          return item;
        }
      });

      setAssignedCommitments(newListWithDates ? newListWithDates : []);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    }
  }, [empresa, compromisos]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`${empresa?.businessName}`}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 4 }}>
        <Grid container spacing={4}>
          {/* Información de la empresa */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Dirección"
              fullWidth
              disabled
              value={empresa?.memberAddress}
              margin="normal"
              size="small"
            />
            <TextField
              label="Nombre"
              fullWidth
              disabled
              value={empresa?.name}
              margin="normal"
              size="small"
            />
            <TextField
              label="Nombre fantasía"
              fullWidth
              disabled
              value={empresa?.businessName}
              margin="normal"
              size="small"
            />
            <TextField
              label="Correo Electrónico"
              fullWidth
              disabled
              value={empresa?.email}
              margin="normal"
              size="small"
            />
            <TextField
              label="Categoría"
              fullWidth
              disabled
              value={Constants.getNombreCategoria(empresa?.category)}
              margin="normal"
              size="small"
            />
            <TextField
              label="Estado"
              fullWidth
              disabled
              value={Constants.getNombreEstado(empresa?.status)}
              margin="normal"
              size="small"
            />
            <TextField
              label="Fecha de Registro"
              fullWidth
              disabled
              value={moment(empresa?.createdAt).format("DD-MM-YYYY")}
              margin="normal"
              size="small"
            />
            {empresa?.roles && empresa?.roles.length > 0 && (
              <TextField
                label="Roles Asignados"
                fullWidth
                disabled
                value={empresa?.roles
                  .map((roleCode) => Constants.getNombreRol(roleCode))
                  .filter((nombreRol) => nombreRol)
                  .join(", ")}
                margin="normal"
                size="small"
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={() =>
                Common.goForward("/view/socio/ServiciosPage/" + empresa?.id)
              }
            >
              {MEMBER_DATA.memberType === Constants.MEMBER_TYPE_EMPRESA &&
              empresa?.id === MEMBER_DATA.memberCode
                ? "Mis Aplicaciones"
                : "Ver Aplicaciones"}
            </Button>
          </Grid>

          {/* Sección de compromisos adheridos */}
          <Grid item xs={12}>
            {loadingCompromisos ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <SeccionCompromisosAdheridosMobile
                assignedCommitments={assignedCommitments}
                setAssignedCommitments={setAssignedCommitments}
                compromisosSeleccionados={compromisosSeleccionados}
                setCompromisosSeleccionados={setCompromisosSeleccionados}
                isReadOnly={true}
                mostrarTitulo={true}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {"Compromisos adheridos por aplicaciones"}
            </Typography>
            {!servicios?.some((s) => s.commitments?.length > 0) ? (
              <Typography mt={3} variant="body2" component="h2">
                Los servicios de la empresa no se encuentran adheridos a ningún
                compromiso
              </Typography>
            ) : (
              servicios?.map((servicio) => {
                if (servicio.commitments?.length > 0 && servicio.enabled) {
                  return (
                    <Accordion key={servicio.code}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="subtitle1">
                          {servicio.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <SeccionCompromisosAdheridosMobile
                          assignedCommitments={servicio.commitments}
                          compromisosSeleccionados={compromisos?.filter((c) =>
                            servicio.commitments.some(
                              (sc) => sc.commitmentCode == c.code
                            )
                          )}
                          isReadOnly={true}
                          mostrarTitulo={false}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                } else {
                  return <></>;
                }
              })
            )}
          </Grid>
        </Grid>

        <Box
          mt={2}
          sx={{ justifyContent: "right", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
