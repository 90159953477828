import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  useTheme,
  CircularProgress,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { useNotification } from "../../hooks/useNotification";
import {
  Clear,
  ExpandMore,
  Group,
  NotInterested,
  Restore,
  Search,
  ZoomIn,
} from "@mui/icons-material";
import { AplicacionesFilterMobile } from "./AplicacionesFilterMobile";
import { ModalServicioFormMobile } from "../servicios/ModalServicioFormMobile";

export const AplicacionCard = ({
  loadingCompromisos,
  handleDetalleServicio,
  getServiciosEmpresas,
  getTiposServicio,
  getCompromisosEnabled,
  setIsFiltered,
  aplicaciones,
  filtroTipo,
  filtroEmpresa,
  setAplicacionesFiltradas,
  handleAgregarServicio,
  showSpinner,
  setFiltroEmpresa,
  empresasConAplicaciones,
  setFiltroTipo,
  tiposServicio,
  isFiltered,
  aplicacionesFiltradas,
  loadingServicios,
  openModalServicio,
  handleCloseModalServicio,
  aplicacionSeleccionada,
  isReadOnlyModalFormServicio,
  compromisos,
  isLoading,
  filterAplicaciones,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAplicacionesToShow = () => {
    return isFiltered ? aplicacionesFiltradas : aplicaciones;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AplicacionesFilterMobile
        filtroTipo={filtroTipo}
        filtroEmpresa={filtroEmpresa}
        setFiltroTipo={setFiltroTipo}
        setFiltroEmpresa={setFiltroEmpresa}
        isReadOnly={isReadOnlyModalFormServicio}
        handleAgregarServicio={handleAgregarServicio}
        empresasConAplicaciones={empresasConAplicaciones}
        tiposServicio={tiposServicio}
        filterAplicaciones={filterAplicaciones}
      />

      {loadingServicios && !showSpinner ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : getAplicacionesToShow().length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" align="center">
            No hay aplicaciones para mostrar.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {getAplicacionesToShow().map((aplicacion) => (
            <Grid item xs={12} sm={6} md={4} key={aplicacion.id}>
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {aplicacion.name}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    Tipo: {aplicacion.applicationType}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Empresa: {aplicacion.company}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {aplicacion.email}
                  </Typography>
                  {aplicacion.url !== "" && (
                    <Typography variant="body2" color="text.secondary">
                      Url: {aplicacion.url}
                    </Typography>
                  )}
                </CardContent>
                <CardActions sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<ZoomIn />}
                        onClick={() => handleDetalleServicio(aplicacion)}
                        color="primary"
                        disabled={isLoading}
                        size="small"
                      >
                        Detalle
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {openModalServicio && (
        <ModalServicioFormMobile
          open={openModalServicio}
          handleClose={handleCloseModalServicio}
          servicio={aplicacionSeleccionada}
          tiposServicio={tiposServicio}
          isReadOnly={isReadOnlyModalFormServicio}
          compromisosEnabled={compromisos}
        />
      )}
    </Box>
  );
};
