import {
  Add,
  ExpandLess,
  ExpandMore,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { sustainableDevelopmentGoals } from "../../support/sustainableDevelopmentGoals";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";
//import { DividerWithTitle } from "../ui/DividerWithTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalSustainableDevelopmentGoals = ({
  open,
  handleClose,
  handleConfirm,
  selectedDevelopmentGoals,
  setSelectedDevelopmentGoals,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const [page, setPage] = useState(1);
  const goalsPerPage = 3;
  const totalPages = Math.ceil(
    sustainableDevelopmentGoals.length / goalsPerPage
  );
  const startIndex = (page - 1) * goalsPerPage;
  const endIndex = page * goalsPerPage;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (event, goalValue) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedDevelopmentGoals((prevSelectedGoals) => {
        if (!prevSelectedGoals.includes(goalValue)) {
          return [...prevSelectedGoals, goalValue];
        }
        return prevSelectedGoals;
      });
    } else {
      setSelectedDevelopmentGoals((prevSelectedGoals) =>
        prevSelectedGoals.filter((selectedGoal) => selectedGoal !== goalValue)
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid sx={style}>
        <Typography
          gutterBottom
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Selección de ODS
        </Typography>

        {sustainableDevelopmentGoals.slice(startIndex, endIndex).map((goal) => (
          <Accordion key={goal.id}>
            <AccordionSummary
              expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            >
              <Typography>
                {goal.id} - {goal.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {goal.objectiveGoals.map((objective, index) => (
                  <Grid item key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDevelopmentGoals?.includes(
                            objective.value
                          )}
                          onChange={(event) =>
                            handleCheckboxChange(event, objective.value)
                          }
                        />
                      }
                      label={objective.value}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Grid mt={2} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
        <Stack direction="row" spacing={1} mt={2} justifyContent="right">
          <Button
            sx={{ boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)" }}
            variant="contained"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={() => {
              handleClose();
              setSelectedDevelopmentGoals([]);
            }}
          />
        </Stack>
      </Grid>
    </Modal>
  );
};
