import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getCollaborations = async (
  sourceMemberAddress,
  targetMemberAddress,
  title,
  category,
  type,
  status,
  fromDate,
  toDate
) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/collaborations?sourceMemberAddress=${sourceMemberAddress}&targetMemberAddress=${targetMemberAddress}&title=${title}&category=${category}&type=${type}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`,
    });
    const { collaborations } = response.data;

    return response.data.collaborations;
  } catch (error) {
    throw error;
  }
};
