import React from "react";
import { Tooltip } from "@mui/material";
import * as Constants from "../../support/Constants";
import { ThumbDownAlt, ThumbUpAlt } from "@mui/icons-material";

const IndicadorSatisfaccion = ({ colaboracion, isSource }) => {
  const { sourceSatisfaction, targetSatisfaction, status } = colaboracion;

  const isVisible =
    status === Constants.COLLABORATION_STATUS_FINALIZED ||
    status === Constants.COLLABORATION_STATUS_REPORTED;

  if (!isVisible) return <></>;

  return (
    <Tooltip
      title={
        isSource
          ? sourceSatisfaction ===
            Constants.COLLABORATION_SATISFACTION_SATISFIED
            ? "Satisfecho"
            : sourceSatisfaction ===
                Constants.COLLABORATION_SATISFACTION_NOT_SATISFIED &&
              "No satisfecho"
          : targetSatisfaction ===
            Constants.COLLABORATION_SATISFACTION_SATISFIED
          ? "Satisfecho"
          : targetSatisfaction ===
              Constants.COLLABORATION_SATISFACTION_NOT_SATISFIED &&
            "No satisfecho"
      }
    >
      <span>
        {isSource ? (
          sourceSatisfaction ===
          Constants.COLLABORATION_SATISFACTION_SATISFIED ? (
            <ThumbUpAlt sx={{ ml: 1 }} />
          ) : (
            sourceSatisfaction ===
              Constants.COLLABORATION_SATISFACTION_NOT_SATISFIED && (
              <ThumbDownAlt sx={{ ml: 1 }} />
            )
          )
        ) : targetSatisfaction ===
          Constants.COLLABORATION_SATISFACTION_SATISFIED ? (
          <ThumbUpAlt sx={{ ml: 1 }} />
        ) : (
          targetSatisfaction ===
            Constants.COLLABORATION_SATISFACTION_NOT_SATISFIED && (
            <ThumbDownAlt sx={{ ml: 1 }} />
          )
        )}
      </span>
    </Tooltip>
  );
};

export default IndicadorSatisfaccion;
