import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getProposals = async (category, title, status) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/proposals?title=${title}&category=${category}&status=${status}`,
    });

    return response.data.proposals;
  } catch (error) {
    throw error;
  }
};

export const getProposal = async (code) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/proposals/code/${code}`,
    });
    const { proposal } = response.data;

    return response.data.proposal;
  } catch (error) {
    throw error;
  }
};
