const sdgsBaseUrl = "https://www.un.org/sustainabledevelopment";

export const sustainableDevelopmentGoals = [
  {
    id: 1,
    title: "Fin de la pobreza",
    description: "Poner fin a la pobreza en todas sus formas en todo el mundo",
    color: "#E5233D",
    link: `${sdgsBaseUrl}/poverty/#tab-aeeaf2d092abd802960`,
    objectiveGoals: [
      {
        value: "1.1",
        description: "",
      },
      {
        value: "1.2",
        description: "",
      },
      {
        value: "1.3",
        description: "",
      },
      {
        value: "1.4",
        description: "",
      },
      {
        value: "1.5",
        description: "",
      },
      {
        value: "1.a",
        description: "",
      },
      {
        value: "1.b",
        description: "",
      },
    ],
  },
  {
    id: 2,
    title: "Hambre cero",
    description:
      "Poner fin al hambre, lograr la seguridad alimentaria y la mejora de la nutrición y promover la agricultura sostenible",
    color: "#DDA73A",
    link: `${sdgsBaseUrl}/hunger/#tab-6f500e1aa274b23d96d`,
    objectiveGoals: [
      {
        value: "2.1",
        description: "",
      },
      {
        value: "2.2",
        description: "",
      },
      {
        value: "2.3",
        description: "",
      },
      {
        value: "2.4",
        description: "",
      },
      {
        value: "2.5",
        description: "",
      },
      {
        value: "2.a",
        description: "",
      },
      {
        value: "2.b",
        description: "",
      },
      {
        value: "2.c",
        description: "",
      },
    ],
  },
  {
    id: 3,
    title: "Salud y bienestar",
    description:
      "Garantizar una vida sana y promover el bienestar en todas las edades",
    color: "#4CA146",
    link: `${sdgsBaseUrl}/health/#tab-3f22056b0e91266e8b2`,
    objectiveGoals: [
      {
        value: "3.1",
        description: "",
      },
      {
        value: "3.2",
        description: "",
      },
      {
        value: "3.3",
        description: "",
      },
      {
        value: "3.4",
        description: "",
      },
      {
        value: "3.5",
        description: "",
      },
      {
        value: "3.6",
        description: "",
      },
      {
        value: "3.7",
        description: "",
      },
      {
        value: "3.8",
        description: "",
      },
      {
        value: "3.9",
        description: "",
      },
      {
        value: "3.a",
        description: "",
      },
      {
        value: "3.b",
        description: "",
      },
      {
        value: "3.c",
        description: "",
      },
      {
        value: "3.d",
        description: "",
      },
    ],
  },
  {
    id: 4,
    title: "Educación de calidad",
    description:
      "Garantizar una educación inclusiva y equitativa de calidad y promover oportunidades de aprendizaje permanente para todos",
    color: "#C7212F",
    link: `${sdgsBaseUrl}/education/#tab-bec3d6b2e412d024e05`,
    objectiveGoals: [
      {
        value: "4.1",
        description: "",
      },
      {
        value: "4.2",
        description: "",
      },
      {
        value: "4.3",
        description: "",
      },
      {
        value: "4.4",
        description: "",
      },
      {
        value: "4.5",
        description: "",
      },
      {
        value: "4.6",
        description: "",
      },
      {
        value: "4.7",
        description: "",
      },
      {
        value: "4.a",
        description: "",
      },
      {
        value: "4.b",
        description: "",
      },
      {
        value: "4.c",
        description: "",
      },
    ],
  },
  {
    id: 5,
    title: "Igualdad de género",
    description:
      "Lograr la igualdad de género y empoderar a todas las mujeres y niñas",
    color: "#EF402D",
    link: `${sdgsBaseUrl}/gender-equality/#tab-b6328c70eedb23d97b1`,
    objectiveGoals: [
      {
        value: "5.1",
        description: "",
      },
      {
        value: "5.2",
        description: "",
      },
      {
        value: "5.3",
        description: "",
      },
      {
        value: "5.4",
        description: "",
      },
      {
        value: "5.5",
        description: "",
      },
      {
        value: "5.6",
        description: "",
      },

      {
        value: "5.a",
        description: "",
      },
      {
        value: "5.b",
        description: "",
      },
      {
        value: "5.c",
        description: "",
      },
    ],
  },
  {
    id: 6,
    title: "Agua limpia y saneamiento",
    description:
      "Garantizar la disponibilidad y la gestión sostenible del agua y el saneamiento para todos",
    color: "#27BFE6",
    link: `${sdgsBaseUrl}/water-and-sanitation/#tab-a92f0eb1628b36792d5`,
    objectiveGoals: [
      {
        value: "6.1",
        description: "",
      },
      {
        value: "6.2",
        description: "",
      },
      {
        value: "6.3",
        description: "",
      },
      {
        value: "6.4",
        description: "",
      },
      {
        value: "6.5",
        description: "",
      },
      {
        value: "6.6",
        description: "",
      },

      {
        value: "6.a",
        description: "",
      },
      {
        value: "6.b",
        description: "",
      },
    ],
  },
  {
    id: 7,
    title: "Energía asequible y no contaminante",
    description:
      "Garantizar el acceso a una energía asequible, fiable, sostenible y moderna para todos",
    color: "#FBC412",
    link: `${sdgsBaseUrl}/energy/#tab-9ff9f7a21a3cd985360`,
    objectiveGoals: [
      {
        value: "7.1",
        description: "",
      },
      {
        value: "7.2",
        description: "",
      },
      {
        value: "7.3",
        description: "",
      },

      {
        value: "7.a",
        description: "",
      },
      {
        value: "7.b",
        description: "",
      },
    ],
  },
  {
    id: 8,
    title: "Trabajo decente y crecimiento económico",
    description:
      "Promover el crecimiento económico sostenido, inclusivo y sostenible, el empleo pleno y productivo y el trabajo decente para todos",
    color: "#A31C44",
    link: `${sdgsBaseUrl}/economic-growth/#tab-2f1cc1f12f763777498`,
    objectiveGoals: [
      {
        value: "8.1",
        description: "",
      },
      {
        value: "8.2",
        description: "",
      },
      {
        value: "8.3",
        description: "",
      },
      {
        value: "8.4",
        description: "",
      },
      {
        value: "8.5",
        description: "",
      },
      {
        value: "8.6",
        description: "",
      },
      {
        value: "8.7",
        description: "",
      },
      {
        value: "8.8",
        description: "",
      },
      {
        value: "8.9",
        description: "",
      },
      {
        value: "8.10",
        description: "",
      },
      {
        value: "8.a",
        description: "",
      },
      {
        value: "8.b",
        description: "",
      },
    ],
  },
  {
    id: 9,
    title: "Industria, innovación e infraestructura",
    color: "#F26A2E",
    link: `${sdgsBaseUrl}/infrastructure-industrialization/#tab-f4621629c483c200ec0`,
    description:
      "Construir infraestructuras resilientes, promover la insdustrialización inclusiva y sostenible y fomentar la innovación",
    objectiveGoals: [
      {
        value: "9.1",
        description: "",
      },
      {
        value: "9.2",
        description: "",
      },
      {
        value: "9.3",
        description: "",
      },
      {
        value: "9.4",
        description: "",
      },
      {
        value: "9.5",
        description: "",
      },

      {
        value: "9.a",
        description: "",
      },
      {
        value: "9.b",
        description: "",
      },
      {
        value: "9.c",
        description: "",
      },
    ],
  },
  {
    id: 10,
    title: "Reducción de las desigualdades",
    description: "Reducir la desigualdad en los países y entre ellos",
    color: "#E01483",
    link: `${sdgsBaseUrl}/inequality/#tab-cb85489469cb0d48293`,
    objectiveGoals: [
      {
        value: "10.1",
        description: "",
      },
      {
        value: "10.2",
        description: "",
      },
      {
        value: "10.3",
        description: "",
      },
      {
        value: "10.4",
        description: "",
      },
      {
        value: "10.5",
        description: "",
      },
      {
        value: "10.6",
        description: "",
      },
      {
        value: "10.7",
        description: "",
      },
      {
        value: "10.a",
        description: "",
      },
      {
        value: "10.b",
        description: "",
      },
      {
        value: "10.c",
        description: "",
      },
    ],
  },
  {
    id: 11,
    title: "Ciudades y comunidades sostenibles",
    description:
      "Lograr que las ciudades y los asentamientos humanos sean inclusivos, seguros, resilientos y sostenibles",
    color: "#F89D2A",
    link: `${sdgsBaseUrl}/cities/#tab-2267862e52845f3d178`,
    objectiveGoals: [
      {
        value: "11.1",
        description: "",
      },
      {
        value: "11.2",
        description: "",
      },
      {
        value: "11.3",
        description: "",
      },
      {
        value: "11.4",
        description: "",
      },
      {
        value: "11.5",
        description: "",
      },
      {
        value: "11.6",
        description: "",
      },
      {
        value: "11.7",
        description: "",
      },
      {
        value: "11.a",
        description: "",
      },
      {
        value: "11.b",
        description: "",
      },
      {
        value: "11.c",
        description: "",
      },
    ],
  },
  {
    id: 12,
    title: "Producción y consumo responsables",
    description: "Garantizar modalidades de consumo y producción sostenibles",
    color: "#BF8D2C",
    link: `${sdgsBaseUrl}/sustainable-consumption-production/#tab-d06cb4fe76db458f248`,
    objectiveGoals: [
      {
        value: "12.1",
        description: "",
      },
      {
        value: "12.2",
        description: "",
      },
      {
        value: "12.3",
        description: "",
      },
      {
        value: "12.4",
        description: "",
      },
      {
        value: "12.5",
        description: "",
      },
      {
        value: "12.6",
        description: "",
      },
      {
        value: "12.7",
        description: "",
      },
      {
        value: "12.8",
        description: "",
      },
      {
        value: "12.a",
        description: "",
      },
      {
        value: "12.b",
        description: "",
      },
      {
        value: "12.c",
        description: "",
      },
    ],
  },
  {
    id: 13,
    title: "Acción por el clima",
    description:
      "Adoptar medidas urgentes para combatir el cambio climático y sus efectos",
    color: "#407F46",
    link: `${sdgsBaseUrl}/climate-change/#tab-c4bdf348fc128b87c4d`,
    objectiveGoals: [
      {
        value: "13.1",
        description: "",
      },
      {
        value: "13.2",
        description: "",
      },
      {
        value: "13.3",
        description: "",
      },
      {
        value: "13.a",
        description: "",
      },
      {
        value: "13.b",
        description: "",
      },
    ],
  },
  {
    id: 14,
    title: "Vida submarina",
    description:
      "Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos para el desarrollo sostenible",
    color: "#1F97D4",
    link: `${sdgsBaseUrl}/oceans/#tab-260cea69595a56b794f`,
    objectiveGoals: [
      {
        value: "14.1",
        description: "",
      },
      {
        value: "14.2",
        description: "",
      },
      {
        value: "14.3",
        description: "",
      },
      {
        value: "14.4",
        description: "",
      },
      {
        value: "14.5",
        description: "",
      },
      {
        value: "14.6",
        description: "",
      },
      {
        value: "14.7",
        description: "",
      },
      {
        value: "14.a",
        description: "",
      },
      {
        value: "14.b",
        description: "",
      },
      {
        value: "14.c",
        description: "",
      },
    ],
  },
  {
    id: 15,
    title: "Vida de ecosistemas terrestres",
    description:
      "Proteger, restablecer y promover el uso sostenible de los ecosistemas terrestres, gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras y detener la pérdida de biodiversidad",
    color: "#59BA47",
    link: `${sdgsBaseUrl}/biodiversity/#tab-ca4ea19654992ca4ecb`,
    objectiveGoals: [
      {
        value: "15.1",
        description: "",
      },
      {
        value: "15.2",
        description: "",
      },
      {
        value: "15.3",
        description: "",
      },
      {
        value: "15.4",
        description: "",
      },
      {
        value: "15.5",
        description: "",
      },
      {
        value: "15.6",
        description: "",
      },
      {
        value: "15.7",
        description: "",
      },
      {
        value: "15.8",
        description: "",
      },
      {
        value: "15.9",
        description: "",
      },
      {
        value: "15.a",
        description: "",
      },
      {
        value: "15.b",
        description: "",
      },
      {
        value: "15.c",
        description: "",
      },
    ],
  },
  {
    id: 16,
    title: "Paz justicia e instituciones sólidas",
    description:
      "Promover sociedades pacíficas e inclusivas para el desarrollo sostenible, facilitar el acceso a la justicia para todos y construir a todos los niveles instituciones eficaces e inclusivas que rindan cuentas",
    color: "#136A9F",
    link: `${sdgsBaseUrl}/peace-justice/#tab-eb030c43e0900c3690b`,
    objectiveGoals: [
      {
        value: "16.1",
        description: "",
      },
      {
        value: "16.2",
        description: "",
      },
      {
        value: "16.3",
        description: "",
      },
      {
        value: "16.4",
        description: "",
      },
      {
        value: "16.5",
        description: "",
      },
      {
        value: "16.6",
        description: "",
      },
      {
        value: "16.7",
        description: "",
      },
      {
        value: "16.8",
        description: "",
      },
      {
        value: "16.9",
        description: "",
      },
      {
        value: "16.10",
        description: "",
      },
      {
        value: "16.a",
        description: "",
      },
      {
        value: "16.b",
        description: "",
      },
    ],
  },
  {
    id: 17,
    title: "Alianzas para lograr los objetivos",
    description:
      "Fortalecer los medios de implementación y revitalizar la Alianza Mundial para el Desarrollo Sostenible",
    color: "#14496B",
    link: `${sdgsBaseUrl}/globalpartnerships/#tab-d100a91968edd4cd742`,
    objectiveGoals: [
      {
        value: "17.1",
        description: "",
      },
      {
        value: "17.2",
        description: "",
      },
      {
        value: "17.3",
        description: "",
      },
      {
        value: "17.4",
        description: "",
      },
      {
        value: "17.5",
        description: "",
      },
      {
        value: "17.6",
        description: "",
      },
      {
        value: "17.7",
        description: "",
      },
      {
        value: "17.8",
        description: "",
      },
      {
        value: "17.9",
        description: "",
      },
      {
        value: "17.10",
        description: "",
      },
      {
        value: "17.11",
        description: "",
      },
      {
        value: "17.12",
        description: "",
      },
      {
        value: "17.13",
        description: "",
      },
      {
        value: "17.14",
        description: "",
      },
      {
        value: "17.15",
        description: "",
      },
      {
        value: "17.16",
        description: "",
      },
      {
        value: "17.17",
        description: "",
      },
      {
        value: "17.18",
        description: "",
      },
      {
        value: "17.19",
        description: "",
      },
    ],
  },
];
