import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Constants from "../../support/Constants";
import { Search } from "@mui/icons-material";
import { Clear } from "@mui/icons-material";

export const PropuestasFilterMobile = ({
  filtroCategoria,
  filtroEstado,
  filtroTitulo,
  setFiltroCategoria,
  setFiltroEstado,
  setFiltroTitulo,
  categoriasPropuesta,
  loading,
  getPropuestas,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleAccordionChange("panel1")}
      sx={{ mb: 2, width: "100%" }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: 48,
          "&.Mui-expanded": {
            minHeight: 48,
            height: 48,
          },
        }}
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText }} />
        }
      >
        <Typography>Filtrar propuestas</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Título"
              variant="outlined"
              size="small"
              value={filtroTitulo}
              onChange={(newValue) => setFiltroTitulo(newValue.target.value)}
              InputProps={{
                endAdornment: filtroTitulo && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setFiltroTitulo("")}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Estado"
              variant="outlined"
              size="small"
              value={filtroEstado}
              select={true}
              defaultValue={Constants.PROPOSAL_STATUS_ANY}
              onChange={(newValue) => setFiltroEstado(newValue.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ANY}
                value={Constants.PROPOSAL_STATUS_ANY}
              >
                Todas
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_PENDING}
                value={Constants.PROPOSAL_STATUS_PENDING}
              >
                Pendiente
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ADMITTED}
                value={Constants.PROPOSAL_STATUS_ADMITTED}
              >
                Admitida
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_NOT_ADMITTED}
                value={Constants.PROPOSAL_STATUS_REJECTED}
              >
                No admitida
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_ACTIVE}
                value={Constants.PROPOSAL_STATUS_ACTIVE}
              >
                Activa
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_APPROVED}
                value={Constants.PROPOSAL_STATUS_APPROVED}
              >
                Aprobada
              </MenuItem>
              <MenuItem
                key={"_status_" + Constants.PROPOSAL_STATUS_REJECTED}
                value={Constants.PROPOSAL_STATUS_REJECTED}
              >
                Rechazada
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Categoría"
              variant="outlined"
              size="small"
              value={filtroCategoria}
              select={true}
              defaultValue={"TODAS"}
              onChange={(newValue) => setFiltroCategoria(newValue.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem key="_status_todos" value={"TODAS"}>
                TODAS
              </MenuItem>
              {categoriasPropuesta?.map((item) => (
                <MenuItem key={"_status_" + item.id} value={item.category}>
                  {item.category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "right", display: "flex" }}>
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() => {
                setExpanded(!expanded);
                getPropuestas();
              }}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
