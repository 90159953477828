import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Slide,
  Typography,
  Radio,
  RadioGroup,
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CloseIcon from "@mui/icons-material/Close";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import CustomTextEditor from "../ui/CustomTextEditor";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalPropuestaFormMobile = ({
  open,
  handleClose,
  isEditing,
  propuestaEditing,
  categoriasPropuesta,
  roles,
  setLoading,
}) => {
  const [initialDate, setInitialDate] = useState(new Date());
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({
    _title: Yup.string().required("Debes ingresar un título a la propuesta"),
    _description: Yup.string()
      .required("Debes ingresar una descripción para la propuesta")
      .test(
        "no-html-tags",
        "Debes ingresar una descripción para la propuesta",
        (value) => {
          const strippedString = value?.replace(/(<([^>]+)>)/gi, ""); // Remueve todas las etiquetas HTML
          return strippedString && strippedString.trim().length > 0; // Verifica si queda algún texto después de remover las etiquetas
        }
      ),
    _category: Yup.string().required("Debes seleccionar una categoría"),
  });
  const initialState = {
    _code: 0,
    _title: "",
    _description: "",
    _category: "",
    _openingDate: initialDate,
    _closingDate: initialDate,
    _minimumVotesPct: 10,
    _passingVotesPct: 50,
    _role: 0,
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _code: propuestaEditing.code,
        _title: propuestaEditing.title,
        _description: propuestaEditing.description,
        _category: propuestaEditing.category,
        _openingDate: moment
          .unix(propuestaEditing.openingDate)
          .format("YYYY-MM-DD"),
        _closingDate: moment
          .unix(propuestaEditing.closingDate)
          .format("YYYY-MM-DD"),
        _minimumVotesPct: propuestaEditing.minimumVotesPct,
        _passingVotesPct: propuestaEditing.passingVotesPct,
        _role: propuestaEditing.role,
      };
    }

    return formData;
  };

  const formPropuesta = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        _title,
        _description,
        _category,
        _openingDate,
        _closingDate,
        _minimumVotesPct,
        _passingVotesPct,
        _role,
      } = values;

      const _openingDateParsed = moment(_openingDate, "YYYY-MM-DD").unix();
      const _closingDateParsed = moment(_closingDate, "YYYY-MM-DD").unix();

      setDisableButton(true);
      setLoading(true);
      openSpinner();
      if (!isEditing) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_PROPOSALS,
          "addProposal",
          Web3Manager.MEMBER_DATA.memberCode,
          Web3Manager.MEMBER_DATA.memberType,
          _title,
          _description,
          _category,
          _openingDateParsed,
          _closingDateParsed,
          _minimumVotesPct,
          _passingVotesPct,
          _role
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_PROPOSALS,
          "modifyProposal",
          propuestaEditing.code,
          _title,
          _description,
          _category,
          _openingDateParsed,
          _closingDateParsed,
          _minimumVotesPct,
          _passingVotesPct,
          _role
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formPropuesta;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {!isEditing ? "Agregar propuesta" : "Editar propuesta"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ margin: "2rem" }} component="form" onSubmit={handleSubmit}>
        <Box mt={2}>
          <TextField
            name="_title"
            id="outlined-basic"
            label="Título"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._title}
            error={Boolean(touched._title && errors._title)}
            helperText={touched._title && errors._title}
          />
        </Box>
        <Box mt={2} sx={{ borderRadius: "4px" }}>
          <CustomTextEditor
            name="_description"
            placeholder="Descripción"
            value={values._description}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
        {touched._description && errors._description && (
          <Typography
            sx={{
              fontSize: "0.75rem",
              marginLeft: "14px",
              marginRight: "14px",
              marginTop: "4px",
            }}
            variant="body2"
            color="error"
          >
            {errors._description}
          </Typography>
        )}
        <br />
        <Grid container spacing={2} mt={2} mb={2} display="flex">
          <Grid item xs={12}>
            <TextField
              name="_category"
              id="outlined-basic"
              label="Categoría"
              variant="outlined"
              size="small"
              fullWidth={true}
              select={true}
              defaultValue=""
              onChange={handleChange}
              onBlur={handleBlur}
              value={values._category ?? ""}
              error={Boolean(touched._category && errors._category)}
              helperText={touched._category && errors._category}
            >
              {categoriasPropuesta?.map((item) => (
                <MenuItem
                  key={"categoriaPropuesta" + item.id}
                  value={item.category}
                >
                  {item.category}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                name="_openingDate"
                label="Fecha de apertura"
                inputFormat="DD-MM-YYYY"
                disablePast
                value={values._openingDate}
                onChange={(newValue) => {
                  formPropuesta.setFieldValue(
                    "_openingDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                  if (newValue.format("YYYY-MM-DD") > values._closingDate) {
                    formPropuesta.setFieldValue(
                      "_closingDate",
                      moment(newValue).format("YYYY-MM-DD")
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={Boolean(touched._openingDate && errors._openingDate)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                name="_closingDate"
                label="Fecha de cierre"
                inputFormat="DD-MM-YYYY"
                value={values._closingDate}
                minDate={values._openingDate}
                disableMaskedInput
                onChange={(newValue) => {
                  formPropuesta.setFieldValue(
                    "_closingDate",
                    moment(newValue).format("YYYY-MM-DD")
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    error={Boolean(touched._closingDate && errors._closingDate)}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography variant="h6" component="h2">
            {" "}
            Dirigida a:{" "}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
              aria-label="_role"
              name="_role"
              value={values._role}
              onChange={(event) => {
                formPropuesta.setFieldValue("_role", event.target.value);
              }}
            >
              <div xs={12}>
                <FormControlLabel
                  key={0}
                  value={0}
                  control={<Radio />}
                  label="Todos los socios"
                />
              </div>
              {roles?.map(([id, name]) => (
                <div key={id} xs={12}>
                  <FormControlLabel
                    value={parseInt(id)}
                    control={<Radio />}
                    label={"Socios con rol " + Constants.getNombreRol(id)}
                  />
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
