import React, { useState } from "react";
import {
  Add,
  ExpandLess,
  ExpandMore,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  Slide,
  Pagination,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { sustainableDevelopmentGoals } from "../../support/sustainableDevelopmentGoals";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";
//import { DividerWithTitle } from "../ui/DividerWithTitle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalSustainableDevelopmentGoalsMobile = ({
  open,
  handleClose,
  handleConfirm,
  selectedDevelopmentGoals,
  setSelectedDevelopmentGoals,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [expanded, setExpanded] = React.useState(false);

  const [page, setPage] = useState(1);
  const goalsPerPage = 10;
  const totalPages = Math.ceil(
    sustainableDevelopmentGoals.length / goalsPerPage
  );
  const startIndex = (page - 1) * goalsPerPage;
  const endIndex = page * goalsPerPage;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleCheckboxChange = (event, goalValue) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedDevelopmentGoals((prevSelectedGoals) => {
        if (!prevSelectedGoals.includes(goalValue)) {
          return [...prevSelectedGoals, goalValue];
        }
        return prevSelectedGoals;
      });
    } else {
      setSelectedDevelopmentGoals((prevSelectedGoals) =>
        prevSelectedGoals.filter((selectedGoal) => selectedGoal !== goalValue)
      );
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Selección de ODS
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        {sustainableDevelopmentGoals.slice(startIndex, endIndex).map((goal) => (
          <Accordion sx={{ p: 1 }} key={goal.id}>
            <AccordionSummary
              expandIcon={expanded ? <ExpandLess /> : <ExpandMore />}
            >
              <Typography>
                {goal.id} - {goal.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {goal.objectiveGoals.map((objective, index) => (
                  <Grid item key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedDevelopmentGoals?.includes(
                            objective.value
                          )}
                          onChange={(event) =>
                            handleCheckboxChange(event, objective.value)
                          }
                        />
                      }
                      label={objective.value}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Grid mt={2} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
          />
        </Grid>
        <Stack direction="row" spacing={1} mt={2} justifyContent="right">
          <Button
            sx={{ boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)" }}
            variant="contained"
            onClick={() => {
              handleConfirm();
              handleClose();
            }}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={() => {
              handleClose();
              setSelectedDevelopmentGoals([]);
            }}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};
