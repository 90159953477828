import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Alert,
  AlertTitle,
  Modal,
  Typography,
  Chip,
  Grid,
} from "@mui/material";
import {
  CheckCircle,
  AssignmentLate,
  AssignmentTurnedIn,
  PendingActions,
} from "@mui/icons-material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";
import moment from "moment/moment";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

export const ModalIniciarColaboracion = ({
  open,
  handleClose,
  colaboracion,
  personas,
  empresas,
  setLoading,
}) => {
  const { openSpinner } = useContext(SpinnerContext);
  const [disableButton, setDisableButton] = useState(false);
  const [nombreAplicacion, setNombreAplicacion] = useState("");
  const [statusCompromiso, setStatusCompromiso] = useState("");
  const [deadlineCompromiso, setDeadlineCompromiso] = useState("");

  const handleIniciarColaboracion = (code) => {
    setDisableButton(true);
    setLoading(true);
    openSpinner(true);
    Web3Manager.callContract(
      Web3Manager.CONTRACT_COLLABORATIONS,
      "updateCollaborationStatus",
      code,
      Constants.COLLABORATION_STATUS_ACTIVE
    )
      .then((response) => {
        setDisableButton(false);
        handleClose(false, response, true);
      })
      .catch((error) => {
        setDisableButton(false);
        setLoading(false);
        handleClose(false, error, false);
      });
  };

  const getChipInfo = (status) => {
    let icon;
    let label;
    if (status === "Cumple") {
      icon = <AssignmentTurnedIn style={{ color: "green" }} />;
      label = "Cumple";
    } else if (status === "Tiene intención") {
      icon = <PendingActions style={{ color: "orange" }} />;
      label = "Tiene intención";
    } else if (status === "No adhiere") {
      icon = <AssignmentLate style={{ color: "red" }} />;
      label = "Ya no adhiere";
    } else {
      icon = null;
      label = "Desconocido";
    }

    return (
      <Chip
        sx={{ p: 2, ml: 1 }}
        icon={icon}
        label={label}
        variant="outlined"
        size="small"
      />
    );
  };

  const getDeadlineDate = (date) => {
    return moment(date).format("DD-MM-YYYY");
  };

  useEffect(() => {
    const fetchData = async () => {
      const socio =
        colaboracion.targetMemberType == Constants.MEMBER_TYPE_PERSONA
          ? await personas.find(
              (p) =>
                p.memberAddress?.toLowerCase() ==
                colaboracion.targetMemberAddress?.toLowerCase()
            )
          : await empresas.find(
              (e) =>
                e.memberAddress?.toLowerCase() ==
                colaboracion.targetMemberAddress?.toLowerCase()
            );

      let adhesion;
      if (colaboracion.applicationId && colaboracion.applicationId !== 0) {
        const aplicacion = await socio.services.find(
          (s) => s.code === colaboracion.applicationId
        );
        adhesion = await aplicacion.commitments.find(
          (c) => c.commitmentCode === colaboracion.collaborationTypeCode
        );
        setNombreAplicacion(aplicacion.name);
      } else {
        adhesion = await socio.commitments.find(
          (c) => c.commitmentCode === colaboracion.collaborationTypeCode
        );
      }

      if (adhesion) {
        setStatusCompromiso(
          Constants.obtenerNombreEstadoCompromiso(adhesion.status)
        );
        if (adhesion.status === Constants.HAS_INTENTION) {
          setDeadlineCompromiso(adhesion.deadlineDate);
        }
      } else {
        setStatusCompromiso("No adhiere");
      }
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style}>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                Colaboración
              </Typography>

              <Chip
                label={Constants.getNombreEstadoColaboracion(
                  colaboracion.status
                )}
                sx={{
                  marginLeft: "0.5rem",
                  backgroundColor:
                    Constants.COLLABORATION_STATUS_COLORS[colaboracion.status],
                }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box container direction="column">
              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1">
                  <strong>Compromiso: </strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {colaboracion.tipoDeColaboracionTitulo}
                </Typography>
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>{"Emisor: "}</strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {colaboracion.sourceMemberName}
                </Typography>
                <Chip
                  label={
                    colaboracion.sourceMemberType ===
                    Constants.MEMBER_TYPE_PERSONA
                      ? `PERSONA`
                      : `EMPRESA`
                  }
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[
                        colaboracion.sourceMemberType
                      ],
                  }}
                />
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>{"Receptor: "}</strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {colaboracion.targetMemberName}
                </Typography>
                <Chip
                  label={
                    colaboracion.targetMemberType ===
                    Constants.MEMBER_TYPE_PERSONA
                      ? `PERSONA`
                      : `EMPRESA`
                  }
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.MEMBER_TYPE_COLORS[
                        colaboracion.targetMemberType
                      ],
                  }}
                />
              </Box>

              {nombreAplicacion && (
                <Box
                  item
                  sx={{ display: "flex", alignItems: "center", mt: 1.5 }}
                >
                  <Typography variant="subtitle1">
                    <strong>Aplicación:</strong>
                  </Typography>
                  &nbsp;
                  <Typography variant="subtitle1">
                    {nombreAplicacion}
                  </Typography>
                </Box>
              )}

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>Adhesión:</strong>
                  {getChipInfo(statusCompromiso)}
                  {deadlineCompromiso && (
                    <> ● {getDeadlineDate(deadlineCompromiso)}</>
                  )}
                  <br />
                </Typography>
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>{"Fecha de creación: "}</strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(colaboracion.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
                <Typography variant="subtitle1">
                  <strong>Categoría: </strong>
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {colaboracion.category}
                </Typography>
              </Box>

              <Box mt={1.5}>
                <Typography variant="subtitle1">
                  <strong>{"Descripción: "}</strong>
                </Typography>
                <Box
                  sx={{
                    maxHeight: "6em",
                    overflowY: "auto",
                  }}
                >
                  <Typography variant="subtitle1">
                    {colaboracion ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: colaboracion.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Box>
            <Box mt={2}>
              <Alert
                severity="info"
                sx={{
                  fontSize: "12px",
                }}
              >
                <AlertTitle>
                  ¿Está seguro que desea iniciar la colaboración?
                </AlertTitle>
                Una vez iniciada, la colaboración será visible para todos los
                usuarios y ya no podrá editarla ni eliminarla
              </Alert>
            </Box>

            <Box
              mt={2}
              sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                endIcon={<CheckCircle />}
                disabled={disableButton}
                onClick={() => handleIniciarColaboracion(colaboracion?.code)}
              >
                Iniciar
              </Button>
              <CancelButton
                label="Cancelar"
                onClick={() => {
                  handleClose(true, null, false);
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};
