import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";
import { jsx } from "@emotion/react";
import { MEMBER_TYPE_EMPRESA, ROLE_DIRECTIVA } from "../../support/Constants";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

/**
 * Los roles aplican a socios de ambos tipos
 */
export const ModalRolesSocioForm = ({
  open,
  handleClose,
  socio,
  roles,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const validationSchema = Yup.object({});

  const getFormData = () => {
    let formData;

    if (socio) {
      formData = {
        memberAddress: socio.memberAddress,
        name: socio.name,
        type: socio.type,
        roles:
          socio.roles && socio.roles.length
            ? socio.roles.map((rol) => String(rol))
            : [],
      };
    } else {
      formData = {
        memberAddress: "",
        name: "",
        roles: [],
        type: "",
      };
    }

    return formData;
  };

  const formRoles = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { roles } = values;

      const roleCodes =
        roles && roles.length ? roles.map((rol) => Number(rol)) : [];

      setLoading(true);
      setDisableButton(true);
      openSpinner();

      Web3Manager.callContract(
        Web3Manager.CONTRACT_ACCESS_CONTROL,
        "setMemberRoles",
        socio.memberAddress,
        roleCodes
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setLoading(false);
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const correspondeRolTipo = (rol, tipo) => {
    if (rol != ROLE_DIRECTIVA) {
      return true;
    } else if (tipo != MEMBER_TYPE_EMPRESA) {
      return true;
    }
    return false;
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formRoles;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Roles de Socio
        </Typography>

        <Box mt={2}>
          <TextField
            name="memberAddress"
            id="outlined-basic"
            label="Dirección (@BLK)"
            variant="outlined"
            size="small"
            fullWidth={true}
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.memberAddress}
            error={Boolean(touched.memberAddress && errors.memberAddress)}
            helperText={touched.memberAddress && errors.memberAddress}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <TextField
            name="name"
            id="outlined-basic"
            label="Nombre"
            variant="outlined"
            size="small"
            fullWidth={true}
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        {
          // Mostramos los roles, para que el usuario elija cuales quiere asignar
          roles.map((rol, index) => {
            if (correspondeRolTipo(rol.code, values.type)) {
              return (
                <Box mt={2}>
                  <FormControlLabel
                    key={"roles" + index}
                    control={
                      <Checkbox
                        name={"roles"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.roles.includes(String(rol.code))}
                        value={rol.code}
                        error={Boolean(touched.roles && errors.roles)}
                        helperText={touched.roles && errors.roles}
                      />
                    }
                    label={rol.name}
                  />
                </Box>
              );
            }
          })
        }

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Confirmar
          </Button>

          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
