import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Box, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import SociosContext from "../../context/socios/SociosContext";
import { SociosTable } from "../../components/socios/SociosTable";
import { useMediaQuery } from "@mui/material";
import { SocioCard } from "../../components/socios/SocioCard";
import SociosFilterMobile from "../../components/socios/SociosFilterMobile";

const SociosPage = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { socios, getSocios, isLoading, setLoading } =
    useContext(SociosContext);

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Socios
        </Typography>
      </Box>
      <>
        {matches && (
          <SociosFilterMobile
            getSocios={getSocios}
            isLoading={isLoading}
            setLoading={setLoading}
            isReadOnly={true}
          />
        )}
        {matches ? (
          <SocioCard
            socios={socios}
            getSocios={getSocios}
            isLoading={isLoading}
            setLoading={setLoading}
            isReadOnly={true}
          />
        ) : (
          <SociosTable
            socios={socios}
            getSocios={getSocios}
            isLoading={isLoading}
            setLoading={setLoading}
            isReadOnly={true}
          />
        )}
      </>
    </Container>
  );
};

export default withRouter(SociosPage);
