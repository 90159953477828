import React from "react";

import { Tooltip } from "@mui/material";

export const BigTooltip = ({ title, children, placement }) => {
  return (
    <Tooltip
      title={<span style={{ fontSize: 16 }}>{title}</span>}
      placement={placement ? placement : "top"}
      arrow
    >
      {children}
    </Tooltip>
  );
};
