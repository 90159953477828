import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import {
  Delete,
  Edit,
  CheckCircle,
  Article,
  PlayCircleOutline,
  Engineering,
  ZoomIn,
  Feedback,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import * as Constants from "../../support/Constants";
import moment from "moment";
import * as Web3Manager from "../../support/Web3Manager";
import { GridActionItem } from "../../components/ui/GridActionItem";
import { ModalColaboracionesForm } from "../../components/colaboraciones/ModalColaboracionesForm";
import { ModalIniciarColaboracion } from "../../components/colaboraciones/ModalIniciarColaboracion";
import { ModalTrabajarColaboracion } from "../../components/colaboraciones/ModalTrabajarColaboracion";
import { ModalFinalizarColaboracion } from "../../components/colaboraciones/ModalFinalizarColaboracion";
import { ModalReporteColaboracion } from "../../components/reportes/ModalReporteColaboracion";
import FiltrosColaboraciones from "./FiltrosColaboraciones";
import FiltrosColaboracionesMobile from "./FiltrosColaboracionesMobile";

export const ColaboracionesTable = ({
  loading,
  handleEditarColaboracion,
  handleTrabajarColaboracion,
  handleIniciarColaboracion,
  handleFinalizarColaboracion,
  handleEliminarColaboracion,
  handleVerReporte,
  handleAgregarColaboracion,
  showSpinner,
  filterColaborations,
  colaboracionesData,
  pathname,
  openModalColaboracion,
  colaboracionSelected,
  handleCloseModalColaboracion,
  modeEdit,
  socios,
  targetPrincipios,
  categoriasColaboracion,
  compromisos,
  setLoading,
  openModalIniciar,
  openModalTrabajar,
  openModalFinalizar,
  openModalReporte,
  handleCloseModalIniciar,
  handleCloseModalTrabajar,
  handleCloseModalFinalizar,
  handleCloseModalReporte,
  comentariosColaboracion,
  filtros,
  setFiltros,
  getColaboraciones,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const personas = socios.filter(
    (s) =>
      s.type === Constants.MEMBER_TYPE_PERSONA &&
      s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
  );
  const empresas = socios.filter(
    (s) =>
      s.type === Constants.MEMBER_TYPE_EMPRESA &&
      s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
  );

  const columnsColaboraciones = [
    {
      field: "collaborationType",
      headerName: "Tipo",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return Constants.getNombreTipoColaboracion(
          params.row.collaborationType
        );
      },
    },
    {
      field: "title",
      headerName: "Nombre",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sourceMemberName",
      headerName: "Emisor",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box container display="flex" alignItems="center">
            <Box item> {params.row.sourceMemberName}</Box>
            <Box item>
              {" "}
              {params.row.status === Constants.COLLABORATION_STATUS_ACTIVE &&
                params.row.turn === 1 && (
                  <Tooltip title="Es su turno">
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#1ABC9C",
                        marginLeft: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "targetMemberName",
      headerName: "Receptor",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box container display="flex" alignItems="center">
            <Box item> {params.row.targetMemberName}</Box>
            <Box item>
              {" "}
              {params.row.status === Constants.COLLABORATION_STATUS_ACTIVE &&
                params.row.turn === 2 && (
                  <Tooltip title="Es su turno">
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#1ABC9C",
                        marginLeft: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "category",
      headerName: "Categoría",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Estado",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        return Constants.getNombreEstadoColaboracion(params.row.status);
      },
    },
    {
      field: "creationDate",
      headerName: "Fecha creación",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format("DD-MM-YYYY");
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const {
          sourceMemberAddress,
          targetMemberAddress,
          sourceSatisfaction,
          targetSatisfaction,
          status,
        } = params.row;
        const userIsSource =
          sourceMemberAddress.toLowerCase() ===
          Web3Manager.MEMBER_DATA.memberAddress.toLowerCase();
        const userIsTarget =
          targetMemberAddress.toLowerCase() ===
          Web3Manager.MEMBER_DATA.memberAddress.toLowerCase();
        const isEditable =
          userIsSource && status === Constants.COLLABORATION_STATUS_PENDING;
        const isWorkable =
          status === Constants.COLLABORATION_STATUS_ACTIVE &&
          (userIsSource || userIsTarget);

        const oneUserNeedsToUpdateSatisfaction =
          (status === Constants.COLLABORATION_STATUS_FINALIZED ||
            status === Constants.COLLABORATION_STATUS_REPORTED) &&
          ((userIsSource &&
            sourceSatisfaction === Constants.COLLABORATION_SATISFACTION_NONE) ||
            (userIsTarget &&
              targetSatisfaction ===
                Constants.COLLABORATION_SATISFACTION_NONE));

        const isReported = status === Constants.COLLABORATION_STATUS_REPORTED;
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={loading}
              icon={
                isEditable ? (
                  <Edit />
                ) : isWorkable ? (
                  <Engineering />
                ) : (
                  <ZoomIn />
                )
              }
              label={
                isEditable ? "Editar" : isWorkable ? "Trabajar" : "Detalle"
              }
              onClick={() =>
                isEditable
                  ? handleEditarColaboracion(params.row)
                  : handleTrabajarColaboracion(params.row)
              }
            />
            {(isEditable || isWorkable) &&
              (isEditable ? (
                <GridActionItem
                  disabled={loading}
                  icon={<PlayCircleOutline />}
                  label="Iniciar"
                  onClick={() => {
                    handleIniciarColaboracion(params.row);
                  }}
                />
              ) : isWorkable ? (
                <GridActionItem
                  disabled={loading}
                  icon={<CheckCircle />}
                  label="Finalizar"
                  onClick={() => {
                    handleFinalizarColaboracion(params.row);
                  }}
                />
              ) : (
                <></>
              ))}

            {isEditable && (
              <GridActionItem
                disabled={loading}
                icon={<Delete />}
                label="Eliminar"
                onClick={() => handleEliminarColaboracion(params.row)}
              />
            )}

            {isReported && (
              <GridActionItem
                disabled={loading}
                icon={<Article />}
                label="Reporte"
                onClick={() => handleVerReporte(params.row)}
              />
            )}
            {oneUserNeedsToUpdateSatisfaction && (
              <GridActionItem
                disabled={loading}
                icon={<Feedback />}
                label="Feedback"
                onClick={() => handleFinalizarColaboracion(params.row)}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Grid container>
      {matches ? (
        <>
          {socios && (
            <FiltrosColaboracionesMobile
              filtros={filtros}
              setFiltros={setFiltros}
              getColaboraciones={getColaboraciones}
              categoriasColaboracion={categoriasColaboracion}
              targetPersonas={personas}
              targetEmpresas={empresas}
              loading={loading}
              showSourceAndTargetFilters={
                !pathname.includes("MisColaboraciones")
              }
            />
          )}
        </>
      ) : (
        <>
          {socios && (
            <FiltrosColaboraciones
              filtros={filtros}
              setFiltros={setFiltros}
              getColaboraciones={getColaboraciones}
              categoriasColaboracion={categoriasColaboracion}
              targetPersonas={socios.filter(
                (s) =>
                  s.type === Constants.MEMBER_TYPE_PERSONA &&
                  s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
              )}
              targetEmpresas={socios.filter(
                (s) =>
                  s.type === Constants.MEMBER_TYPE_EMPRESA &&
                  s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
              )}
              loading={loading}
              showSourceAndTargetFilters={
                !pathname.includes("MisColaboraciones")
              }
            />
          )}
        </>
      )}
      <DataGrid
        rows={filterColaborations(colaboracionesData, pathname)}
        loading={loading && !showSpinner}
        pageSize={50}
        autoHeight={true}
        columns={columnsColaboraciones}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => row.code}
        rowsPerPageOptions={[50]}
      />

      <br />

      {openModalColaboracion && (
        <ModalColaboracionesForm
          colaboracionEditing={colaboracionSelected}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          isEditing={modeEdit}
          personas={socios.filter(
            (s) =>
              s.type === Constants.MEMBER_TYPE_PERSONA &&
              s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
          )}
          empresas={socios.filter(
            (s) =>
              s.type === Constants.MEMBER_TYPE_EMPRESA &&
              s.status === Constants.MEMBER_STATUS_WALLETASSIGNED
          )}
          principios={targetPrincipios}
          compromisos={compromisos}
          categoriasColaboracion={categoriasColaboracion}
          setLoading={setLoading}
        />
      )}

      {openModalIniciar && (
        <ModalIniciarColaboracion
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalIniciar}
          handleClose={handleCloseModalIniciar}
          setLoading={setLoading}
        />
      )}

      {openModalTrabajar && (
        <ModalTrabajarColaboracion
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalTrabajar}
          handleClose={handleCloseModalTrabajar}
          setLoading={setLoading}
          comentarios={comentariosColaboracion}
        />
      )}

      {openModalFinalizar && (
        <ModalFinalizarColaboracion
          colaboracion={colaboracionSelected}
          personas={personas}
          empresas={empresas}
          open={openModalFinalizar}
          handleClose={handleCloseModalFinalizar}
          setLoading={setLoading}
          comentarios={comentariosColaboracion}
        />
      )}

      {openModalReporte && (
        <ModalReporteColaboracion
          colaboracion={colaboracionSelected}
          open={openModalReporte}
          handleClose={handleCloseModalReporte}
        />
      )}
    </Grid>
  );
};
