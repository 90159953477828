import { Box, Button, Divider, Modal, Typography } from "@mui/material";

import React from "react";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70%", sm: "70%", md: "50%", lg: "30%" },
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalRegistroSocio = ({
  open,
  handleConfirmRegister,
  handleCancel,
  isLoading,
}) => {
  const useMetamask = Web3Manager.useMetamask;

  return (
    <Modal
      open={open}
      onClose={handleCancel}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirmar registro
        </Typography>

        <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
          <Typography variant="subtitle1">
            Bienvenido a Fibras, {Web3Manager.MEMBER_DATA.memberName}!
          </Typography>
        </Box>
        <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
          <Typography variant="subtitle1">
            {useMetamask && (
              <>
                Usted se encuentra conectado a Metamask con la siguiente cuenta:
                <Box
                  mt={1}
                  mb={2}
                  sx={{ justifyContent: "left", display: "flex" }}
                >
                  <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                    {Web3Manager.MEMBER_DATA.memberAddress}
                  </Typography>
                </Box>
                <Divider />
                <Box mt={2} sx={{ display: "flex" }}>
                  <Typography variant="body1" fontWeight="bold">
                    ¿Confirma que quiere completar su registro usando la cuenta
                    actual?
                  </Typography>
                </Box>
              </>
            )}
            {!useMetamask && (
              <>
                Para completar el registro y poder operar en la plataforma,
                crearemos una cuenta en Blockchain asociada a su usuario.
              </>
            )}
          </Typography>
        </Box>

        <Box
          mt={2}
          mb={1}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmRegister}
            disabled={isLoading}
          >
            Confirmar
          </Button>
          <CancelButton
            label="Cancelar"
            onClick={handleCancel}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  );
};
