// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };
      case "SET_SOCIOS":
        return {
          ...state,
          socios: action.payload.socios,
        };
      case "SET_COMPROMISOS_SOCIO":
          return {
            ...state,
            compromisosSocio: action.payload.compromisosSocio,
          };
     
      default:
        return state;
    }
  };
  