import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Constants from "../../support/Constants";
import moment from "moment";
import SociosContext from "../../context/socios/SociosContext";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { CancelButton } from "../ui/CustomButtons";
import { SeccionCompromisosAdheridosMobile } from "../compromisos/SeccionCompromisosAdheridosMobile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DetallePersonaFormMobile = ({ open, handleClose, persona }) => {
  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);

  useEffect(() => {
    if (persona && persona.code) {
      getCompromisosEnabled();
    }
  }, [persona]);

  useEffect(() => {
    if (persona) {
      const compromisosSeleccionadosList = compromisos?.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return persona.commitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      });

      const newListWithDates = persona.commitments?.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate).format("YYYY-MM-DD"),
          };
        } else {
          return item;
        }
      });

      setAssignedCommitments(newListWithDates ? newListWithDates : []);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    }
  }, [persona, compromisos]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`${persona?.firstName} ${persona?.lastName}`}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 4 }}>
        <Grid container spacing={4}>
          {/* Información de la persona */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Dirección"
              fullWidth
              disabled
              value={persona?.memberAddress}
              margin="normal"
              size="small"
            />
            <TextField
              label="Nombre"
              fullWidth
              disabled
              value={persona?.firstName}
              margin="normal"
              size="small"
            />
            <TextField
              label="Apellido"
              fullWidth
              disabled
              value={persona?.lastName}
              margin="normal"
              size="small"
            />
            <TextField
              label="Correo Electrónico"
              fullWidth
              disabled
              value={persona?.email}
              margin="normal"
              size="small"
            />
            <TextField
              label="Categoría"
              fullWidth
              disabled
              value={Constants.getNombreCategoria(persona?.category)}
              margin="normal"
              size="small"
            />
            <TextField
              label="Estado"
              fullWidth
              disabled
              value={Constants.getNombreEstado(persona?.status)}
              margin="normal"
              size="small"
            />
            <TextField
              label="Fecha de Registro"
              fullWidth
              disabled
              value={moment(persona?.createdAt).format("DD-MM-YYYY")}
              margin="normal"
              size="small"
            />
            {persona?.roles && persona?.roles.length > 0 && (
              <TextField
                label="Roles Asignados"
                fullWidth
                disabled
                value={persona?.roles
                  .map((roleCode) => Constants.getNombreRol(roleCode))
                  .filter((nombreRol) => nombreRol)
                  .join(", ")}
                margin="normal"
                size="small"
              />
            )}
          </Grid>

          {/* Sección de compromisos adheridos */}
          <Grid item xs={12}>
            {loadingCompromisos ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <SeccionCompromisosAdheridosMobile
                assignedCommitments={assignedCommitments}
                setAssignedCommitments={setAssignedCommitments}
                compromisosSeleccionados={compromisosSeleccionados}
                setCompromisosSeleccionados={setCompromisosSeleccionados}
                isReadOnly={true}
                mostrarTitulo={true}
              />
            )}
          </Grid>
        </Grid>

        <Box
          mt={2}
          sx={{ justifyContent: "right", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
