import { Box, Button, Modal, Typography } from "@mui/material";

import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "70%", sm: "70%", md: "50%", lg: "30%" },
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalSocioSinRegistrar = ({ open }) => {
  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Registro de socio
        </Typography>
        <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
          <Typography variant="subtitle1">
            Usted no se encuentra registrado en Fibras.
          </Typography>
        </Box>

        <Box
          mt={2}
          mb={1}
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              (window.location.href = process.env.REACT_APP_URL_REDIRECT_ALEPH)
            }
          >
            Registrarse
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
