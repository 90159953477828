import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  TextField,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Search, TuneOutlined } from "@mui/icons-material";

import CompromisosFiltroAplicaA from "./CompromisosFiltroAplicaA";
import CompromisosFiltroDimensiones from "./CompromisosFiltroDimensiones";

import CompromisosFiltroODS from "./CompromisosFiltroODS";

const CompromisosFilter = ({
  filtroCodigo,
  setFiltroCodigo,
  filtroTitulo,
  setFiltroTitulo,
  filtroComunidad,
  setFiltroComunidad,
  filtroAplicaA,
  setFiltroAplicaA,
  filtroDimensiones,
  setFiltroDimensiones,
  filtroODS,
  setFiltroODS,
  handleBuscarCompromisos,
  isReadOnly,
  isLoading,
  showSpinner,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [mostrarMasFiltros, setMostrarMasFiltros] = useState(false);

  return (
    <Grid container justifyContent="right" spacing={1}>
      {!matches && (
        <>
          {!isReadOnly && (
            <Grid item>
              <TextField
                label="Código"
                variant="outlined"
                size="small"
                value={filtroCodigo}
                onChange={(e) => setFiltroCodigo(Number(e.target.value))}
                sx={{ width: 100 }}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              label="Título"
              variant="outlined"
              size="small"
              value={filtroTitulo}
              onChange={(e) => setFiltroTitulo(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Comunidad"
              variant="outlined"
              size="small"
              value={filtroComunidad}
              onChange={(e) => setFiltroComunidad(e.target.value)}
            />
          </Grid>
          {mostrarMasFiltros && (
            <>
              <Grid item>
                <CompromisosFiltroAplicaA
                  filtroAplicaA={filtroAplicaA}
                  setFiltroAplicaA={setFiltroAplicaA}
                />
              </Grid>
              <Grid item>
                <CompromisosFiltroDimensiones
                  filtroDimensiones={filtroDimensiones}
                  setFiltroDimensiones={setFiltroDimensiones}
                />
              </Grid>
              <Grid item>
                <CompromisosFiltroODS
                  filtroODS={filtroODS}
                  setFiltroODS={setFiltroODS}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Tooltip
              placement="top"
              arrow
              title={
                !mostrarMasFiltros ? "Mostrar más filtros" : "Ocultar filtros"
              }
            >
              <IconButton
                aria-label="Mostrar más filtros"
                data-testid="button-tune"
                onClick={() => setMostrarMasFiltros(!mostrarMasFiltros)}
              >
                <TuneOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<Search />}
              onClick={handleBuscarCompromisos}
              disabled={isLoading || showSpinner}
            >
              Buscar
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CompromisosFilter;
