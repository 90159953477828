import React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { BigTooltip } from './BigTooltip';

export const GridActionItem = ({ disabled, icon, label, onClick, sx }) => {
  return (
    <GridActionsCellItem
      disabled={disabled}
      icon={
        <BigTooltip title={label} style={{ transform: 'scale(1.5)' }}>
          {icon}
        </BigTooltip>
      }
      label={label}
      size='small'
      style={{
        color: '#fff',
        backgroundColor: label === 'Eliminar' ? '#FF0000' : '#005A59',
      }}
      sx={{ ...sx }}
      onClick={onClick}
    />
  );
};
