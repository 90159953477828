import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Chip,
  Stack,
  CircularProgress,
} from "@mui/material";

import {
  ZoomIn,
  Edit,
  AssignmentTurnedIn,
  PendingActions,
  Public,
  Groups,
  AccountBalance,
  Unpublished,
} from "@mui/icons-material";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { ModalColaboracionesForm } from "../../components/colaboraciones/ModalColaboracionesForm";
import { DetallePersonaFormMobile } from "../../components/socios/DetallePersonaFormMobile";
import { DetalleEmpresaFormMobile } from "../../components/socios/DetalleEmpresaFormMobile";

export const MisCompromisosCard = ({
  compromisos,
  getEstadoCompromisoSocio,
  compromisosSocio,
  socios,
  handleIrDetalleCompromiso,
  getDeadlineDate,
  setCompromisoEditing,
  setOpenModalAdherirse,
  setOpenModalEliminarAdhesionCompromiso,
  isLoading,
  showSpinner,
  getApplicationNameToShow,
  openModalDetallePersona,
  handleCloseModalDetallePersona,
  personaSeleccionada,
  openModalDetalleEmpresa,
  handleCloseModalDetalleEmpresa,
  empresaSeleccionada,
  openModalColaboracion,
  handleCloseModalColaboracion,
  principios,
  categoriasColaboracion,
  setLoading,
  modalColaboracionInitData,
  buscarSociosCompromiso,
}) => {
  const getChipInfo = (status) => {
    switch (status) {
      case "Cumple":
        return {
          icon: <AssignmentTurnedIn style={{ color: "green" }} />,
          label: "Cumple",
          color: "success",
        };
      case "Tiene intención":
        return {
          icon: <PendingActions style={{ color: "orange" }} />,
          label: "Tiene intención",
          color: "warning",
        };
      default:
        return {
          icon: null,
          label: "Desconocido",
          color: "default",
        };
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {isLoading && !showSpinner ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress size={50} />
          </Box>
        ) : compromisosSocio?.length === 0 ? (
          <Box sx={{ mt: 2 }}>
            <Typography align="center" variant="body1" color="text.secondary">
              Usted no se encuentra adherido a ningún compromiso.
            </Typography>
          </Box>
        ) : (
          compromisosSocio?.map((compromisosSocioItem) => {
            const status = getEstadoCompromisoSocio(compromisosSocioItem);

            const { icon, label, color } = getChipInfo(status);
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={
                  compromisosSocioItem.commitmentCode +
                  "," +
                  compromisosSocioItem.applicationId
                }
              >
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {compromisosSocioItem.title}
                    </Typography>

                    <Chip
                      icon={icon}
                      label={label}
                      color={color}
                      variant="outlined"
                      size="small"
                      sx={{ p: 2, mb: 2 }}
                    />
                    {getDeadlineDate(compromisosSocioItem) !== "-" && (
                      <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        <strong>Fecha límite:</strong>{" "}
                        {getDeadlineDate(compromisosSocioItem)}
                      </Typography>
                    )}
                    {Web3Manager.MEMBER_DATA.memberType !==
                      Constants.MEMBER_TYPE_PERSONA && (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        <strong>Aplicación:</strong>{" "}
                        {compromisosSocioItem.applicationName}
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Comunidad:</strong>{" "}
                      {compromisosSocioItem.community}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Aplicable a:</strong>{" "}
                      {compromisosSocioItem.appliesToPersons && "Personas"}
                      {compromisosSocioItem.appliesToPersons &&
                        (compromisosSocioItem.appliesToCompanies ||
                          compromisosSocioItem.appliesToServices) &&
                        ", "}
                      {compromisosSocioItem.appliesToCompanies && "Empresas"}
                      {compromisosSocioItem.appliesToCompanies &&
                        compromisosSocioItem.appliesToServices &&
                        ", "}
                      {compromisosSocioItem.appliesToServices && "Aplicaciones"}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      <strong>Dimensiones ESG:</strong>
                      <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                        {/* Dimension E */}
                        <Chip
                          icon={<Public />}
                          label="(E)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromisosSocioItem.dimensionE}
                        />
                        {/* Dimension S */}
                        <Chip
                          icon={<Groups />}
                          label="(S)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromisosSocioItem.dimensionS}
                        />
                        {/* Dimension G */}
                        <Chip
                          icon={<AccountBalance />}
                          label="(G)"
                          color="primary"
                          variant="outlined"
                          disabled={!compromisosSocioItem.dimensionG}
                        />
                      </Stack>
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<ZoomIn />}
                          onClick={() =>
                            handleIrDetalleCompromiso(compromisosSocioItem)
                          }
                          color="primary"
                          disabled={isLoading || showSpinner}
                          size="small"
                        >
                          Detalle
                        </Button>
                      </Grid>
                      {compromisosSocioItem.applicationId == 0 && (
                        <>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<Edit />}
                              onClick={() => {
                                setOpenModalAdherirse(true);
                                setCompromisoEditing(compromisosSocioItem);
                              }}
                              color="primary"
                              disabled={isLoading || showSpinner}
                              size="small"
                            >
                              Editar adhesión
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<Unpublished />}
                              onClick={() => {
                                setOpenModalEliminarAdhesionCompromiso(true);
                                setCompromisoEditing(compromisosSocioItem);
                              }}
                              color="primary"
                              disabled={isLoading || showSpinner}
                              size="small"
                            >
                              Eliminar adhesión
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })
        )}
      </Box>
      {openModalDetallePersona && (
        <DetallePersonaFormMobile
          open={openModalDetallePersona}
          handleClose={handleCloseModalDetallePersona}
          persona={personaSeleccionada}
        />
      )}

      {openModalDetalleEmpresa && (
        <DetalleEmpresaFormMobile
          open={openModalDetalleEmpresa}
          handleClose={handleCloseModalDetalleEmpresa}
          empresa={empresaSeleccionada}
        />
      )}

      {openModalColaboracion && (
        <ModalColaboracionesForm
          colaboracionEditing={null}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          isEditing={false}
          personas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_PERSONA
          )}
          empresas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_EMPRESA
          )}
          principios={principios}
          compromisos={compromisos}
          categoriasColaboracion={categoriasColaboracion}
          setLoading={setLoading}
          initData={modalColaboracionInitData}
        />
      )}
    </Box>
  );
};
