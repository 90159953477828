import React from "react";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";

export default () => (
  <Container maxWidth="lg">
    <Grid container>
      <Grid item md={12}>
        <Typography variant="h4" align="center" p={5}>
          Debe conectarse para poder utilizar esta funcionalidad
        </Typography>
      </Grid>
    </Grid>
  </Container>
);
