import React from 'react';
import Container from "@mui/material/Container";
import {Grid, Typography} from "@mui/material";

export default () =>
    <Container maxWidth="lg">
        <Grid container>
            <Grid item md={12}>
                <Typography variant='h4' align='center' p={5}>
                    Usted ya se encuentra registrado en el sistema como socio de tipo diferente al que intenta acceder.
                    Contacte con un administrador para regularizar su situación
                </Typography>
            </Grid>
        </Grid>
    </Container>