import { Box, Button, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import * as Constants from "../../support/Constants";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { Edit, Search, ZoomIn } from "@mui/icons-material";
import { ModalCompromisoDetalle } from "../compromisos/ModalCompromisoDetalle";
import { ModalCompromisosSeleccion } from "../compromisos/ModalCompromisosSeleccion";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { GridActionItem } from "../ui/GridActionItem";
import * as Common from "../../support/Common";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const SeccionCompromisosAdheridos = ({
  assignedCommitments,
  setAssignedCommitments,
  compromisosSeleccionados,
  setCompromisosSeleccionados,
  isReadOnly,
  mostrarTitulo,
  isCreatingService,
  loading,
}) => {
  const { compromisos, getCompromisosEnabled } = useContext(CompromisosContext);
  const [openModalCompromisosSeleccion, setOpenModalCompromisosSeleccion] =
    useState(null);
  const [openModalDetalleCompromiso, setOpenModalDetalleCompromiso] =
    useState(false);
  const [compromisoSeleccionado, setCompromisoSeleccionado] = useState(null);

  var errors = [];
  var touched = [];

  const handleOpenModalDetalleCompromiso = (compromiso) => {
    setOpenModalDetalleCompromiso(true);
    setCompromisoSeleccionado(compromiso);
  };

  const handleCloseModalDetalleCompromiso = () => {
    setOpenModalDetalleCompromiso(false);
    setCompromisoSeleccionado(null);
  };

  const handleOpenCommitmentsModal = () => {
    setCompromisosSeleccionados(
      compromisos.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return assignedCommitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      })
    );
    setOpenModalCompromisosSeleccion(true);
  };

  const handleCloseCommitmentsModal = (nuevosCompromisosSeleccionados) => {
    setOpenModalCompromisosSeleccion(false);

    let updatedAssignedCommitments = [...assignedCommitments];

    // Añadir los compromisos seleccionados que no estén en updatedAssignedCommitments
    nuevosCompromisosSeleccionados?.forEach((compromisoSeleccionado) => {
      if (
        !updatedAssignedCommitments.some(
          (ac) => ac.commitmentCode == compromisoSeleccionado.code
        )
      ) {
        updatedAssignedCommitments.push({
          commitmentCode: compromisoSeleccionado.code,
          status: 1,
          deadlineDate: new Date(),
          modificationDate: 0,
        });
      }
    });

    // Eliminar los compromisos de updatedAssignedCommitments que no están en compromisosSeleccionados
    updatedAssignedCommitments = updatedAssignedCommitments.filter(
      (assignedCommitment) =>
        nuevosCompromisosSeleccionados.some(
          (compromisoSeleccionado) =>
            compromisoSeleccionado.code === assignedCommitment.commitmentCode
        )
    );

    setAssignedCommitments(updatedAssignedCommitments);
    setCompromisosSeleccionados(nuevosCompromisosSeleccionados);
  };

  const handleIrDetalleCompromiso = (compromiso) => {
    Common.goForward(
      "/view/compromiso/CompromisoSociosAdheridosPage/" + compromiso.code
    );
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" lg={12}>
        <Grid item lg={6}>
          {mostrarTitulo && (
            <Typography
              id="modal-modal-title"
              variant="subtitle1"
              component="h2"
            >
              Compromisos adheridos
            </Typography>
          )}
        </Grid>
        <Grid item lg={6} container justifyContent="flex-end">
          <Grid item>
            {!isReadOnly && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleOpenCommitmentsModal()}
                endIcon={<Edit />}
              >
                Edit.
              </Button>
            )}
          </Grid>
        </Grid>

        {assignedCommitments?.length === 0 ? (
          <Typography
            mt={3}
            variant="body2"
            component="h2"
            style={{ textAlign: "center" }}
          >
            No se encuentra adherido a ningún compromiso
          </Typography>
        ) : (
          assignedCommitments?.map((assignedCommitment) => {
            const compromiso = compromisosSeleccionados?.find(
              (c) => c?.code == assignedCommitment.commitmentCode
            );

            return (
              (compromiso || assignedCommitment) && (
                <Grid container spacing={2} alignItems="center">
                  <Grid item lg={3}>
                    <Box mt={2}>
                      <TextField
                        name={
                          "_compromiso_" + assignedCommitment.commitmentCode
                        }
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        select={true}
                        defaultValue=""
                        fullWidth={true}
                        title={
                          assignedCommitment?.modificationDate &&
                          assignedCommitment?.modificationDate > 0
                            ? "Se modificó en fecha " +
                              moment
                                .unix(assignedCommitment.modificationDate)
                                .format("YYYY-MM-DD HH:mm")
                            : ""
                        }
                        onChange={(event) => {
                          const value = event.target.value;
                          // Actualizar el estado assignedCommitments
                          setAssignedCommitments((prevAssignedCommitments) => {
                            return prevAssignedCommitments.map((commitment) => {
                              if (
                                commitment.commitmentCode ===
                                assignedCommitment.commitmentCode
                              ) {
                                return {
                                  ...commitment,
                                  status: value,
                                  deadlineDate:
                                    value != Constants.HAS_INTENTION
                                      ? 0
                                      : new Date(),
                                };
                              }
                              return commitment;
                            });
                          });
                        }}
                        //onBlur={handleBlur}
                        value={assignedCommitment.status}
                        error={Boolean(
                          touched[
                            "_compromiso_" + assignedCommitment.commitmentCode
                          ] &&
                            errors[
                              "_compromiso_" + assignedCommitment.commitmentCode
                            ]
                        )}
                        helpertext={
                          touched[
                            "_compromiso_" + assignedCommitment.commitmentCode
                          ] &&
                          errors[
                            "_compromiso_" + assignedCommitment.commitmentCode
                          ]
                        }
                        disabled={isReadOnly}
                      >
                        <MenuItem
                          key={
                            assignedCommitment.commitmentCode +
                            "|" +
                            Constants.COMPLIES
                          }
                          value={Constants.COMPLIES}
                        >
                          Cumple
                        </MenuItem>

                        <MenuItem
                          key={
                            assignedCommitment.commitmentCode +
                            "|" +
                            Constants.HAS_INTENTION
                          }
                          value={Constants.HAS_INTENTION}
                        >
                          Tiene intención
                        </MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item lg={9}>
                    <Box
                      mt={2}
                      sx={{
                        justifyContent: "space-between",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Tooltip
                        title={
                          compromiso
                            ? compromiso.title
                            : assignedCommitment.title
                        }
                      >
                        <TextField
                          sx={{ width: "80%" }}
                          id="outlined-basic"
                          label="Compromiso"
                          variant="outlined"
                          size="small"
                          disabled={true}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          value={
                            compromiso
                              ? compromiso.title
                              : assignedCommitment.title
                          }
                        />
                      </Tooltip>

                      {assignedCommitment.status == Constants.HAS_INTENTION ? (
                        <Tooltip
                          title={
                            "Tiene hasta " +
                            assignedCommitment.deadlineDate +
                            " para cumplirlo"
                          }
                        >
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              name={
                                "_compromiso_" +
                                assignedCommitment.commitmentCode +
                                "_date"
                              }
                              label="Plazo"
                              inputFormat="DD-MM-YYYY"
                              value={assignedCommitment.deadlineDate}
                              onChange={(newValue) => {
                                // Actualizar el estado assignedCommitments
                                setAssignedCommitments(
                                  (prevAssignedCommitments) => {
                                    return prevAssignedCommitments.map(
                                      (commitment) => {
                                        if (
                                          commitment.commitmentCode ==
                                          assignedCommitment.commitmentCode
                                        ) {
                                          return {
                                            ...commitment,
                                            deadlineDate: new Date(newValue),
                                          };
                                        }
                                        return commitment;
                                      }
                                    );
                                  }
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              disabled={isReadOnly}
                            />
                          </LocalizationProvider>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      <GridActionItem
                        icon={<ZoomIn />}
                        label="Detalle"
                        onClick={() =>
                          handleOpenModalDetalleCompromiso(compromiso)
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              )
            );
          })
        )}
      </Grid>

      {openModalCompromisosSeleccion && (
        <ModalCompromisosSeleccion
          open={openModalCompromisosSeleccion}
          handleClose={handleCloseCommitmentsModal}
          compromisos={compromisos}
          compromisosSeleccionados={compromisosSeleccionados}
          setCompromisosSeleccionados={setCompromisosSeleccionados}
          isCreatingService={isCreatingService}
        />
      )}

      {openModalDetalleCompromiso && (
        <ModalCompromisoDetalle
          open={openModalDetalleCompromiso}
          handleClose={handleCloseModalDetalleCompromiso}
          compromiso={compromisoSeleccionado}
        />
      )}
    </>
  );
};
