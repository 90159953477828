import axios from "axios";

export const getPrinciples = async (code, title) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/principles`;

    if (code !== undefined) {
      url += `?code=${code}`;
    }
    if (title !== undefined) {
      url += `${code !== undefined ? "&" : "?"}title=${title}`;
    }

    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    });
    return response.data.principles;
  } catch (error) {
    throw error;
  }
};
