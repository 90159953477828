import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import GroupIcon from "@mui/icons-material/Group";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const CompromisosFiltroDimensiones = ({
  filtroDimensiones,
  setFiltroDimensiones,
}) => {
  const opcionesDimensiones = [
    { key: "E", label: "(E)", icon: <PublicIcon /> },
    { key: "S", label: "(S)", icon: <GroupIcon /> },
    { key: "G", label: "(G)", icon: <AccountBalanceIcon /> },
  ];

  const handleChangeFiltroDimensiones = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "Seleccionar todas") {
      setFiltroDimensiones(opcionesDimensiones.map((op) => op.key));
    } else if (value[value.length - 1] === "Limpiar selección") {
      setFiltroDimensiones([]);
    } else {
      setFiltroDimensiones(value);
    }
  };

  return (
    <TextField
      id="filtro-dimensiones"
      select
      label="Dimensiones ESG"
      size="small"
      value={filtroDimensiones ?? []}
      onChange={handleChangeFiltroDimensiones}
      SelectProps={{
        multiple: true,
        renderValue: (selected) =>
          selected
            .map(
              (s) => opcionesDimensiones.find((o) => o.key === s)?.label || s
            )
            .join(", "),
      }}
      variant="outlined"
      fullWidth
      sx={{ minWidth: 200 }}
    >
      {opcionesDimensiones.map((opcion) => (
        <MenuItem key={opcion.key} value={opcion.key}>
          <Checkbox checked={filtroDimensiones?.includes(opcion.key)} />
          <Chip
            icon={opcion.icon}
            label={opcion.label}
            color="primary"
            variant="outlined"
          />
        </MenuItem>
      ))}
      <MenuItem value="Seleccionar todas">
        <ListItemText primary="Seleccionar todas" />
      </MenuItem>
      <MenuItem value="Limpiar selección">
        <ListItemText primary="Limpiar selección" />
      </MenuItem>
    </TextField>
  );
};

export default CompromisosFiltroDimensiones;
