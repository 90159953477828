import {
  Box,
  Button,
  Grid,
  Modal,
  Typography,
  Tab,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import TextField from "@mui/material/TextField";

import React, { useContext, useEffect, useState } from "react";
import * as Constants from "../../support/Constants";
import moment from "moment/moment";
import { Search } from "@mui/icons-material";
import { ModalTexto } from "../utiles/ModalTexto";
import { CancelButton } from "../ui/CustomButtons";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import ServiciosContext from "../../context/servicios/ServiciosContext";

import SociosContext from "../../context/socios/SociosContext";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { SeccionCompromisosAdheridos } from "../compromisos/SeccionCompromisosAdheridos";
import * as Common from "../../support/Common";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MEMBER_DATA } from "../../support/Web3Manager";
import { GridActionItem } from "../ui/GridActionItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const ModalDetalleEmpresaForm = ({ open, handleClose, empresa }) => {
  const [openModalTexto, setOpenModalTexto] = useState(false);
  const [texto, setTexto] = useState("");

  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);

  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const {
    servicios,
    getServiciosEmpresa,
    cleanServicios,
    isLoading: loadingServicios,
  } = useContext(ServiciosContext);

  const handleOpenModalTexto = (texto) => {
    setOpenModalTexto(true);
    setTexto(texto);
  };

  const handleCloseModalTexto = () => {
    setOpenModalTexto(false);
    setTexto("");
  };

  const [valueTab, setValueTab] = useState("1");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (empresa && empresa.code) {
      getCompromisosEnabled();
      getServiciosEmpresa(empresa.id);
      setValueTab("1");
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa) {
      const compromisosSeleccionadosList = compromisos?.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return empresa.commitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      });

      const newListWithDates = empresa.commitments?.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate).format("YYYY-MM-DD"),
          };
        } else {
          return item;
        }
      });

      setAssignedCommitments(newListWithDates ? newListWithDates : []);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    }
  }, [empresa, compromisos]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(true, null, false);
        cleanServicios();
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
      }}
    >
      <Box sx={style} component="form">
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {"Detalle de empresa"}
        </Typography>
        <br />
        <Grid container spacing={4}>
          <Grid item lg={5}>
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={empresa?.memberAddress}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={empresa?.name}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Nombre Fantasía"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={empresa?.businessName}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Correo Electrónico"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={empresa?.email}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Categoría"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={Constants.getNombreCategoria(empresa?.category)}
              />
            </Box>

            <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
              <TextField
                id="outlined-basic"
                label="Estado"
                variant="outlined"
                fullWidth={true}
                size="small"
                disabled={true}
                value={Constants.getNombreEstado(empresa?.status)}
              />
              {empresa?.statusMessage != null &&
                empresa?.statusMessage !== "" && (
                  <Box ml={0.5}>
                    <GridActionItem
                      icon={<Search />}
                      label="Ver mensaje estado"
                      onClick={() =>
                        handleOpenModalTexto(empresa?.statusMessage)
                      }
                    />
                  </Box>
                )}
              <TextField
                sx={{ marginLeft: 1 }}
                id="outlined-basic"
                label="Fecha de Registro"
                variant="outlined"
                fullWidth={true}
                size="small"
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={moment(empresa?.registerDate).format("DD-MM-YYYY")}
              />
            </Box>

            {empresa?.roles && empresa?.roles.length > 0 && (
              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  label="Roles Asignados"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  disabled={true}
                  value={empresa?.roles
                    .map((roleCode) => Constants.getNombreRol(roleCode))
                    .filter((nombreRol) => nombreRol)
                    .join(", ")}
                />
              </Box>
            )}

            <br />
            <Divider />

            {empresa?.status !== Constants.MEMBER_STATUS_NOWALLET && (
              <Box
                mt={1}
                mb={1}
                sx={{ justifyContent: "left", display: "flex" }}
              >
                <Button
                  color="primary"
                  onClick={() =>
                    Common.goForward("/view/socio/ServiciosPage/" + empresa?.id)
                  }
                >
                  {MEMBER_DATA.memberType === Constants.MEMBER_TYPE_EMPRESA &&
                  empresa?.id === MEMBER_DATA.memberCode
                    ? "Mis Aplicaciones"
                    : "Ver Aplicaciones"}
                </Button>
              </Box>
            )}
          </Grid>

          <Grid item lg={7} sx={{ position: "relative" }}>
            {loadingCompromisos || loadingServicios ? (
              <CircularProgress
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : (
              <Grid container spacing={4}>
                <Grid item lg={12}>
                  <TabContext value={valueTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Compromisos adheridos" value="1" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <Box>
                        <Typography variant="subtitle1" color="#005A59">
                          {"Compromisos adheridos como empresa"}
                        </Typography>

                        <SeccionCompromisosAdheridos
                          assignedCommitments={assignedCommitments}
                          setAssignedCommitments={setAssignedCommitments}
                          compromisosSeleccionados={compromisosSeleccionados}
                          setCompromisosSeleccionados={
                            setCompromisosSeleccionados
                          }
                          isReadOnly={true}
                          mostrarTitulo={false}
                        />

                        <Typography mt={4} variant="subtitle1" color="#005A59">
                          {"Compromisos adheridos por servicios"}
                        </Typography>

                        <Box
                          sx={{
                            maxHeight: "25em",
                            overflowY: "auto",
                          }}
                        >
                          {!servicios?.some(
                            (s) => s.commitments?.length > 0
                          ) ? (
                            <Typography
                              mt={3}
                              variant="body2"
                              component="h2"
                              style={{ textAlign: "center" }}
                            >
                              Los servicios de la empresa no se encuentran
                              adheridos a ningún compromiso
                            </Typography>
                          ) : (
                            servicios?.map((servicio) => {
                              if (
                                servicio.commitments?.length > 0 &&
                                servicio.enabled
                              ) {
                                return (
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography variant="subtitle1">
                                        {servicio.name}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <SeccionCompromisosAdheridos
                                        assignedCommitments={
                                          servicio.commitments
                                        }
                                        compromisosSeleccionados={compromisos?.filter(
                                          (c) =>
                                            servicio.commitments.some(
                                              (sc) =>
                                                sc.commitmentCode == c.code
                                            )
                                        )}
                                        isReadOnly={true}
                                        mostrarTitulo={false}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                );
                              } else {
                                return <></>;
                              }
                            })
                          )}
                        </Box>
                      </Box>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <ModalTexto
          open={openModalTexto}
          handleClose={handleCloseModalTexto}
          titulo={"Detalle del estado"}
          texto={texto}
        />

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
