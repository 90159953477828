import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Constants from "../../support/Constants";
import { Edit, ZoomIn } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import { ModalCompromisoDetalleMobile } from "./ModalCompromisoDetalleMobile";
import { ModalCompromisosSeleccionMobile } from "./ModalCompromisosSeleccionMobile";
import * as Common from "../../support/Common";

export const SeccionCompromisosAdheridosMobile = ({
  assignedCommitments,
  setAssignedCommitments,
  isReadOnly,
  mostrarTitulo,
  compromisosSeleccionados,
  setCompromisosSeleccionados,
  handleGuardarCompromisos,
  isCreatingService,
}) => {
  const theme = useTheme();

  const [openModalDetalleCompromiso, setOpenModalDetalleCompromiso] =
    useState(false);
  const [compromisoSeleccionado, setCompromisoSeleccionado] = useState(null);
  const { compromisos } = useContext(CompromisosContext);

  const [openModalCompromisosSeleccion, setOpenModalCompromisosSeleccion] =
    useState(null);

  const handleOpenModalDetalleCompromiso = (compromiso) => {
    setOpenModalDetalleCompromiso(true);
    setCompromisoSeleccionado(compromiso);
  };

  const handleCloseModalDetalleCompromiso = () => {
    setOpenModalDetalleCompromiso(false);
    setCompromisoSeleccionado(null);
  };

  const handleOpenCommitmentsModal = () => {
    setCompromisosSeleccionados(
      compromisos.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return assignedCommitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      })
    );
    setOpenModalCompromisosSeleccion(true);
  };

  const handleCloseCommitmentsModal = (nuevosCompromisosSeleccionados) => {
    setOpenModalCompromisosSeleccion(false);

    let updatedAssignedCommitments = [...assignedCommitments];

    // Añadir los compromisos seleccionados que no estén en updatedAssignedCommitments
    nuevosCompromisosSeleccionados?.forEach((compromisoSeleccionado) => {
      if (
        !updatedAssignedCommitments.some(
          (ac) => ac.commitmentCode == compromisoSeleccionado.code
        )
      ) {
        updatedAssignedCommitments.push({
          commitmentCode: compromisoSeleccionado.code,
          status: 1,
          deadlineDate: new Date(),
          modificationDate: 0,
        });
      }
    });

    // Eliminar los compromisos de updatedAssignedCommitments que no están en compromisosSeleccionados
    updatedAssignedCommitments = updatedAssignedCommitments.filter(
      (assignedCommitment) =>
        nuevosCompromisosSeleccionados.some(
          (compromisoSeleccionado) =>
            compromisoSeleccionado.code === assignedCommitment.commitmentCode
        )
    );

    setAssignedCommitments(updatedAssignedCommitments);
    setCompromisosSeleccionados(nuevosCompromisosSeleccionados);
  };

  const handleIrDetalleCompromiso = (compromiso) => {
    Common.goForward(
      "/view/compromiso/CompromisoSociosAdheridosPage/" + compromiso.code
    );
  };

  return (
    <>
      <Box>
        {mostrarTitulo && assignedCommitments.length > 0 && (
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            Compromisos adheridos
          </Typography>
        )}

        {assignedCommitments.length === 0 ? (
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            No se encuentra adherido a ningún compromiso
          </Typography>
        ) : (
          assignedCommitments.map((assignedCommitment, index) => {
            const compromiso = compromisosSeleccionados?.find(
              (c) => c?.code === assignedCommitment.commitmentCode
            );

            return (
              <React.Fragment key={index}>
                <Box sx={{ marginBottom: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Compromiso"
                        variant="outlined"
                        size="small"
                        /* value={assignedCommitment.title || 'Compromiso'} */
                        value={
                          compromiso
                            ? compromiso.title
                            : assignedCommitment.title
                        }
                        InputProps={{ readOnly: true }}
                        disabled={isReadOnly}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        label="Estado"
                        variant="outlined"
                        value={assignedCommitment.status}
                        onChange={(event) => {
                          const newStatus = event.target.value;
                          const updatedCommitments = assignedCommitments.map(
                            (commitment) =>
                              commitment.commitmentCode ===
                              assignedCommitment.commitmentCode
                                ? { ...commitment, status: newStatus }
                                : commitment
                          );
                          setAssignedCommitments(updatedCommitments);
                        }}
                        disabled={isReadOnly}
                      >
                        <MenuItem value={Constants.COMPLIES}>Cumple</MenuItem>
                        <MenuItem value={Constants.HAS_INTENTION}>
                          Tiene intención
                        </MenuItem>
                      </TextField>
                    </Grid>

                    {assignedCommitment.status === Constants.HAS_INTENTION && (
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            label="Plazo"
                            inputFormat="DD-MM-YYYY"
                            value={assignedCommitment.deadlineDate}
                            onChange={(newValue) => {
                              const updatedDeadline =
                                moment(newValue).format("YYYY-MM-DD");
                              const updatedCommitments =
                                assignedCommitments.map((commitment) =>
                                  commitment.commitmentCode ===
                                  assignedCommitment.commitmentCode
                                    ? {
                                        ...commitment,
                                        deadlineDate: updatedDeadline,
                                      }
                                    : commitment
                                );
                              setAssignedCommitments(updatedCommitments);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth size="small" />
                            )}
                            disabled={
                              isReadOnly ||
                              assignedCommitment.status !==
                                Constants.HAS_INTENTION
                            }
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        startIcon={<ZoomIn />}
                        onClick={() =>
                          handleOpenModalDetalleCompromiso(compromiso)
                        }
                        color="primary"
                      >
                        Detalle
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                {index !== assignedCommitments.length - 1 && (
                  <Divider sx={{ my: 2 }} />
                )}
              </React.Fragment>
            );
          })
        )}
        {!isReadOnly && (
          <Grid>
            <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => handleOpenCommitmentsModal()}
                color="primary"
                size="small"
              >
                Editar
              </Button>
              {!isCreatingService && (
                <Button
                  variant="contained"
                  onClick={handleGuardarCompromisos}
                  color="primary"
                  size="small"
                >
                  Guardar
                </Button>
              )}
            </Stack>
          </Grid>
        )}
      </Box>

      {openModalDetalleCompromiso && (
        <ModalCompromisoDetalleMobile
          open={openModalDetalleCompromiso}
          handleClose={handleCloseModalDetalleCompromiso}
          compromiso={compromisoSeleccionado}
        />
      )}

      {openModalCompromisosSeleccion && (
        <ModalCompromisosSeleccionMobile
          open={openModalCompromisosSeleccion}
          handleClose={handleCloseCommitmentsModal}
          compromisos={compromisos}
          compromisosSeleccionados={compromisosSeleccionados}
          setCompromisosSeleccionados={setCompromisosSeleccionados}
          isCreatingService={isCreatingService}
        />
      )}
    </>
  );
};
