import React, { useContext, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalCategoriaVotacionForm = ({
  open,
  handleClose,
  setLoading,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);

  const initialState = {
    _categoria: "",
  };

  const validationSchema = Yup.object({
    _categoria: Yup.string().required(
      "Debe ingresar una categoría de votación"
    ),
  });

  const formik = useFormik({
    initialValues: initialState,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      const { _categoria } = values;

      setDisableButton(true);
      openSpinner();

      Web3Manager.callContract(
        Web3Manager.CONTRACT_PARAMETERS,
        "addBallotCategory",
        _categoria
      )
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Categoría de votación
        </Typography>
        <Box mt={2}>
          <TextField
            name="_categoria"
            id="outlined-basic"
            label="Categoría"
            variant="outlined"
            size="small"
            fullWidth={true}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values._categoria}
            error={Boolean(touched._categoria && errors._categoria)}
            helperText={touched._categoria && errors._categoria}
          />
        </Box>
        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disableButton}
          >
            Agregar
          </Button>
          <CancelButton
            label="Cerrar"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
