import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  Grid,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { CheckCircle } from "@mui/icons-material";
import { CancelButton } from "../ui/CustomButtons";
import { useFormik } from "formik";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import SociosContext from "../../context/socios/SociosContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "70%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalAdherirCompromiso = ({
  open,
  handleClose,
  compromiso,
  compromisoEditing,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner, closeSpinner } = useContext(SpinnerContext);
  const {
    adherirACompromiso,
    editarAdhesionCompromiso,
    eliminarAdhesionCompromiso,
    isLoading,
  } = useContext(SociosContext);

  const isEditing = compromisoEditing !== undefined;
  const initialState = {
    _socioType: Web3Manager.MEMBER_DATA.memberType,
    _socioCode: Web3Manager.MEMBER_DATA.memberCode,
    _commitmentCode: compromiso.code,
    _status: Constants.HAS_INTENTION,
    _deadlineDate: moment().add(3, "months").format("YYYY-MM-DD"),
    _modificationDate: moment().format("YYYY-MM-DD"),
  };

  const getFormData = () => {
    let formData;

    if (!isEditing) {
      formData = initialState;
    } else {
      formData = {
        _socioType: Web3Manager.MEMBER_DATA.memberType,
        _socioCode: Web3Manager.MEMBER_DATA.memberCode,
        _commitmentCode: compromisoEditing.commitmentCode,
        _status: compromisoEditing.status,
        _deadlineDate:
          compromisoEditing.status === Constants.HAS_INTENTION
            ? moment(compromisoEditing.deadlineDate).format("YYYY-MM-DD")
            : moment().add(3, "months").format("YYYY-MM-DD"),
        _modificationDate: moment(compromisoEditing.modificationDate).format(
          "YYYY-MM-DD"
        ),
      };
    }

    return formData;
  };

  const formAdherirCompromiso = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const {
        _socioType,
        _socioCode,
        _commitmentCode,
        _status,
        _deadlineDate,
        _modificationDate,
      } = values;

      let _deadlineDateParsed = moment(_deadlineDate, "YYYY-MM-DD").unix();
      const _modificationDateParsed = moment(
        _modificationDate,
        "YYYY-MM-DD"
      ).unix();

      if (_status === Constants.COMPLIES) {
        _deadlineDateParsed = 0;
      }

      setDisableButton(true);
      openSpinner();
      if (!isEditing) {
        adherirACompromiso(
          _socioType,
          _socioCode,
          _commitmentCode,
          _status,
          _deadlineDateParsed,
          _modificationDateParsed
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            handleClose(false, error, false);
          });
      } else {
        if (_status === Constants.REMOVE_COMMITMENT) {
          eliminarAdhesionCompromiso(_socioCode, _socioType, _commitmentCode)
            .then((response) => {
              setDisableButton(false);
              resetForm();
              handleClose(false, response, true);
            })
            .catch((error) => {
              setDisableButton(false);
              handleClose(false, error, false);
            });
        } else {
          editarAdhesionCompromiso(
            _socioType,
            _socioCode,
            _commitmentCode,
            _status,
            _deadlineDateParsed,
            _modificationDateParsed
          )
            .then((response) => {
              setDisableButton(false);
              resetForm();
              handleClose(false, response, true);
            })
            .catch((error) => {
              setDisableButton(false);
              handleClose(false, error, false);
            });
        }
      }
    },
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
  } = formAdherirCompromiso;

  return (
    <Modal
      open={open}
      onClose={() => {
        resetForm();
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style}>
        <Grid container direction="column" spacing={2} sx={{ padding: 3 }}>
          <Typography variant="h6">
            {!isEditing
              ? `Adherir al compromiso ${compromiso.title}`
              : `Editar adhesión al compromiso ${compromiso.title}`}
          </Typography>
          <Grid container spacing={2} mt={1}>
            <Grid item>
              <TextField
                select
                name="_status"
                label="Estado del compromiso"
                value={values._status}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                size="small"
                variant="outlined"
                sx={{ width: 200 }}
              >
                <MenuItem value={Constants.COMPLIES}>Cumple</MenuItem>
                <MenuItem value={Constants.HAS_INTENTION}>
                  Tiene intención
                </MenuItem>
                {isEditing && (
                  <MenuItem value={Constants.REMOVE_COMMITMENT}>
                    Eliminar adhesión
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item>
              {values._status === Constants.HAS_INTENTION && (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Fecha límite"
                    inputFormat="DD-MM-YYYY"
                    value={values._deadlineDate}
                    onChange={(newValue) => {
                      formAdherirCompromiso.setFieldValue(
                        "_deadlineDate",
                        moment(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    disablePast
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        size="small"
                        sx={{ width: 200 }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Grid>
          </Grid>
          {!isEditing && (
            <Box mt={3} mb={2}>
              <Alert
                severity="info"
                sx={{
                  fontSize: "12px",
                }}
              >
                <AlertTitle>
                  ¿Está seguro que desea adherirse al compromiso?
                </AlertTitle>
              </Alert>
            </Box>
          )}
          <Box
            mt={2}
            sx={{ justifyContent: "right", display: "flex", gap: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<CheckCircle />}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Confirmar
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                resetForm();
                handleClose(true, null, false);
              }}
              disabled={isLoading}
            />
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalAdherirCompromiso;
