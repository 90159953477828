import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import TextField from "@mui/material/TextField";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { useNotification } from "../../hooks/useNotification";
import { getCompany } from "../../services/CompaniesServices";

const EmpresaPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  /////////////////////////////////////////////////////////////////////////////////////////////////////

  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCompanyData = () => {
    setIsLoading(true);
    getCompany(Web3Manager.MEMBER_DATA.memberAddress.toLowerCase())
      .then((currentCompanyList) => {
        if (currentCompanyList && currentCompanyList.length > 0) {
          const currentCompanyData = {
            _address: currentCompanyList[0].memberAddress,
            _name: currentCompanyList[0].name,
            _businessName: currentCompanyList[0].businessName,

            _email: currentCompanyList[0].email,
            _businessArea: currentCompanyList[0].businessArea,
            _category: Constants.getNombreCategoria(
              currentCompanyList[0].category
            ),
            _roles: currentCompanyList[0].roles.filter(
              (role) =>
                role !== Constants.ROLE_ADMIN && role !== Constants.ROLE_SOCIO
            ),
          };

          setCompanyData(currentCompanyData);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    // Recuperamos los datos asociados

    // Recuperamos (o no) a la persona. Si ya esta registrada, se traen los datos y se cambia a modo edicion
    const memberData = Web3Manager.MEMBER_DATA;
    if (memberData) {
      if (memberData.memberType === Constants.MEMBER_TYPE_EMPRESA) {
        // Verificamos el estado de la empresa. Para entrar a operar, debe estar approved
        if (Web3Manager.isAccountDisabled()) {
          Common.goForward("/errorSocioInhabilitado");
        }
        getCompanyData();
      } else if (memberData.memberType === Constants.MEMBER_TYPE_PERSONA) {
        // El registro es imcompatible, porque estamos entrando a una pantalla de gestion de empresas
        Common.goForward("/errorRegistroIncompatible");
      }
    } else {
      // El socio no esta registrado, porque no encontramos nada asociado a esta cuenta
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Mis datos
        </Typography>
      </Box>

      <Box>
        <Grid container justifyContent={"center"} spacing={4}>
          <Grid item lg={4}>
            {isLoading ? (
              <Box
                mt={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box mt={2}>
                  <TextField
                    name="_address"
                    id="outlined-basic"
                    label="Dirección"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={companyData?._address}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    name="_name"
                    id="outlined-basic"
                    label="Nombre"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={companyData?._name}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    name="_businessName"
                    id="outlined-basic"
                    label="Nombre Fantasía"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={companyData?._businessName}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    name="_email"
                    id="outlined-basic"
                    label="Correo Electrónico"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    value={companyData?._email}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    name="_category"
                    id="outlined-basic"
                    label="Categoría"
                    variant="outlined"
                    size="small"
                    fullWidth={true}
                    disabled={true}
                    value={companyData?._category}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>

                {companyData &&
                  companyData._roles &&
                  companyData._roles.length > 0 && (
                    <Box mt={2}>
                      <TextField
                        id="outlined-basic"
                        label="Roles Asignados"
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        disabled={true}
                        value={companyData._roles
                          .map((roleCode) => Constants.getNombreRol(roleCode))
                          .filter((nombreRol) => nombreRol)
                          .join(", ")}
                      />
                    </Box>
                  )}

                <>
                  <br />
                  <Divider />

                  <Box
                    mt={1}
                    mb={1}
                    sx={{ justifyContent: "left", display: "flex" }}
                  >
                    <Button
                      onClick={() =>
                        Common.goForward(
                          "/view/socio/ServiciosPage/" + companyData._address
                        )
                      }
                    >
                      Mis aplicaciones
                    </Button>
                  </Box>
                </>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <br />
    </Container>
  );
};

export default withRouter(EmpresaPage);
