import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { Search, TuneOutlined } from "@mui/icons-material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import * as Constants from "../../support/Constants";

const FiltrosColaboraciones = ({
  filtros,
  setFiltros,
  getColaboraciones,
  categoriasColaboracion,
  targetPersonas,
  targetEmpresas,
  loading,
  showSourceAndTargetFilters,
}) => {
  const [mostrarMasFiltros, setMostrarMasFiltros] = useState(false);

  const {
    emisor,
    receptor,
    titulo,
    categoria,
    tipo,
    estado,
    fechaDesde,
    fechaHasta,
  } = filtros;

  const [mergedTargets, setMergedTargets] = useState([]);

  useEffect(() => {
    const mergedArray = [...targetPersonas, ...targetEmpresas].map((item) => {
      return { name: item.name, memberAddress: item.memberAddress };
    });
    setMergedTargets(mergedArray);
  }, [targetEmpresas, targetPersonas]);

  return (
    <Grid container justifyContent="right">
      <Grid item lg={mostrarMasFiltros ? 12 : 6}>
        <Box mt={1} sx={{ justifyContent: "right", display: "flex" }} pb={3}>
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Título"
            variant="outlined"
            size="small"
            value={titulo}
            onChange={(event) =>
              setFiltros({ ...filtros, titulo: event.target.value })
            }
          />
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Tipo"
            variant="outlined"
            size="small"
            value={tipo}
            select={true}
            defaultValue={Constants.COLLABORATION_ANY}
            onChange={(event) =>
              setFiltros({ ...filtros, tipo: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="_type_todos" value={Constants.COLLABORATION_ANY}>
              TODOS
            </MenuItem>

            <MenuItem
              key="_type_principle"
              value={Constants.COLLABORATION_PRINCIPLE}
            >
              Principio
            </MenuItem>
            <MenuItem
              key="_type_commitment"
              value={Constants.COLLABORATION_COMMITMENT}
            >
              Compromiso
            </MenuItem>
          </TextField>
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Categoría"
            variant="outlined"
            size="small"
            select={true}
            value={categoria}
            defaultValue={"TODAS"}
            onChange={(event) =>
              setFiltros({
                ...filtros,
                categoria: event.target.value,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="_category_todas" value={"TODAS"}>
              TODAS
            </MenuItem>
            {categoriasColaboracion?.map((item) => (
              <MenuItem key={item.id} value={item.category}>
                {item.category}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ marginLeft: 1, width: 150 }}
            id="outlined-basic"
            label="Estado"
            variant="outlined"
            size="small"
            value={estado}
            select={true}
            defaultValue={Constants.COLLABORATION_STATUS_ANY}
            onChange={(event) =>
              setFiltros({ ...filtros, estado: event.target.value })
            }
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem
              key="_status_todos"
              value={Constants.COLLABORATION_STATUS_ANY}
            >
              TODOS
            </MenuItem>
            <MenuItem
              key="_status_pendiente"
              value={Constants.COLLABORATION_STATUS_PENDING}
            >
              Pendiente
            </MenuItem>
            <MenuItem
              key="_status_en_curso"
              value={Constants.COLLABORATION_STATUS_ACTIVE}
            >
              En curso
            </MenuItem>
            <MenuItem
              key="_status_finalizada"
              value={Constants.COLLABORATION_STATUS_FINALIZED}
            >
              Finalizada
            </MenuItem>
            <MenuItem
              key="_status_reportada"
              value={Constants.COLLABORATION_STATUS_REPORTED}
            >
              Reportada
            </MenuItem>
          </TextField>

          {mostrarMasFiltros && (
            <>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="DD-MM-YYYY"
                  value={fechaDesde}
                  onChange={(newValue) =>
                    setFiltros({
                      ...filtros,
                      fechaDesde: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginLeft: 1, width: 180 }}
                      size="small"
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="DD-MM-YYYY"
                  value={fechaHasta}
                  onChange={(newValue) =>
                    setFiltros({
                      ...filtros,
                      fechaHasta: newValue,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ marginLeft: 1, width: 180 }}
                      size="small"
                      {...params}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  )}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </LocalizationProvider>

              {mergedTargets && showSourceAndTargetFilters && (
                <Autocomplete
                  name="emisor"
                  options={mergedTargets}
                  getOptionLabel={(option) =>
                    `${option?.name} (${option?.memberAddress?.substring(
                      0,
                      20
                    )}...)`
                  }
                  onChange={(event, value) => {
                    setFiltros({
                      ...filtros,
                      emisor: value ? value.memberAddress : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Socio emisor"
                      variant="outlined"
                      size="small"
                      sx={{ marginLeft: 1, width: 150 }}
                      fullWidth={true}
                    />
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 250,
                      },
                    },
                  }}
                />
              )}
              {mergedTargets && showSourceAndTargetFilters && (
                <Autocomplete
                  name="receptor"
                  options={mergedTargets}
                  getOptionLabel={(option) =>
                    `${option?.name} (${option?.memberAddress?.substring(
                      0,
                      20
                    )}...)`
                  }
                  onChange={(event, value) => {
                    setFiltros({
                      ...filtros,
                      receptor: value ? value.memberAddress : "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Socio receptor"
                      variant="outlined"
                      size="small"
                      sx={{ marginLeft: 1, width: 150 }}
                      fullWidth={true}
                    />
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 250,
                      },
                    },
                  }}
                />
              )}
            </>
          )}
          <Tooltip
            placement="top"
            arrow
            title={
              !mostrarMasFiltros ? "Mostrar más filtros" : "Ocultar filtros"
            }
          >
            <IconButton
              aria-label="Mostrar más filtros"
              data-testid="button-tune"
              onClick={() => setMostrarMasFiltros(!mostrarMasFiltros)}
            >
              <TuneOutlined />
            </IconButton>
          </Tooltip>
          <Button
            sx={{ marginLeft: 1 }}
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={() =>
              getColaboraciones(
                emisor,
                receptor,
                titulo,
                categoria,
                tipo,
                estado,
                fechaDesde,
                fechaHasta
              )
            }
            endIcon={<Search />}
          >
            Buscar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FiltrosColaboraciones;
