import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import moment from "moment";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";

import { useNotification } from "../../hooks/useNotification";

import SociosContext from "../../context/socios/SociosContext";
import CompromisosContext from "../../context/compromisos/CompromisosContext";
import SpinnerContext from "../../context/spinner/SpinnerContext";

import { MisCompromisosTable } from "./MisCompromisosTable";
import { MisCompromisosCard } from "./MisCompromisosCard";
import ModalAdherirCompromiso from "../../components/compromisos/ModalAdherirCompromiso";
import ModalAdherirCompromisoMobile from "../../components/compromisos/ModalAdherirCompromisoMobile";
import { ModalEliminarAdhesionCompromiso } from "../../components/compromisos/ModalEliminarAdhesionCompromiso";
import { ModalEliminarAdhesionCompromisoMobile } from "../../components/compromisos/ModalEliminarAdhesionCompromisoMobile";

const MisCompromisosPage = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { handleOpenNotificacionTx } = useNotification();

  const [filtroNombre, setFiltroNombre] = useState("");
  const [filtroTitulo, setFiltroTitulo] = useState("");
  const [filtroComunidad, setFiltroComunidad] = useState("");
  const [compromisosSocio, setCompromisosSocio] = useState([]);
  const [compromisosSocioEnriched, setCompromisosSocioEnriched] = useState([]);
  const [compromisoEditing, setCompromisoEditing] = useState({});
  const [openModalAdherirse, setOpenModalAdherirse] = useState(false);
  const [
    openModalEliminarAdhesionCompromiso,
    setOpenModalEliminarAdhesionCompromiso,
  ] = useState(false);

  const { compromisos, getCompromisos } = useContext(CompromisosContext);
  const { showSpinner, closeSpinner } = useContext(SpinnerContext);
  const {
    socios,
    getSocios,
    isLoading: loadingCompromisos,
  } = useContext(SociosContext);

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }
    getCompromisos(0, "", "");

    getSocios(
      Constants.MEMBER_TYPE_SIN_REGISTRO,
      Constants.CATEGORY_ANY,
      "",
      Constants.MEMBER_STATUS_ANY,
      true
    );
  }, []);

  useEffect(() => {
    const currentSocio = socios.find(
      (socio) => socio.memberId === Web3Manager.MEMBER_DATA.memberId
    );

    let compromisos = [];
    let compromisosSocio = [];
    currentSocio?.commitments?.forEach((commitment) => {
      compromisosSocio.push({
        ...commitment,
        applicationId: 0,
        applicationName: "-",
      });
    });
    let compromisosAplicaciones = [];
    currentSocio?.services?.forEach((service) => {
      service.commitments.forEach((commitment) => {
        compromisosAplicaciones.push({
          ...commitment,
          applicationId: service.code,
          applicationName: service.name,
        });
      });
    });
    compromisos = compromisosSocio?.concat(compromisosAplicaciones);

    setCompromisosSocio(compromisos);
  }, [socios]);

  useEffect(() => {
    const compromisosSocioMerged = compromisosSocio?.map((compromisoSocio) => {
      const compromiso = compromisos.find(
        (c) => c.code === compromisoSocio.commitmentCode
      );
      if (compromiso) {
        return {
          ...compromisoSocio,
          title: compromiso.title,
          community: compromiso.community,
          appliesToPersons: compromiso.appliesToPersons,
          appliesToCompanies: compromiso.appliesToCompanies,
          appliesToServices: compromiso.appliesToServices,
          dimensionE: compromiso.dimensionE,
          dimensionS: compromiso.dimensionS,
          dimensionG: compromiso.dimensionG,
          sustainableDevelopmentGoals: compromiso.sustainableDevelopmentGoals,
        };
      }
    });

    const sortedCompromisos = compromisosSocioMerged.slice().sort((a, b) => {
      const compareTitulo = a.title.trim().localeCompare(b.title.trim());
      if (compareTitulo !== 0) {
        return compareTitulo;
      }

      return a.applicationName.trim().localeCompare(b.applicationName.trim());
    });

    setCompromisosSocioEnriched(sortedCompromisos);
  }, [compromisos, compromisosSocio]);

  const getEstadoCompromisoSocio = (row) => {
    return Constants.obtenerNombreEstadoCompromiso(row.status);
  };

  const getDeadlineDate = (row) => {
    if (row.status === Constants.HAS_INTENTION) {
      return moment(row.deadlineDate).format("DD-MM-YYYY");
    } else {
      return "-";
    }
  };

  const handleIrDetalleCompromiso = (row) => {
    Common.goForward(
      "/view/compromiso/CompromisoSociosAdheridosPage/" + row.commitmentCode
    );
  };

  const handleCloseModalAdherirse = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getSocios(
              Constants.MEMBER_TYPE_SIN_REGISTRO,
              Constants.CATEGORY_ANY,
              "",
              Constants.MEMBER_STATUS_ANY,
              true
            );
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenModalAdherirse(false);
  };

  const handleCloseModalEliminarAdhesionCompromiso = (
    canceled,
    txResponse,
    isSuccess
  ) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getSocios(
              Constants.MEMBER_TYPE_SIN_REGISTRO,
              Constants.CATEGORY_ANY,
              "",
              Constants.MEMBER_STATUS_ANY,
              true
            );
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No se cierra el modal
      }
    }
    setOpenModalEliminarAdhesionCompromiso(false);
  };

  return (
    <Container maxWidth="xl">
      <>
        <Typography
          variant="h3"
          color={"#005A59"}
          align="center"
          p={3}
          sx={{
            fontSize: {
              lg: "48px",
              sm: "36px",
              xs: "30px",
            },
          }}
        >
          Mis compromisos
        </Typography>

        <Grid>
          {matches ? (
            <MisCompromisosCard
              compromisos={compromisos}
              getEstadoCompromisoSocio={getEstadoCompromisoSocio}
              filtroNombre={filtroNombre}
              setFiltroNombre={setFiltroNombre}
              compromisosSocio={compromisosSocioEnriched}
              socios={socios}
              getDeadlineDate={getDeadlineDate}
              handleIrDetalleCompromiso={handleIrDetalleCompromiso}
              setOpenModalAdherirse={setOpenModalAdherirse}
              setOpenModalEliminarAdhesionCompromiso={
                setOpenModalEliminarAdhesionCompromiso
              }
              setCompromisoEditing={setCompromisoEditing}
              isLoading={loadingCompromisos}
              showSpinner={showSpinner}
            />
          ) : (
            <MisCompromisosTable
              compromisos={compromisos}
              getEstadoCompromisoSocio={getEstadoCompromisoSocio}
              filtroNombre={filtroNombre}
              setFiltroNombre={setFiltroNombre}
              compromisosSocio={compromisosSocioEnriched}
              socios={socios}
              getDeadlineDate={getDeadlineDate}
              filtroTitulo={filtroTitulo}
              setFiltroTitulo={setFiltroTitulo}
              filtroComunidad={filtroComunidad}
              setFiltroComunidad={setFiltroComunidad}
              isReadOnly={true}
              handleIrDetalleCompromiso={handleIrDetalleCompromiso}
              isLoading={loadingCompromisos}
              setOpenModalAdherirse={setOpenModalAdherirse}
              setOpenModalEliminarAdhesionCompromiso={
                setOpenModalEliminarAdhesionCompromiso
              }
              setCompromisoEditing={setCompromisoEditing}
              showSpinner={showSpinner}
            />
          )}
        </Grid>
      </>
      {openModalAdherirse &&
        (matches ? (
          <ModalAdherirCompromisoMobile
            open={openModalAdherirse}
            compromiso={compromisoEditing}
            handleClose={handleCloseModalAdherirse}
            isEditing={true}
            compromisoEditing={compromisoEditing}
          />
        ) : (
          <ModalAdherirCompromiso
            open={openModalAdherirse}
            compromiso={compromisoEditing}
            handleClose={handleCloseModalAdherirse}
            isEditing={true}
            compromisoEditing={compromisoEditing}
          />
        ))}
      {openModalEliminarAdhesionCompromiso &&
        (matches ? (
          <ModalEliminarAdhesionCompromisoMobile
            open={openModalEliminarAdhesionCompromiso}
            compromiso={compromisoEditing}
            handleClose={handleCloseModalEliminarAdhesionCompromiso}
          />
        ) : (
          <ModalEliminarAdhesionCompromiso
            open={openModalEliminarAdhesionCompromiso}
            compromiso={compromisoEditing}
            handleClose={handleCloseModalEliminarAdhesionCompromiso}
          />
        ))}
    </Container>
  );
};

export default withRouter(MisCompromisosPage);
