import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CancelButton, SubmitButton } from "../ui/CustomButtons";
import { DataGrid } from "@mui/x-data-grid";
import { ZoomIn } from "@mui/icons-material";
import { ModalCompromisoDetalle } from "./ModalCompromisoDetalle";
import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";
import { GridActionItem } from "../ui/GridActionItem";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "60%",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export const ModalCompromisosSeleccion = ({
  open,
  handleClose,
  compromisos,
  compromisosSeleccionados,
  setCompromisosSeleccionados,
  isCreatingService,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDetalleCompromiso, setOpenDetalleCompromiso] = useState(false);
  const [compromiso, setCompromiso] = useState(null);

  const handleRowSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleAceptar = () => {
    const nuevosCompromisosSeleccionados = compromisos.filter((compromiso) =>
      selectedRows.includes(compromiso.code)
    );

    handleClose(nuevosCompromisosSeleccionados);
  };

  const handleDetalleCompromiso = (compromisoSelected) => {
    setOpenDetalleCompromiso(true);
    setCompromiso(compromisoSelected);
  };

  const handleCloseDetalleCompromiso = () => {
    setOpenDetalleCompromiso(false);
    setCompromiso(null);
  };

  const columnsCompromisos = [
    {
      field: "title",
      headerName: "Título",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Acciones",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <GridActionItem
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetalleCompromiso(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setSelectedRows(
      compromisosSeleccionados?.map((compromiso) => compromiso.code) || []
    );
  }, []);

  const filterCompromisos = (compromisos) => {
    if (isCreatingService) {
      return compromisos.filter((c) => c.appliesToServices);
    }
    if (Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_PERSONA) {
      return compromisos.filter((c) => c.appliesToPersons);
    }

    if (Web3Manager.MEMBER_DATA.memberType === Constants.MEMBER_TYPE_EMPRESA) {
      return compromisos.filter((c) => c.appliesToCompanies);
    }

    return [];
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose(compromisosSeleccionados)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Selección de compromisos adheridos
          </Typography>

          <br />

          <DataGrid
            getRowId={(row) => row.code}
            rows={filterCompromisos(compromisos)}
            pageSize={10}
            autoHeight={true}
            loading={false}
            columns={columnsCompromisos}
            onSelectionModelChange={handleRowSelectionChange}
            selectionModel={selectedRows}
            rowsPerPageOptions={[10]}
            checkboxSelection
          />

          <Stack direction="row" spacing={1} mt={2} justifyContent="right">
            <Button
              sx={{ boxShadow: "2px 2px 4px rgba(0, 0, 0.0, 0.4)" }}
              variant="contained"
              onClick={() => {
                handleAceptar();
              }}
            >
              Aceptar
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                handleClose(compromisosSeleccionados);
              }}
              disabled={false}
            />
          </Stack>
        </Box>
      </Modal>
      {openDetalleCompromiso && (
        <ModalCompromisoDetalle
          open={openDetalleCompromiso}
          handleClose={handleCloseDetalleCompromiso}
          compromiso={compromiso}
        />
      )}
    </>
  );
};
