import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Chip,
  CircularProgress,
} from "@mui/material";

import {
  ZoomIn,
  Handshake,
  AssignmentTurnedIn,
  PendingActions,
} from "@mui/icons-material";
import * as Constants from "../../../support/Constants";
import * as Web3Manager from "../../../support/Web3Manager";

import { CompromisoSociosAdheridosFilterMobile } from "./CompromisoSociosAdheridosFilterMobile";

import { DetallePersonaFormMobile } from "../../socios/DetallePersonaFormMobile";
import { DetalleEmpresaFormMobile } from "../../socios/DetalleEmpresaFormMobile";
import { ModalColaboracionesFormMobile } from "../../colaboraciones/ModalColaboracionesFormMobile";

export const CompromisoSociosAdheridosCard = ({
  adhesiones,
  isLoading,
  showSpinner,
  getEstadoCompromisoSocio,
  getDeadlineDate,
  getApplicationNameToShow,
  handleDetalleSocio,
  handleColaborarConSocio,
  openModalDetallePersona,
  handleCloseModalDetallePersona,
  personaSeleccionada,
  openModalDetalleEmpresa,
  handleCloseModalDetalleEmpresa,
  empresaSeleccionada,
  openModalColaboracion,
  handleCloseModalColaboracion,
  socios,
  principios,
  compromisos,
  categoriasColaboracion,
  setLoading,
  modalColaboracionInitData,
  filtroNombre,
  setFiltroNombre,
  buscarAdhesiones,
}) => {
  const getMemberType = (value) => {
    if (value === Constants.MEMBER_TYPE_PERSONA) {
      return "Persona";
    } else if (value === Constants.MEMBER_TYPE_EMPRESA) {
      return "Empresa";
    } else {
      return "N/A";
    }
  };

  const getChipInfo = (status) => {
    switch (status) {
      case "Cumple":
        return {
          icon: <AssignmentTurnedIn style={{ color: "green" }} />,
          label: "Cumple",
          color: "success",
        };
      case "Tiene intención":
        return {
          icon: <PendingActions style={{ color: "orange" }} />,
          label: "Tiene intención",
          color: "warning",
        };
      default:
        return {
          icon: null,
          label: "Desconocido",
          color: "default",
        };
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CompromisoSociosAdheridosFilterMobile
        filtroNombre={filtroNombre}
        setFiltroNombre={setFiltroNombre}
        buscarAdhesiones={buscarAdhesiones}
        showSpinner={showSpinner}
      />

      {isLoading && !showSpinner ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : adhesiones.length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" align="center">
            No hay socios adheridos para mostrar.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {adhesiones.map((sociosComp) => {
            const status = getEstadoCompromisoSocio(sociosComp);

            const { icon, label, color } = getChipInfo(status);
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={sociosComp.commitmentCode + "," + sociosComp.applicationId}
              >
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {sociosComp.name}
                    </Typography>
                    <Chip
                      icon={icon}
                      label={label}
                      color={color}
                      variant="outlined"
                      size="small"
                      sx={{ p: 2, mb: 2 }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Tipo: {getMemberType(sociosComp.type)}
                    </Typography>
                    {getApplicationNameToShow(sociosComp) !== "-" && (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        Aplicación: {getApplicationNameToShow(sociosComp)}
                      </Typography>
                    )}

                    {getDeadlineDate(sociosComp) !== "-" && (
                      <Typography
                        variant="body2"
                        sx={{
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        Fecha límite: {getDeadlineDate(sociosComp)}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<ZoomIn />}
                          onClick={() => handleDetalleSocio(sociosComp)}
                          color="primary"
                          disabled={isLoading || showSpinner}
                          size="small"
                        >
                          Detalle
                        </Button>
                      </Grid>
                      {sociosComp?.memberAddress.toLowerCase() !=
                        Web3Manager.MEMBER_DATA.memberAddress.toLowerCase() && (
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<Handshake />}
                            onClick={() => handleColaborarConSocio(sociosComp)}
                            color="primary"
                            disabled={isLoading || showSpinner}
                            size="small"
                          >
                            Colaborar
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
      {openModalDetallePersona && (
        <DetallePersonaFormMobile
          open={openModalDetallePersona}
          handleClose={handleCloseModalDetallePersona}
          persona={personaSeleccionada}
        />
      )}

      {openModalDetalleEmpresa && (
        <DetalleEmpresaFormMobile
          open={openModalDetalleEmpresa}
          handleClose={handleCloseModalDetalleEmpresa}
          empresa={empresaSeleccionada}
        />
      )}

      {openModalColaboracion && (
        <ModalColaboracionesFormMobile
          colaboracionEditing={null}
          open={openModalColaboracion}
          handleClose={handleCloseModalColaboracion}
          isEditing={false}
          personas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_PERSONA
          )}
          empresas={socios.filter(
            (s) => s.type == Constants.MEMBER_TYPE_EMPRESA
          )}
          principios={principios}
          compromisos={compromisos}
          categoriasColaboracion={categoriasColaboracion}
          setLoading={setLoading}
          initData={modalColaboracionInitData}
        />
      )}
    </Box>
  );
};
