import React, { useEffect, useState } from "react";

import moment from "moment";

import * as Web3Manager from "../../support/Web3Manager";
import * as Decimals from "../../support/Decimals";

import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Box,
  Grid,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Search as SearchIcon, ZoomIn } from "@mui/icons-material";
import { DataGrid, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { CancelButton } from "../ui/CustomButtons";
import { useNotification } from "../../hooks/useNotification";
import { getMemberHours } from "../../services/HoursRegisterServices";
import { DetalleHorasMobile } from "./DetalleHorasMobile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalHorasSocioFormMobile = ({ open, handleClose, socio }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { handleOpenNotificacionError } = useNotification();
  const [filtroDesde, setFiltroDesde] = useState(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [filtroHasta, setFiltroHasta] = useState(
    moment().endOf("year").format("YYYY-MM-DD")
  );
  const [pagos, setPagos] = useState([]);
  const [totalHoras, setTotalHoras] = useState(0);

  const [detalleHorasOpen, setDetalleHorasOpen] = useState(false);
  const [registroHorasSeleccionado, setRegistroHorasSeleccionado] =
    useState(null);

  const handleDetalleHoras = (registroHoras) => {
    setRegistroHorasSeleccionado(registroHoras);
    setDetalleHorasOpen(true);
  };

  const handleDetalleHorasClose = () => {
    setDetalleHorasOpen(false);
    setRegistroHorasSeleccionado(null);
  };

  const getPagos = () => {
    // Transformamos los filtros de fechas a timestamp unix (esperado por unix)

    let _fromDate = 0;
    if (filtroDesde) {
      _fromDate = moment(filtroDesde, "YYYY-MM-DD").startOf("day").unix();
    }
    let _toDate = 0;
    if (filtroHasta) {
      _toDate = moment(filtroHasta, "YYYY-MM-DD").endOf("day").unix();
    }

    getMemberHours(socio.code, _fromDate, _toDate)
      .then((payments) => {
        const pagos = [];

        if (payments && payments.hourRegisterData) {
          payments.hourRegisterData.forEach((payment) => {
            pagos.push({
              _hourRegisteredCode: payment.hourRegisteredCode,
              _value: payment.value,
              _hoursDateFrom: payment.dateFrom,
              _hoursDateTo: payment.dateTo,
              _hoursDescription: payment.hoursDescription,
              _dateInfo: "",
            });
          });
        }
        setPagos(pagos);
      })
      .catch((error) => {
        //console.error(error);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const columnsPagos = [
    {
      field: "_value",
      headerName: "Horas",
      flex: 0.5,
      valueGetter: (params) => {
        return Decimals.format(params.value);
      },
    },
    {
      field: "_dateInfo",
      headerName: "Fecha(s)",
      flex: 1,
      renderCell: (params) => {
        const firstDate = moment
          .unix(params.row._hoursDateFrom)
          .format("DD-MM-YYYY");
        const secondDate = moment
          .unix(params.row._hoursDateTo)
          .format("DD-MM-YYYY");
        return (
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <span>{firstDate}</span>
            <span style={{ margin: "0 1px" }}>-</span>
            <span>{secondDate}</span>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Acciones",

      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                startIcon={<ZoomIn />}
                onClick={() => handleDetalleHoras(params.row)}
              >
                Detalle
              </Button>
            </Grid>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getPagos();
  }, [socio]);

  useEffect(() => {
    const horasRegistradas = pagos.reduce((total, pago) => {
      const { whole, fractional } = pago._value;
      return total + whole + fractional / 10;
    }, 0);
    setTotalHoras(horasRegistradas);
  }, [pagos]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Grid sx={{ marginLeft: 2 }}>
          <strong>Total de horas: </strong> {totalHoras}
        </Grid>

        <GridFooter />
      </GridFooterContainer>
    );
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Horas donadas por el socio
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box sx={{ p: 4 }}>
          {/* Filtros y búsqueda */}
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Dirección (@BLK)"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                value={socio ? socio.memberAddress : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                value={socio ? socio.name : ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Desde"
                  inputFormat="DD-MM-YYYY"
                  value={filtroDesde}
                  onChange={setFiltroDesde}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Fecha Hasta"
                  inputFormat="DD-MM-YYYY"
                  value={filtroHasta}
                  onChange={setFiltroHasta}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={getPagos}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>

          {/* Listado de horas */}
          <Box sx={{ mt: 4, width: "100%" }}>
            <DataGrid
              rows={pagos}
              pageSize={5}
              autoHeight={true}
              columns={columnsPagos}
              getRowId={(row) => row._hourRegisteredCode}
              components={{ Footer: CustomFooter }}
            />
          </Box>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <CancelButton
              label="Cerrar"
              onClick={() => {
                handleClose();
              }}
            />
          </Box>
        </Box>
      </Dialog>
      {detalleHorasOpen && (
        <DetalleHorasMobile
          open={detalleHorasOpen}
          handleClose={handleDetalleHorasClose}
          registroHoras={registroHorasSeleccionado}
        />
      )}
    </>
  );
};
