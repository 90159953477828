import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Grid,
  useTheme,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Search } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export const HorasFilterMobile = ({
  filtroDesde,
  setFiltroDesde,
  filtroHasta,
  setFiltroHasta,
  loading,
  getHoras,
}) => {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleAccordionChange('panel1')}
      sx={{ mb: 2, width: '100%' }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          height: 48,
          '&.Mui-expanded': {
            minHeight: 48,
            height: 48,
          },
        }}
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.primary.contrastText }} />
        }
      >
        <Typography>Filtrar Horas</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mt: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label='Fecha Desde'
                inputFormat='DD-MM-YYYY'
                value={filtroDesde}
                onChange={(newValue) =>
                  setFiltroDesde(newValue.format('YYYY-MM-DD'))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '100%', marginBottom: 1 }}
                    size='small'
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label='Fecha Hasta'
                inputFormat='DD-MM-YYYY'
                value={filtroHasta}
                onChange={(newValue) =>
                  setFiltroHasta(newValue.format('YYYY-MM-DD'))
                }
                renderInput={(params) => (
                  <TextField
                    sx={{ width: '100%', marginBottom: 1 }}
                    size='small'
                    {...params}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                )}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{}}
              variant='contained'
              color='primary'
              disabled={loading}
              onClick={() => getHoras()}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
