import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getPersons = async (category, status, name) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/persons?category=${category}&status=${status}&name=${name}`,
    });
    return response.data.persons;
  } catch (error) {
    throw error;
  }
};

export const getPerson = async (address) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/persons/address/${address}`,
    });

    return response.data.person;
  } catch (error) {
    throw error;
  }
};

export const getPersonByMemberId = async (memberId) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/persons/memberId/${memberId}`,
    });

    return response.data.person;
  } catch (error) {
    throw error;
  }
};
