import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import {
  Search,
  ZoomIn,
  Delete,
  Edit,
  Handshake,
  Restore,
} from "@mui/icons-material";

import moment from "moment";

import { ServiciosFilterMobile } from "./ServiciosFilterMobile";
import { ModalEliminarSerivio } from "./ModalEliminarServicio";
import { ModalServicioFormMobile } from "./ModalServicioFormMobile";

export const ServiciosCard = ({
  modeEdit,
  isLoading,
  handleDetalleServicio,
  showSpinner,
  handleEditarServicio,
  handleDeshabilitarServicio,
  handleHabilitarServicio,
  handleEliminarServicio,
  filtroNombre,
  setFiltroNombre,
  getServiciosEmpresa,
  empresaId,
  servicios,
  openModalServicio,
  handleCloseModalServicio,
  servicioSeleccionada,
  tiposServicio,
  isReadOnlyModalFormServicio,
  compromisos,
  openEliminarServicio,
  handleCloseEliminarServicio,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ServiciosFilterMobile
        filtroNombre={filtroNombre}
        setFiltroNombre={setFiltroNombre}
        showSpinner={showSpinner}
        isLoading={isLoading}
        getServiciosEmpresa={getServiciosEmpresa}
        empresaId={empresaId}
      />

      {isLoading && !showSpinner ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Centra el contenido horizontalmente
            justifyContent: "center",
            height: "50vh", // Centra el contenido verticalmente
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : servicios.length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" color="text.secondary" align="center">
            No hay aplicaciones para mostrar.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {servicios.map((serviciosItem) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={serviciosItem.code}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {serviciosItem.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Tipo: {serviciosItem.applicationType}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Email: {serviciosItem.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      URL: {serviciosItem.url ? serviciosItem.url : "N/A"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        textOverflow: "ellipsis",
                      }}
                    >
                      Fch. Registro:{" "}
                      {serviciosItem.creationDate
                        ? moment(serviciosItem.creationDate).format(
                            "DD-MM-YYYY"
                          )
                        : "N/A"}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          startIcon={<ZoomIn />}
                          onClick={() => handleDetalleServicio(serviciosItem)}
                          color="primary"
                          disabled={isLoading || showSpinner}
                          size="small"
                        >
                          Detalle
                        </Button>
                      </Grid>
                      {modeEdit && (
                        <>
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<Edit />}
                              onClick={() =>
                                handleEditarServicio(serviciosItem)
                              }
                              color="primary"
                              disabled={isLoading || showSpinner}
                              size="small"
                            >
                              Editar
                            </Button>
                          </Grid>
                          {serviciosItem.enabled ? (
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                startIcon={<Edit />}
                                onClick={() =>
                                  handleDeshabilitarServicio(serviciosItem)
                                }
                                color="primary"
                                disabled={isLoading || showSpinner}
                                size="small"
                              >
                                Deshabilitar
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                startIcon={<Restore />}
                                onClick={() =>
                                  handleHabilitarServicio(serviciosItem)
                                }
                                color="primary"
                                disabled={isLoading || showSpinner}
                                size="small"
                              >
                                Habilitar
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              variant="contained"
                              startIcon={<Delete />}
                              onClick={() =>
                                handleEliminarServicio(serviciosItem)
                              }
                              color="primary"
                              disabled={isLoading || showSpinner}
                              size="small"
                            >
                              Eliminar
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

      <ModalServicioFormMobile
        open={openModalServicio}
        handleClose={handleCloseModalServicio}
        servicio={servicioSeleccionada}
        tiposServicio={tiposServicio}
        isReadOnly={isReadOnlyModalFormServicio}
        compromisosEnabled={compromisos}
      />
      <ModalEliminarSerivio
        open={openEliminarServicio}
        servicio={servicioSeleccionada}
        handleClose={handleCloseEliminarServicio}
      />
    </Box>
  );
};
