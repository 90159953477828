// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case "SET_SERVICIOS":
      return {
        ...state,
        servicios: action.payload.servicios,
      };

    case "SET_APLICACIONES":
      return {
        ...state,
        aplicaciones: action.payload.aplicaciones,
      };

    default:
      return state;
  }
};
