// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
    switch (action.type) {
      case "SET_LOADING":
        return {
          ...state,
          isLoading: action.payload.isLoading,
        };
      case "SET_COMPROMISOS":
        return {
          ...state,
          compromisos: action.payload.compromisos,
        };
      case "SET_COMPROMISO":
        return {
          ...state,
          compromiso: action.payload.compromiso,
        };
      case "SET_FILTRO":
        return {
            ...state,
            filtro: action.payload.filtro,
        };
  
      default:
        return state;
    }
  };
  