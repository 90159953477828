import React, { useContext, useState } from "react";
import { useFormik } from "formik";

import { Unpublished } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
  Slide,
  useTheme,
  useMediaQuery,
  Toolbar,
  Grid,
  Alert,
  AlertTitle,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import SpinnerContext from "../../context/spinner/SpinnerContext";
import SociosContext from "../../context/socios/SociosContext";
import * as Web3Manager from "../../support/Web3Manager";
import { CancelButton } from "../ui/CustomButtons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalEliminarAdhesionCompromisoMobile = ({
  open,
  compromiso,
  handleClose,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [disableButton, setDisableButton] = useState(false);
  const { openSpinner } = useContext(SpinnerContext);
  const { eliminarAdhesionCompromiso } = useContext(SociosContext);

  const formData = {
    _socioCode: Web3Manager.MEMBER_DATA.memberCode,
    _socioType: Web3Manager.MEMBER_DATA.memberType,
    _compromisoCode: compromiso?.commitmentCode,
  };

  const formEliminarAdhesionCompromiso = useFormik({
    initialValues: formData,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const { _socioCode, _socioType, _compromisoCode } = values;

      setDisableButton(true);
      openSpinner();
      eliminarAdhesionCompromiso(_socioCode, _socioType, _compromisoCode)
        .then((response) => {
          setDisableButton(false);
          resetForm();
          handleClose(false, response, true);
        })
        .catch((error) => {
          setDisableButton(false);
          handleClose(false, error, false);
        });
    },
  });

  const { handleSubmit } = formEliminarAdhesionCompromiso;

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(true, null, false)}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Eliminar adhesión
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose(true, null, false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
        <Typography variant="h6" gutterBottom>
          {compromiso?.title}
        </Typography>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Alert
            severity="warning"
            sx={{
              fontSize: "12px",
            }}
          >
            <AlertTitle>
              ¿Estás seguro que deseas eliminar la adhesión al compromiso?
            </AlertTitle>
          </Alert>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end" mt={3}>
            <Button
              variant="contained"
              color="error"
              type="submit"
              disabled={disableButton}
              endIcon={<Unpublished />}
            >
              Eliminar Adhesión
            </Button>
            <CancelButton
              label="Cancelar"
              onClick={() => {
                handleClose(true, null, false);
              }}
            />
          </Stack>
        </Grid>
      </Box>
    </Dialog>
  );
};
