
import React, { useEffect } from "react";

import moment from "moment";

import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";

import { ModalServicioForm } from "../../components/servicios/ModalServicioForm";
import { GridActionItem } from "../../components/ui/GridActionItem";

import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { ZoomIn, Search } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const AplicacionesTable = ({
  loadingCompromisos,
  handleDetalleServicio,
  getServiciosEmpresas,
  getTiposServicio,
  getCompromisosEnabled,
  setIsFiltered,
  aplicaciones,
  filtroTipo,
  filtroEmpresa,
  setAplicacionesFiltradas,
  handleAgregarServicio,
  showSpinner,
  setFiltroEmpresa,
  empresasConAplicaciones,
  setFiltroTipo,
  tiposServicio,
  isFiltered,
  aplicacionesFiltradas,
  loadingServicios,
  openModalServicio,
  handleCloseModalServicio,
  aplicacionSeleccionada,
  isReadOnlyModalFormServicio,
  compromisos,
}) => {
  const columnsAplicaciones = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "company",
      headerName: "Empresa",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "applicationType",
      headerName: "Tipo",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "creationDate",
      headerName: "Fecha de registro",
      flex: 0.7,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format("DD-MM-YYYY");
        } else {
          return "N/A";
        }
      },
      hideSortIcons: true,
    },

    {
      field: "actions",
      headerName: "Acciones",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <GridActionItem
              disabled={loadingCompromisos}
              icon={<ZoomIn />}
              label="Detalle"
              onClick={() => handleDetalleServicio(params.row)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    getServiciosEmpresas();
    getTiposServicio();
    getCompromisosEnabled();
  }, []);

  const filterAplicaciones = () => {
    setIsFiltered(true);
    let aplicacionesFiltradas = aplicaciones;

    // Filtrar por tipo
    if (filtroTipo) {
      aplicacionesFiltradas = aplicacionesFiltradas.filter(
        (app) => app.applicationType === filtroTipo
      );
    }

    // Filtrar por empresa
    if (filtroEmpresa) {
      aplicacionesFiltradas = aplicacionesFiltradas.filter(
        (app) => app.company === filtroEmpresa
      );
    }

    setAplicacionesFiltradas(aplicacionesFiltradas);
  };

  return (
    <>
      <Grid container>
        {Web3Manager.MEMBER_DATA.memberType ===
        Constants.MEMBER_TYPE_EMPRESA ? (
          <Grid item lg={2}>
            <Box sx={{ justifyContent: "left", display: "flex" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAgregarServicio()}
                startIcon={<AddCircleIcon />}
                disabled={showSpinner}
              >
                Agregar
              </Button>
            </Box>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item lg={12}>
          <Box sx={{ display: "flex", justifyContent: "right" }} pb={3}>
            <TextField
              sx={{ marginLeft: 1, width: 300 }}
              id="outlined-basic"
              label="Empresa"
              variant="outlined"
              size="small"
              value={filtroEmpresa}
              select={true}
              onChange={(newValue) => setFiltroEmpresa(newValue.target.value)}
            >
              <MenuItem value={undefined}>Todas</MenuItem>
              {empresasConAplicaciones.map((empresa) => (
                <MenuItem key={empresa} value={empresa}>
                  {empresa}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ marginLeft: 1, width: 150 }}
              id="outlined-basic"
              label="Tipo"
              variant="outlined"
              size="small"
              value={filtroTipo}
              select={true}
              onChange={(newValue) => setFiltroTipo(newValue.target.value)}
            >
              <MenuItem value={undefined}>Todos</MenuItem>
              {tiposServicio.map((item) => (
                <MenuItem key={item.id} value={item.type}>
                  {item.type}
                </MenuItem>
              ))}
            </TextField>
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              onClick={() => filterAplicaciones()}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DataGrid
        rows={isFiltered ? aplicacionesFiltradas : aplicaciones}
        loading={(loadingCompromisos || loadingServicios) && !showSpinner}
        pageSize={50}
        autoHeight={true}
        columns={columnsAplicaciones}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        getRowId={(row) => `${row.code}-${row.company}`}
        rowsPerPageOptions={[50]}
        sortModel={[
          {
            field: "creationDate",
            sort: "asc",
          },
        ]}
      />

      <br />
      {openModalServicio && (
        <ModalServicioForm
          open={openModalServicio}
          handleClose={handleCloseModalServicio}
          servicio={aplicacionSeleccionada}
          tiposServicio={tiposServicio}
          isReadOnly={isReadOnlyModalFormServicio}
          compromisosEnabled={compromisos}
        />
      )}
    </>
  );
};
