import React from "react";
import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Grid,
  Stack,
  Tooltip,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CancelButton } from "../ui/CustomButtons";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  Accessibility,
  AccountBalance,
  Business,
  Groups,
  PhoneAndroid,
  Public,
} from "@mui/icons-material";
import * as Constants from "../../support/Constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ModalCompromisoDetalleMobile = ({
  open,
  handleClose,
  compromiso,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => handleClose()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Detalles del Compromiso
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 4 }}>
        <Typography variant="h6" gutterBottom>
          {compromiso?.title}
        </Typography>

        <Typography variant="subtitle1">
          {compromiso ? (
            <div
              dangerouslySetInnerHTML={{
                __html: compromiso.description,
              }}
            />
          ) : (
            ""
          )}
        </Typography>

        <Grid
          sx={{
            bgcolor: "#F5F5F5",
            "& svg": {
              fontSize: {
                lg: "1.5rem",
                xs: "1.25rem",
              },
            },
            "& .MuiTypography-root": {
              fontSize: {
                lg: "0.875rem",
                xs: "0.8rem",
              },
            },
          }}
          container
          spacing={1}
          borderRadius={4}
          p={3}
          mt={2}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="body2" sx={{ p: 1 }}>
              <strong>DIMENSIONES ESG:</strong>
              <Stack direction="row" spacing={1} marginTop={1.5}>
                <Tooltip title="Environment" placement="top" arrow>
                  <div>
                    <Chip
                      icon={<Public />}
                      label="(E)"
                      color="primary"
                      variant="outlined"
                      disabled={!compromiso?.dimensionE}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Social" placement="top" arrow>
                  <div>
                    <Chip
                      icon={<Groups />}
                      label="(S)"
                      color="primary"
                      variant="outlined"
                      disabled={!compromiso?.dimensionS}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="Governance" placement="top" arrow>
                  <div>
                    <Chip
                      icon={<AccountBalance />}
                      label="(G)"
                      color="primary"
                      variant="outlined"
                      disabled={!compromiso?.dimensionG}
                    />
                  </div>
                </Tooltip>
              </Stack>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ p: 1 }}>
              <strong>APLICABLE A:</strong>
              <Stack direction="row" spacing={1} marginTop={1.5}>
                <Grid item>
                  <Tooltip title="Personas" placement="top" arrow>
                    <Box
                      bgcolor="#F5F5F5"
                      borderRadius={4}
                      p={1}
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <Accessibility
                        color={
                          compromiso?.appliesToPersons ? "primary" : "disabled"
                        }
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Empresas" placement="top" arrow>
                    <Box
                      bgcolor="#F5F5F5"
                      borderRadius={4}
                      p={1}
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <Business
                        color={
                          compromiso?.appliesToCompanies
                            ? "primary"
                            : "disabled"
                        }
                      />
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Aplicaciones" placement="top" arrow>
                    <Box
                      bgcolor="#F5F5F5"
                      borderRadius={4}
                      p={1}
                      sx={{
                        border: "1px solid #ccc",
                      }}
                    >
                      <PhoneAndroid
                        color={
                          compromiso?.appliesToServices ? "primary" : "disabled"
                        }
                      />
                    </Box>
                  </Tooltip>
                </Grid>
              </Stack>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ p: 1 }}>
              <strong>COMUNIDAD:</strong>
            </Typography>
            <Typography
              variant="body2"
              sx={{ p: 1, fontSize: "1.2rem !important" }}
            >
              {compromiso?.community}
            </Typography>
          </Grid>
          {compromiso?.sustainableDevelopmentGoals.length > 0 && (
            <Grid item>
              <Typography variant="body2" sx={{ p: 1 }}>
                <strong>ODS EN LOS QUE IMPACTA:</strong>
              </Typography>
              <Grid container spacing={1} alignItems="center" sx={{ p: 1 }}>
                {compromiso?.sustainableDevelopmentGoals.map((goal, index) => (
                  <Grid item key={index}>
                    <Tooltip
                      title={Constants.getGoalTitle(goal.split(".")[0])}
                      placement="top"
                      arrow
                    >
                      <a
                        href={Constants.getGoalLink(goal.split(".")[0])}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <Chip
                          label={goal}
                          sx={{
                            backgroundColor: Constants.getGoalColor(
                              goal.split(".")[0]
                            ),
                            color: "#FFFFFF",
                            fontWeight: "bold",
                            border: "1px solid",
                            borderColor: Constants.getGoalColor(
                              goal.split(".")[0]
                            ),
                            fontSize: "1rem",
                          }}
                        />
                      </a>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>

        <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CancelButton label="Cerrar" onClick={() => handleClose()} />
        </Box>
      </Box>
    </Dialog>
  );
};
