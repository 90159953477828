import React, { useReducer } from "react";
import * as Web3Manager from "../../support/Web3Manager";
import { useNotification } from "../../hooks/useNotification";
import ServiciosReducer from "./ServiciosReducer";
import ServiciosContext from "./ServiciosContext";
import { getCompanyById } from "../../services/CompaniesServices";
import { getApplications } from "../../services/ApplicationsServices";
import moment from "moment";

const ServiciosState = (props) => {
  const initialState = {
    servicios: [],
    aplicaciones: [],
    isLoading: false,
  };

  const [state, dispatch] = useReducer(ServiciosReducer, initialState);
  const { servicios, aplicaciones, isLoading } = state;

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const getServiciosEmpresaCurrent = async (filtroCodigo, filtroNombre) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const result = [];

      const response =
        await Web3Manager.CONTRACT_APPLICATIONS.getCurrentCompanyApplications(
          filtroCodigo,
          filtroNombre
        );

      if (response && response.length > 0) {
        response.forEach((elemento) => {
          var commitments = [];
          elemento?.commitments?.forEach((assignedCommitment) => {
            commitments.push({
              commitmentCode: assignedCommitment.commitmentCode.toNumber(),
              status: assignedCommitment.status,
              deadlineDate: assignedCommitment.deadlineDate.toNumber(),
            });
          });

          result.push({
            code: elemento.code.toNumber(),
            name: elemento.name,
            description: elemento.description,
            applicationType: elemento.applicationType,
            email: elemento.email,
            url: elemento.url,
            enabled: elemento.enabled,
            commitments: commitments,
            creationDate: elemento.creationDate.toNumber(),
          });
        });
      }
      dispatch({
        type: "SET_SERVICIOS",
        payload: { servicios: result },
      });
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const getServiciosEmpresa = async (empresaId, filtroCodigo, filtroNombre) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const result = [];

      const response = await getApplications(
        empresaId,
        filtroCodigo,
        filtroNombre
      );

      if (response && response.length > 0) {
        response.forEach((elemento) => {
          result.push({
            code: elemento.code,
            name: elemento.name,
            description: elemento.description,
            applicationType: elemento.applicationType,
            email: elemento.email,
            url: elemento.url,
            enabled: elemento.enabled,
            commitments: elemento.commitments,
            creationDate: elemento.createdAt,
          });
        });
      }

      dispatch({
        type: "SET_SERVICIOS",
        payload: { servicios: result },
      });
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(error);
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  //para el listado en vista "Aplicaciones"
  const getServiciosEmpresas = async () => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const aplicaciones = await getApplications();

      const result = [];

      for (const elemento of aplicaciones) {
        const company = await getCompanyById(elemento.companyId);

        result.push({
          id: elemento.id,
          code: elemento.code,
          name: elemento.name,
          description: elemento.description,
          applicationType: elemento.applicationType,
          email: elemento.email,
          url: elemento.url,
          enabled: elemento.enabled,
          commitments: elemento.commitments,
          creationDate: elemento.createdAt,
          company: company.businessName, // Asegúrate de tener el campo companyName en tu respuesta del servidor
        });
      }

      dispatch({
        type: "SET_APLICACIONES",
        payload: { aplicaciones: result.filter((s) => s.enabled) },
      });
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const crearServicio = async (
    _name,
    _description,
    _tipoServicio,
    _email,
    _url,
    _enabled,
    _commitments
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const response = await Web3Manager.callContract(
        Web3Manager.CONTRACT_APPLICATIONS,
        "addApplication",
        _name,
        _description,
        _tipoServicio,
        _email,
        _url,
        _enabled,
        _commitments
      );

      return response;
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const modificarServicio = async (
    _code,
    _name,
    _description,
    _tipoServicio,
    _email,
    _url,
    _enabled,
    _commitments
  ) => {
    dispatch({ type: "SET_LOADING", payload: { isLoading: true } });

    try {
      const newListWithUnixDates = _commitments.map((item) => {
        if (item.modificationDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            modificationDate: moment(
              item.modificationDate,
              "YYYY-MM-DD"
            ).unix(), // Convertimos el campo a unix
          };
        } else {
          return item;
        }
      });

      const response = await Web3Manager.callContract(
        Web3Manager.CONTRACT_APPLICATIONS,
        "modifyApplication",
        _code,
        _name,
        _description,
        _tipoServicio,
        _email,
        _url,
        _enabled,
        newListWithUnixDates
      );

      return response;
    } catch (error) {
      //console.error("Error: " + JSON.stringify(error));
      handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
    } finally {
      dispatch({ type: "SET_LOADING", payload: { isLoading: false } });
    }
  };

  const cleanServicios = () => {
    dispatch({
      type: "SET_SERVICIOS",
      payload: { servicios: [] },
    });
  };

  return (
    <ServiciosContext.Provider
      value={{
        servicios,
        getServiciosEmpresa,
        getServiciosEmpresaCurrent,
        getServiciosEmpresas,
        aplicaciones,
        isLoading,
        crearServicio,
        modificarServicio,
        cleanServicios,
      }}
    >
      {props.children}
    </ServiciosContext.Provider>
  );
};
export default ServiciosState;
