import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Alert,
  AlertTitle,
  CircularProgress,
  Grid,
} from "@mui/material";

import * as Web3Manager from "../../support/Web3Manager";
import * as Constants from "../../support/Constants";
import moment from "moment/moment";
import { CancelButton } from "../ui/CustomButtons";
import { getPerson } from "../../services/PersonsServices";

export const ModalReporteColaboracion = ({
  open,
  handleClose,
  colaboracion,
}) => {
  const [loading, setLoading] = useState(true);
  const [reporteData, setReporteData] = useState([]);
  const [validatorData, setValidatorData] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
  };

  const getReport = (code) => {
    setLoading(true);
    Web3Manager.CONTRACT_REPORTS.getReportByCollaboration(code)
      .then((response) => {
        setReporteData(response);
        setLoading(false);
      })
      .catch((error) => {
        handleClose(false, error, false);
        setLoading(false);
      });
  };

  useEffect(() => {
    getReport(colaboracion.code);
  }, [colaboracion]);

  const getValidatorData = (address) => {
    getPerson(address)
      .then((response) => {
        setValidatorData(response);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    getValidatorData(reporteData[0]?.validatorAddress);
  }, [reporteData]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box container sx={style}>
        {!loading ? (
          <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2">
                Reporte {reporteData[0].collaborationTitle}
                <Chip
                  label={Constants.getNombreEstadoReporte(
                    reporteData[0].status
                  )}
                  sx={{
                    marginLeft: "0.5rem",
                    backgroundColor:
                      Constants.REPORT_STATUS_COLORS[reporteData[0].status],
                  }}
                />
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <strong>Código reporte:</strong>{" "}
                {reporteData[0].code.toNumber()}
              </Typography>{" "}
              <Typography variant="subtitle1">
                <strong>Código colaboración:</strong>{" "}
                {reporteData[0].collaborationCode.toNumber()}
              </Typography>{" "}
              <Typography variant="subtitle1">
                <strong>Reportada el:</strong>{" "}
                {moment
                  .unix(reporteData[0].creationDate.toNumber())
                  .format("DD-MM-YYYY")}
              </Typography>{" "}
            </Grid>

            <Grid item xs={12}>
              {reporteData[0].status === Constants.REPORT_STATUS_FINALIZED ? (
                <>
                  {reporteData[0].analysis !== "" ? (
                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        <strong>Análisis:</strong>
                      </Typography>
                      <Box sx={{ overflowY: "auto", maxHeight: 200, mt: 1 }}>
                        <Typography variant="body1" component="span">
                          {reporteData[0].analysis}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>
                        Aún no se ha realizado un análisis del reporte
                      </strong>
                    </Typography>
                  )}
                  {reporteData[0].conclusions !== "" ? (
                    <Box mt={2}>
                      <Typography variant="subtitle1">
                        <strong>Conclusiones:</strong>
                      </Typography>

                      <Box sx={{ overflowY: "auto", maxHeight: 200, mt: 1 }}>
                        <Typography variant="body1" component="span">
                          {reporteData[0].conclusions}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Typography variant="subtitle1">
                      <strong>El validador no ha emitido conclusiones</strong>
                    </Typography>
                  )}
                  {reporteData[0].status ===
                    Constants.REPORT_STATUS_FINALIZED &&
                    validatorData.length > 0 && (
                      <Box mt={2}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Validador: {validatorData[0].firstName}{" "}
                          {validatorData[0].lastName} (
                          {reporteData[0].validatorAddress})
                        </Typography>
                      </Box>
                    )}
                </>
              ) : (
                <Alert severity="info" sx={{ fontSize: "12px", mt: 2 }}>
                  <AlertTitle>
                    El reporte se encuentra en proceso de evaluación
                  </AlertTitle>
                  Una vez finalizado dicho proceso, podrás ver el análisis y
                  conclusiones emitidas por el equipo de validadores
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box
                mt={2}
                sx={{ display: "flex", justifyContent: "left", gap: "10px" }}
              >
                <CancelButton
                  label="Cerrar"
                  onClick={() => {
                    handleClose(true, null, false);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Box
            item
            mt="25%"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
