import axios from "axios";
import { handleRequestError } from "../support/ApiUtils";

export const getApplicationTypes = async () => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/parameters/applicationTypes`,
    });

    return response.data.applicationTypes;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const getCollaborationCategories = async () => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/parameters/collaborationCategories`,
    });

    return response.data.collaborationCategories;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};

export const getBallotCategories = async () => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/parameters/ballotCategories`,
    });

    return response.data.ballotCategories;
  } catch (error) {
    handleRequestError(error);
    throw error;
  }
};
