import axios from "axios";

export const getCommitments = async (
  code,
  title,
  community,
  appliesTo,
  dimensions,
  sdgs
) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/commitments`;
    let params = [];

    if (code !== undefined) {
      params.push(`code=${code}`);
    }

    if (title !== undefined) {
      params.push(`title=${title}`);
    }

    if (community !== undefined) {
      params.push(`community=${community}`);
    }

    if (appliesTo && appliesTo.length > 0) {
      const appliesToStr = appliesTo.join(",");
      params.push(`appliesTo=${appliesToStr}`);
    }

    if (dimensions && dimensions.length > 0) {
      const dimensionsStr = dimensions.join(",");
      params.push(`dimensions=${dimensionsStr}`);
    }

    if (sdgs && sdgs.length > 0) {
      const sdgsStr = sdgs.join(",");
      params.push(`sdgs=${sdgsStr}`);
    }

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    });

    return response.data.commitments;
  } catch (error) {
    throw error;
  }
};

export const getCommunities = async () => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/commitments/communities`;

    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    });

    return response.data.communities;
  } catch (error) {
    throw error;
  }
};

export const getAssignedCommitments = async (
  code,
  title,
  community,
  appliesTo,
  dimensions,
  sdgs
) => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/commitments/assigned`;
    let params = [];

    if (code !== undefined) {
      params.push(`code=${code}`);
    }

    if (title !== undefined) {
      params.push(`title=${title}`);
    }

    if (community !== undefined) {
      params.push(`community=${community}`);
    }

    if (appliesTo && appliesTo.length > 0) {
      const appliesToStr = appliesTo.join(",");
      params.push(`appliesTo=${appliesToStr}`);
    }

    if (dimensions && dimensions.length > 0) {
      const dimensionsStr = dimensions.join(",");
      params.push(`dimensions=${dimensionsStr}`);
    }

    if (sdgs && sdgs.length > 0) {
      const sdgsStr = sdgs.join(",");
      params.push(`sdgs=${sdgsStr}`);
    }

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    });

    return response.data.commitments;
  } catch (error) {
    throw error;
  }
};
