import React, { useState, useEffect, useContext } from "react";

import moment from "moment/moment";
import Linkify from "linkify-react";
import { useFormik } from "formik";

import * as Constants from "../../support/Constants";
import * as Web3Manager from "../../support/Web3Manager";

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Chip,
  Typography,
  Stack,
  Grid,
  Divider,
  Tooltip,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { HowToVote, Info } from "@mui/icons-material";
import { useTheme, useMediaQuery } from "@mui/material";

import { VoteBreakdown } from "./VoteBreakdown";
import { useNotification } from "../../hooks/useNotification";
import { CancelButton } from "../ui/CustomButtons";
import SpinnerContext from "../../context/spinner/SpinnerContext";
import {
  getMemberVoteProposal,
  getBallotResultProposal,
} from "../../services/BallotsServices";

const memberTypeMap = {
  0: "Sin registro",
  1: "Persona",
  2: "Empresa",
};

const roleMap = {
  0: "General",
  1: "Admin",
  2: "Gestión",
  3: "Directiva",
  4: "Validador",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalVotarPropuestaMobile = ({
  open,
  handleClose,
  propuesta,
  setLoading,
  isReadOnly,
}) => {
  const { handleOpenNotificacionError } = useNotification();
  const { openSpinner } = useContext(SpinnerContext);

  const [voteData, setVoteData] = useState([]);
  const [ballotData, setBallotData] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const canVote =
    !isReadOnly &&
    Web3Manager.MEMBER_DATA.memberCategory === Constants.CATEGORY_ACTIVO &&
    (propuesta.role === 0 ||
      Web3Manager.MEMBER_DATA.memberRoles.includes(propuesta.role));

  const getVote = (code) => {
    setLoading(true);
    setDisableButton(true);
    getMemberVoteProposal(
      Web3Manager.MEMBER_DATA.memberType,
      Web3Manager.MEMBER_DATA.memberCode,
      code
    )
      .then((response) => {
        setDisableButton(false);
        setLoading(false);
        setVoteData(response);
      })
      .catch((error) => {
        setLoading(false);
        setDisableButton(false);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const getBallotData = (code) => {
    setLoading(true);
    setDisableButton(true);
    getBallotResultProposal(code)
      .then((response) => {
        setDisableButton(false);
        setLoading(false);
        setBallotData(response);
      })
      .catch((error) => {
        setLoading(false);
        setDisableButton(false);
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDeleteVote = () => {
    setDisableButton(true);
    setLoading(true);
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_BALLOTS,
      "deleteVote",
      propuesta.code
    )
      .then((response) => {
        setDisableButton(false);
        setLoading(false);
        handleClose(false, response, true);
      })
      .catch((error) => {
        setDisableButton(false);
        setLoading(false);
        handleClose(false, error, false);
      });
  };

  const initialState = {
    _code: propuesta.code,
    _value: 0,
  };

  const getFormData = () => {
    let formData;

    if (!voteData) {
      formData = initialState;
    } else {
      formData = {
        _code: propuesta.code,
        _value: voteData.value,
      };
    }

    return formData;
  };

  const formVotacion = useFormik({
    initialValues: getFormData(),
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const { _code, _value } = values;

      setDisableButton(true);
      setLoading(true);
      openSpinner();
      if (!voteData) {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_BALLOTS,
          "addVote",
          _code,
          _value
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      } else {
        Web3Manager.callContract(
          Web3Manager.CONTRACT_BALLOTS,
          "modifyVote",
          _code,
          _value
        )
          .then((response) => {
            setDisableButton(false);
            resetForm();
            handleClose(false, response, true);
          })
          .catch((error) => {
            setDisableButton(false);
            setLoading(false);
            handleClose(false, error, false);
          });
      }
    },
  });

  const { handleSubmit, values, resetForm } = formVotacion;

  useEffect(() => {
    getVote(propuesta.code);
    getBallotData(propuesta.code);
  }, []);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={() => handleClose(true, null, false)}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Detalle Propuesta
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              resetForm();
              handleClose(true, null, false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box container>
        <Grid container direction="column" spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                {"Propuesta " + propuesta.title}
              </Typography>

              <Chip
                label={Constants.getNombreEstadoPropuesta(propuesta.status)}
                sx={{ marginLeft: "0.5rem" }}
              />
            </Box>
          </Grid>

          <Grid item>
            <Box item>
              <Chip
                label={propuesta.category}
                sx={{
                  backgroundColor:
                    Constants.PROPOSALS_CATEGORY_COLORS[propuesta.category],
                }}
              />
              <Chip
                label={roleMap[propuesta.role]?.toUpperCase()}
                sx={{ marginLeft: 2, bgcolor: "#90EE90" }}
              />
            </Box>

            <Box container direction="column" marginTop={1}>
              <Box
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    {"Proponente: "}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {`${propuesta?.memberName}`}
                  </Typography>
                  <Chip
                    label={memberTypeMap[propuesta.memberType]}
                    sx={{
                      marginLeft: "0.5rem",
                      width: "100px",
                      backgroundColor:
                        Constants.MEMBER_TYPE_COLORS[propuesta.memberType],
                    }}
                  />
                </Box>
              </Box>
              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Código de la propuesta: "}
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">{propuesta.code}</Typography>
              </Box>

              <Box item sx={{ display: "flex", alignItems: "center", mt: 1.0 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Fecha de creación:
                </Typography>
                &nbsp;
                <Typography variant="subtitle1">
                  {moment(propuesta.creationDate).format("DD-MM-YYYY")}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item>
            <Box container>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  {"Descripción: "}
                </Typography>
                <Box mt={1}>
                  <Typography variant="subtitle1">
                    {propuesta ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: propuesta.description,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </Box>

              {propuesta.minimumVotesPct !== 0 && (
                <Box container>
                  <Box
                    item
                    marginTop={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography variant="body1" component="span">
                      <Tooltip title="Porcentaje del total de socios que debe votar para que se apruebe la propuesta">
                        <IconButton>
                          <Info />
                        </IconButton>
                      </Tooltip>
                      Porcentaje mínimo de votantes:
                      {propuesta.minimumVotesPct}%
                    </Typography>{" "}
                    <Typography variant="body1" component="span">
                      <Tooltip title="Porcentaje mínimo de votos positivos de la propuesta para que se apruebe">
                        <IconButton>
                          <Info />
                        </IconButton>
                      </Tooltip>
                      Porcentaje mínimo de votos positivos:{" "}
                      {propuesta.passingVotesPct}%
                    </Typography>{" "}
                  </Box>
                </Box>
              )}

              <Box item marginTop={2}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {" "}
                  Desglose de votos:
                </Typography>

                <VoteBreakdown ballotData={ballotData} />
              </Box>

              {propuesta.statusMessage !== "" &&
                (propuesta.status === Constants.PROPOSAL_STATUS_ADMITTED ||
                  propuesta.status ===
                    Constants.PROPOSAL_STATUS_NOT_ADMITTED) && (
                  <Box item marginTop={2}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {" "}
                      Mensaje de estado
                    </Typography>
                    <Typography>
                      <Linkify>{propuesta.statusMessage}</Linkify>
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>

          <Grid item>
            {propuesta.status === Constants.PROPOSAL_STATUS_ACTIVE &&
            canVote ? (
              <Box display="flex" flexDirection="column">
                <Divider />
                <Typography mt={2} variant="subtitle1" fontWeight="bold">
                  Ingrese su votación:
                </Typography>

                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="valor voto"
                      name="_voteValue"
                      value={values._value}
                      //defaultValue={voteData[0]?.value}
                      onChange={(event) => {
                        formVotacion.setFieldValue(
                          "_value",
                          event.target.value
                        );
                      }}
                    >
                      <FormControlLabel
                        key={0}
                        value={0}
                        control={<Radio />}
                        label="Si"
                      />
                      <FormControlLabel
                        key={1}
                        value={1}
                        control={<Radio />}
                        label="No"
                      />
                      <FormControlLabel
                        key={2}
                        value={2}
                        control={<Radio />}
                        label="Abstención"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box marginTop={2}>
                  <Stack direction="column" spacing={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      endIcon={<HowToVote />}
                      disabled={disableButton}
                      onClick={handleSubmit}
                    >
                      {!voteData ? "Votar" : "Guardar"}
                    </Button>
                    {voteData && (
                      <Button
                        disabled={disableButton}
                        onClick={() => handleDeleteVote(propuesta.code)}
                        variant="contained"
                        color="error"
                        size="small"
                        endIcon={<Delete />}
                      >
                        Eliminar voto
                      </Button>
                    )}
                    <CancelButton
                      label="Cerrar"
                      onClick={() => {
                        handleClose(true, null, false);
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
            ) : (
              <Box
                mt={2}
                sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
              >
                <CancelButton
                  label="Cerrar"
                  onClick={() => {
                    handleClose(true, null, false);
                  }}
                />
              </Box>
            )}

            <Box></Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
