import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Box, Button, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import * as Web3Manager from "../../support/Web3Manager";
import * as Common from "../../support/Common";
import * as Constants from "../../support/Constants";
import { DataGrid } from "@mui/x-data-grid";
import { RemoveCircle, Search } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { useNotification } from "../../hooks/useNotification";

const NotificacionesSocioPage = () => {
  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const [notificaciones, setNotificaciones] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEliminarNotificaciones = () => {
    // Cargamos las direcciones de todos los socios que tienen notificaciones
    const memberAddresses = [];

    notificaciones.forEach((notificacion) => {
      if (!memberAddresses.includes(notificacion.memberAddress)) {
        memberAddresses.push(notificacion.memberAddress);
      }
    });

    setLoading(true);
    Web3Manager.callContract(
      Web3Manager.CONTRACT_MEMBER_NOTIFICATIONS,
      "deleteNotifications",
      memberAddresses
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getNotificaciones();
          })
        );
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const getNotificaciones = () => {
    setLoading(true);
    Web3Manager.CONTRACT_MEMBER_NOTIFICATIONS.getNotifications()
      .then((response) => {
        const notificaciones = [];

        if (response && response.length > 0) {
          response.forEach((item) => {
            if (item.notifications && item.notifications.length > 0) {
              // Efectivamente tenemos una notificacion para esta direccion
              item.notifications.forEach((itemNotification) => {
                notificaciones.push({
                  memberAddress: item.memberAddress,
                  memberType: item.memberType,
                  memberName: item.memberName,
                  email: item.email,
                  code: itemNotification.code,
                  subject: itemNotification.subject,
                  text: itemNotification.text,
                });
              });
            }
          });
        }

        setNotificaciones(notificaciones);

        setLoading(false);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
        setLoading(false);
      });
  };

  const columnsNotificaciones = [
    {
      field: "memberAddress",
      headerName: "Dirección",
      flex: 0.7,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span className="csutable-cell-trucate">
            {params.value.substring(0, 6) +
              "..." +
              params.value.substring(
                params.value.length - 4,
                params.value.length
              )}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "memberName",
      headerName: "Nombre",
      flex: 0.8,
    },
    {
      field: "memberType",
      headerName: "Tipo",
      flex: 0.5,
      valueGetter: (params) => {
        if (params.value === Constants.MEMBER_TYPE_PERSONA) {
          return "Persona";
        } else if (params.value === Constants.MEMBER_TYPE_EMPRESA) {
          return "Empresa";
        } else {
          return "N/A";
        }
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    {
      field: "code",
      headerName: "Código",
      flex: 0.5,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 0.8,
    },
    {
      field: "text",
      headerName: "Texto",
      flex: 1,
    },
  ];

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward("/errorConnection");
      return;
    }

    getNotificaciones();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h3" color={"#005A59"} align="center" p={3}>
          Notificaciones de socios
        </Typography>
      </Box>

      <Grid container>
        <Grid item lg={4}>
          <Box sx={{ justifyContent: "left", display: "flex" }} pb={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEliminarNotificaciones()}
              startIcon={<RemoveCircle />}
            >
              Eliminar Todas
            </Button>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <Box sx={{ justifyContent: "right", display: "flex" }} pb={3}>
            <Button
              sx={{ marginLeft: 1 }}
              variant="contained"
              color="primary"
              onClick={() => getNotificaciones()}
              disabled={loading}
              endIcon={<Search />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
      </Grid>

      <DataGrid
        rows={notificaciones}
        pageSize={50}
        autoHeight={true}
        loading={loading}
        columns={columnsNotificaciones}
        getRowId={(row) => row.memberAddress + "_" + row.code.toNumber()}
        rowsPerPageOptions={[50]}
      />

      <br />
    </Container>
  );
};

export default withRouter(NotificacionesSocioPage);
