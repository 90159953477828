import React from "react";
import { Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
     this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container maxWidth="lg">
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h4" align="center" p={5}>
                Ha ocurrido un error procesando la solicitud
              </Typography>
            </Grid>
          </Grid>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
