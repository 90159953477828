import axios from "axios";

export const getCompanies = async (category, status, name) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/companies?category=${category}&status=${status}&name=${name}`,
    });

    return response.data.companies;
  } catch (error) {
    throw error;
  }
};

export const getCompany = async (address) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/companies/address/${address}`,
    });

    return response.data.company;
  } catch (error) {
    throw error;
  }
};

export const getCompanyByMemberId = async (memberId) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/companies/memberId/${memberId}`,
    });
    return response.data.company;
  } catch (error) {
    throw error;
  }
};

export const getCompanyById = async (id) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_BACKEND_URL}/companies/id/${id}`,
    });
    return response.data.company[0];
  } catch (error) {
    throw error;
  }
};
