import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Container from '@mui/material/Container';
import * as Web3Manager from '../../support/Web3Manager';
import * as Common from '../../support/Common';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, esES } from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import {
  Delete,
  DeleteForever,
  NotInterested,
  Restore,
  Search,
  ZoomIn,
} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { ModalServicioForm } from '../../components/servicios/ModalServicioForm';
import moment from 'moment';
import { useNotification } from '../../hooks/useNotification';
import ServiciosContext from '../../context/servicios/ServiciosContext';
import { ModalEliminarSerivio } from '../../components/servicios/ModalEliminarServicio';
import SpinnerContext from '../../context/spinner/SpinnerContext';
import { GridActionItem } from '../../components/ui/GridActionItem';
import { getApplicationTypes } from '../../services/ParametersServices';
import * as Constants from '../../support/Constants';
import CompromisosContext from '../../context/compromisos/CompromisosContext';
import { ServiciosTable } from '../../components/servicios/ServiciosTable';
import { ServiciosCard } from '../../components/servicios/ServiciosCard';

const ServiciosPage = ({ match }) => {
  const empresaId = match.params.empresaId;

  const { handleOpenNotificacionError, handleOpenNotificacionTx } =
    useNotification();

  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const [filtroNombre, setFiltroNombre] = useState('');
  const [tiposServicio, setTiposServicio] = useState([]);
  const [openModalServicio, setOpenModalServicio] = useState(false);
  const [modeEdit, setModeEdit] = useState(false);
  const [servicioSeleccionada, setServicioSeleccionada] = useState(null);
  const [isReadOnlyModalFormServicio, setIsReadOnlyModalFormServicio] =
    useState(false);
  const [openEliminarServicio, setOpenEliminarServicio] = useState(false);

  const { showSpinner, openSpinner, closeSpinner } = useContext(SpinnerContext);

  const { servicios, getServiciosEmpresa, isLoading } =
    useContext(ServiciosContext);

  const getTiposServicio = () => {
    getApplicationTypes()
      .then((response) => {
        setTiposServicio(response);
      })
      .catch((error) => {
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleAgregarServicio = () => {
    setServicioSeleccionada(null);
    setIsReadOnlyModalFormServicio(false);
    setOpenModalServicio(true);
  };

  const handleEditarServicio = (app) => {
    setServicioSeleccionada(app);
    setIsReadOnlyModalFormServicio(false);
    setOpenModalServicio(true);
  };

  const handleDetalleServicio = (app) => {
    setServicioSeleccionada(app);
    setIsReadOnlyModalFormServicio(true);
    setOpenModalServicio(true);
  };

  const handleCloseModalServicio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getServiciosEmpresa(empresaId);
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return;
      }
    }

    setOpenModalServicio(false);
    setServicioSeleccionada(null);
  };

  const handleHabilitarServicio = (app) => {
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_APPLICATIONS,
      "enableApplication",
      app.code
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getServiciosEmpresa(empresaId);
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleDeshabilitarServicio = (app) => {
    openSpinner();
    Web3Manager.callContract(
      Web3Manager.CONTRACT_APPLICATIONS,
      "disableApplication",
      app.code
    )
      .then((response) => {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(response, () => {
            getServiciosEmpresa(empresaId);
            closeSpinner();
          })
        );
      })
      .catch((error) => {
        closeSpinner();
        handleOpenNotificacionError(Web3Manager.processErrorResponse(error));
      });
  };

  const handleEliminarServicio = (servicioSelected) => {
    setOpenEliminarServicio(true);
    setServicioSeleccionada(servicioSelected);
  };

  const handleCloseEliminarServicio = (canceled, txResponse, isSuccess) => {
    if (!canceled) {
      if (isSuccess) {
        handleOpenNotificacionTx(
          Web3Manager.processSuccessResponse(txResponse, () => {
            getServiciosEmpresa(empresaId);
            closeSpinner();
          })
        );
      } else {
        closeSpinner();
        return; // No cerrar el modal
      }
    }

    setOpenEliminarServicio(false);
    setServicioSeleccionada(null);
  };

  useEffect(() => {
    if (!Web3Manager.isAccountConnected()) {
      Common.goForward('/errorConnection');
      return;
    }

    getTiposServicio();
    getCompromisosEnabled();

    // Recuperamos (o no) a la persona. Si ya esta registrada, se traen los datos y se cambia a modo edicion
    const memberData = Web3Manager.MEMBER_DATA;
    if (memberData) {
      // Verificamos el estado de la empresa. Para entrar a operar, debe estar approved
      if (Web3Manager.isAccountDisabled()) {
        Common.goForward('/errorSocioInhabilitado');
      }

      if (
        memberData.memberCode == empresaId &&
        memberData.memberType === Constants.MEMBER_TYPE_EMPRESA
      ) {
        //Se hace tolower porque llegan con distintas mayuscula y minuscula
        setModeEdit(true);
      }

      getServiciosEmpresa(empresaId);
    } else {
      // El socio no esta registrado, porque no encontramos nada asociado a esta cuenta
      Common.goForward('/errorSocioNoRegistrado');
    }
  }, []);

  const columnsServicios = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'applicationType',
      headerName: 'Tipo',
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'url',
      headerName: 'Url',
      flex: 1.5,
      minWidth: 250,
    },
    {
      field: 'creationDate',
      headerName: 'Fch. Registro',
      flex: 0.5,
      minWidth: 150,
      valueGetter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return 'N/A';
        }
      },
    },

    {
      field: 'action',
      headerName: 'Acciones',
      sortable: false,
      flex: modeEdit ? 1 : 0.5,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            <GridActionItem
              icon={<ZoomIn />}
              label='Detalle'
              onClick={() => handleDetalleServicio(params.row)}
              disabled={isLoading || showSpinner}
            />
            {modeEdit && (
              <>
                <GridActionItem
                  icon={<Edit />}
                  label='Editar'
                  onClick={() => handleEditarServicio(params.row)}
                  disabled={isLoading || showSpinner}
                />

                {params.row.enabled ? (
                  <GridActionItem
                    icon={<NotInterested />}
                    label='Deshabilitar'
                    onClick={() => handleDeshabilitarServicio(params.row)}
                    disabled={isLoading || showSpinner}
                  />
                ) : (
                  <GridActionItem
                    icon={<Restore />}
                    label='Habilitar'
                    onClick={() => handleHabilitarServicio(params.row)}
                    disabled={isLoading || showSpinner}
                  />
                )}
                <GridActionItem
                  icon={<Delete />}
                  label='Eliminar'
                  onClick={() => handleEliminarServicio(params.row)}
                  disabled={isLoading || showSpinner}
                />
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  if (modeEdit) {
    columnsServicios.splice(4, 0, {
      field: 'enabled',
      headerName: 'Habilitado?',
      flex: 0.5,
      valueGetter: (params) => {
        return params.value ? 'SI' : 'NO';
      },
    });
  }
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth='xl'>
      <Box>
        <Typography
          variant='h3'
          color={'#005A59'}
          align='center'
          p={3}
          sx={{
            fontSize: {
              lg: '48px',
              sm: '36px',
              xs: '30px',
            },
          }}
        >
          Aplicaciones de empresa
        </Typography>
      </Box>

      <Grid container>
        <Grid item lg={4}>
          <Box sx={{ justifyContent: 'left', display: 'flex' }}>
            {modeEdit && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleAgregarServicio()}
                startIcon={<AddCircleIcon />}
                disabled={showSpinner || isLoading}
              >
                Agregar
              </Button>
            )}
          </Box>
        </Grid>

        {matches ? (
          <ServiciosCard
            modeEdit={modeEdit}
            isLoading={isLoading}
            handleDetalleServicio={handleDetalleServicio}
            showSpinner={showSpinner}
            handleEditarServicio={handleEditarServicio}
            handleDeshabilitarServicio={handleDeshabilitarServicio}
            handleHabilitarServicio={handleHabilitarServicio}
            handleEliminarServicio={handleEliminarServicio}
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            getServiciosEmpresa={getServiciosEmpresa}
            empresaId={empresaId}
            servicios={servicios}
            openModalServicio={openModalServicio}
            handleCloseModalServicio={handleCloseModalServicio}
            servicioSeleccionada={servicioSeleccionada}
            tiposServicio={tiposServicio}
            isReadOnlyModalFormServicio={isReadOnlyModalFormServicio}
            compromisos={compromisos}
            openEliminarServicio={openEliminarServicio}
            handleCloseEliminarServicio={handleCloseEliminarServicio}
          />
        ) : (
          <ServiciosTable
            modeEdit={modeEdit}
            isLoading={isLoading}
            handleDetalleServicio={handleDetalleServicio}
            showSpinner={showSpinner}
            handleEditarServicio={handleEditarServicio}
            handleDeshabilitarServicio={handleDeshabilitarServicio}
            handleHabilitarServicio={handleHabilitarServicio}
            handleEliminarServicio={handleEliminarServicio}
            filtroNombre={filtroNombre}
            setFiltroNombre={setFiltroNombre}
            getServiciosEmpresa={getServiciosEmpresa}
            empresaId={empresaId}
            servicios={servicios}
            openModalServicio={openModalServicio}
            handleCloseModalServicio={handleCloseModalServicio}
            servicioSeleccionada={servicioSeleccionada}
            tiposServicio={tiposServicio}
            isReadOnlyModalFormServicio={isReadOnlyModalFormServicio}
            compromisos={compromisos}
            openEliminarServicio={openEliminarServicio}
            handleCloseEliminarServicio={handleCloseEliminarServicio}
          />
        )}
      </Grid>
    </Container>
  );
};

export default withRouter(ServiciosPage);
