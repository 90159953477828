import React, { useContext, useState } from "react";
import RoutesConfig from "./routing/RoutesConfig";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import "./index.css";
import SociosState from "./context/socios/SociosState";
import { CustomToastContainer } from "./components/ui/toast";
import SpinnerModal from "./components/ui/SpinnerModal";
import SpinnerContext from "./context/spinner/SpinnerContext";
import keycloakClient from "./keycloak.js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "react-quill/dist/quill.snow.css"; //Hacemos general el estilo de react-quill (editor de texto)

const Component = () => {
  const { showSpinner } = useContext(SpinnerContext);
  const [member, setMember] = useState(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <ReactKeycloakProvider
        authClient={keycloakClient}
        initOptions={{
          checkLoginIframe: false,
        }}
        LoadingComponent={<>Loading...</>}
      >
        <CustomToastContainer />

        <SociosState>
          <Header member={member} setMember={setMember} />
        </SociosState>

        <div style={{ flex: "1 1" }}>
          <RoutesConfig member={member} />
        </div>

        {showSpinner && <SpinnerModal open={showSpinner} />}

        <Footer />
      </ReactKeycloakProvider>
    </div>
  );
};

export default Component;
