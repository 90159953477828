import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";

import React, { useContext, useEffect, useState } from "react";
import * as Constants from "../../support/Constants";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { ModalTexto } from "../utiles/ModalTexto";
import { CancelButton } from "../ui/CustomButtons";
import { SeccionCompromisosAdheridos } from "../compromisos/SeccionCompromisosAdheridos";
import CompromisosContext from "../../context/compromisos/CompromisosContext";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { GridActionItem } from "../ui/GridActionItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "70%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export const ModalDetallePersonaForm = ({ open, handleClose, persona }) => {
  const [openModalTexto, setOpenModalTexto] = useState(false);
  const [texto, setTexto] = useState("");

  const [compromisosSeleccionados, setCompromisosSeleccionados] = useState([]);
  const [assignedCommitments, setAssignedCommitments] = useState([]);

  const {
    compromisos,
    getCompromisosEnabled,
    isLoading: loadingCompromisos,
  } = useContext(CompromisosContext);

  const handleOpenModalTexto = (texto) => {
    setOpenModalTexto(true);
    setTexto(texto);
  };

  const handleCloseModalTexto = () => {
    setOpenModalTexto(false);
    setTexto("");
  };

  const [valueTab, setValueTab] = useState("1");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    if (persona && persona.code) {
      getCompromisosEnabled();
      setValueTab("1");
    }
  }, [persona]);

  useEffect(() => {
    if (persona) {
      const compromisosSeleccionadosList = compromisos?.filter((compromiso) => {
        // Verificar si el código del compromiso está presente en la lista de commitmentCode
        return persona.commitments?.some(
          (commitment) => commitment.commitmentCode == compromiso.code
        );
      });

      const newListWithDates = persona.commitments?.map((item) => {
        if (item.deadlineDate) {
          return {
            ...item, // Copiamos todos los campos del objeto original
            deadlineDate: moment(item.deadlineDate).format("YYYY-MM-DD"), // Convertimos el campo a unix
          };
        } else {
          return item;
        }
      });

      setAssignedCommitments(newListWithDates ? newListWithDates : []);
      setCompromisosSeleccionados(compromisosSeleccionadosList);
    }
  }, [persona, compromisos]);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(true, null, false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {"Detalle de persona"}
        </Typography>
        <br />

        <Grid container spacing={4}>
          <Grid item lg={5}>
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={persona?.memberAddress}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={persona?.firstName}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Apellido"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={persona?.lastName}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Correo Electrónico"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={persona?.email}
              />
            </Box>

            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Categoría"
                variant="outlined"
                size="small"
                fullWidth={true}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={Constants.getNombreCategoria(persona?.category)}
              />
            </Box>

            <Box mt={2} sx={{ justifyContent: "left", display: "flex" }}>
              <TextField
                id="outlined-basic"
                label="Estado"
                variant="outlined"
                fullWidth={true}
                size="small"
                disabled={true}
                value={Constants.getNombreEstado(persona?.status)}
              />
              {persona?.statusMessage != null &&
                persona?.statusMessage !== "" && (
                  <Box ml={0.5}>
                    <GridActionItem
                      icon={<Search />}
                      label="Ver mensaje estado"
                      onClick={() =>
                        handleOpenModalTexto(persona?.statusMessage)
                      }
                    />
                  </Box>
                )}
              <TextField
                sx={{ marginLeft: 1 }}
                id="outlined-basic"
                label="Fecha de Registro"
                variant="outlined"
                fullWidth={true}
                size="small"
                disabled={true}
                InputLabelProps={{ shrink: true }}
                value={moment(persona?.createdAt).format("DD-MM-YYYY")}
              />
            </Box>

            {persona?.roles && persona?.roles.length > 0 && (
              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  label="Roles Asignados"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  disabled={true}
                  value={persona?.roles
                    .map((roleCode) => Constants.getNombreRol(roleCode))
                    .filter((nombreRol) => nombreRol)
                    .join(", ")}
                />
              </Box>
            )}
          </Grid>

          <Grid item lg={7} sx={{ position: "relative" }}>
            <Grid container spacing={4}>
              <Grid item lg={12}>
                <TabContext value={valueTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Compromisos adheridos" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {loadingCompromisos ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <SeccionCompromisosAdheridos
                        assignedCommitments={assignedCommitments}
                        setAssignedCommitments={setAssignedCommitments}
                        compromisosSeleccionados={compromisosSeleccionados}
                        setCompromisosSeleccionados={
                          setCompromisosSeleccionados
                        }
                        isReadOnly={true}
                        mostrarTitulo={false}
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ModalTexto
          open={openModalTexto}
          handleClose={handleCloseModalTexto}
          titulo={"Detalle del estado"}
          texto={texto}
        />

        <Box
          mt={2}
          sx={{ justifyContent: "left", display: "flex", gap: "10px" }}
        >
          <CancelButton
            label="Cerrar"
            onClick={() => {
              handleClose(true, null, false);
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};
